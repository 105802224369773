@import '../../../theme/variables.scss';
.topnavigation__mobile {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  @media (min-width: 1025px){
    display: none;
  }
  .topnavigation__mobile__left {

  }
  .topnavigation__mobile__right {
    margin-left: auto;
    .topnavigation__mobile__inner {
      .btn__navbar,
      .btn__search {
          background-color: transparent;
      }
      .btn__search {

      }
      .btn__navbar {

      }
    }
  }
}

.modal-search-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1043;
  height: 60px;
  border-bottom: 1px solid #E5E5E5;
  transition: all 0.2s ease;
  transform: translateY(-80px);
  &.open {
    transform: translateY(0);
  }
  .modal-search-box-input {
    width: calc(100% - 60px);
    font-family: $Pangram;
    font-size: 16px;
    line-height: 48px;
    height: 59px;
    color: $primaryColor !important;
    border-color: transparent !important;
    &:focus,
    &:active {
      border-color: transparent !important;
    }
    &::placeholder {
      color: $primaryColor !important;
    }
  }
  .modal-search-box-button {
    min-width: 60px;
    min-height: 59px;
    background-color: transparent;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &.open .search-wrapper-open-container {
    display: block;
  }
  .search-wrapper-open-container {
    display: none;
    border-radius: 0;
    border: 0px solid #e5e5e5;
    box-shadow: none;
  }
}

.mob__nav__list {
  > li {
    font-family: $Pangram;
    font-size: 16px;
    display: block;
    padding: 7px 0;
    font-weight: 600;
    &.active {
      color: #c7ce4a;
    }
  }
}
