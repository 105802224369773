@import './../../../../theme/variables.scss';
.recording__links--container{
  border: 1px solid #ddd;
  border-top: 0;
  .recording__links--list{
      list-style: none;
      padding: 0;
      margin: 0;
      >li{
        padding:15px 45px;
        display: block;
        border-bottom: 1px solid #ddd;
        &:last-child{
          border-bottom: 0px solid #CED0DA;
        }
        > a{
          font-size: 15px;
          font-weight: 600;
          color: #C6CE4A;
          letter-spacing: 0;
        }
      }
  }
}
