.height-issue{
    overflow-x: initial !important;
        margin-bottom: 65px !important;
}
.height-issue .css-1pcexqc-container, .height-issue .css-1qx6hiz{
    box-shadow:none !important;
}

.height-issue .multiselect__checkboxes__field button[class*="css-"]+div>div{
overflow-y: auto;
    max-height: 120px;
}
.dropdown-z-index .material-textfield.form-group{
     z-index: 9;
 }

