@import './../../../../theme/variables.scss';
.posirel {
  position: relative;
  .react-datepicker-wrapper {
  left: 25px;
}
}

.overDueRed {
  @media #{$phone767}{
    text-align: right !important;
  }
}
.table__td--desktop {
  @media #{$phone767}{
    display: none;
  }
}
.table__td--mobile {
  display: none;
  @media #{$phone767}{
    display: block;
  }
}
.showname__mob {
  display: none;
  @media #{$phone767}{
    display: block;
    font-size: 14px;
    color: $primaryColor;
  }
}
.showschedule__mob {
  display: none;
  @media #{$phone767}{
    display: block;
    font-size: 11px;
    color: #73777A;
    margin-top: 5px;
  }
}
.flexingmob {
  @media #{$phone767}{
    float: right;
  }
  .btn__edit {
    @media #{$phone767}{
      margin: 0 0 0 9px;
    }
  }
}
.table__responsive--mob {
  @media #{$phone767}{
    overflow-x: hidden;
  }
}
.useslist__extra {
  @media #{$tablet}{
    width: 100% !important;
  }
  @media #{$phone767}{
    margin: 0 -15px;
    width: calc(100% + 30px) !important;
  }
}
.margin__btm--20 {
  @media #{$phone767}{
    margin-bottom: 15px;
  }
}
.flexing {
  display: flex;
}
.clients__section{
  .uses-list {
    @media #{$tablet}{
      display: block;
      float: left;
      width: 100%;
    }
  }
}
.flex__relative {
	position: relative;
	.react-datepicker-wrapper {
		position: absolute;
	    top: -10px !important;
	    width: 110px!important;;
	    left: -7px!important;;
	}
}

.datepicker__newcalendar {
  .react-calendar__navigation {
    background: #c3cf23;
    button {
      color: #fff !important;
    }
    .react-calendar__navigation__label {
      font-weight: 600;
      font-size: 14px;
        background: #fff;
        color: $primaryColor !important;
        border-bottom: 1px solid #ececec;
        &:hover, &:focus {
        background: #fff;
          color: $primaryColor !important;
          border-bottom: 1px solid #ececec;
      }
    }
  }
  .react-calendar__viewContainer {
    abbr {
      cursor: pointer !important;
    }
  }
  .react-calendar {
    width: 100%;
      border: 1px solid #ececec;
    button {
      color: #c3cf23;
      &:hover, &:focus {
        background: #c3cf23;
        color: #fff;
      }
    }
    button[disabled] {
      color: #ccc !important;
      &:hover, &:focus {
        background: #f0f0f0;
        color: #fff;
        cursor: not-allowed;
      }
    }
  }
  .react-calendar__tile--active {
      background: #c3cf23 !important;
      color: #fff !important;
  }
  .label__calendar{
    font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1.46154px;
      text-transform: uppercase;
      color: #9B9B9B;
      margin-bottom: 15px;
      font-family: 'Poppins';
  }
  .react-calendar__tile--hasActive {
    background: #c3cf23;
      color: #fff !important;
      &:hover {
        background: #c3cf23;
      color: #fff !important;
      }
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
  }
}
.header__datepicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  h4 {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
  .calendar__close {
    img {
      width: 32px;
      cursor: pointer;
    }
  }
}
.sub__btn--date {
  background: #C3CF21;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.75385px;
    text-transform: capitalize;
    color: #FFFFFF;
    width: 100%;
    margin: 25px 0 0 0;
    padding: 14px 20px;
    border: 0;
    border-radius: 0;
}
.pagination-container.pagination-container-wrapper{
   a{
     position: inherit !important;
   }
 }


