@import './../../theme/variables.scss';
.loader__agent {
    background: $primaryColor;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
}
.loader__agent_z_index {
  z-index: 99999;
}

.desktopFilter-agent {
  width: 841px;
  margin: 0 auto 25px !important;
}
.tab-content.loader-container {
    margin: 0 auto;
    margin-left: 50%;
    margin-top: 5%;
}

.agent-product-dtl-tab {

  .nav-tabs {
    border-bottom: 0px solid #ddd;
    margin: 0 auto;
    display: table;
    li {
        float: left;
        margin-bottom: 25px;
        a {
          color: #73777A;
          font-family: 'Poppins' !important;
          font-size: 16px;
          font-weight: 700;
          &:hover {
            background: none !important;
            border-color: #fff;
          }
        }
    }
  }
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  border-bottom: 2px solid #C7CE4A !important;
  border: 0;
  color: #C7CE4A;
  
}
.fill_top{
   background: #C7CE4A;
   width: 100%;
   float: left;
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   padding: 25px 25px;
   color: #FFF;
   z-index: 999;
   text-align: center;
}
.strategist_box--wrapper {
    width: 100%;
    float: left;
}
  .strategist_box{
  width: 50%;
  float: left;
}
.str_button{
    width: 50%;
    float: left;
    text-align: right;
  }
.strategist-chat {
  .right-btn {
    float: right;
  }
  
}

.agentSearchBox{
  max-width: 842px;
  margin: 0 auto !important;  
  .costom-search{
    height: 60px;
    padding-left: 60px !important;
    border-bottom:none;
    box-shadow: 0px 1px 4px 1px #cccc;
    font-family: 'Poppins' !important;
    &::placeholder{
     color: #3B3B3B;
    }
  }

}

.agent__page--container{
  padding-top: 84px;
  .b__left{
    border-left: 1px solid #e5e5e5;
    @media #{$tablet}{
      border-left: 0px solid #e5e5e5;
    }
    @media #{$phone}{
      border-left: 0px solid #e5e5e5;
    }
  }
  .agent__page--head{
    max-width: 672px;
    margin: 49px auto;
    text-align: center;
    h1{
      font-size: 21px;
      font-weight: 600;
      color: #73777A;
      margin: 0 0 14px;
    }
    p{
      font-size: 15px;
      color: #73777A;
      text-align: center;
    }
  }
  .status-label {
    font-size: 10px;
    color: #C7CE4A;
    }


  .agent__page--list{
    .agent__page--list-view{
      > li{
        background: #FFFFFF;
        box-shadow: 0 0 6px 5px #EEEEEE;
        border-radius: 4px;
        margin: 0 auto 40px;
        max-width: 841px;

      }
    }

    .agent__page--list__info{


      .agent__page--list__info-header{
        padding: 22px 35px;
        display:-webkit-flex;
        display:flex;
        -webkit-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-justify-content:space-between;
        justify-content:space-between;
        -webkit-align-items:center;
        align-items:center;
        -webkit-align-content:center;
        align-content:center;
        @media #{$phone6,$phone6Plus}{
          padding: 35px 15px;
        }
        .agent__page--list--avtar-title{
          font-size: 16px;
          line-height: 26px;
          color: #3B3B3B;
          font-weight: 700;
          margin: 0;
        }
        .agent__page--list--avtar-profile{
           font-size: 13px;
           line-height: 21px;
          color: #73777A;
          text-align: left;
          margin: 0;
        }
        .agent__page--list__info-header--action{

          .custom-select{
            display: inline-block;
            max-width: max-content;
            height: 48px;
            min-width: 152px;
            border: 0;
            font-size: 15px;
            color: #73777A;
          }
          .btn{
            height: 48px;
            padding: 15px 6px;
            min-width: 182px;
            margin-left: 20px;
          }
        }
      }
      .agent__page--list__info-body{
        padding: 35px;
        @media #{$phone6,$phone6Plus}{
          padding: 35px 15px;
        }
      }

    }

    .agent__page--list-items{
      margin: 0 0 14px;
      .price__size{
        font-size: 31px;
        line-height: 40px;
        color: #C6CE4A;
        @media #{$phone6}{
          font-size: 24px;
        }
      }
      h2{
        font-size: 15px;
        font-weight: 600;
        color: #73777A;
        margin: 0 0 7px;
        line-height: 25px;
      }
      h4{
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        color: #787878;
        text-align: left;

        a{
          color: #C6CE4A;
          text-decoration: underline;
        }
      }
    }

    .agent__page--list__contact--info{
      border-top: 1px solid #E5E5E5;
      padding: 22px 35px;
      @media #{$phone6,$phone6Plus}{
        padding: 35px 15px;
      }
      .list__contact{
        h2{
          font-size: 13px;
          font-weight: 600;
          color: #73777A;
          text-align: left;
          margin: 0 0 7px;
        }
        h4{
          font-size: 15px;
          color: #73777A;
          font-weight: 400;
          a{
            color: #C6CE4A;
            text-decoration: underline;
          }
        }
      }
    }


  }

  .red-appointment {
    color: #D0021B;
    text-decoration: underline;
    cursor: pointer;
  }

}
