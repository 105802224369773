.posi__sticky {
  position: sticky !important;
  top: 15px;
}
.pane--table2 table {
  border-collapse: collapse;
  background: white;
  table-layout: fixed;
  width: 100%;
  margin-top: 5px;
  float: left;
}
.pane--table2 th, .pane--table2 td {
  padding: 8px 14px !important;
  width: 250px !important;
  white-space: unset !important;
  word-break: break-word !important;
  border: 0 !important;
}
.maxwd {
  width: 250px !important;
}
.pane--table2 {
  width: 100%;
  // overflow-x: scroll;
}
.mar__top--zero {
  margin-top: 0 !important;
}
.pane--table2 tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
  height: 52vh;
  font-weight: 400;
  color: #73777A;
    font-size: 14px;
}
.pane--table2 thead {
  display: block;
  width: calc(100% - 17px);
  border-top: 1px solid #ddd;
  color: #73777A;
    font-size: 14px;
    font-weight: 600;
    
}
.pane--table2 thead th {
  vertical-align: middle !important;
  white-space: pre-line !important;
  word-break: break-word;
}
.loader__spacing {
  text-align: center;
    padding: 50px;
}


.jungler__page--padding .jungler__list .table > thead > tr > th {
  padding: 8px 15px !important;
}
.information-icon-button {
  width: 20px;
  height: 20px;
  border: none;
  top: 0px;
  outline: none !important;
  cursor: pointer;
  margin-left: 10px;
  background-size: 20px;
  position: inherit;
}
.multilines {
	white-space : unset !important;
	vertical-align: middle !important;
}

.defaultCheckboxContainer {
  display: inline-block;
    width: auto;
    margin: 0 30px 0 0;
     input{
      margin: 0 15px 0 0;
     }

}
.filterWrapOvveride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
      // opacity: 0.50;
    }
  }
}

.filterHideOverride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
      opacity: 0.50;
    }
  }
}
.defaultChecboxCustom {

  width: 100%;
  vertical-align: middle;

    input {
     margin-right: 15px;
     vertical-align: middle;
    }
  span {
    vertical-align: middle;

  }

}

.custom_th{
  padding: 5px 17px !important;
}