@import '../../theme/variables.scss';

.mobile-sidebar-resize {
  width: 275px;
  padding: 20px 20px;
}

.sidebar-layout-sider {
  position: fixed;
  background: #F5F5F5;
  transition: all 0.2s;
  max-width: 282px;
  min-width: 282px;
  width: 282px;
  left: 0;
  top: 0;
  height: 100%;
  overflow: auto;
  z-index: 100;
  transform: translateX(0);
  transition: all 0.2s ease;
  @media (max-width: 1025px){
    transform: translateX(-100%);
    opacity: 0;
  }
  &.open {
    transform: translateX(0);
    opacity: 1;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.30);
  }
  .sidebar-layout-sider__children {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
  }
  .sidebar-layout-sider__logo {
    padding: 24px 15px;
    text-align: center;
    img{
      width: 100%;
      height: auto;
    }
  }
  .sidebar-layout__menu{
    &__dark {
      .sidebar-item {
        &-is-selected {
          .sidebar-item__link {
            color: $primaryColor;
          }
        }
        &__link {
          font-family: $Pangram;
          display: flex;
          align-items: center;
          padding: 13px 18px;
          font-weight: 500;
          font-size: 16px;
          color: #9B9B9B;
          display: inline-block;
          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
        &__icon {
          width: 25px;
          height: 25px;
          margin-right: 20px;
          display: inline-block;
          text-align: center;
          @media (max-width: 767px){
            display: none;
          }
        }
      }
    }
  }
}
