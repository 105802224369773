@import './../../../theme/variables.scss';
.complete__total {
    color: $primaryColor;
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    display: inline-block;
}
.only__for-index{
    font-size: 14px !important;
    font-weight: bold !important;
}
.clients__received--section{
    display: flex;
    justify-content: space-between;
    .text__label--sm{
        font-weight: 600;
    }    
    .stragist-span{
        max-width: 270px;
        @media #{$phone}{
            width: 100% !important;
            max-width: 100%;
         }
        .custom-select{
            width: 100% !important;        
        }
        .selectinputs__global {
            @media #{$phone}{
                width: 100% !important;
                max-width: 100%;
             } 
        }
    }
    select.custom-select{
        background-position: 93%;
    }
}

.clients__received-center{
    font-size: 21px !important;
    margin-top: 70px !important;
    background: transparent !important;
    font-weight: bold;
}
