.chats__section__cta {
    .text-message-max-length-error {
        color: #D51236;
    }
}

.chats__container {
    .delivered {
        color: #28a745
    }
    .undelivered {
        color: #D51236;
    }
}
