@import './../../../../theme/variables.scss';
.notes-right.new__common--btn {
	right: 10px;
}
.addnotes_bg {
	.notes-right {
	@media #{$phone}{
		left: 15px;
    width: calc(100% - 30px);
    padding: 14px 0;
	}
}
}
.clients__Messages--container{
 .chat__container {
 	.addnotes_bg {
 		position: absolute;
	    right: 15px;
	    top: 0;
	    background:transparent;
	    width: 100%;
	    text-align: right;
	    padding: 10px 0;
	    z-index: 9;
 	}
  }
}
.mobileEdit {
	@media #{$phone}{
		font-size: 14px !important;
		padding: 18px 0 !important;
	}
}
.schedule__sidebar--header {
	@media #{$phone}{
		text-align: left !important;
    font-size: 18px !important;
	}
	.close-btn {
		@media #{$phone}{
			right: 20px !important;
		}
	}
}
.schedule__sidebar--bodymob {
	@media #{$phone}{
		height: calc(100vh - 160px) !important;
	}
}
