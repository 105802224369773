@import './../../../theme/variables.scss';

.custom__calendar--wrapper {
  width: 576px;
  @media #{$phone}{
    max-width: 70%;
    margin-left: 15%;
    }
  .modal-body {
    padding: 10px 0px 20px;
  }
  .is-inRange {
    background: #dcdcdc !important;
  }
  .is-selected {
    background: #C7CE4A !important;
  }
}

.selouter__ext {
  top: 82px !important;
}
.cust__hover {
    border: 2px solid #C7CE4A !important;
    outline: 0;
}
  .datepicker__fix--wrapper {
    display: inline-block;
    margin-bottom: 10px;
    @media #{$phone}{
      width: 100%;
      }
    .date__picker {
      height: 55px;
      width: 200px;
      cursor: pointer;
      padding: 0 20px 0 60px;
      margin-right: 10px;
      font-size: 14px;
      color: $primaryColor;
      text-align: left;
      font-weight: 600;
      font-family: 'Poppins';
      border: 2px solid #e5e5e5;
      border-radius: 4px;
      background-color: #fff;
      background-image: url('/img/calendar-icon.svg'), url('/img/custom-select-arrow.svg');
      background-position: 10px center, calc(100% - 15px) center; 
      background-size: 28px, 14px;
      background-repeat: no-repeat;
      appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      &:focus {
        border: 2px solid #C7CE4A !important;
        outline: 0;
      }
      option {
        font-weight: 600;
      font-family: 'Poppins';
      }
      @media #{$phone}{
        width: 100% !important;
        }
    }
  }

.all__reporting--headers {
  background: #f7f8f6;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 25px;
  @media #{$phone}{
    padding: 24px 15px;
  }
  .logo__header {
    font-weight: 600;
    font-size: 23px;
    line-height: 38px;
    width: calc(100% - 40px);
    @media #{$phone}{
      font-size: 18px;
      line-height: 33px;
    }
    a {
      color: #72777A;
    }
  }
  .close__btn {
    width: 40px;
    text-align: right;
    img {
      width: 23px;
    }
  }
}

.reports__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-bottom: 40px;
  .dp-wrapper {
    width: 300px;
    display: inline-block;
    margin: 0 10px 0 0;
    @media #{$phone}{
      width: 100%;
      margin: 0 0 0 0;
     }

    .custom-select {
      margin: 0 0 0 0 !important;
      @media #{$phone}{
        width: 100% !important;
        margin-bottom: 10px !important;
       }
    }
    label {
    	color: rgba(0, 0, 0, 0.54);
    	float: left;
    }
    .date__picker {
      height: 55px;
      padding-left: 60px !important;
      font-size: 14px;
      background-color: #fff;
      background: url('/img/calendar-icon.svg') no-repeat !important;
      background-position: 6% 8px !important;
      text-align: left;
      font-weight: 600;
      font-family: 'Poppins' !important;
      background-size: 32px !important;
      border: 2px solid #e5e5e5 !important;
    }
    .sel-outer {
      width: 225px;
      max-height: 200px;
      overflow-y: auto;
      box-shadow: 2px 5px 10px #c5c5c5;
      position: absolute;
      background: #fff;
      z-index: 99;
      top: 60px;
    }
  }



  .all__custom--wrapper {
    @media #{$phone}{
      width: 100%;
      }
    .date__picker {
      height: 55px;
      width: 200px;
      cursor: pointer;
      padding: 0 20px 0 60px;
      margin-right: 10px;
      font-size: 14px;
      color: $primaryColor;
      text-align: left;
      font-weight: 600;
      font-family: 'Poppins';
      border: 2px solid #e5e5e5;
      border-radius: 4px;
      background-image: url('/img/calendar-icon.svg'), url('/img/custom-select-arrow.svg');
      background-position: 10px center, calc(100% - 15px) center; 
      background-size: 28px, 14px;
      background-repeat: no-repeat;
      background-color: #fff;
      appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      &:focus {
        border: 2px solid #C7CE4A !important;
        outline: 0;
      }
      option {
        font-weight: 600;
      font-family: 'Poppins';
      }
      @media #{$phone}{
       width: 100%;
       }
    }
  }
  .right__cont {
    text-align: right;
    width: calc(100% - 310px);
    display: inline-block;
    @media #{$desktop1023}{
      width: 100%;
      margin-top: 15px;
      }
    @media #{$phone}{
      width: 100%;
      margin-top: 0;
      }
  }
  .all__custom--wrapper {
    display: inline-block;
    margin-bottom: 10px;
  }
}

.right__cont--full {
  width: 100% !important;
  text-align: right;
}

.remove__padd--lr {
  @media #{$tablet}{
   padding-left: 15px !important;
   padding-right: 15px !important;
  }
  @media #{$phone}{
   padding-left: 15px !important;
   padding-right: 15px !important;
  } 
}

.datepicker__fix {
  position: relative;
  .click__btn {
    width: 300px !important;
    display: flex;
    align-items: center;
    @media #{$phone}{
      width: 100% !important;
      }
  }
  .rdr-DateRange {
    position: relative;
    z-index: 9;
    width: 564px;
    left: auto;
    right: 100px;
    @media #{$tablet}{
      right: 250px;
    }
    @media #{$phone}{
      width: auto;
      left: auto;
      right: auto;
    } 
    .rdr-Calendar {
      background: #e5e5e5 !important;
      margin: 1px;
      .is-selected {
        background: #C7CE4A !important;
      }
    }
  }
  .sub__btn {
    position: absolute;
    z-index: 9;
    background: #c7ce4a;
    width: 150px;
    height: 46px;
    bottom: -46px;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 33%;
    border: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
  }
}

.reporting__export--btn {
  font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background: #C7CE4A;
    border-radius: 4px;
    padding: 17px 30px;
    border: 0;
    outline: none;
    
}
.exporthide {
  @media #{$tablet768}{
    display: none;
   }
}

.no__display {
  display: none;
}
.marg__rt--ten {
  margin-right: 10px !important;
  .custom-select {
    opacity: 0.50;
  }
}
.cust__sel--filter {
  height: 55px !important;
    line-height: 55px !important;
    top: 0;
    width: auto !important;
}
.reporting__btn__new {
	font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background: #C7CE4A;
    border-radius: 4px;
    min-width: 128px;
    padding: 17px 30px;
    margin-left: 6px;
    float: right;
    margin-top: 23px;
    border: 0;
    outline: none;
}

.defaultCheckboxContainer {
  display: inline-block;
    width: auto;
    margin: 0 30px 0 0;
     input{
      margin: 0 15px 0 0;
     }

}
.defaultChecboxCustom {

  width: 100%;
  vertical-align: middle;

    input {
     margin-right: 15px;
     vertical-align: middle;
    }
  span {
    vertical-align: middle;

  }

}

.filterWrapOvveride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
    }
  }
}

.filterHideOverride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
      opacity: 0.50;
    }
  }
}


.clients__received--section{
  display: flex;
  justify-content: space-between;
  .text__label--sm{
      font-weight: 600;
  }    
  .stragist-span{
      max-width: 270px;
      @media #{$phone}{
          width: 100% !important;
          max-width: 100%;
       }
      .custom-select{
          width: 100% !important;        
      }
      .selectinputs__global {
          @media #{$phone}{
              width: 100% !important;
              max-width: 100%;
           } 
      }
  }
  select.custom-select{
      background-position: 93%;
  }
}

.clients__received-center{
  font-size: 21px !important;
  margin-top: 70px !important;
  background: transparent !important;
  font-weight: bold;
}
