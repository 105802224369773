@import './../../../theme/variables.scss';
.analytics__page--container{
  padding-top: 84px;
  padding-bottom: 20px;
  .analytics__page--header{
    background: #f7f7f5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 84px;
    z-index: 1043;
    padding:0 15px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-justify-content:space-between;
    justify-content:space-between;
    -webkit-align-items:center;
    align-items:center;
    -webkit-align-content:center;
    align-content:center;

    .analytics__page--header-title{
      font-size: 23px;
      font-weight: 600;
      color: #73777A;
    }
    .custom-select{
      display: inline-block;
      max-width: 140px;
      margin-right: auto;
      margin-left: 70px;
      background-color: transparent;
      border: 0;
      font-size: 15px;
      color: #73777A;
      text-align: left;
      font-family: 'Poppins' !important;
    }
    .analytics__page--close{
      margin-left: 115px;
    }
  }

  .analytics__heading{
    max-width: 412px;
    h1{
      font-size: 31px;
      font-weight: 600;
      color: #73777A;
      margin: 44px 0 10px;
    }
    p{
      font-size: 15px;
      color: #73777A;
      margin: 0 0 26px;
    }
  }
  .analytics__count{
    font-size: 72px;
    font-weight: 600;
    color: #C6CE4A;
  }
  .analytics__action{
    text-align: right;
    .custom-select{
      min-width: 180px;
      width: auto;
      display: inline-block;
      border: 1px solid #CED0DA;
      height: 56px;
      margin-left: 41px;
      font-family: 'Poppins' !important;
    }
  }
  .analytics__graph{
    height: 499px;
  }
  .analytics__last--updated{
    font-size: 17px;
    color: #C6CE4A;
    text-align: center;
    margin-left: auto;
  }
  .analytics__export--btn{
    text-align: right;
    margin-left: auto;
  }
}
