@import './../../theme/variables.scss';
.clients__page--container{
  background: #f4f4f4;
  padding-top: 84px;
  .clients__page--head{
    max-width: 672px;
    margin: 75px auto 0;
    text-align: center;
    .clients__page--username{
      color: #c7ce4b;
      text-transform: capitalize;
    }
    h1{
      font-size: 21px;
      font-weight: 600;
      color: #73777A;
      margin: 0 0 14px;
    }
    p{
      font-size: 15px;
      color: #73777A;
      text-align: center;
    }
  }

  .ShowMoreToggle {
    float: right;
    text-align: right;
    color: $secondaryColor;
    font-weight: 600;
    text-decoration: underline;
    font-size: 13px;
    padding-left: 30px;
  }
  .clients__page--profile-wrapper{
    background: #FFFFFF;
    border: 2px solid #F1F2F2;
    position: relative;
    max-width: 402px;
    margin: 65px auto 39px;
    padding: 55px 31px 31px;

    .clients__page--profile-info{
      text-align: center;
      margin: 0 0 36px;
      .clients__page--profile--avtar{
        width: 125px;
        height: 125px;
        border-radius: 100px;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: -65px;
        transform: translateX(-50%);
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .profile__title{
        font-size: 14px;
        font-weight: 600;
        color: #9B9B9B;
        margin: 28px 0 10px;
      }
      .profile__email{
        font-size: 14px;
        font-weight: 600;
        color: #C6CE4A;
        margin: 0 0 10px;
        display: block;
      }
      .profile__phone{
        font-size: 14px;
        font-weight: 600;
        color: #C6CE4A;
        display: block;
      }
    }

    .profile__details{
      p{
        font-size: 13px;
        color: $primaryColor;
        letter-spacing: 0;
        line-height: 24px;
        margin: 0 0 15px;
      }
      .btn__schedule{
        font-size: 16px;
        font-weight: 600;
        color: #C6CE4A;
        letter-spacing: 0;
        @media #{$phone,$phone6,$phone6Plus}{
          white-space: unset;
          margin-top: 10px;
        }
      }
    }
  }

  .clients__page--list{
    .clients__page--list-view{
      > li{
        background: #FFFFFF;
        box-shadow: 0 0 6px 5px #EEEEEE;
        border-radius: 4px;
        margin: 0 0 40px;
      }
    }

    .clients__page--list__info{
      padding: 24px 35px;
      @media #{$phone6,$phone6Plus}{
        padding: 22px 19px;
      }
      .agent-schedule {
        position: absolute;
        right: 30px;
        top: 30px;
        min-width: 128px;
        padding: 12px 6px;
        @media #{$phone,$phone6,$phone6Plus}{
          position: static;
        }
      }

      .avtar__wrapper{
        margin: 0 0 21px;
      }
      .clients__page--list--avtar{
        width: 54px;
        height: 54px;
        border-radius: 100px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .clients__page--list--avtar-title{
        font-size: 16px;
        color: #3B3B3B;
        font-weight: 700;
      }
      .clients__page--list--avtar-profile{
        font-size: 13px;
        color: #73777A;
        text-align: left;
      }
      .clients__page--list--description{
        font-size: 13px;
        line-height: 21px;
        color: #73777A;
        text-align: left;
      }
      .viewmore__btn {
        float: right;
        font-weight: 600;
        color: #73777A;
        font-size: 12px;
        border: 1px solid #e2e2e2;
        padding: 5px 8px;
        border-radius: 5px;
        margin-top: 5px;
        background: #f9f9f9;
        cursor: pointer;
      }
      .avart__arrow--icon {
        width: 12px;
      }
    }

    .clients__page--list__contact--info{
      border-top: 1px solid #E5E5E5;
      padding: 22px 35px;
      @media #{$phone6,$phone6Plus}{
        padding: 22px 19px;
      }
      .list__contact{
        h2{
          font-size: 13px;
          font-weight: 600;
          color: #73777A;
          text-align: left;
          margin: 0 0 7px;
        }
        h4{
          font-size: 15px;
          color: #73777A;
          font-weight: 400;
          a{
            color: #C6CE4A;
            text-decoration: underline;
          }
        }
      }
    }

    .clients__town--reports{
      padding: 25px 19px;
      .clients__town--reports-head{
        font-size: 17px;
        font-weight: 600;
        color: $primaryColor;
        text-align: left;
        margin: 0 0 43px;
        .town__icon {
          margin-right: 17px;
          vertical-align: text-bottom;
        }
      }
      .clients__town--reports-list{
        .clients__town--title{
          font-size: 17px;
          font-weight: 600;
          color: #C6CE4A;
          text-align: left;
          margin: 0 0 5px;
          display: block;
          text-decoration: underline;
        }
        .clients__town--description {
          margin-bottom: 5px;
        }
        .clients__town--reports-action {
          margin-bottom: 25px;
        }
        .clients__town--reports-action{
          .btn{
            font-size: 13px;
            font-weight: 600;
            color: #FFFFFF;
            padding: 14px 6px;
            margin-right: 13px;
            &:first-child{
              width: calc(60% - 13px);
            }
            &:last-child{
              width: calc(56%);
              margin-right: 0;
            }
          }
        }
      }

    }
  }

  .schedule__sidebar--container{
    height: 100%;
    width: 100%;
    max-width: 554px;
    position: fixed;
    z-index: 1043;
    top: 0;
    right: 0;
    background-color: #fff;
    border-radius: 0;
    transition: 0.3s;
    transform: translateX(100%);
    &.open{
      transform: translateX(0%);
    }
    .schedule__sidebar--header{
      border-bottom: 1px solid #e5e5e5;
      width: 100%;
      height: 85px;
      line-height: 85px;
      padding: 0 15px;
      text-align: center;
      font-size: 21px;
      font-weight: 600;
      color: #73777A;
      text-align: center;
      position: relative;
      .close-btn{
        position: absolute;
        right: 30px;
        top: 0;
        cursor: pointer;
      }
    }
    .schedule__sidebar--body{
      padding: 30px;
      height: calc(100vh - 194px);
      overflow-x: auto;
      @media #{$phone6,$phone6Plus}{
        padding: 30px 15px;
        height: calc(100vh - 170px);
      }
      p{
        font-size: 15px;
        color: #73777A;
        margin: 0 0 27px;
      }
      .schedule__sidebar--form--wrapper{
        .form-group{
          margin-bottom: 36px;
        }
        .custom-select{
          padding: 10px 40px 11px 24px;
          height: 55px;
          border: 1px solid #CED0DA;
          font-size: 13px;
          color: $primaryColor;
          line-height: 19px;
        }
        .custom-textarea{
          padding: 10px 40px 11px 24px;
          width: 100%;
          height: 180px;
          border: 1px solid #CED0DA;
          font-size: 13px;
          color: $primaryColor;
          line-height: 19px;
          border-radius: 4px;
        }
        .date__picker {
          height: 55px;
          padding-left: 71px;
          font-size: 14px;
          background: url(/img/calendar-icon.svg) no-repeat center;
          background-position: 19px;
        }
        .rem__marg--btm {
          margin-bottom: 0;
        }
        .schedule__checkbox{
          margin-bottom: 14px;
          display: block;
          text-align: left;
        }
        .schedule__sidebar--form--calendar{
          padding: 0 30px;
          .No-timeSlots {
            background: #fff;
            border: 1px solid $primaryColor;
            text-align: center;
            color: $primaryColor;
            border-radius: 0;
            font-weight: 400;
            font-size: 14px;
            width: 60%;
            margin: 0 auto;
          }
          @media #{$phone6}{
            padding: 0;
          }
        }
        .error_field {
          background: #fff;
          text-align: center;
          color: $primaryColor;
          font-weight: 400;
          font-size: 14px;
          width: 80%;
          margin: 0 auto;
          border: 0;
        }
        .react-calendar__tile--active {
          background-color: $secondaryColor;
          color: #fff !important;
        }

        .schedule__time--list {
          width: 100%;
          float: left;
          margin: 0 0 15px 0;
          ul > li {
            float: left;
            width: 29.333%;
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            border: 1px solid $primaryColor;
            margin: 0 2% 5px 2%;
            cursor: pointer;
            > a {
              color: $primaryColor;
            }
            &.active {
              background: #C3CF21;
              border: 1px solid #C3CF21;
              color: #fff !important;
              a {
                color: #fff !important;
              }
            }
          }
        }

        // slick Control
        .slick-prev, .slick-next {
          font-size: 0;
          line-height: 0;
          position: absolute;
          top: 40%;
          display: block !important;
          width: 20px;
          height: 20px;
          padding: 0;
          -webkit-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
          cursor: pointer;
          color: $primaryColor;
          border: none;
          outline: none;
          background: transparent;
        }

        .slick-prev {
          &:hover, &:focus {
            color: transparent;
            outline: none;
            background: transparent;
          }
        }

        .slick-next {
          &:hover, &:focus {
            color: transparent;
            outline: none;
            background: transparent;
          }
        }
        .slick-prev {
          &:hover:before, &:focus:before {
            opacity: 1;
          }
        }
        .slick-next {
          &:hover:before, &:focus:before {
            opacity: 1;
          }
        }
        .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
          opacity: .25;
        }
        .slick-prev:before, .slick-next:before {
          font-family: 'slick';
          font-size: 20px;
          line-height: 1;
          opacity: .75;
          color: white;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        .slick-prev {
          left: -3px;
          &:before {
            content: "";
            width: 8px;
            height: 12px;
            position: absolute;
            background: url("/../../img/left-arrow-icon.svg");
            background-repeat: no-repeat;
            left: 0;
            opacity: 1;
          }
        }
        .slick-next {
          right: -25px;
          top: 40%;
          &:before {
            content: "";
            width: 8px;
            height: 12px;
            position: absolute;
            background: url("/../../img/right-arrow-icon.svg");
            background-repeat: no-repeat;
            right: 15px;
            opacity: 1;
          }
        }
        // slick Control


      }
      .schedule__sidebar--small-calendar{
        width: 325px;
        margin: 0 auto;
        padding: 0 25px;
        @media #{$phone6}{
          width: 100%;
          padding: 0;
        }
        .react-calendar{
          border: 0px;
          margin: 0 0 47px;
          .react-calendar__navigation{
            padding: 0 32px;
          }
          .react-calendar__tile--now{
            /*background: #444C63;
            color: #fff;*/
          }
          .react-calendar__month-view__days__day--weekend{
            color: $primaryColor;
          }
          .react-calendar__navigation__prev2-button,
          .react-calendar__navigation__next2-button{
            display: none;
          }
          .react-calendar__month-view__weekdays{
            border-bottom: 1px solid #97B3CE;
          }
          .react-calendar__navigation__label{
            font-weight: 600;
            color: $primaryColor;
          }
          .react-calendar__tile{
            font-size: 12px;
            font-weight: 600;
            border-radius: 100px;
          }
        }
      }
    }
    .schedule__sidebar--footer{
      padding: 10px 30px 24px;
      @media #{$phone6}{
        padding: 5px 15px;
      }
      // .btn__schedule{
      //   padding-top: 28px;
      //   padding-bottom: 28px;
      //   font-size: 21px;
      //   font-weight: 600;
      //   color: #FFFFFF;
      //   &:hover,&:focus{
      //     color: $white__Color;
      //     background-color: $secondaryColor;
      //     border-color:  $secondaryColor;
      //     outline:none;
      //     box-shadow:none;
      //   }
      // }
    }
  }
}
