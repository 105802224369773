@import './../../theme/variables.scss';
.overflow__tabview {
	@media #{$tablet768}{
		height: calc(100vh - 100px);
    overflow-y: auto;
	}
}
.form__grp--marg {
	margin-top: 25px;
	display: inline-block;
	width: 100%;
}
.bns_text{
	font-weight: 600 !important;
    margin-right: 7px;
		margin-bottom: 7px;
		padding-top: 4px;
}
.check_margin{
	margin-left: 10px !important;
}
.form-group.text-group.amount__input {
	input {
		margin-bottom: 5px !important;
	}
}
.amount__label {
	margin-top: 55px !important;
}
.googleInputC {
	// input {
	// 	margin-bottom: 5px !important;
	// 	margin-top: 20px;
	// }
	.textinputs__global {
		width: 100%;
		padding: 5px 15px;
		border: 2px solid #e5e5e5;
		background: #fff;
		color: rgba(0, 0, 0, 0.87);
		font-size: 14px;
		height: 54px;
		border-radius: 4px;
		&:focus {
			border: 2px solid #C7CE4A;
			outline: 0;
		}
	}
}
.commission__label {
	margin-top: 30px;
}
.address-label {
	font-size: 13px !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.54) !important;
    text-align: left;
    line-height: 16px !important;
    margin: 0 0 10px !important;
    top: 0 !important;
	&:focus {
		top: 0 !important;

	}
}




.styles__searchInput___2F18g:focus .address-label {
	color: red;
}
.deal__information--container{

}
.styles__autoCompleteContainer___1ToUG {
	padding: 15px;
    box-shadow: 0 0 15px #ccc;
    margin-top: 5px;
}
.styles__searchInputContainer___6qinj input:focus ::-webkit-input-placeholder {
	color: pink !important;
}

.styles__searchContainer___3zLYs {
	svg {
		display: none;
	}
	input {
		border: 0;
		border-bottom: 1px solid #E6EBF0;
		width: 100%;
		height: 30px;
		color: $primaryColor;
		font-size: 16px;
		border-radius: 0;
		outline: none;
		padding: 0px 0 0px !important;
		margin-bottom: 5px;
	}
}

.agent_err{
	color: red;
	display: block;
	float: left;
	width: 100%;
	margin-top: 5px;
}
.error_field{
	color: red;
    display: block;
    float: left;
    margin-top: 0px;
}
.btm_marg {
	margin-bottom: 55px !important;
}
.deal__information--head{
	font-size: 17px;
	font-weight: 600;
	color: $primaryColor;
	text-align: left;
	line-height: 16px;
	position: relative;
	margin: 0 0 37px;
	&:after{
		content: '';
		position: absolute;
		left: 0;
		bottom: -14px;
		height: 4px;
		width: 46px;
		background: #354052;

	}
}
.deal__information--form{
	.form-group{
		margin-bottom: 37px;
	}
	.additional__textarea{
		border: 1px solid #CED0DA;
		border-radius: 4px;
		resize: none;
		font-size: 13px;
		color: rgba(0,0,0,0.54);
		text-align: left;
		line-height: 16px;
		padding: 16px 15px;
		&:focus{
			border: 1px solid #CED0DA;
		}
	}
	.date__picker{
		height: 52px;
		padding-left: 71px !important;
		font-size: 14px;
		background: url(/img/calendar-icon.svg) no-repeat center;
		background-position: 19px;
		&::placeholder {
		    color: $primaryColor;
				font-weight: 600;
		    opacity: 1; /* Firefox */
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		   color:  $primaryColor;
		}
		&::-ms-input-placeholder { /* Microsoft Edge */
		   color:  $primaryColor;
		}
	}
}
.deal__information--form__btn{
	text-align: right;
	margin: 0 0 37px;
	button{
		margin-left: 15px;
		@media #{$phone}{
			margin-left: 2%;
			margin-right: 2%;
      }
	}
	.btn__green--lg {
		@media #{$phone}{
			width: 48%;
		}
	}
		.btn__black--lg {
			@media #{$phone}{
				width: 48%;
			}
	}
}
