@import './../../../../theme/variables.scss';
.view__breakdown--marg {
    @media #{$phone767}{
        width: 100% !important;
    } 
}
.latest__town--reportmarg {
    @media #{$tablet768}{
        padding-top: 100px;
    }
}
.btn__add--places {
    margin-left: 153px;
    position: absolute;
    top: -40px;
    right: 0;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
    font-weight: 600;
    font-size: 13px;
    border-radius: 4px;
    &:focus{
        outline:none;
    }
    @media #{$tablet768}{
        right: 15px;
        top: 20px !important;
        position: absolute;
        left: 0;
        width: calc(100% - 30px);
        margin: 0 15px;
        padding: 16px;
        font-size: 14px;
    }
 }
.mobtablegoogle {
    @media #{$tablet768}{
        width: 1000px !important;
    }
    th, td {
        @media #{$tablet768}{
            white-space: pre-wrap !important;
            word-break: break-word;
        }  
    }

}
.search-placebox.pac-container {
    z-index: 999999 !important;
    position: absolute !important;
    left: 30px;
    padding: 0px;
    right: 30px;
    width: calc(100% - 60px) !important;
    border: 0;
     input {
        width: 100%;
        padding: 5px 15px !important;
        border: 2px solid #e5e5e5 !important;
        background: #fff;
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        height: 54px !important;
        border-radius: 4px !important;
        &:focus {
          border: 2px solid #C7CE4A !important;
          outline: 0;
        }
     }
}

.edit_margin{
    margin-top: 40px !important;
}

.pac-container{
	z-index: 999999 !important;
    position: absolute !important;
    left: 30px;
    padding: 3px 16px;
    box-shadow: none;
    height: auto !important;
    right: 30px;
    hr {
    	display: none !important;
    }
    div {
        bottom: 3px !important;
    }
}
.modalbtns {
	margin-top: 50px;
	.submitBtnModal {
		padding: 12px 60px;
	}
}
.vicinity__err {
    margin-top: 20px !important;
}
.pac-container.pac-logo {
    max-width: 100% !important;
}
.view__breakdown.view__breakdown--edit {
    width: 210px;
    background-position-y: center !important;
    @media #{$phone767}{
        margin-left: 0;
        margin-right: 15px;
        width: calc(100% - 15px);
    }
}
.navigation__links.extra__padding {
    @media #{$phone767}{
        width: calc(100% - 30px);
        margin: 0 15px;
    }
}
