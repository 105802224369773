@import './../../theme/variables.scss';
.calendar__page--container{
  padding-top: 84px;
  .rbc-row-segment{
    height:100px;
    overflow: hidden;
  }
  
  .rbc-timeslot-group{
  min-height: 110px;
}
.rbc-day-slot .rbc-event{
  min-height: 110px;
}

  .calendar__page--header{
    background: #f7f7f5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 84px;
    z-index: 1043;
    padding:0 15px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-justify-content:space-between;
    justify-content:space-between;
    -webkit-align-items:center;
    align-items:center;
    -webkit-align-content:center;
    align-content:center;

    .calendar__page--header-title{
      font-size: 23px;
      font-weight: 600;
      color: #73777A;
        @media #{$phone}{
          float: left;
          width: 49% !important;
          font-size: 18px;
        }
    }
    .calendar__page--close{
      margin-left: 115px;
      @media #{$phone}{
        margin-left: 0;
        z-index: 99;
      }
    }
  }

  .calendar__page--wrapper{
    height: calc(100vh - 84px);

    .calendar__page--big-calendar,
    .calendar__page--small-calendar{
      float: left;
    }
    .calendar__page--small-calendar{
      width: 325px;
      padding: 0 25px;
      @media #{$phone}{
        width: 100% !important;
      }

      .react-calendar{
        border: 0px;
        .react-calendar__month-view__weekdays{
          border-bottom: 1px solid #97B3CE;
        }
        .react-calendar__navigation__label{
          font-weight: 600;
          color: $primaryColor;
        }
        .react-calendar__tile{
          font-size: 12px;
          font-weight: 600;
          border-radius: 100px;
        }
      }
    }
    .calendar__page--big-calendar{
      width: 100%;
      height:100%;
      @media #{$phone}{
        width: 100% !important;
        margin-top: 25px;
      }

      .rbc-toolbar{
        position: absolute;
        top: 0;
        z-index: 1055;
        right: 80px;
        border-right: 1px solid #DADFEA;
        height: 84px;
        .rbc-toolbar button{
          border: 0;
          font-size: 15px;
          font-weight: 600;
          color: #73777A;
        }
        .rbc-toolbar-label{
          font-size: 17px;
          color: #565F76;
          }
      }


    }
  }
}

.forCursor{
  cursor: pointer;
}
