@import './../../theme/variables.scss';
.btns__fullwidth__chat {
  width: 40% !important;
  margin-left: 18px;
}
.forsafari {
  li {
    display: unset !important;
    .details {
      width: calc(100% - 80px);
      float: right;
    }
  }
  .you {
    .details {
      text-align: right;
      width: calc(100% - 80px);
      float: left;
    }
  }
}
.clo {
  float: right;
  padding: 15px 15px 0 0;
  cursor: pointer;
  z-index: 1;
  position: relative;
}
.emptychat__wrapper {
  width: calc(100% - 370px);
  height: calc(100vh - 90px);
  position: relative;
  float: left;
  text-align: center;
  color: $secondaryColor;
  font-size: 18px;
  font-weight: 500;
  .emptychat {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  @media #{$phone767}{
    display: none;
  }
}

.no_chat--history {
  color: $secondaryColor;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.town__design{
  font-family: 'Pier Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline !important;
  color: $secondaryColor !important;
  margin: 0 5px 0 0;
  padding: 0;
  text-transform: uppercase;
  &:focus, &:hover, &:visited, &:active {
    color: $secondaryColor !important;
  }
}
.chatmsg__main--wrapper {
  padding-top: 80px;
  .chatmsg__header{
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 84px;
    z-index: 1043;
    padding:0 15px;
    border-bottom: 1px solid #CED0DA;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-justify-content:space-between;
    justify-content:space-between;
    -webkit-align-items:center;
    align-items:center;
    -webkit-align-content:center;
    align-content:center;
    .chatmsg__header--title{
      font-family: 'Pier Sans';
      font-style: normal;
      font-weight: bold;
      font-size: 23px;
      line-height: 28px;
      // color: #71777B;
      color: $primaryColor;
      width: calc(100% - 50px);
      @media #{$phone, $phone6, $phone6Plus}{
        font-size: 17px;
      }
    }
    .chatmsg__header--close{
      width: 23px;
      img {
        width: 23px;
      }
    }
  }
  .chatmst__list--none {
    display: none;
  }
  .chatmsg__list {
    width: 370px;
    float: left;
    height: calc(100vh - 85px);
    overflow-y: auto;
    border-right: 1px solid #CED0DA;
    @media #{$phone, $phone6, $phone6Plus}{
      border-right: 0px solid #CED0DA;
      width: 100%;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        border-bottom: 1px solid #CED0DA;
        cursor: pointer;
        .profile__img {
          width: 55px;
          height: 55px;
          overflow: hidden;
          border-radius: 100px;
          margin-right: 14px;
          display: inline-block;
          vertical-align: middle;
          img {
            width: 54px;
            height: 54px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .profile__info {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 70px);
          h2 {
            font-family: 'Pier Sans';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: #3B3B3B;
            margin: 0 0 9px 0;
            padding: 0;
          }
          h4 {
            font-family: 'Pier Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: #73777A;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        a {
          padding: 18px 5px 18px 16px;
          display: block;
        }
      }
      .active__list {
        background: rgba($secondaryColor, .3)
      }
    }
  }
  .chat__centre--block {
    @media #{$phone767}{
      display: block !important;
    }
  }
  .chat__centre {
    width: calc(100% - 749px);
    height: calc(100vh - 85px);
    float: left;
    @media (max-width: 1100px) {
      width: calc(100% - 371px);
    }
    @media #{$phone767}{
      width: 100% !important;
      display: none;
    }
    .chat__centre--up {
      width: 100%;
      border-bottom: 1px solid #CED0DA;
      padding: 21px 20px 20px 20px;
      h5 {
        font-family: 'Pier Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        // color: #73777A;
        color: $primaryColor;
        margin: 0 0 13px 0;
        padding: 0;
      }
      h4 {
        font-family: 'Pier Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-decoration: underline;
        color: $secondaryColor;
        margin: 0;
        padding: 0;
      }
    }
    .chat__centre--chatbox {
      width: 100%;
      background: #fff;
      height: calc(100vh - 180px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media #{$tablet768}{
        //height: calc(100vh - 220px);
        height: calc(100vh - 50%);

      }
      @media #{$phone, $phone6, $phone6Plus}{
        //height: calc(100vh - 190px);
        height: calc(100vh - 50%);

      }
      ul {
        list-style: none;
        padding: 0 0 35px 0;
        margin: 0;
        overflow-y: auto;
        height: calc(100% - 65px);
        display: flex;
        flex-direction: column-reverse;
        li {
          padding: 25px 23px 0 23px;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          img {
            margin-right: 16px;
            width: 62px;
            height: 62px;
            object-fit: cover;
            margin-top: 23px;
            border-radius: 50%;
          }
          .details {
            flex: 1;
            h6 {
              font-family: "Pier Sans";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.337647px;
              color: #9B9B9B;
              margin: 0 0 7px 0;
              .time {
                font-family: $PierSans;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: -0.289412px;
                color: #9B9B9B;
                margin: 8px 0 0 0;
                display: inline-block;
              }
            }
            h5 {
              font-family: "Pier Sans";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: -0.289412px;
              color: #9B9B9B;
              margin: 8px 0 0 0;
            }
            p {
              margin: 0;
              font-family: "Pier Sans";
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.41px;
              white-space: pre-line;
              max-width: 100%;
              word-wrap: break-word;
              color: #231F20;
              padding: 12px 15px;
              background: #E5E5EA;
              border-radius: 17px;
              display: inline-block;
              position: relative;
              z-index: 9;
              &::before {
                content: "";
                background: none !important;
                width: 40px;
                height: 40px;
                background-size: 40px;
                position: absolute;
                left: -10px;
                background-repeat: no-repeat;
                z-index: -9;
                top: 2px;
              }
            }
          }
        }

        .you {
          flex-direction: row-reverse;
          img {
            margin-left: 16px;
            margin-right: 0;
          }
          .details {
            text-align: right;
            p {
              background: $secondaryColor !important;
              color: #fff !important;
              display: inline-block;
              text-align: left;
              position: relative;
              z-index: 9;
              margin-right: 5px;
              white-space: pre-line;
              max-width: 100%;
              word-wrap: break-word;
              &::before {
                content: "";
                background: url(/img/green-tail.png);
                width: 40px;
                height: 40px;
                background-size: 36px;
                background-repeat: no-repeat;
                position: absolute;
                left: auto !important;
                z-index: -9;
                top: 2px;
                right: -14px;
              }
            }
          }
        }
      }
      .send__wrapper {
        height: 204px;
        background: #fff;
        border: 2px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 0;
        padding: 0px 0 0px 15px;
        margin: 0 23px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @media #{$phone, $phone6, $phone6Plus}{
          height: 58px;
          border-radius: 50px;
          margin: 0 20px;
          padding: 0 10px 0 24px;
        }
        textarea {
          border: none;
          background: #fff;
          font-family: "Pier Sans";
          resize: none;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 22px;
          // color: #9B9B9B;
          color: $primaryColor;
          padding: 7px 0;
          height: 100%;
          width: calc(100% - 80px);
          margin-bottom: 0 !important;
          &:focus {
            outline: 0;
          }
          @media #{$phone, $phone6, $phone6Plus}{
            font-size: 16px;
            color: #9B9B9B;
            padding: 0;
            background: none;
            height: 26px;
          }
        }
        button {
          border: none;
          background: none;
          font-family: "Pier Sans";
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: normal;
          color: #9B9B9B;
          float: right;
          cursor: pointer;
          outline: none;
          width: 80px;
          height: auto;
          position: absolute;
          right: 15px;
          bottom: 20px;
          @media #{$phone, $phone6, $phone6Plus}{
            font-size: 16px;
            height: 100%;
            padding-top: 0;
            background: none;
            position: static;
          }
        }
      }
    }
  }
  .chat__schedules--wrapper {
    width: 378px;
    height: calc(100vh - 85px);
    background: #F7F8F6;
    float: left;
    padding: 30px 18px;
    overflow-y: auto;
    @media (max-width: 1100px){
      display: none;
    }
    @media #{$phone767}{
      width: 100% !important;
    }
    h3 {
      font-family: 'Pier Sans';
      font-weight: bold;
      font-size: 23px;
      line-height: 28px;
      // color: #73777A;
      color: $primaryColor;
      margin: 0 0 38px 0;
      padding: 0;
    }
    .chat__schedules--profile {
      width: 100%;
      text-align: center;
      img {
        width: 72px;
        height: 72px;
        object-fit: cover;
        border-radius: 50%;
      }
      h2 {
        font-family: 'Pier Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: $primaryColor;
        padding: 0;
        margin: 20px 0 14px 0;
      }
      a{
        color: $secondaryColor;
        &:hover, &:focus{
          color: $secondaryColor;
        }
      }
      h6 {
        font-family: 'Pier Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-decoration-line: underline;
        color: $secondaryColor;
        margin: 0 0 23px 0;
        padding: 0;
        cursor: pointer;
        img {
          width: auto;
          height: auto;
          object-fit: unset;
          border-radius: unset;
          margin-right: 8px;
        }
      }
      .show__more--details {
        font-family: 'Pier Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #5E6A7F;
        margin: 0 0 19px 0;
        padding: 0 20px;
        // white-space: pre;
        // span {
        //   font-weight: bold;
        //   color: #BFD100;
        //   cursor: pointer;
        // }
      }
      p {
        font-family: 'Pier Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        // color: #5E6A7F;
        color: $primaryColor;
        margin: 0 0 19px 0;
        padding: 0 20px;
        span {
          font-weight: bold;
          color: #BFD100;
          cursor: pointer;
        }
      }
    }
    .status__wrapper {
      width: 100%;
      text-align: left;
      margin-bottom: 14px;
      h6 {
        font-family: 'Pier Sans';
        font-size: 14px;
        line-height: 17px;
        color: $primaryColor;
        margin: 0;
        padding: 0;
      }
      .toggle__wrapper {
        p {
          font-family: 'Pier Sans';
          font-weight: normal;
          font-size: 14px;
          color: $primaryColor;
        }
        span {
          font-family: 'Pier Sans';
          font-weight: 600;
          font-size: 14px;
          color: $primaryColor;
        }
      }
    }
    .schedule__btn {
      background: $secondaryColor;
      font-family: 'Pier Sans';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 1.75385px;
      text-transform: uppercase;
      color: #FFFFFF;
      width: 100%;
      height: 58px;
      padding: 0 10px;
      margin-bottom: 17px;
      border: 0;
    }
    .thanks__btn {
      background: $primaryColor;
      font-family: 'Pier Sans';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 1.75385px;
      text-transform: uppercase;
      color: #FFFFFF;
      width: 100%;
      height: auto;
      padding: 13px 10px;
      margin-bottom: 21px;
      border: 0;
    }
    h5 {
      font-family: 'Pier Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      // color: #5C6A81;
      color: $primaryColor;
      span {
        font-weight: bold;
      }
    }
  }
}

.agentchatfont {
  font-family: 'Poppins' !important;
  font-weight: normal !important;
}
.agentchatfont__med {
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
}





// ********
.message__page--container{
  padding-top: 84px;
  .message__page--header{
    background: #f7f7f5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 84px;
    z-index: 1043;
    padding:0 15px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-justify-content:space-between;
    justify-content:space-between;
    -webkit-align-items:center;
    align-items:center;
    -webkit-align-content:center;
    align-content:center;

    .message__page--header-title{
      font-size: 23px;
      font-weight: 600;
      color: #73777A;
      @media #{$phone, $phone6, $phone6Plus}{
        font-size: 15px;
        width: 40%;
      }
    }
    .message__page--header-right{
      margin-left: auto;
      @media #{$phone, $phone6, $phone6Plus}{
        margin-left: 0;
      }
      .btn{
        min-width: 167px;
        height: 48px;
        @media #{$phone, $phone6, $phone6Plus}{
          margin-left: 0;
          min-width: 120px;
        }
      }
    }
    .message__page--close{
      margin-left: 115px;
      @media #{$phone, $phone6,$phone6Plus}{
        margin-left: 0;
      }
    }
  }




  .message__page--wrapper{
    .chat__container{
      margin: 0;
      border: 0;
      border-left: 1px solid #E6EBF0;
    }
    .noChatHistory {
      color: $secondaryColor;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
    .chat__msg--history{
      min-height: inherit;
      max-height: inherit;
      overflow-y: auto;
      padding: 25px;
      height: calc(100vh - 142px - 76px);
      @media #{$tablet,$phone}{
        height: calc(100vh - 190px - 53px);
      }
    }
  }
  .message__page--left-side,
  .message__page--right-side{
    float: left;
  }
  .message__page--left-side{
    width: 369px;
    height: calc(100vh - 85px);
    overflow-y: auto;
    @media #{$phone}{
      width: 100%;
      height: calc(60vh - 85px);
    }
  }
  .message__page--right-side{
    width: calc(100% - 369px);
    @media #{$phone}{
      width: calc(100% - 0px);
      padding-bottom: 50px;
    }
  }

  .message__page--left-side{
    .chat__user--list{
      a {
        li {
          padding: 15px 20px 15px 45px;
        }
      }
    }
  }
  .message__page--left-side{
    .chat__user--list{
      > li {
        border-bottom: 1px solid #CED0DA;
        padding: 15px 20px 15px 45px;
        cursor: pointer;
        &:hover{
          background: #f7f7f7;
        }
      }

      .active {
        background: $secondaryColor;
        &:hover{
          background: $secondaryColor;
        }
        .chat__user--name {
          color: #fff !important;
        }
        .chat__user--type {
          color: #fff !important;
        }
      }


      .chat__user--avtar{
        width: 55px;
        height: 55px;
        overflow: hidden;
        border-radius: 100px;
        margin-right: 14px;
        display: inline-block;
        vertical-align: middle;
        img{
          width: 100%;
          height: 100%;
          object-fit:cover;
        }
      }
      .chat__user--info{
        display: inline-block;
        vertical-align: middle;
        max-width: 75%;
        .chat__user--name{
          font-size: 16px;
          line-height: 26px;
          color: #3B3B3B;
          display: block;
          margin: 0;
        }
        .chat__user--type{
          font-size: 13px;
          line-height: 21px;
          font-weight: 400;
          color: #73777A;
          text-align: left;
          display: block;
          margin: 0;
        }
      }



    }
  }
  .message__page--right-side{
    .chat__header{
      padding:15px 32px 9px;
      position: relative;
      @media #{$phone}{
        height: 100%;
      }
      .chat__header--heading{
        display: block;
        line-height: 25px;
        font-size: 15px;
        color: #73777A;
        margin: 0 0 2px;
      }
      .chat__header--users{
        font-size: 17px;
        font-weight: 600;
        color: $secondaryColor;
        text-align: left;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
  }


}
