@import './../../../theme/variables.scss';
.tdwidth250 {
  width: 250px;
}
.selouter__not--touch {
  width: 300px !important;
  overflow-x: hidden;
}
.appintment__click--btn {
  left: 8% !important;
}
.reporting__btn--marg {
  margin-top: 0 !important;
}
.spanclsass {
  display: inline-block;
  margin-right: 10px;
  @media #{$phone}{
    width: 100%;
   }
}
.width_300 {
  width: 300px !important;
  @media #{$phone}{
    width: 100% !important;
   }
}
.wrap-text {
    white-space: nowrap;
}
.agent-span {
	margin-top: 0 !important;
}
.link {
    text-decoration: underline;
    color: #c7ce4a;
}
.modal-header {
	color: #c3cf21;
    font-size: 22px;
    margin: 20px 0px 0px 0px;
}

.modal-header div {
	font-weight: 500;
    font-size: 14px;
    display: block;
    width: 500px;
}
.modal-contents {
    margin: 5px 10px 5px 10px;
}

.defaultCheckboxContainer {
  display: inline-block;
    width: auto;
    margin: 0 30px 0 0;
     input{
      margin: 0 15px 0 0;
     }

}
.defaultChecboxCustom {

  width: 100%;
  vertical-align: middle;

    input {
     margin-right: 15px;
     vertical-align: middle;
    }
  span {
    vertical-align: middle;

  }

}

.filterWrapOvveride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
    }
  }
}

.filterHideOverride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
      opacity: 0.50;
    }
  }
}
.clear-filter{
  &.m-0{
    margin:0!important;
  }
}