@import './../../theme/variables.scss';
.send__wrapper {
  textarea {
    max-height: 150px !important;
  }
}
.send__wrapper.sendwrapper {
  @media #{$phone, $phone6, $phone6Plus}{
    bottom: 7px !important;
    .textareapadnew {
      padding-right: 30px !important;
    }
    .imguploadnew {
      position: absolute;
      bottom: 15px;
      background: #fff;
      width: 24px;
      padding: 5px 0;
      height: 24px;
      right: 80px;
    }
  }
}
.img--padding{
  margin-right: 3px;
  text-align: center;
}
.add__cursor{
  cursor: pointer;
}
.pdf_file{
  background: none !important;
  border: 1px solid #eae5e5;
  padding: 10px 15px;
  width: auto;
  display: inline-block;
  border-radius: 4px;
  color: $primaryColor !important;
  font-weight: 500 !important;
  &:before {
    content: '' !important;
    background: none !important;
    width: 0 !important;
    height: 0 !important;
  }
  img {
    border-radius: 0 !important;
    height: 38px !important;
    width: auto !important;
    margin-right: 10px !important;
    vertical-align: middle;
    margin: 0 10px 0 0 !important;
  }
}
.clickable__image{
  cursor: pointer;
  border-radius: 0 !important;
  height: auto!important;
  max-width: 50%!important;
  width: 90px!important;
}

.imguploadnew {
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 5px 0;
  img {
    width: 20px;
  }
}
.textareapadnew {
  padding: 7px 0 37px 0 !important;
}
.chatcenternew {
  overflow-y: auto !important;
  height: calc(100% - 290px) !important;
}
.chatcentrechatboxnew {
  height: auto !important;
}
.fontbold {
  font-weight: 600 !important;
}
.poppinsfontss {
  font-family: 'Poppins' !important;
}
.sendwrapper {
  height: 204px;
  background: #fff;
  border: 2px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 0;
  padding: 0px 0 0px 15px;
  margin: 0 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media #{$phone, $phone6, $phone6Plus}{
    height: 58px;
    border-radius: 50px;
    margin: 0 20px;
    padding: 0 10px 0 24px;
  }
  textarea {
    border: none;
    background: #fff;
    font-family: "Pier Sans";
    resize: none;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #9B9B9B;
    padding: 7px 0;
    height: 100%;
    width: calc(100% - 80px);
    margin-bottom: 0 !important;
    &:focus {
      outline: 0;
    }
    @media #{$phone, $phone6, $phone6Plus}{
      font-size: 16px;
      color: #9B9B9B;
      padding: 0;
      background: none;
      height: 26px;
    }
  }
  button {
    border: none;
    background: none;
    font-family: "Pier Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    color: #9B9B9B;
    float: right;
    cursor: pointer;
    outline: none;
    width: 80px;
    height: auto;
    position: absolute;
    right: 15px;
    bottom: 20px;
    @media #{$phone, $phone6, $phone6Plus}{
      font-size: 16px;
      height: 100%;
      padding-top: 0;
      background: none;
      position: static;
    }
  }
}

.view__more--less {
  font-family: Pier Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #BBD400;
  top: -15px;
  position: relative;
  cursor: pointer;
}
.para__lines{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.profile__letter--onlyleft {
  width: 62px;
  height: 62px;
  color: #fff;
  background: $secondaryColor;
  font-family: $PierSans;
  font-size: 20px;
  line-height: 62px;
  letter-spacing: 1.75px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 5px;
  text-transform: uppercase;
}
.profile__letter--only {
  width: 62px;
  height: 62px;
  color: #fff;
  background: $secondaryColor;
  font-family: $PierSans;
  font-size: 20px;
  line-height: 62px;
  letter-spacing: 1.75px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 15px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 5px;
  text-transform: uppercase;
}
.profile__letter--big {
  width: 72px;
  height: 72px;
  background: $secondaryColor;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #F8F8F8;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0;
  line-height: 78px;
  text-align: center;
  text-transform: uppercase;
}
.profile__letter--client {
  width: 54px;
  height: 54px;
  background: $secondaryColor;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #F8F8F8;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0;
  line-height: 58px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
