@import './../../../../theme/variables.scss';
.panelbody__edit {
  @media #{$phone}{
    padding: 15px 20px !important;
    font-size: 12px;
  }
}
.panel-body {
#mainmenu-wrapper {
  z-index: 9999999 !important; 
  position: absolute !important;
}
#subSticky {
  position: absolute !important;
}
}
.panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ddd;
  overflow-x: auto;
}
.clients__emails--noborder {
  border:0 !important;
}
.clients__emails--container{

  .panel-default {
    border-color: #ECEDE8;
  }
  .panel-heading {
    padding: 14px 38px;
    border-radius: 0;
    position: relative;
    @media #{$tablet768}{
			padding: 14px 20px;
		}
  }
  .panel-default > .panel-heading {
    color: #333;
    background-color: #f7f7f6;
    border-color: #ECEDE8;
  }
  .panel-title > a {
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  .panel-group .panel {
    margin-bottom: 0;
    border-radius: 0;
  }
  .panel-body {
    padding: 15px 38px;
  }
  .panel-heading .accordion-toggle:after {
    content: url(/img/profile-arrow.svg);
    position: absolute;
    right: 12px;
    top: 18px;
    transition: all .2s ease-in;
   }
  .panel-heading .accordion-toggle.collapsed:after {
    content: url(/img/profile-arrow.svg);
    transition: all .2s ease-in;
    transform: rotate(-180deg);
  }
  .emails__list--wrapper{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;

    @media #{$tablet}{
			display: unset;
    }
    @media #{$phone}{
			display: unset;
		}

    .emails__profile{
      width: 35%;
      @media #{$tablet}{
        width: 30%;
        margin-bottom: 10px;
      }
      @media #{$phone}{
        width: 100%;
        margin-bottom: 10px;
      }
      &--avtar{
        width: 33px;
        height: 33px;
        border-radius: 100px;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        float: left;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &--name{
        font-size: 13px;
        font-weight: 600;
        color: #73777A;
        display: inline-block;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // width: calc(90% - 45px);
        float: left;
        padding-top: 10px;
      }
      .emails__profile--name {
        @media #{$tablet}{
          width: 100%;
          padding-top: 0;
        }
        @media #{$phone}{
          padding-top: 0 !important;
        }
      }
    }
    .emails__profile--subject{
      font-size: 13px;
      font-weight: 600;
      color: #73777A;
      display: inline-block;
      width: 35%;
      line-height: normal;
      @media #{$tablet}{
        width: 70%;
        max-width: 70%;
        margin-bottom: 10px;
        padding-left: 45px;
      }
      @media #{$phone}{
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-left: 0;
      }
    }
    .emails__time{
    font-size: 14px;
    font-weight: 600;
    color: #C6CE4A;
    text-align: right;
    width: 30%;
    @media #{$tablet}{
      width: 100%;
      text-align: left;
    }
    // @media #{$tablet768}{
    //   width: 100%;
    //   text-align: left;
    //   padding-left: 0;
    // }
    @media #{$phone}{
      width: 100%;
      text-align: left;
      padding-left: 0;
    }
    
  }
  }
}
