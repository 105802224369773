.allpoppadding__paranew__div {
    max-height: calc(80vh - 100px);
    overflow-y: auto;
}
.allpoppadding__paranew {
    font-family: 'Pier Sans';
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #606A7D;
    margin-bottom: 10px;
    text-align: left;
}
.savebtn__assign {
    background: #C3CF21;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.75385px;
    color: #FFFFFF;
    width: 100%;
    border-radius: 3px;
    text-align: center;
    text-transform: capitalize;
    border: 0;
    padding: 16px 20px;
    margin-top: 25px;
  }
.editmodlhead {
    margin: 0 !important;
    text-align: left !important;
    padding-bottom: 15px !important;
}
.head_modal{
    margin-top: -15px;
}
.agent_pt{
    padding-top: 5px;
    &:hover{
        cursor: pointer;
    }
}
.not__found{ 
    padding: 120px 50px !important;
    text-align: center;
    color: #c7cd4a !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    background: #fff !important;
}

.jungler__list--fullwidth{
  width: 100% !important;
}
.align-items-baseline{
    align-items: baseline !important;
}
