@import './../../../../theme/variables.scss';
.custom__filter--dropdown {
    height: 47px !important;
    line-height: 47px !important;
}
.sel-outer--agent {
    top: 43px !important;
}
.mobile__right {
    @media #{$phone}{
        text-align: right !important;
      } 
}
.selouter__full {
    @media #{$phone}{
        width: 100% !important;
        overflow-x: hidden;
    } 
    top: 50px !important;
    overflow-x: hidden;
}