@import './../../../../theme/variables.scss';
.desktop__textarea {
    @media #{$phone767}{
        display: none;
    }
}
.mobile__inputs {
    display: none;
    @media #{$phone767}{
        display: block;
    }
}
.type_msg_mob {
    @media #{$phone767}{
        border: 2px solid #E5E5E5 !important;
        border-radius: 40px;
        left: 10px !important;
        right: 10px !important;
        width: calc(100% - 20px);
        bottom: 10px !important;
    }
    .chat__msg--send__btn {
        @media #{$phone767}{
            width: 70px !important;
            font-size: 16px !important;
        } 
    }
    .chat___msg--input.inputmob {
        @media #{$phone767}{
            width: calc( 100% - 70px) !important;
            padding: 21px 0 15px 20px !important;
        } 
    }
}
.chat__container.chat__container--mobi {
    @media #{$phone767}{
        margin: 0 0 0px 0 !important;
        height: calc(100vh - 190px);
        overflow-y: auto;
    }
}
.chat__header--details {
    @media #{$phone767}{
        padding: 15px 50px 15px 20px !important;
        height: auto !important;
    }
    .profile__close {
        display: none;
        @media #{$phone767}{
            position: absolute;
            right: 20px;
            top: 20px;
            display: block;
        }  
    }
}
.message__page--ht {
.chatagentmobi.seventy__vh {
    @media #{$phone767}{
		width: 100% !important;
        height: 400px !important;
        max-height: 400px !important;
	}
}
}
.hiderightmob {
    @media #{$phone767}{
        width: 100% !important;
        position: fixed !important;
        top: 0;
        transform: translateX(100%);
        z-index: 9999;
        height: 100vh;
        background: #ffffff;
        left: 0;
        right: 0;
        transition: all .3s ease-in-out;
	}
}
.hiderightmobshow {
    transform: translateX(0%) !important;
}

.mesgs.mesgs__new {
.chat__msg--history {
    max-height: calc(70vh - 76px) !important;
    min-height: calc(50vh - 76px) !important;
    } 
}
.message__page--container {
    .chat__msg--history.chat__msg--historymob {
        @media #{$phone767}{
            max-height: calc(100vh - 180px) !important;
            min-height: calc(100vh - 180px) !important;
        }
    }
}
.mesgs.mesgs__new {
    @media #{$phone767}{
        height: 100% !important;
	}
}
// .message__leftside--ht {
//     height: calc(50vh + 104px) !important;
// }
// .chatmsg__rightside--ht {
//     height: calc(50vh - 76px) !important;
// }

.not__show {
    display: none;
}
.user__name--font {
    font-size: 14px !important;
}
.ful {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}
.message__page--ht {
    .message__page--left-side {
        height: 100% !important;
        max-height: 100% !important;
    }
    .seventy__vh {
        max-height: 70vh !important;
    }
}
.chat__header {
    border-left: 1px solid #E6EBF0;
}
.client_blank_msg{
    width: 100%;
    float: left;
    text-align: center;
    padding: 100px 0;
    margin-bottom: 25px;
}
.chat___msg--input-write-edit {
    .chat___msg--input {
        border-top: 1px solid #E6EBF0;
        min-height: 52px;
        max-height: 52px;
    }

}

.type_msg-newchat {
    border-top: 1px solid #E6EBF0;
    position: relative;
}


.client__agent--chat {
    @media #{$phone767}{
        margin-bottom: 25px;
	}
	.message__page--container {
    padding-top: 0;

    .message__page--left-side  {
	    width: 250px;
	    height: calc(100vh - 85px);
        overflow-y: auto;
        border-right: 0px solid #CED0DA !important;
        @media #{$phone767}{
            width: 100% !important;
        }
	}
	.message__page--right-side {
    	width: calc(100% - 250px);
	}
	.outgoin_msg_img {
    display: inline-block;
    max-width: 25%;
    text-align: right;
    width: auto;
    @media #{$tablet768}{
        width: 100% !important;
        max-width: 100% !important;
    }
    @media #{$phone767}{
        width: 100% !important;
        max-width: 100% !important;
    }
}
.incoming_msg {
    margin: 13px 0;
}
.incoming_msg_img {
    display: inline-block;
    max-width: 25%;
    width: auto;
}
.received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: middle;
    width: 75%;
}
  }
}


