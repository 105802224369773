@import './../../../../theme/variables.scss';
.clients__deal--container{
  border: 1px solid #E6EBF0;
  margin: 0 0 20px;
  .clients__deal--bg{
    padding: 26px 26px;
    @media #{$phone}{
      padding: 26px 20px;
      }
  }
  .clients__deal--action{
    margin: 0 0 34px;
    width: 300px;
    float: right;
    text-align: right;
    @media #{$phone}{
      // margin: 15px 15px 15px 15px;
      float: left;
      text-align: left;
      // width: calc(100% - 30px);
      margin: 0px 0px 15px 0px;
      width: 100%;
      }
      .btn__green {
        @media #{$phone}{
          width: 100% !important;
        }
      }
    .btn{
      width: calc(100% / 2 - 10px);
      margin-left: 10px;
      &:first-child{
        margin-left: 0;
      }
    }
    .btn__black--outline {
      @media #{$phone}{
        padding: 12px 12px;
        width: 100%;
        }
    }
  }
}
.mobile__position {
  @media #{$tablet768}{
    position: absolute;
    top: 15px;
    right: 15px;
    width: auto;
    left: 0;
    }
    @media #{$phone}{
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      left: 0;
      }
}
.dealList {
  @media #{$phone}{
    padding-top: 60px;
    }
}
