@import '../../../../theme/variables.scss';
.administration__profile {
  .profile__card__wrapper {
    width: 100%;
    max-width: 621px;
    margin: 50px auto;
    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }
}
