@import './../../../../../../theme/variables.scss';

.btn-trash {
  float: left;
  .delete-icon {
    path {
      fill: $primaryColor;
    }
  }
}
.btn-cancel {
  color: $primaryColor;
  background-color: transparent;
  min-width: 100px;
}
.btn-save{
  color: #fff;
  background-color:#49A44F;
  min-width: 100px;
  &:hover {
    color: #fff;
  }
}
