@import './../../../../../theme/variables.scss';

.jc-info-icon {
    margin-left: 10px;
    cursor: pointer;
  }
  .jc-accordion-header {
    padding: 10px 25px;
  }
  .react-modal-header.jc-accordion-header {
    border-bottom: 0px !important;
    padding: 25px 0px 10px 40px !important;
  }
  .jc-accordion {
    padding-top: 0px !important;
    .outline-btn,
    .fill-btn {
      cursor: pointer;
    }
    .ant-collapse {
      border: 0px !important;
      margin-bottom: 10px !important;
    }
    .accordion-wrapper {
      padding: 15px !important;
    }
    .ant-collapse .ant-collapse-item .ant-collapse-header:after {
      content: '+' !important;
      position: absolute;
      font-size: 30px;
      top: 0px;
      right: 10px;
      color: $secondaryColor;
      font-weight: 500;
    }
    svg {
      display: none;
    }

    .ant-collapse .ant-collapse-item .ant-collapse-header .anticon-right {
      display: none!important;
    }
  
    .ant-collapse .ant-collapse-item-active .ant-collapse-header:after {
      content: '-' !important;
      position: absolute;
      font-size: 30px;
      top: 0px;
      right: 13px;
      color: $primaryColor;
      font-weight: 500;
    }
  
    .ant-collapse > .ant-collapse-item {
      border-bottom: 0px !important;
    }
    .ant-collapse-header {
      background: #f5f5f5;
      font-weight: 600;
      border-radius: 5px !important;
    }
    .ant-collapse-content-box {
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-top: 15px;
    }
    .agent-pro {
      font-weight: 600;
    }
    .jc-body-copy {
      margin: 20px 0px;
      p {
        margin-bottom: 0px;
      }
    }
    .acc-btn {
      padding: 5px 15px;
    }
    .table-row {
      padding: 5px 0px;
    }
    .acc-client-info {
      margin-top: 10px;
    }
    .acc-table {
      padding: 7px 15px;
      width: 100%;
    }
    .tcount {
      color: $secondaryColor;
      font-weight: 600;
    }
  }
  .react-modal .jc-accordion-width {
    min-width: 700px !important;
  }
  
  .avatar-button {
    display: flex;
    justify-content: space-between;
  }
  
  .agent-pro {
    display: flex;
    justify-content: left !important;
    align-items: center;
  }
  
  .agent-pro img {
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    box-shadow: 6px 7px 6px #00000024;
    border-radius: 100%;
    margin-right: 15px;
  }
  .agent-pro .avatar-img {
    width: 50px;
    height: 50px;
    border: 5px solid #fff;
    box-shadow: 6px 7px 6px #00000024;
    border-radius: 100%;
    margin-right: 15px;
    .material-textfield .material-textfield-input {
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 6px 13px !important;
    }
  }
  
  .acc-btn {
    padding: 10px 15px;
    border-radius: 5px;
    color: $primaryColor;
  }
  
  .outline-btn {
    border: 2px solid $primaryColor;
    margin-right: 10px;
  }
  
  .fill-btn {
    background: $primaryColor;
    border: 2px solid $primaryColor;
    color: #fff;
  }
  
  .button-con {
    display: flex;
    justify-content: end;
    align-items: flex-start;
    width: 260px;
  }
  
  .acc-table {
    border: 1px solid #d2d2d2;
    padding: 15px;
    margin-top: 10px;
    margin-right: 70px;
  }
  
  .table-row {
    border-bottom: 1px solid #d2d2d2;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
  }
  
  .table-row:last-child {
    border-bottom: 0px;
  }
  
  .acc-table-container {
    display: flex;
  }
  
  @media only screen and (max-width: 600px) {
    .react-modal .jc-accordion-width {
      min-width: 100% !important;
    }
    .react-modal-header.jc-accordion-header {
      padding: 25px 0px 10px 15px !important;
    }
    .acc-table-container {
      flex-direction: column;
    }
    .acc-client-info {
      margin-top: 20px;
    }
    .avatar-button {
      justify-content: left;
      flex-direction: column;
    }
    .button-con {
      justify-content: left;
    }
    .acc-table-container {
      display: flex;
      flex-direction: column;
    }
    .avatar-con{
      width:auto;
    }
  }
  
  .center-v {
    transform: scale(1) translateX(-50%) translateY(50%) !important;
  }
  .delete_jc {
    text-align: right;
    padding-right: 20px !important;
  }
  .jc_select_width select {
    margin-right: 0px !important;
    min-width: 250px !important;
  }
  .jc_select_width .material-textfield-input {
    padding-left: 13px !important;
        font-size: 16px;
  }
  
  .dashboard-react-modal-popup-close-icon {
    position: absolute;
    right: 23px;
    top: 16px;
    width: 32px;
    height: 32px;
    background: #ccc;
    border-radius: 50%;
    border: none;
    float: right;
    cursor: pointer;
    background: url('/img_v2/close-icon.svg') no-repeat;
    background-size: cover;
    background-position: center center;
  }
  
  .avatar-img .text-img {
    margin-left: 11px;
    margin-top: 11px;
  }
  
  .jc_select_width {
    margin-bottom: 0px !important;
  }
  
  .avatar-con {
    width: calc(100% - 280px);
  }

  .dashboard-layout-content1 {
    .checkbox__list.checkbox-list-grid>li {
        padding: 10px;
    }
}

.etmb1 {
    margin-bottom: 30px;

    .fa-envelope-o {
        color: $secondaryColor;
        display: inline-block;
        margin-right: 15px;
    }
}
