@import './../../../theme/variables.scss';
.dp-wrapper__minus {
    margin-top: -12px !important;
}
.selouter__strat {
    width: 250px !important;
}
.list__hide--hor {
    overflow-x: hidden;
}
.sel-outer__minus {
    top: 60px !important;
}
.datepicker__fix--edit {
    .rdr-DateRange {
        right: 240px !important;
        @media #{$phone}{
            right: 0px !important;
        }
    }
}
.sel__fl {
	float: left;
    margin-right: 15px;
    margin-top: 22px;
}
.questionarrie__sel {
    width: auto;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    @media #{$phone}{
        width: 100%;
        margin: 0 0 5px 0;
    }
    .custom-sel-filter {
        @media #{$phone}{
            width: 100% !important;
        } 
    }
}
.flex__prop{
	    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.custom-checkbox {
	text-align: left;
}
.view-more{
	display: block;
	color: #cfd651;
	text-decoration: underline;
    cursor: pointer;
}
.min__pad {
    td {
        padding: 8px 10px !important;
        display: table-cell !important;
        min-height: 60px;
    }
}
.first__th--td {
    background: #fff;
    
}
.margin-bottom-codes {
	margin-bottom: 10px !important;
}
.modal-header {
    padding: 0px !important;
}