/* media queries */
/* iPadPro needs work */
$iPadPro: "(min-device-width: 1024px) and (max-device-width: 1366px)";
// $tablet: "(min-width: 768px) and (max-width: 1024px)";
$desktop1300: "(max-width: 1300px)";
$desktop1200: "(max-width: 1200px)";
$desktop1199: "(max-width: 1199px)";
$tablet: "(max-width: 1024px)";

$tabletLandscape: "(min-width: 768px) and (max-width: 1024px) and (orientation : landscape)";
$desktop: "(min-width: 1024px)";
/* $phone: "(min-device-width: 320px) and (max-device-width: 736px)"; */
$desktop1024: "(max-width: 1024px)";
$desktop1000: "(max-width: 1000px)";
$tablet980: "(max-width: 980px)";
$tablet900: "(max-width: 900px)";
$tablet899: "(max-width: 899px)";
$tablet768: "(max-width: 768px)";
// $phone: "(min-device-width: 320px) and (max-device-width: 767px)";
// $phone6: "(min-device-width: 375px) and (max-device-width: 667px)";
// $phone6Plus: "(min-device-width: 414px) and (max-device-width: 736px)";
$phone: "(max-width: 767px)";
$phone6: "(max-width: 767px)";
$phone6Plus: "(max-width: 767px)";
$phone767: "(max-width: 767px)";
$phone360: "(max-width: 360px)";
$desktop1023: "(max-width: 1023px)";
$mobileLandscape: "(min-width: 320px) and (max-width: 767px) and (orientation : landscape)";
/* iPadPro needs work */

$mobileCalendarDisplay: "(max-width: 1160px)";

$Pangram: 'Pangram';
$PierSans: 'Pier Sans';

// Color
$greenDark: #00AB69;
$green: #19D692;
$skyBlue: #00E1E1;
$blue: #030DCF;
$lightBlue: #70CEF5;
$cyanBlue: #0065A1;
$darkBlue: #35363B;
$white: #fff;
$black: #000;
$red: #CF0808;
$gray: #9B9B9B;
$primaryColor: $black;
$secondaryColor: $cyanBlue;
$backgroundGray: #f5f5f5;
// Color


// font Weight
$xlbold: 900;
$exbold: 800;
$bold: 700;
$semibold: 600;
$medium: 500;
$normal: 400;
// font Weight

/* Custom Var styles */
$white__Color:#fff;
$black__Color:#000;
$red__Color:#D0021B;
$green__Color:#00AB69;
$grey__Color:#F7F7F5;
/* Custom Var styles */
// BTN Style
$btn__font--size:15px;
$btn__width:147px;
$btn__p--top:16px;
$btn__p--bottom:16px;
// BTN Style

$width:100%;

@mixin readOnlyClient() {
    &.ant-table-row.antd-table-row-no-link, 
    &.ant-table-row.antd-table-row-no-link:hover, 
    &.read-only-client {
      * {
        color: #a0a8b0; 
      }
      color: #a0a8b0;
      background-color: #d6e0eb;
      cursor: default;
      *:hover {
        cursor: default;
      }

    }
}
