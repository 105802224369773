@import '../../../../theme/variables.scss';
.client__details {
  @media (max-width: 767px) {
    .card {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  .mob__status__select {
    @media (min-width: 767px) {
      display: none;
    }
  }
  .mob__client__select {
    margin: 20px 0;
    @media (min-width: 767px) {
      display: none;
    }
  }
}
