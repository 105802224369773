@import './../../../../theme/variables.scss';
.color__green--underline{
    text-decoration: underline !important;
    color: #C7CE4A !important;
}
.mob-filter-dropdown {
    right: 0;
    left: auto;
    height: 350px;
    overflow-y: auto;
    padding-top: 15px;
}
.mob-filter-dropdown li {
    padding: 10px 0 0 25px;
}
.more-dropdown {
    width: 60px;
    display: inline-block;
    a {
        color: #787878;
        text-decoration: none;
    }
}
