@import './../../../../theme/variables.scss';
.marglr {
    margin: 0 0 0 10px !important;
}
.margb {
    margin: 0 0 15px 0 !important;
}
.ReactModal__Overlay--after-open{
    overflow: auto;
    z-index: 1;
}
.town__err{
    margin-bottom: 10px !important;
    margin-top: -13px !important;
}
.student__town{
    margin-top: -50px !important;
}
.add__report__btn {
    position: absolute;
    float: right;
    right: 0px;
}
.add__report__txt {
    position: absolute;
    float: right;
    right: 0px;
    padding: 0 16px;
    color: #ffffff !important;
    background: #a8af26;
}

.position__container {
    position: relative;
}
.link__view__more {
    color: #c7cd4a;
    margin-top: 5px;
    cursor: pointer;
}
.btn__add--places {
    margin-left: 153px;
    position: absolute;
    top: 24px;
    right: 0px;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    min-width: 130px;
    &:focus{
        outline:none;
    }
 }
.add__button__fixed_new {
    margin-left: 153px;
    position: absolute;
    top: 50px;
    right: 150px;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    min-width: 130px;
    &:focus{
        outline:none;
    }
 }

.fl__right {
    float: right;
}
.posi__rel {
    position: relative;
}
.padd__top--add {
    @media #{$phone767}{
        padding-top: 60px;
    }
}
.research__add--btn {
    right: 15px;
    top: -55px;
    position: absolute;
    @media #{$phone767}{
        right: 15px;
        top: -20px !important;
        position: absolute;
        left: 15px;
        width: calc(100% - 15px);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .add__button__fixed_new {
        position: static;
        padding: 10px;
        background: #C7CE4A;
        border: 0;
        color: white;
        border-radius: 4px;
        font-weight: 600;
        font-size: 13px;
        min-width: 130px;
        margin-right: 14px;
        @media #{$phone767}{
            padding: 16px;
            width: 46%;
            margin: 0;
            font-size: 14px;
        }
    }
    .right_button {
        margin-left: 0;
        position: static;
        padding: 10px;
        background: #C7CE4A;
        border: 0;
        color: white;
        border-radius: 4px;
        font-weight: 600;
        font-size: 13px;
        min-width: 130px;
        @media #{$phone767}{
            margin-left: 0;
            padding: 16px;
            width: 46%;
            font-size: 14px;
        }
    }
}

.atom__add--btn {
    right: 15px;
    top: -55px;
    position: absolute;
    @media #{$phone767}{
        right: 15px;
        top: -120px !important;
        position: absolute;
        left: 15px;
        width: calc(100% - 15px);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .add__button__fixed_new {
        position: static;
        padding: 10px;
        background: #C7CE4A;
        border: 0;
        color: white;
        border-radius: 4px;
        font-weight: 600;
        font-size: 13px;
        min-width: 130px;
        margin-right: 14px;
        @media #{$phone767}{
            padding: 16px;
            width: 46%;
            margin: 0;
            font-size: 14px;
        }
    }
    .right_button {
        margin-left: 0;
        position: static;
        padding: 10px;
        background: #C7CE4A;
        border: 0;
        color: white;
        border-radius: 4px;
        font-weight: 600;
        font-size: 13px;
        min-width: 130px;
        @media #{$phone767}{
            margin-left: 0;
            padding: 16px;
            width: 46%;
            font-size: 14px;
        }
    }
}
.padd__left--zero {
    padding-left: 0;
}
.btn__add--commute {
    margin-left: 153px;
    position: absolute;
    top: -40px;
    right: 0;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
    font-weight: 600;
    font-size: 13px;
    border-radius: 4px;
    &:focus{
        outline:none;
    }
    @media #{$tablet768}{
        right: 15px;
        top: 20px !important;
        position: absolute;
        left: 0;
        width: calc(100% - 30px);
        margin: 0 15px;
        padding: 16px;
        font-size: 14px;
    }
 }
.add__button__fixed {
    margin-left: 153px;
    position: absolute;
    top: 66px;
    right: 204px;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
    &:focus{
        outline:none;
    }
 }
 .right_button{
    margin-left: 153px;
    position: absolute;
    top: 50px;
    right: 0;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    min-width: 130px;
 }
 .sidebar__shadow {
    box-shadow: -1px 0 25px #e4e4e4;
    height: 100vh !important;
    overflow-y: auto;
    .schedule__sidebar--body {
        height: calc(100vh - 94px);
    }
 }


 .schedule__sidebar--form--wrapper {
    ul {
        float: left;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 7px;
        padding-bottom :7px;
        li {
            width: 100%;
            margin-bottom: 2px;
            .label__text {
                width: 60px;
                color: #c7cd4a;
                font-weight: 600;
                font-size: 14px;
                display: inline-block;
            }
            .label__details {
                width: calc(100% - 60px);
                padding-left: 15px;
            }
            .label__text--edit {
                padding-left: 50px;
                width: auto;
            }
            .label__details--edit {
                width: auto;
            }
            .btn__add {
                    width: auto;
                    background: #c7cd4a;
                    color: #fff;
                    height: auto;
                    text-align: center;
                    border: 0;
                    border-radius: 2px;
                    font-weight: 600;
                    padding: 5px 10px;
                }
        }
    }
 }
