@import './../../theme/variables.scss';
.padtopdash {
	padding-top: 120px !important;
}
.minuspadtop {
	margin-top:25px !important;
}
.extrapadtophome {
    @media #{$tablet768}{
        padding-top: 100px !important;
    }
}

.dropdown__mobview {
	display: none;
	width: 100%;
	margin-top: 5px;
	position: relative;
	@media #{$tablet980}{
		display: block;
		margin-top: 20px;
	}
	@media #{$phone767}{
		display: block;
		margin-top: 5px;
	}
	.selectdropdown {
		border: 2px solid #e5e5e5;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: $primaryColor;
    padding: 18px 17px 18px 17px;
    -webkit-appearance: none;
    overflow: hidden;
    background-image: url('/img/custom-select-arrow.svg');
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: calc(100% - 15px);
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
		cursor: pointer;
		width: 100%;
		&:focus {
			outline: 0;
			border: 2px solid #C7CE4A;
		}
	}
	&::after {
		content: '';
    height: 60px;
    width: 4px;
    background: #C7CE4A;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px 0 0 4px;
	}
}
.desktop__only {
	@media #{$tablet980}{
		display: none !important;
	}
}
.dropdown__mobview--marg {
	@media #{$tablet980}{
		margin-top: 70px;
		margin-bottom: 40px;
	}
}

.overFlow__filter {
	white-space: nowrap;
	overflow-x: auto;
	@media #{$tablet980}{
		margin-bottom: 15px;
	}
}
	.strategist_top{
	   background: #C7CE4A;
	   width: 100%;
	   float: left;
	   padding: 16px 25px;
	   color: #FFF;
	   z-index: 999;
	   text-align: center;
	   a {
	      text-decoration: underline;
	      color: #FFF;
	      cursor: pointer;  
	    }
	}
	.filter__sub--container{
		border-top: 1px solid #E6EBF0;
		.custom-select{
			max-width: 228px;
			display: inline-block;
			height: 46px;
			border: 0;
			border-right: 1px solid #E6EBF0;
			font-size: 15px;
			font-weight: 600;
			color: #787878;
			letter-spacing: 0.44px;
			text-align: center;
			background-position: calc(100% - 12px);
			border-radius: 0;
		}
		.hide__inactive{
			margin-left: 24px;
			display: inline-block;
		}
	}
	.fordt-picker {
		.react-datepicker-wrapper {
			position: absolute;
			top: 12px;
			width: 125px;
		}
		.react-datepicker__input-container {
			button {
				background: none;
				border: 0;
			}
		}
	}
	
.padding-left {
	padding-left: 50px;
	@media #{$tablet}{
		padding-left: 20px;
	}
	@media #{$phone}{
		padding-left: 15px !important;
    }
}
.mobileFilter {
    .dropdown {
      @media #{$tablet}{
		width: 100% !important;
      }
	}
  }
  .uses-list {
	float: left;
	width: 100%;
	@media #{$tablet}{
		width: 84%;
		overflow: hidden;
      }
}
.upcoming__appointments--table-list {
	float: left;
	width: 100%;
}

  .btn__edit{
	margin: 0 9px;
	display: inline-block;
  }

  .mobileFilter {
    display: none;
    .dropdown {
        width: 100%;
        padding: 20px 15px;
        margin-top: 5px;
        margin-bottom: 25px;
        border: 1px solid #f1f1f1;
        float: left;
    }
    .mobile-dropdown>li {
        margin: 10px 0;
        float: left;
		width: 100%;
		padding: 0 25px;
		.filter__container--button {
			@media #{$phone}{
				width: 100%;
				height: auto;
				padding: 14px 22px;
				white-space: unset;
			  }
		}
    }
    .mobile-dropdown>li>a {
        padding: 5px 20px 5px 0px;
        font-weight: 500;
        font-size: 16px;
    }
    
    .dropdown {
      a {
        color: #666;
        font-weight: 600;
        font-size: 14px;
        text-decoration: none;
        width: 100%;
        float: left;

        @media #{$tablet}{
          width: 100%;
        }
      }
    }
    
    @media #{$tablet}{
			display: block;
    }
    @media #{$phone}{
			display: block;
	}
  }
  .desktopFilter {
    @media #{$tablet}{
			display: none;
    }
    @media #{$phone}{
			display: none;
		}
  }





.dashboard__navbar--tabs{
	list-style: none;
	padding: 0;
	margin: 54px 0 0;
	@media #{$tablet}{
		width: 100%;
	}
	>li {
		float: left;
		width: calc(100% / 4);
		text-align: center;
		border-right: 1px solid #E5E5E5;
		background-color: rgba(236, 237, 232, 0.4);
		box-shadow: 0 0px 5px 2px rgba(31, 31, 31, 0.04);
		&:last-child{
			border-right: 0px solid #E5E5E5;
		}
		@media #{$tablet}{
			width: calc(50% / 2);
		}
		@media #{$phone}{
			width: 100%;
		}
		> a{
 			font-size: 17px;
			font-weight: 500;
			color: #73777A;
			padding:24px 0;
			display: block;
			border-bottom: 4px solid transparent;
			@media #{$phone}{
				padding: 20px 0 16px 0;
			}
 			&.active{
				color: #C6CE4A;
				border-bottom: 4px solid #C6CE4A;
				background: #fff;
			}
			&:hover,&:focus{
				text-decoration: none;
				color: #C6CE4A !important;
			}
		}
	}
}

.uses-list{

	.table{
		margin-bottom:0;
	}
	.table > thead tr{
		border-top: 1px solid #E6EBF0;
	}
	.table-striped>tbody>tr:nth-of-type(odd) {
	    background-color: rgba(236,237,232,0.40);
	}
	.table > thead > tr > th{
		border-bottom: 0px solid #ddd;
		font-size: 13px;
		font-weight:600;
		color: #73777A;
		padding: 7px 45px;
	}
	.table > tbody > tr > td{
		padding: 19px 45px;
		font-size: 13px;
		font-weight:400;
		color: #73777A;
		border-top: 0px solid #ddd;
		vertical-align: middle;
	}

}

// Media Css
@media (max-width:667px){
	.dashboard-status {
	    margin: 68px auto 30px auto;
	}
	ul.list-status > li{
		width:50%;
		&:nth-of-type(2){
			border-right: 0px solid #fff;
		}
	}
	.balance-card {
	    max-width: inherit;
	}
	.card {
	    margin: 0 0 30px;
	}
}
// Media Css
