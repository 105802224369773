@import './../../theme/variables.scss';
.mesgs {
.chat__msg--history.transparent__scroll.clientchat__transparent--scroll {
  height: calc(80vh - 100px - 76px) !important;
}
}
.type_msg {
  width: 100%;
}
.chatIcon {
  width: 100px;
  display: block;
  text-align: center;
  margin: 10px auto;
}
.message__page--container{
  padding-top: 84px;
  .message__page--header{
    background: #f7f7f5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 84px;
    z-index: 1043;
    padding:0 15px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-justify-content:space-between;
    justify-content:space-between;
    -webkit-align-items:center;
    align-items:center;
    -webkit-align-content:center;
    align-content:center;

    .message__page--header-title{
      font-size: 23px;
      font-weight: 600;
      color: #73777A;
      @media #{$phone6,$phone6Plus}{
        font-size: 15px;
      }
    }
    .message__page--header-right{
      margin-left: auto;
      .btn{
        min-width: 167px;
        height: 48px;
      }
    }
    .message__page--close{
      margin-left: 115px;
      @media #{$phone6,$phone6Plus}{
            margin-left: 15px;
      }
    }
  }
  .message__page--wrapper{
    .chat__container{
      margin: 0;
      border: 0;
      border-left: 1px solid #E6EBF0;
    }
    .noChatHistory {
      color: #C7CE4A;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .chat__msg--history{
      // min-height: inherit;
      // max-height: inherit;
      // overflow-y: auto;
      // padding: 25px;

      min-height: inherit;
      max-height: inherit;
      overflow-y: auto;
      padding: 48px 25px 0 25px !important;
      height: calc(100vh - 65px - 76px) !important;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      
      @media #{$tablet,$phone}{
        height: calc(100vh - 190px - 76px);
      }
      .testing__chat {
        width: 100%;
        height: calc(80vh - 220px);
        overflow-y: auto;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: nowrap;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
      }

      .testing__chat::-webkit-scrollbar {
        width: 1em;
      }
      .testing__chat::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
      }
      .testing__chat::-webkit-scrollbar-thumb {
        background-color:  none;
        outline: none
      }
    }
  }
  .message__page--left-side,
  .message__page--right-side{
    float: left;
  }
  .message__page--left-side{
    width: 369px;
    height: calc(100vh - 85px);
    overflow-y: auto;
    border-right: 1px solid #CED0DA;
    @media #{$phone}{
      width: 100%;
      height: calc(50vh - 85px);
    }
  }
  .message__page--right-side{
    width: calc(100% - 369px);
    position: relative;
    @media #{$phone}{
      width: calc(100% - 0px);
      padding-bottom: 50px;
    }
  }
  .message__page--left-side{
    .chat__user--list{
      > li {
        border-bottom: 1px solid #CED0DA;
        padding: 15px 20px 15px 45px;
        cursor: pointer;
        &:hover{
          background: #f7f7f7;
        }        
      }

      .active {
        background: #C7CE4A;
        &:hover{
          background: #C7CE4A;
        } 
        .chat__user--name {
          color: #fff !important;
        }
        .chat__user--type {
          color: #fff !important;
        }
      }
      

      .chat__user--avtar{
        width: 55px;
        height: 55px;
        overflow: hidden;
        border-radius: 100px;
        margin-right: 14px;
        display: inline-block;
        vertical-align: middle;
        img{
          width: 100%;
          height: 100%;
          object-fit:cover;
        }
      }
      .chat__user--info{
        display: inline-block;
        vertical-align: middle;
        max-width: 75%;
        .chat__user--name{
          font-size: 16px;
          line-height: 26px;
          color: #3B3B3B;
          display: block;
          margin: 0;
        }
        .chat__user--type{
          font-size: 13px;
          line-height: 21px;
          font-weight: 400;
          color: #73777A;
          text-align: left;
          display: block;
          margin: 0;
        }
      }



    }
  }
  .message__page--right-side{
    .chat__header{
      padding:15px 32px 9px;

      .chat__header--heading{
        display: block;
        line-height: 25px;
        font-size: 15px;
        color: #73777A;
        margin: 0 0 2px;
      }
      .chat__header--users{
        font-size: 17px;
        font-weight: 600;
        color: #C6CE4A;
        text-align: left;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
  }


}



