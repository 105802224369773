@import '../../../../theme/variables.scss';
.date__selector {
  .datepicker-calendar-icon {
    position: absolute;
    bottom: 18px;
    left: 15px;
  }
  select.material-textfield-input {
    padding-left: 40px;
  }
}
.clients__received {
  font-family: $Pangram;
  font-size: 16px;
  line-height: 19px;
  color: $primaryColor;
  margin-top: 6px;
  span {
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
    color: $primaryColor;
    margin-right: 5px;

  }
}
.expand__list {
  font-family: $Pangram;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #C7CE4A;
  position: absolute;
  top: 16px;
  right: 22px;
  cursor: pointer;
}
.clients__status__list {
  margin-top: 15px;
  >li {
    cursor: pointer;
    a, span {
      font-family: $Pangram;
      font-weight: $medium;
      font-size: 16px;
      line-height: 31px;
      color: #C7CE4A;
    }
  }
}
.total__closed__list {
  margin-top: 15px;
  margin-bottom: 0;
  >li {
    display: flex;
    justify-content: space-between;
    span {
      font-family: $Pangram;
      font-size: 16px;
      line-height: 34px;
      color: $primaryColor;
    }
  }
}
.total__closed__count {
  font-family: $Pangram;
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  color: #C7CE4A;
}
