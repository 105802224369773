.jungler__page--padding .jungler__list .table > thead > tr > th {
  padding: 8px 15px !important;
}
.information-icon-button {
  width: 20px;
  height: 20px;
  border: none;
  top: 0px;
  outline: none !important;
  cursor: pointer;
  margin-left: 10px;
  background-size: 20px;
  position: inherit;
}
.multilines {
	white-space : unset !important;
	vertical-align: middle !important;
}

.defaultCheckboxContainer {
  display: inline-block;
    width: auto;
    margin: 0 30px 0 0;
     input{
      margin: 0 15px 0 0;
     }

}
.filterWrapOvveride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
      // opacity: 0.50;
    }
  }
}

.filterHideOverride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
      opacity: 0.50;
    }
  }
}
.defaultChecboxCustom {

  width: 100%;
  vertical-align: middle;

    input {
     margin-right: 15px;
     vertical-align: middle;
    }
  span {
    vertical-align: middle;

  }

}

.custom_th{
  padding: 5px 17px !important;
}