@import './../../theme/variables.scss';
.spacetab {
	margin-top: 0 !important;
}
.bordertoptable {
	// border-top: 1px solid #E6EBF0;
	tr {
		border: 0 !important;
	}
}
.coverage__checkbox {
	padding: 0  10px !important;
	display: block;
	.custom-checkbox {
		height: auto;
		input {
			width: 1px;
		}
	}
}

.filter__sub--container.filter__sub--container-edit.filterarrg {
	margin: 0 0 15px 0 !important;
	border-bottom: 1px solid #E6EBF0;
	@media #{$tablet980}{
		border-top: 0px solid #E6EBF0 !important;
		border-bottom: 0px solid #E6EBF0;
	}
	@media #{$tablet768}{
		width: calc(100% - 15px);
		padding-right: 15px;
		border-top: 0px solid #E6EBF0 !important;
		border-bottom: 0px solid #E6EBF0;
	}
	@media #{$phone}{
    padding-right: 0px;
	}
	li {
		@media #{$desktop1199}{
    // width: 25% !important;
    // max-width: 25% !important;
 }
		@media #{$tablet980}{
			// width: 33%;
			// max-width: 33%;
			width: 100% !important;
			max-width: 100% !important;
		}
		@media #{$tablet768}{
			width: 100% !important;
			max-width: 100% !important;
		}
	}
}
.shiftleft {
	margin-right: 20px;
	@media #{$phone}{
		margin-right: 0;
	 }
}
.right__in--mob {
	@media #{$phone}{
		text-align: right;
	 }
}
.mob__rt--15 {
	@media #{$phone}{
		padding-right: 15px;
	 }
}
.widthauto {
	@media #{$phone}{
	 width: auto !important;
	 margin-left: 20px !important;
	}
}
.hide__hori--scroll {
	@media #{$phone}{
	overflow-x: hidden !important;
	}
}
.mob__btn--edit {
	@media #{$phone}{
		margin: 0 !important;
		left: auto !important;
    right: 24px !important;
	}
}
.for_pre_client_top{
	// margin-top: 60px !important;
}
.jungler__page--container{


	.filter__sub--container{
		border-top: 1px solid #E6EBF0;
		min-width: 100% !important;
		max-width: 100%;
		
		.custom-select{
			max-width: 228px;
			display: inline-block;
			height: 46px;
			border: 0;
			border-right: 1px solid #E6EBF0;
			font-size: 15px;
			font-weight: 600;
			color: #787878;
			letter-spacing: 0.44px;
			text-align: center;
			background-position: calc(100% - 12px);
			border-radius: 0;
		}
		.hide__inactive{
			margin-left: 24px;
			display: inline-block;
		}
		.displ-none {
			display: none;
		}
	}
	.fordt-picker {
		position: relative;
		padding: 8px 60px !important;
		.text-green {
			color: #C7CE4A;
			position: absolute;
			left: 0;
			top: 25px;
			@media #{$phone}{
				width: 80px;
				text-align: right;
			}
		}
		.btn__edit {
			margin: 0 9px;
			position: absolute;
			left: 70px;
			top: 25px;
		}
		.react-datepicker-wrapper {
			position: absolute;
			top: 23px;
			left: 0;
			width: 100px;
			height: 18px;
		}
		.react-datepicker__input-container {
			button {
				background: none;
				border: 0;
				height: 20px;
				width: 120px;
			}
		}
	}
	.jungler__list{
		a {
			&:hover {
				text-decoration: none;
			}
		}
		.btn__edit {
			margin: 0 9px;
		}
		.table {
			margin-bottom: 0;
			> thead tr {
				border-top: 1px solid #E6EBF0;
			}
		}
		.table-striped > tbody > tr:nth-of-type(odd) {
			background-color: rgba(236, 237, 232, 0.4);
		}
		.table > {
			thead > tr > th,
			tbody > tr > td {
				color: #73777A;
				font-size: 13px;
			}
			thead > tr > th {
				border-bottom: 0px solid #ddd;
				font-weight: 600;
				padding: 7px 25px;
					&:last-child {
						padding: 7px 10px;
					}
			}
			tbody > tr > td {
				padding: 8px 25px;
				font-weight: 400;
				border-top: 0px solid #ddd;
				vertical-align: middle;
				&:last-child {
					padding: 7px 10px;
				}
			}
			tbody > tr > td.noClientResults {
			    text-align: center;
				padding: 50px;
				font-size: 18px;
				color: #c7ce49;
				font-weight: 600;
			}

		}
	}
}

/*Follow up date model css*/
.datepicker__newcalendar {
  .react-calendar__navigation {
    background: #c3cf23;
    button {
      color: #fff !important;
    }
    .react-calendar__navigation__label {
      font-weight: 600;
      font-size: 14px;
        background: #fff;
        color: $primaryColor !important;
        border-bottom: 1px solid #ececec;
        &:hover, &:focus {
        background: #fff;
          color: $primaryColor !important;
          border-bottom: 1px solid #ececec;
      }
    }
  }
  .react-calendar__viewContainer {
    abbr {
      cursor: pointer !important;
    }
  }
  .react-calendar {
    width: 100%;
      border: 1px solid #ececec;
    button {
      color: #c3cf23;
      &:hover, &:focus {
        background: #c3cf23;
        color: #fff;
      }
    }
    button[disabled] {
      color: #ccc !important;
      &:hover, &:focus {
        background: #f0f0f0;
        color: #fff;
        cursor: not-allowed;
      }
    }
  }
  .react-calendar__tile--active {
      background: #c3cf23 !important;
      color: #fff !important;
  }
  .label__calendar{
    font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1.46154px;
      text-transform: uppercase;
      color: #9B9B9B;
      margin-bottom: 15px;
      font-family: 'Poppins';
  }
  .react-calendar__tile--hasActive {
    background: #c3cf23;
      color: #fff !important;
      &:hover {
        background: #c3cf23;
      color: #fff !important;
      }
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none;
  }
}
.header__datepicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  h4 {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
  .calendar__close {
    img {
      width: 32px;
      cursor: pointer;
    }
  }
}
.sub__btn--date {
  background: #C3CF21;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.75385px;
    text-transform: capitalize;
    color: #FFFFFF;
    width: 100%;
    margin: 25px 0 0 0;
    padding: 14px 20px;
    border: 0;
    border-radius: 0;
}
 .pagination-container.pagination-container-wrapper{
    a{
      position: inherit !important;
    }
  }
