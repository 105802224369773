@import './../../theme/variables.scss';
.calendar__page--container{
  padding-top: 84px;
  .calendar__page--header{
    background: #f7f7f5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 84px;
    z-index: 1043;
    padding:0 15px;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-justify-content:space-between;
    justify-content:space-between;
    -webkit-align-items:center;
    align-items:center;
    -webkit-align-content:center;
    align-content:center;

    .calendar__page--header-title{
      font-size: 23px;
      font-weight: 600;
      color: #73777A;
    }
    .appointment-btn {
      position: absolute;
      right: 100px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      background: #C7CE4A;
      border-radius: 4px;
      min-width: 128px;
      padding: 12px 6px;
      cursor: pointer;
      border: 0;
      outline: none;
      @media #{$phone}{
        right: 50px;
      }
    }
    .calendar__page--close{
      margin-left: 115px;
    }
  }

  .calendar__page--wrapper{
    height: calc(100vh - 84px);

    .calendar__page--big-calendar,
    .calendar__page--small-calendar{
      float: left;
    }
    .calendar__page--small-calendar{
      width: 325px;
      padding: 0 25px;

      .react-calendar{
        border: 0px;
        .react-calendar__month-view__weekdays{
          border-bottom: 1px solid #97B3CE;
        }
        .react-calendar__navigation__label{
          font-weight: 600;
          color: $primaryColor;
        }
        .react-calendar__tile{
          font-size: 12px;
          font-weight: 600;
          border-radius: 100px;
        }
      }
    }
    .calendar__page--big-calendar{
      width: calc(100% - 325px);
      height:100%;

      .rbc-toolbar{
        position: absolute;
        top: 0;
        z-index: 1055;
        right: 260px;
        border-right: 0px solid #DADFEA;
        height: 84px;
        .rbc-toolbar button{
          border: 0;
          font-size: 15px;
          font-weight: 600;
          color: #73777A;
        }
        .rbc-toolbar-label{
          font-size: 17px;
          color: #565F76;
          }
      }


    }
  }
}

.schedule__sidebar--container{
  height: 100%;
  width: 100%;
  max-width: 554px;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 0;
  transition: 0.3s;
  transform: translateX(100%);
  &.open{
    transform: translateX(0%);
  }
  .schedule__sidebar--header{
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    height: 85px;
    line-height: 85px;
    padding: 0 15px;
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    color: #73777A;
    text-align: center;
    position: relative;
    .close-btn{
      position: absolute;
      right: 30px;
      top: 0;
      cursor: pointer;
    }
  }
  .schedule__sidebar--body{
    padding: 30px;
    height: calc(100vh - 194px);
    overflow-x: auto;
    @media #{$phone6,$phone6Plus}{
      padding: 30px 15px;
      height: calc(100vh - 170px);
    }
    p{
      font-size: 15px;
      color: #73777A;
      margin: 0 0 27px;
    }
    .schedule__sidebar--form--wrapper{
      .form-group{
        margin-bottom: 36px;
      }
      .custom-select{
        padding: 10px 40px 11px 24px;
        height: 55px;
        border: 1px solid #CED0DA;
        font-size: 13px;
        color: $primaryColor;
        line-height: 19px;
      }
      .custom-select-small{
        padding: 10px 40px 11px 24px;
        height: 55px;
        border: 1px solid #CED0DA;
        font-size: 13px;
        color: $primaryColor;
        line-height: 19px;
      }
      .custom-textarea{
        padding: 20px 40px 20px 24px;
        height: 200px;
        border: 1px solid #CED0DA;
        font-size: 13px;
        color: $primaryColor;
        resize: none;
      }
      .rem__marg--btm {
        margin-bottom: 0;
      }
      .date__picker {
        height: 55px;
        padding-left: 71px;
        font-size: 14px;
        background: url('/img/calendar-icon.svg') no-repeat center;
        background-position: 19px;
    }
      .schedule__checkbox{
        margin-bottom: 14px;
        display: block;
        text-align: left;
      }
      .schedule__sidebar--form--calendar{
        padding: 0 30px;
        .No-timeSlots {
          background: #fff;
          border: 1px solid $primaryColor;
          text-align: center;
          color: $primaryColor;
          border-radius: 0;
          font-weight: 400;
          font-size: 14px;
          width: 60%;
          margin: 0 auto;
        }
        @media #{$phone6}{
          padding: 0;
        }
      }
      .error_field {
        background: #fff;
        text-align: center;
        color: $primaryColor;
        font-weight: 400;
        font-size: 14px;
        width: 80%;
        margin: 0 auto;
        border: 0;
      }
      .react-calendar__tile--active {
            background-color: #C7CE4A;
            color: #fff !important;
      }

      .schedule__time--list {
        width: 100%;
        float: left;
        margin: 0 0 15px 0;
        ul > li {
          float: left;
          width: 29.333%;
          height: 38px;
          line-height: 38px;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          border: 1px solid $primaryColor;
          margin: 0 2% 5px 2%;
          cursor: pointer;
          > a {
            color: $primaryColor;
          }
          &.active {
            background: #C3CF21;
            border: 1px solid #C3CF21;
            color: #fff !important;
            a {
              color: #fff !important;
            }
          }
        }
      }

      // slick Control
      .slick-prev, .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 40%;
        display: block !important;
        width: 20px;
        height: 20px;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
        color: $primaryColor;
        border: none;
        outline: none;
        background: transparent;
      }

      .slick-prev {
        &:hover, &:focus {
          color: transparent;
          outline: none;
          background: transparent;
        }
      }

      .slick-next {
        &:hover, &:focus {
          color: transparent;
          outline: none;
          background: transparent;
        }
      }
      .slick-prev {
        &:hover:before, &:focus:before {
          opacity: 1;
        }
      }
      .slick-next {
        &:hover:before, &:focus:before {
          opacity: 1;
        }
      }
      .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
        opacity: .25;
      }
      .slick-prev:before, .slick-next:before {
        font-family: 'slick';
        font-size: 20px;
        line-height: 1;
        opacity: .75;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      .slick-prev {
        left: -3px;
        &:before {
          content: "";
          width: 8px;
          height: 12px;
          position: absolute;
          background: url("/../../img/left-arrow-icon.svg");
          background-repeat: no-repeat;
          left: 0;
          opacity: 1;
        }
      }
      .slick-next {
        right: -25px;
        top: 40%;
        &:before {
          content: "";
          width: 8px;
          height: 12px;
          position: absolute;
          background: url("/../../img/right-arrow-icon.svg");
          background-repeat: no-repeat;
          right: 15px;
          opacity: 1;
        }
      }
      // slick Control


    }
  }
  .schedule__sidebar--footer{
    padding: 10px 30px 24px;
    @media #{$phone6}{
      padding: 5px 15px;
    }
    // .btn__schedule{
    //   padding-top: 28px;
    //   padding-bottom: 28px;
    //   font-size: 21px;
    //   font-weight: 600;
    //   color: #FFFFFF;
    //   &:hover,&:focus{
    //     color: $white__Color;
    //     background-color: #C7CE4A;
    //     border-color:  #C7CE4A;
    //     outline:none;
    //     box-shadow:none;
    //   }
    // }
  }
}
