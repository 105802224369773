@import '../../../../theme/variables.scss';

.wrap-td {
    min-width: 275px;
    white-space: normal;
    line-height: 25px;
}

.virtual-table .ant-table-container:before,
  .virtual-table .ant-table-container:after {
    display: none;
  }
  .virtual-table-cell {
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    background: #FFF;
  }
 [data-theme="dark"]  .virtual-table-cell {
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #303030;
    background: #141414;
  }


.all-clients-antd-table {
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }
  .antd-table-row-dark {
    background-color: #fafafa;
  }
}

.all-clients-antd-table {
	.ant-table-row {
		cursor: pointer;
	}
}

.bulk-actions-follow-up-date {
  .bulk-actions-follow-up-date-datepicker {
    margin-left: 125px !important;    
  }
}

.bulk-actions-internal-note {
  textarea, button {
    margin-top: 10px;
  }
  button {
    margin-bottom: 10px;
  }
}
