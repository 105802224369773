@import '../../../../../../../theme/variables.scss';
.dragable_td {
  td {
    font-family: $Pangram;
    font-weight: $medium;
    font-size: 16px;
    color: $primaryColor;
    height: 58px;
    vertical-align: middle;
    padding: 10px 20px;  
    a {
      color: $primaryColor;
      text-decoration: underline;
    }
 }
} 
