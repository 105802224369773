@import './../../../../theme/variables.scss';
.green__types {
    color: #c7cd4a !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    text-decoration: underline !important;
}
.green__anchor {
    a {
        color: $secondaryColor !important;
    }
}
.marg__minus--top {
    margin-top: -17px !important;
}

.add__space{
   height: 150px !important;
   resize: none;
   padding: 5px 10px !important;
}
.upload__cursor {
    cursor: pointer;
    color: #c7cd4a !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    text-decoration: underline !important;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
}
