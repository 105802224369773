@import '../../../../../theme/variables.scss';
.activity__history__list {
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .activity-history-text {
      font-family: $Pangram;
      font-size: 16px;
      line-height: 22px;
      color: $primaryColor;
      margin-bottom: 5px;
    }
    .activity-history-time {
      font-family: $Pangram;
      font-size: 12px;
      line-height: 22px;
      color: #9B9B9B;
    }
  }
}
