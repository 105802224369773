@import '../../../../../../theme/variables.scss';

.calendar__sidebar {
  width: 100%;
  max-width: 350px;
  height: 100vh;
  background: #fff;
  overflow: auto;
  border-right: 1px solid #E5E5E5;
  &.fixed__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .calendar__sidebar__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    min-height: 68px;
    padding: 0 25px;
    .title-icon {
      width: 33px;
      height: 33px;
      margin-right: 18px;
      path {
        fill: $primaryColor;
      }
    }
    .calendar__sidebar__title {
      font-family: $Pangram;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: $primaryColor;
    }
  }
  .calendar__sidebar__body {
    padding: 17px 0;
    .calendar__sidebar__body_inner {
      padding: 0 15px 0 24px;
    }
    .calendar__create__appointment,
    .btn__update__availability {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .calendar-icon {
        margin-right: 17px;
      }
      .calendar-caret {
        margin-left: auto;
        path {
          fill: #fff;
        }
      }
    }
    h4 {
      font-family: $Pangram;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: $primaryColor;
      margin-bottom: 7px;
    }
    p {
      font-family: $Pangram;
      font-size: 12px;
      line-height: 20px;
      color: #979797;
      margin-bottom: 13px;
    }
  }
  .calendar__small {
   .react-calendar__month-view__days .react-calendar__tile {
      // for active but not today
      &.react-calendar__tile--active {
        color: #fff;
        background: #cccccc !important;
      }
      &.react-calendar__tile--now {
         color: #fff !important;
         background: $primaryColor !important;
      }
    }
  }
}

// @media($tablet) {
@media($mobileCalendarDisplay) {
  .calender__fullpage {
    padding-left: 0px !important;
    & > * {
      width: 100% !important;
      max-width: 100%;
      position: relative !important;
      top: 0px !important;
      left: 0px !important;
    }

    .calendar__sidebar {
      &.fixed__sidebar { height: auto; }
      .calendar__sidebar__header {
        border-bottom: 0px;
      }
      .calendar__sidebar__body {
        padding: 0px;
        hr, 
        .calendar__small { 
          display: none; 
        }
      }
    }

    .calendar__full__view {
      padding-top: 0px !important;
      height: auto !important;
    }

  }

}
