@import './../../../../theme/variables.scss';

.error_comment_msg {
  color: red;
  display: block;
  float: left;
  width: 100%;
  margin-top: 12px;
}
.profile-image-40 {
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.model__z__index{
  z-index: 9999 !important;;
}
.no-break {
  white-space: nowrap;
}
.padd-45 {
  padding-left: 45px;
}
.comment-update {
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  .counter {
    background: #c7ce4a;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 16px;
    position: absolute;
    right: 8px;
    padding: 4px;
  }
}
// comment css open
.comment-wrapper {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 20px 30px;
  width: 780px !important;
  max-width: 780px !important;
  .comment-box-wrapper {
    .modalclose {
      position: absolute;
      right: 25px;
      top: 20px;
      width: 40px;
      height: 40px;
      // background: #F1F1F1;
      // border-radius: 50%;
      cursor: pointer;
      img {
        width: 40px
      }
    }
    h2 {
      font-family: "Pier Sans";
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      color: $primaryColor;
      margin: 0 0 15px 0;
    }
    p {
      font-family: "Pier Sans";
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $primaryColor;
      margin: 0 50px 24px 0;
      padding: 0;
    }
    textarea {
      border: 1px solid #E5E5E5;
      width: 100%;
      /*height: 130px !important;*/
      font-family: "Pier Sans";
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $primaryColor;
      resize: none;
      padding: 10px 15px;
      margin-bottom: 15px;
      outline: none;
    }
    .send-btn {
      width: 178px;
      height: 53px;
      background: $secondaryColor;
      font-family: "Pier Sans";
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 1.75385px;
      text-transform: uppercase;
      color: #FFFFFF;
      border: 0;
      margin-bottom: 10px;
    }
  }
  .comment-list-wrapper {
    height: calc(100vh - 380px);
    overflow-y: auto;
    ul {
      li {
        margin-bottom: 17px;
        .justify-content-start {
          justify-content: flex-start !important;
        }
        .profile-image {
          width: 40px;
          height: 40px;
          background: #F1F1F1;
          border-radius: 50%;
          margin-right: 15px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .profile-name {
          font-family: "Pier Sans";
          font-weight: bold;
          font-size: 16px;
          margin-right: 10px;
          margin-left: 0;
        }
        .profile-date {
          font-family: "Pier Sans";
          font-weight: normal;
          font-size: 14px;
          color: #9B9B9B;
        }
      }
      .txt-wrapper {
        background: #F8F8F8;
        width: calc(100% - 55px);
        padding: 15px;
        margin-left: 55px;
        margin-bottom: 6px;
        p {
          font-family: "Pier Sans";
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $primaryColor;
          padding: 0;
          margin: 0;
          white-space: pre-wrap;
        }
      }
      .btn-wrapper {
        width: calc(100% - 55px);
        margin-left: 55px;
        .edit-delete-chat {
          font-family: "Pier Sans";
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          letter-spacing: 1.46154px;
          text-decoration-line: underline;
          text-transform: uppercase;
          color: #9B9B9B;
          margin-right: 20px;
        }
      }

    }
  }
}
// comment css close


.schbodymob {
  @media #{$phone}{
    padding: 30px 15px 0px 15px !important;
  }
}
.schmob-btn {
  @media #{$phone}{
    padding-left: 0 !important;
    padding-right: 0 !important;

  }
  .btn__schedule {
    @media #{$phone}{
      padding: 24px !important;
      font-size: 16px !important;
      border-radius: 0;
      position: relative;
      bottom: -13px;
    }
  }
}
.clients__strategy--containermob {
  @media #{$phone}{
    margin: 0 -20px !important;
  }
  .strategy-right {
    @media #{$phone}{
      width: calc(100% - 30px);
      left: 15px;
      right: 15px;
    }
  }
}
.padtopmob {
  @media #{$phone}{
    padding-top: 25px;
  }
}
.tablewidthtab {
  @media #{$tablet}{
    min-width: 1000px !important;
  }
  // @media #{$phone}{
  //   min-width: 640px !important;
  // }
}
.table__td--desktopview {
  @media #{$phone}{
    display: none !important;
  }
}
.schedule__sidebar--body.schedule__sidebar--bodyfull {
  height: calc(100vh - 160px) !important;
}
.btn__green.btn__green--pad {
  padding: 12px 20px !important;
  @media #{$phone}{
    padding: 10px 14px !important;
    font-size: 14px !important;
  }
}
.share__btn--new {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 4px;
  color: #FFFFFF;
}
.checkbox__listing {
  width: 100%;
  background: #fff;
  float: right;
  z-index: 9;
  margin-top: -20px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      width: 100%;
      float: left;
      padding: 0px 0px 18px 0;
      border-bottom: 2px solid #E5E5E5;
      .listing__left {
        width: calc(100% - 60px);
        float: left;
        h6 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 21px;
          text-decoration-line: underline;
          color: $secondaryColor;
          margin-bottom: 12px;
        }
        h5 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 21px;
          color: $primaryColor;
          margin-bottom: 5px;
        }
        p {
          font-family: "Poppins";
          font-size: 11px;
          line-height: 18px;
          color: #9B9B9B;
          margin: 0;
          padding: 0;
        }
      }
      .listing__right {
        width: 60px;
        float: left;
        text-align: right;
        padding-top: 12px;
      }
    }
  }
}

.checkbox__listing .form-group {
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
}

.checkbox__listing .form-group label {
  position: relative;
  cursor: pointer;
}

.checkbox__listing .form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #CED0DA;
  border-radius: 50%;
  padding: 15px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox__listing .form-group input:checked + label:before {
  content: '';
  display: block;
  border: 1px solid $secondaryColor;
  background: $secondaryColor;
}

.checkbox__listing .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 7px;
  left: 12px;
  width: 8px;
  height: 16px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}



.extra__spacing{
  margin-left: 20px;
  @media #{$phone}{
    margin-left: 0;
  }
}
.clients__strategy--container{
  border: 1px solid #E6EBF0;
  margin-bottom: 30px;
  .btn-bg {
    position: relative;
    height: 44px;
    .strategy-right {
      position: absolute;
      right: 26px;
      top: 15px;
    }
  }
  .strategy-right {
    float: right;
  }
  .table-responsive-edit {
    overflow-y: auto;
  }
  .table-edit {
    width: 100% !important;
    height: auto;
    th {
      padding: 22px 15px;
      color: #73777A;
      font-size: 13px;
      font-weight: 600;
      border-bottom: 0px solid #ddd;
    }
    td {
      padding: 16px 22px;
      color: #73777A;
      border-top: 0px solid #ddd;
      font-size: 14px;
      vertical-align: middle;
      @media #{$phone}{
        padding: 16px 15px;
      }
    }
    .clients__strategy--avtar {
      width: 33px;
      height: 33px;
      border-radius: 100px;
      overflow: hidden;
      margin-right: 12px;
      cursor: pointer;
      /* display: inline-block; */
      /* vertical-align: middle; */
      float: left;
      width: auto;
      margin-top: -8px;
    }
    .clients__strategy--name {
      font-size: 13px;
      color: #C6CE4A;
      cursor: pointer;;
      /* width: calc(100% - 33px - 12px); */
      /* display: inline-block; */
      text-decoration: underline;
      float: left;
      width: auto;
    }

  }
  .rating-icons {
    li {
      display: inline-block;
      margin-right: 7px;
      padding: 0 !important;
    }
    img {
      width: 16px;
    }
  }
  .clients__strategy--list{
    li{
      font-size: 13px;
      color: #73777A;
      padding: 12px 0;
      .twn-names {
        @media #{$tablet}{
          float: left;
          width: 100%;
          padding-top: 7px;
        }
        @media #{$phone}{
          float: left;
          width: 100%;
          padding-top: 7px;
        }
      }
      .text-right-edit {
        float: right;
        @media #{$tablet}{
          float: left;
          margin-top: 15px;
        }
        @media #{$phone}{
          float: left;
          margin-top: 15px;
        }
      }
      .d-flex {
        @media #{$tabletLandscape}{
          display: flex !important;
        }
        @media #{$tablet}{
          display: unset;
        }
        @media #{$phone}{
          display: unset;
        }
      }
    }
    .btn__black--outline-update {
      width: auto !important;
    }
  }
  .clients__strategy--list{
    list-style: none;
    padding: 20px 30px;
    margin: 0;
    @media #{$tablet}{
      padding: 20px 10px;
    }
    @media #{$phone}{
      padding: 20px 10px;
    }
    > li{
      font-size: 13px;
      color: #73777A;
      padding: 17px 37px;
      float: left;
      width: 100%;
      @media #{$phone}{
        padding: 17px 15px;
      }
      .d-flex {
        @media #{$tablet}{
          display: block;
        }
        @media #{$phone}{
          display: block;
        }
        .clients__strategy--action {
          @media #{$tablet}{
            margin-top: 15px;
          }
          @media #{$phone}{
            margin-top: 15px;
          }
        }
      }
    }
    .clients__strategy--avtar{
      width: 33px;
      height: 33px;
      border-radius: 100px;
      overflow: hidden;
      margin-right: 12px;
      display: inline-block;
      vertical-align: middle;
      img {
        width: 33px;
        height: 33px;
        object-fit: cover;
      }
    }
    .clients__strategy--name{
      font-size: 13px;
      color: #C6CE4A;
      width: calc(100% - 33px - 12px);
      display: inline-block;
      text-decoration: underline;
    }
    .clients__strategy--action{
      .btn{
        width: calc(100% / 2 - 10px);
        margin-left: 10px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }
}
