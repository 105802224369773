@import './../../../theme/variables.scss';
.poppinsfontsedit {
  font-family: 'Poppins' !important;
  .headingpopup {
    font-size: 16px;
    line-height: 20px;
  }
}
.widthofmodal {
  width: 550px;
  max-width: 96%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);
  padding: 24px;
  outline: 0;
}
.modalcontent-marg {
  margin: 10px 0 0 0 !important;
}
.modalheader-left {
  text-align: left !important;
  margin-bottom: 15px !important;
  padding-bottom: 15px !important;
  margin-top: 5px !important;
}
.colpad__lr {
  padding: 0 25px !important;
}
.typeahead {
  .reportsearchsminus.add {
    // width: calc(100% - 45px) !important;
    // margin-right: 45px !important;
    box-shadow: none !important;
    border: 2px solid #e5e5e5 !important;
    background-position: 22px center !important;
  }
}
.fullwidth__agentrepo.fulagent {
  @media #{$tablet768}{
    width: 100% !important;
  }
}

.reportsearchs {
  @media #{$tablet768}{
    width: 54% !important;
  }
  @media #{$phone767}{
    width: 25% !important;
  }
}
.right__cont.right__cont--agent.fullwidth__agentrepo {
  @media #{$phone767}{
    width: 100% !important;
  }
}
.for__suburbs--filter{
  width: 50%;
  display: inline-block;
  position: relative;
  @media #{$phone767}{
    width: 100%;
    margin-bottom: 10px;
  }
  .sel-outer {
    top: 55px !important;
  }
}

.for__town--filter{
  width: 50%;
  display: inline-block;
  position: relative;
  @media #{$phone767}{
    width: 100%;
    margin-bottom: 10px;
  }
  .sel-outer {
    top: 55px !important;
  }
}

.dp__wrapper--hafl {
  width: 600px !important;
  display: inline-block;
  margin: 0 10px 0 0;
  position: relative;
  @media #{$phone767}{
    width: 100%;
  }
  .strategist-search-float {
    width: 290px;
    margin-right: 10px;
    display: inline-block;
    @media #{$phone767}{
      width: 100%;
    }
  }
}
.right__cont--agent {
  width: calc(100% - 610px) !important;
}
.input__width--wrapper {
  width: 200px !important;
  margin-top: 0 !important;
  .strategist-search-float {
    input {
      float: none !important;
      padding: 13px 20px 13px 40px !important;
    }
  }
}

.selouter__agent--statatics {
  top: 85px !important;
  width: 300px !important;
  overflow-x: hidden;
}



.searchbox__agent--input {
  padding: 0 10px;
  display: inline-block;
  width: 100%;
}
.updiv {
  z-index: 999;
  background: #fff;
  padding: 10px 0 0 0;
  overflow: hidden;
}
.loader__center{
  width: 100%;
  text-align: center;
  float: left;
  margin: 100px 0;
  background: #fff !important;
}

.list_table {
  table {
    position: relative;
    width: 1366px;
    overflow: hidden;
    border-collapse: collapse;
    margin: 0 auto;
    @media #{$desktop1300}{
      width: 1200px;
    }
    @media #{$desktop1200}{
      width: 1000px;
    }
    @media #{$desktop1000}{
      width: 900px;
    }
    @media #{$tablet900}{
      width: 860px;
    }
    @media #{$tablet899}{
      width: calc(800px - 50px);
    }
    @media #{$phone}{
      width: calc(600px - 50px) !important;
    }
  }
  thead {
    position: relative;
    display: block;
    width: 1366px;
    overflow: visible;
    height: 50px;
    @media #{$desktop1300}{
      width: 1200px;
    }
    @media #{$desktop1200}{
      width: 1000px;
    }
    @media #{$desktop1000}{
      width: 900px;
    }
    @media #{$tablet900}{
      width: 860px;
    }
    @media #{$tablet899}{
      width: calc(800px - 50px);
    }
    @media #{$phone}{
      width: calc(600px - 50px) !important;
    }
    th {
      min-width: 200px;
      padding-left: 10px;
      line-height: normal;
      height: 50px;
      vertical-align: top;
      &:first-child {
        position: relative;
        display: block;
      }
    }
  }
  tbody {
    position: relative;
    display: block;
    width: 1366px;
    overflow-x: scroll;
    @media #{$desktop1300}{
      width: 1200px;
    }
    @media #{$desktop1200}{
      width: 1000px;
    }
    @media #{$desktop1000}{
      width: 900px;
    }
    @media #{$tablet900}{
      width: 860px;
    }
    @media #{$tablet899}{
      width: calc(800px - 50px);
    }
    @media #{$phone}{
      width: calc(600px - 50px) !important;
    }
    td {
      min-width: 200px;
      border: 0 !important;
      &:first-child {
        position: relative;
        display: block;
      }
    }
  }
}

// tr {
//   td {
//     &:first-child {
//       background: green;
//       &:nth-child(even) {
//         background: #fff;
//       }
//     }
//   }
// }

.for__static {
  background: #fff;

}

// .testing {
//   z-index: 99;
//   background: red;
// }













.for__sort{
  cursor: pointer;
}
.tables {
  max-width: 100%;
  overflow-x: auto;
  float: left;
}
.pane--table2 .tables table  {
  border-collapse: collapse;
  background: white;
  table-layout: inherit;
  width: 100%;
  margin-top: 5px;
  float: left;
  overflow-x: auto;
}
.pane--table2 .tables thead {
  display: block;
  width: calc(100% - 17px);
  border-top: 1px solid #ddd;
  color: #73777A;
  font-size: 14px;
  font-weight: 600;
}
.pane--table2 .tables th, .pane--table2 .tables td {
  padding: 8px 14px !important;
  min-width: 200px !important;
  white-space: unset;
  word-break: break-word;
  border: 0 !important;
  vertical-align: middle !important;
  white-space: pre-line !important;
  word-break: break-word;
}
.pane--table2 .tables tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
  height: 52vh;
  font-weight: 400;
  color: #73777A;
  font-size: 14px;
}



.wrap-text {
  white-space: nowrap;
}
.agent-span {
  margin-top: 0 !important;
}
.link {
  text-decoration: underline;
  color: $secondaryColor;
}
.modal-header {
  color: #c3cf21;
  font-size: 22px;
  margin: 20px 0px 0px 0px;
}

.modal-header div {
  font-weight: 500;
  font-size: 14px;
  display: block;
  width: 500px;
}
.modal-contents {
  margin: 5px 10px 5px 10px;
}


.defaultCheckboxContainer {
  display: inline-block;
  width: auto;
  margin: 0 10px 0 0 !important;
  input{
    margin: 0 15px 0 0;
  }

}
.defaultChecboxCustom {

  width: 100%;
  vertical-align: middle;

  input {
    margin-right: 15px;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;

  }

}

.filterWrapOvveride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
    }
  }
}

.filterHideOverride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
      opacity: 0.50;
    }
  }
}
.clear-filter{
  &.m-0{
    margin:0!important;
  }
}
