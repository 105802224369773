@import './../../../../theme/variables.scss';
.btn.btn__green.back-right.zindexingup.new__common--btn {
	right: 26px;
}
.schedule__sidebar--body.schedule__bodyauto {
	@media #{$phone}{
	height: auto !important;
	}
}
.textareainputs__global.textareainputs__globalht {
	@media #{$phone}{
	height: 200px !important;
	}
}
.zindexingup {
	z-index: 9;
}
.schedule__sidebar--footeredit {
	.btn__schedule {
		@media #{$phone}{
			padding-top: 18px !important;
			padding-bottom: 18px !important;
			font-size: 16px !important;
		}
	}
}
.chat__container {
	@media #{$phone}{
		margin: 0 0 90px 0 !important;
	}
	.back-right {
		@media #{$phone}{
			width: calc(100% - 30px);
			left: 15px;
		}
	}
}
.back-right{
  position: absolute;
  right: 15px;
  top: 15px;
}
.clients__Messages--container{
 
}
.chat___msg--input-write-edit {
	    float: left;
	    width: 100.2%;
	    margin: 0 -1px !important;
	    margin-bottom: 40px !important;
    .chat___msg--input {
    	    margin-bottom: 0px !important;
		    border: 1px solid #E6EBF0 !important;
		    height: 57px !important;
		    min-height: 57px !important;
    }
}
