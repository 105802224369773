@import './../../theme/variables.scss';
.strategist_top1{
	background: $secondaryColor;
	width: 100%;
	float: left;
	padding: 16px 25px;
	color: #FFF;
	z-index: 999;
	text-align: center;
	a {
		text-decoration: underline;
		color: #FFF;
		cursor: pointer;
	}
}
.for_pre_top{
	margin-top: 60px !important;
}
.rem-left1 {
	margin-left: -15px;
	margin-top: 23px;
}
.mob-filter-scroller {
	height: 400px;
	overflow-y: auto;
}
.jungler__page--container {
	.desktopFilter {
		@media #{$tablet}{
			display: none;
		}
		@media #{$phone}{
			display: none;
		}
	}

	.jungler__list {
		float: left;
		width: calc(100% - 30px);
		overflow-x: auto;
		@media #{$phone}{
			width: 100%;
		}
	}


	.mobileFilter {
		display: none;

		.mobile-dropdown {
			width: 100%;
			padding: 20px 15px;
			margin-top: 5px;
			margin-bottom: 25px;
		}
		.mobile-dropdown>li {
			margin: 10px 0;
			float: left;
			width: 100%;
		}
		.mobile-dropdown>li>a {
			padding: 5px 20px;
			font-weight: 500;
			font-size: 16px;
		}

		.dropdown {
			a {
				color: #666;
				font-weight: 600;
				font-size: 14px;
				text-decoration: none;
				width: 100%;
				float: left;

				@media #{$tablet}{
					width: 100%;
					float: left;
					background: #fff;
					padding: 5px 10px;
				}
			}
		}

		@media #{$tablet}{
			display: block;
		}
		@media #{$phone}{
			display: block;
		}
	}


	.filter__container--users {
		margin: 35px 0 35px 0;
	}
	.filter__sub--container{
		border-top: 1px solid #E6EBF0;
		// @media #{$tablet}{
		// 	border-top: 0px solid #E6EBF0 !important;
		// 	margin-bottom: 30px;
		// }
		@media #{$phone}{
			border-top: 0px solid #E6EBF0 !important;
			margin-bottom: 30px;
		}
		.custom-select{
			max-width: 228px;
			display: inline-block;
			height: 46px;
			border: 0;
			border-right: 1px solid #E6EBF0;
			font-size: 15px;
			font-weight: 600;
			color: #787878;
			letter-spacing: 0.44px;
			text-align: center;
			background-position: calc(100% - 12px);
			padding: 14px 20px !important;
			@media #{$tablet}{
				max-width: 50%;
				width: 50%;
				background-size: 16px;
			}
			@media #{$phone}{
				max-width: 100% !important;
				width: 100%;
				background-size: 16px;
			}
		}
		.hide__inactive{
			margin-left: 24px;
			display: inline-block;
			@media #{$phone}{
				margin-left: 10px !important;
				margin-bottom: 35px;
				margin-top: 5px;
			}
		}
	}
	.jungler__list{
		.table {
			margin-bottom: 0;
			> thead tr {
				border-top: 1px solid #E6EBF0;
				th {
					cursor: pointer;
				}
			}
		}
		.table-striped > tbody > tr:nth-of-type(odd) {
			background-color: rgba(236, 237, 232, 0.4);
		}
		.table > {
			thead > tr > th,
			tbody > tr > td {
				color: #73777A;
				font-size: 13px;
			}
			thead > tr > th {
				border-bottom: 0px solid #ddd;
				font-weight: 600;
				padding: 7px 25px;
				white-space: nowrap;
			}
			tbody > tr > td {
				padding: 8px 25px;
				font-weight: 400;
				border-top: 0px solid #ddd;
				vertical-align: middle;
			}

		}
	}
}

.pagination-container {
	text-align: center;
	font-weight: bold;
	margin-top: 25px;
	float: left;
	width: 100%;
}
.pagination-container .pagination>.active>a,
.pagination-container .pagination>.active>a:focus,
.pagination-container .pagination>.active>a:hover,
.pagination-container .pagination>.active>span,
.pagination-container .pagination>.active>span:focus,
.pagination-container .pagination>.active>span:hover {
	z-index: 3;
	color: #fff;
	cursor: default;
	background-color: $secondaryColor;
	border-color: $secondaryColor;
}
.pagination-container .pagination>li>a:focus,
.pagination-container .pagination>li>a:hover,
.pagination-container .pagination>li>span:focus,
.pagination-container .pagination>li>span:hover {
	z-index: 2;
	color: #333;
	background-color: #eee;
	border-color: #ddd;
}
.pagination-container .pagination>li>a,
.pagination-container .pagination>li>span {
	position: relative;
	float: left;
	padding: 12px 18px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: #333;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #ddd;
}




