.ps-tble .table__fixed .ant-table-header .ant-table-cell, .table__fixed .ant-table-tbody .ant-table-cell{
        padding: 13px 15px !important;

}
.ant-table-thead {
    th{

    width: 150px !important;
    }
}
