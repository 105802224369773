@import '/fonts/Pangram/stylesheet.css';
@import './theme/global.scss';
@import './theme/variables.scss';

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-ExtraLight.eot');
  src: local("Source Sans Pro ExtraLight"), local("SourceSansPro-ExtraLight"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLight.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLight.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLight.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format("svg");
  font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-ExtraLightIt.eot');
  src: local("Source Sans Pro ExtraLight Italic"), local("SourceSansPro-ExtraLightIt"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLightIt.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLightIt.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLightIt.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLightIt.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-ExtraLightIt.svg#SourceSansPro-ExtraLightIt') format("svg");
  font-weight: 200;
  font-style: italic;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Bold.eot');
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url('/fonts/SourceSansPro/SourceSansPro-Bold.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-Bold.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-Bold.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-Bold.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-Bold.svg#SourceSansPro-Bold') format("svg");
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Black.eot');
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"), url('/fonts/SourceSansPro/SourceSansPro-Black.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-Black.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-Black.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-Black.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-Black.svg#SourceSansPro-Black') format("svg");
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Regular.eot');
  src: local("Source Sans Pro"), local("SourceSansPro-Regular"), url('/fonts/SourceSansPro/SourceSansPro-Regular.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-Regular.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-Regular.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-Regular.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-Regular.svg#SourceSansPro-Regular') format("svg");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-LightIt.eot');
  src: local("Source Sans Pro Light Italic"), local("SourceSansPro-LightIt"), url('/fonts/SourceSansPro/SourceSansPro-LightIt.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-LightIt.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-LightIt.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-LightIt.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-LightIt.svg#SourceSansPro-LightIt') format("svg");
  font-weight: 300;
  font-style: italic;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Semibold.eot');
  src: local("Source Sans Pro Semibold"), local("SourceSansPro-Semibold"), url('/fonts/SourceSansPro/SourceSansPro-Semibold.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-Semibold.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-Semibold.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-Semibold.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-Semibold.svg#SourceSansPro-Semibold') format("svg");
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-It.eot');
  src: local("Source Sans Pro Italic"), local("SourceSansPro-It"), url('/fonts/SourceSansPro/SourceSansPro-It.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-It.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-It.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-It.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-It.svg#SourceSansPro-It') format("svg");
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-SemiboldIt.eot');
  src: local("Source Sans Pro Semibold Italic"), local("SourceSansPro-SemiboldIt"), url('/fonts/SourceSansPro/SourceSansPro-SemiboldIt.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-SemiboldIt.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-SemiboldIt.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-SemiboldIt.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-SemiboldIt.svg#SourceSansPro-SemiboldIt') format("svg");
  font-weight: 600;
  font-style: italic;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-BoldIt.eot');
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldIt"), url('/fonts/SourceSansPro/SourceSansPro-BoldIt.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-BoldIt.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-BoldIt.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-BoldIt.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-BoldIt.svg#SourceSansPro-BoldIt') format("svg");
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Light.eot');
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"), url('/fonts/SourceSansPro/SourceSansPro-Light.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-Light.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-Light.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-Light.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-Light.svg#SourceSansPro-Light') format("svg");
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro/SourceSansPro-BlackIt.eot');
  src: local("Source Sans Pro Black Italic"), local("SourceSansPro-BlackIt"), url('/fonts/SourceSansPro/SourceSansPro-BlackIt.eot?#iefix') format("embedded-opentype"), url('/fonts/SourceSansPro/SourceSansPro-BlackIt.woff2') format("woff2"), url('/fonts/SourceSansPro/SourceSansPro-BlackIt.woff') format("woff"), url('/fonts/SourceSansPro/SourceSansPro-BlackIt.ttf') format("truetype"), url('/fonts/SourceSansPro/SourceSansPro-BlackIt.svg#SourceSansPro-BlackIt') format("svg");
  font-weight: 900;
  font-style: italic;
}


@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Light.eot');
  src: url('/fonts/Poppins/Poppins-Light.eot?#iefix') format("embedded-opentype"), url('/fonts/Poppins/Poppins-Light.woff2') format("woff2"), url('/fonts/Poppins/Poppins-Light.woff') format("woff"), url('/fonts/Poppins/Poppins-Light.ttf') format("truetype"), url('/fonts/Poppins/Poppins-Light.svg#Poppins-Light') format("svg");
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins.eot');
  src: url('/fonts/Poppins/Poppins.eot?#iefix') format("embedded-opentype"), url('/fonts/Poppins/Poppins.woff2') format("woff2"), url('/fonts/Poppins/Poppins.woff') format("woff"), url('/fonts/Poppins/Poppins.ttf') format("truetype"), url('/fonts/Poppins/Poppins.svg#Poppins') format("svg");
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-SemiBold.eot');
  src: url('/fonts/Poppins/Poppins-SemiBold.eot?#iefix') format("embedded-opentype"), url('/fonts/Poppins/Poppins-SemiBold.woff2') format("woff2"), url('/fonts/Poppins/Poppins-SemiBold.woff') format("woff"), url('/fonts/Poppins/Poppins-SemiBold.ttf') format("truetype"), url('/fonts/Poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format("svg");
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Medium.eot');
  src: url('/fonts/Poppins/Poppins-Medium.eot?#iefix') format("embedded-opentype"), url('/fonts/Poppins/Poppins-Medium.woff2') format("woff2"), url('/fonts/Poppins/Poppins-Medium.woff') format("woff"), url('/fonts/Poppins/Poppins-Medium.ttf') format("truetype"), url('/fonts/Poppins/Poppins-Medium.svg#Poppins-Medium') format("svg");
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins/Poppins-Regular.eot');
  src: url('/fonts/Poppins/Poppins-Regular.eot?#iefix') format("embedded-opentype"), url('/fonts/Poppins/Poppins-Regular.woff2') format("woff2"), url('/fonts/Poppins/Poppins-Regular.woff') format("woff"), url('/fonts/Poppins/Poppins-Regular.ttf') format("truetype"), url('/fonts/Poppins/Poppins-Regular.svg#Poppins-Regular') format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dosis-Bold';
  src: url('/fonts/Dosis/Dosis-Bold.eot');
  src: url('/fonts/Dosis/Dosis-Bold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Dosis/Dosis-Bold.woff2') format('woff2'),
  url('/fonts/Dosis/Dosis-Bold.woff') format('woff'),
  url('/fonts/Dosis/Dosis-Bold.ttf') format('truetype'),
  url('/fonts/Dosis/Dosis-Bold.svg#Dosis-Bold') format('svg');
}

@font-face {
  font-family: 'Dosis-ExtraLight';
  src: url('/fonts/Dosis/Dosis-ExtraLight.eot');
  src: url('/fonts/Dosis/Dosis-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Dosis/Dosis-ExtraLight.woff2') format('woff2'),
  url('/fonts/Dosis/Dosis-ExtraLight.woff') format('woff'),
  url('/fonts/Dosis/Dosis-ExtraLight.ttf') format('truetype'),
  url('/fonts/Dosis/Dosis-ExtraLight.svg#Dosis-ExtraLight') format('svg');
}

@font-face {
  font-family: 'Dosis-Medium';
  src: url('/fonts/Dosis/Dosis-Medium.eot');
  src: url('/fonts/Dosis/Dosis-Medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Dosis/Dosis-Medium.woff2') format('woff2'),
  url('/fonts/Dosis/Dosis-Medium.woff') format('woff'),
  url('/fonts/Dosis/Dosis-Medium.ttf') format('truetype'),
  url('/fonts/Dosis/Dosis-Medium.svg#Dosis-Medium') format('svg');
}

@font-face {
  font-family: 'Dosis-Regular';
  src: url('/fonts/Dosis/Dosis-Regular.eot');
  src: url('/fonts/Dosis/Dosis-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Dosis/Dosis-Regular.woff2') format('woff2'),
  url('/fonts/Dosis/Dosis-Regular.woff') format('woff'),
  url('/fonts/Dosis/Dosis-Regular.ttf') format('truetype'),
  url('/fonts/Dosis/Dosis-Regular.svg#Dosis-Regular') format('svg');
}

@font-face {
  font-family: 'Dosis-SemiBold';
  src: url('/fonts/Dosis/Dosis-SemiBold.eot');
  src: url('/fonts/Dosis/Dosis-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Dosis/Dosis-SemiBold.woff2') format('woff2'),
  url('/fonts/Dosis/Dosis-SemiBold.woff') format('woff'),
  url('/fonts/Dosis/Dosis-SemiBold.ttf') format('truetype'),
  url('/fonts/Dosis/Dosis-SemiBold.svg#Dosis-SemiBold') format('svg');
}
@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-BoldItalic.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-BoldItalic.woff') format('woff'),
  url('/fonts/PierSans/PierSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-Light.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-Light.woff') format('woff'),
  url('/fonts/PierSans/PierSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-Italic.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-Italic.woff') format('woff'),
  url('/fonts/PierSans/PierSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-Regular.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-Regular.woff') format('woff'),
  url('/fonts/PierSans/PierSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-Bold.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-Bold.woff') format('woff'),
  url('/fonts/PierSans/PierSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-Medium.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-Medium.woff') format('woff'),
  url('/fonts/PierSans/PierSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-MediumItalic.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-MediumItalic.woff') format('woff'),
  url('/fonts/PierSans/PierSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-LightItalic.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-LightItalic.woff') format('woff'),
  url('/fonts/PierSans/PierSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-BlackItalic.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-BlackItalic.woff') format('woff'),
  url('/fonts/PierSans/PierSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Pier Sans';
  src: url('/fonts/PierSans/PierSans-Black.woff2') format('woff2'),
  url('/fonts/PierSans/PierSans-Black.woff') format('woff'),
  url('/fonts/PierSans/PierSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

body {
  color: #333;
  font-family: 'Poppins','Source Sans Pro','Pier Sans' !important;
}



// Old Css
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/

.menu-top-menu-container {
  ul li {
    display: inline-block;
    padding: 0 25px;
    font-family: 'Dosis-SemiBold';
    font-size: 18px;
    position: relative;
    a {
      color: $primaryColor;
    }
    .sub-menu {
      display: none;
    }
    &:hover .sub-menu {
      display: block;
      position: absolute;
      float: left;
      height: auto;
      text-align: left;
      margin: 0;
      padding: 16px 0 20px 0;
      background: #fff;
      box-shadow: 0 4px 6px #ccc;
      z-index: 9999;
      width: 220px;
    }
  }
  li .sub-menu li {
    display: block;
    padding: 15px 25px 0 25px;
  }
}

.main-navigation {
  clear: both;
  display: block;
  float: left;
  width: 100%;
  ul {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
    ul {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
      float: left;
      position: absolute;
      top: 100%;
      left: -999em;
      z-index: 99999;
      ul {
        left: -999em;
        top: 0;
      }
      li {
        &:hover > ul, &.focus > ul {
          left: 100%;
        }
      }
      a {
        width: 200px;
      }
    }
    li {
      &:hover > ul, &.focus > ul {
        left: auto;
      }
    }
  }
  li {
    float: left;
    position: relative;
  }
  a {
    display: block;
    text-decoration: none;
  }
}

.menu-item-has-children:after {
  content: "";
  width: 10px;
  height: 20px;
  background-image: url("/../../img/menu-dropdown.png");
  position: absolute;
  right: 5px;
  top: 9px;
  background-repeat: no-repeat;
}

.popup-marg {
  margin-top: 60px;
}

/*popup open*/

.contact-popup {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  background: #fff;
  height: 100%;
}

.pop-up-header h4 {
  padding-top: 20px;
  font-size: 20px;
  color: #3B3B3B;
  padding-left: 10px;
}

.popup-logo {
  width: 250px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.pop-up-header .close {
  padding-top: 20px;
  font-size: 20px;
  opacity: 1;
  padding-right: 10px;
}

.contact-box-outer {
  // position: relative;
  height: calc(100vh - 100px);
}

.contact-box {
  /* width: 100%;
  margin: 0 auto;
  background: #fff;
  min-height: 350px;
  text-align: center;
  padding: 100px 60px;
  margin-bottom: 100px; */
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.85);
  width: 84%;
  padding: 45px 80px 45px 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.contact-box-new {
  width: 44% !important;
  margin: 0 3% !important;
  background: #fff;
  min-height: 350px;
  text-align: center;
  padding: 50px 30px;
  float: left;
}

.bg-padd-services {
  padding: 100px 25px 100px 25px;
}

.bg-padd {
  padding: 100px 75px 20px 75px;
}

.contact-box {
  h4 {
    text-align: center;
    font-size: 32px;
    font-family: "Dosis-Medium";
    margin-bottom: 27px;
    margin-top: 40px;
  }
  p {
    text-align: center;
    font-size: 26px;
    font-family: "Dosis-Regular";
    margin-bottom: 27px;
  }
}

.contact-frm {
  padding: 0 63px;
  h4 {
    font-size: 24px;
    font-family: "Dosis-SemiBold";
    margin-bottom: 17px;
    padding-top: 31px;
  }
  p {
    font-size: 16px;
    font-family: "Dosis-Medium";
    margin-bottom: 45px;
    line-height: 23px;
  }
}

.outer-input {
  position: relative;
}
// .town-report-edit {
//   input {
//     &[type="email"], &[type="password"], &[type="text"], &[type="number"] {
//       border: 0;
//       border-bottom: 1px solid #9b9b9b;
//       width: 100%;
//       float: left;
//       height: 30px;
//       color: #000;
//       font-size: 16px;
//       border-radius: 0;
//       // font-family: Dosis-Regular;
//       margin-bottom: 10px !important;
//       outline: none;
//       padding: 0px 0 0px !important;
//     }
//   }
// }
.contact-frm {
  .error_field {
    color: red;
    display: block;
    float: left;
    margin-top: -50px;
  }
  // label {
  //   width: 100%;
  //   color: #9b9b9b;
  //   font-size: 14px !important;
  //   position: absolute !important;
  //   top: -20px !important;
  //   left: 0;
  //   line-height: 16px;
  // }
  // input {
  //   &[type="email"] {
  //     border: 0;
  //     border-bottom: 1px solid #9b9b9b;
  //     width: 100%;
  //     float: left;
  //     height: 30px;
  //     color: #000;
  //     font-size: 16px;
  //     border-radius: 0;
  //     font-family: Dosis-Regular;
  //     margin-bottom: 55px !important;
  //     outline: none;
  //     padding: 0px 0 0px !important;
  //   }
  //   &[type="password"] {
  //     border: 0;
  //     border-bottom: 1px solid #9b9b9b;
  //     width: 100%;
  //     float: left;
  //     height: 30px;
  //     color: #000;
  //     font-size: 16px;
  //     border-radius: 0;
  //     font-family: Dosis-Regular;
  //     margin-bottom: 55px !important;
  //     outline: none;
  //     padding: 0px 0 0px !important;
  //   }
  //   &[type="text"] {
  //     border: 0;
  //     border-bottom: 1px solid #9b9b9b;
  //     width: 100%;
  //     float: left;
  //     height: 30px;
  //     color: #000;
  //     font-size: 16px;
  //     border-radius: 0;
  //     font-family: Dosis-Regular;
  //     margin-bottom: 55px !important;
  //     outline: none;
  //     padding: 0px 0 0px !important;
  //   }
  //   &[type="number"] {
  //     border: 0;
  //     border-bottom: 1px solid #9b9b9b;
  //     width: 100%;
  //     float: left;
  //     height: 30px;
  //     color: #000;
  //     font-size: 16px;
  //     border-radius: 0;
  //     font-family: Dosis-Regular;
  //     margin-bottom: 55px !important;
  //     outline: none;
  //     padding: 0px 0 0px !important;
  //   }
  // }

  // textarea {
  //   border: 0;
  //   border: 1px solid #9B9B9B;
  //   width: 100%;
  //   float: left;
  //   height: 170px;
  //   color: #000;
  //   font-size: 16px;
  //   border-radius: 0;
  //   font-family: 'Dosis-Regular';
  //   margin-bottom: 60px;
  //   outline: none;
  //   margin-top: 5px;
  //   resize: none;
  //   padding: 5px 7px;
  // }
  // input:focus {
  //   border-bottom: 1px solid #00AB69;
  //   + label {
  //     color: #00AB69 !important;
  //   }
  // }
}

.minus-marg {
  top: -200px !important;
}

.contact-frm {
  textarea:focus {
    border: 2px solid $secondaryColor;
    + label {
      color: $secondaryColor;
    }
  }
  select {
    border: 0;
    border-bottom: 1px solid #9B9B9B;
    width: 100%;
    float: left;
    height: 50px;
    color: #9B9B9B;
    font-size: 16px;
    border-radius: 0;
    font-family: 'Dosis-Regular';
    margin-bottom: 55px;
    margin-top: -30px;
    outline: none;
    background: #fff url("/../../img/select-icon.svg");
    background-position: right center;
    background-repeat: no-repeat;
    appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.half-select {
  width: 50% !important;
}

.btn-full {
  width: 100%;
  height: 58px;
  font-size: 18px !important;
  margin-bottom: 38px;
}

.green-circle {
  width: 50px;
  height: 50px;
  background: $secondaryColor;
  float: left;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  img {
    padding: 14px;
  }
}

.for-link {
  color: $primaryColor !important;
  font-size: 20px;
  font-family: 'Dosis-SemiBold';
  padding: 10px 0 0 10px;
  float: left;
}

.DefaultBtn {
  padding: 0;
  text-align: center;
  background: $secondaryColor;
  border: 2px solid $secondaryColor !important;
  color: #fff;
  font-size: 14px;
  border-radius: 0;
  font-family: 'Dosis-Bold';
  outline: none;
  box-shadow: none;
  border: none;
  a {
    color: #fff;
  }
  &:hover, &:focus, &:active {
    color: #fff;
    text-decoration: none;
    outline: none !important;
    box-shadow: none;
    border: none;
  }
}
.second-line{
  margin-left: 15px;
}
.third-line{
  margin-left: 15px;
  margin-top: 10px;
}

.ant-picker-date-panel .ant-picker-body thead tr th {
  text-align: center;
}

/*popup close*/

/* Small menu. */
.menu-toggle, .main-navigation.toggled ul {
  display: block;
}

@media screen and (max-width: 1024px) {
  .contact-frm {
    padding: 0 40px;
  }
}

@media screen and (min-width: 37.5em) {
  .menu-toggle {
    display: none;
  }
  .main-navigation ul {
    display: block;
  }
}
// Old Css
