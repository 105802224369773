@import './../../theme/variables.scss';
.strategy--container{

}
.local_select--box {
	@media #{$phone}{
		width: 100%;
		max-width: 100%;
	}
}
.strategy--form{
	.form-group{
		margin-bottom: 37px;
	}
	.custom-select{
		height: 48px;
		padding: 13px 18px;
		font-size: 14px;
		font-weight: 400;
		color: #9B9B9B;
		letter-spacing: 0;
		border: 0;
		&:focus{
			border: 0;
		}
	}
	.strategy--form__card{
		border: 1px solid #CED0DA;
		margin: 0 0 33px;
	}
	.locations__list{
		list-style: none;
		padding: 0;
		margin: 0;
		> li{
			display: block;
			position: relative;
			padding: 24px;
			cursor: pointer;
			border-bottom: 1px solid #CED0DA;
			&.active{
				background: $secondaryColor;
			}
			&.active .locations__list--select,
			&.active .select__agent--name{
				display: block;
				color: #fff;
			}
      &:last-child{
        border-bottom: 0px solid #CED0DA;
      }
		}
	}
	.checkbox__icon{
		margin-right: 19px;
	}
	.select__agent--name{
		font-size: 17px;
		font-weight: 600;
		color: #9B9B9B;
		letter-spacing: 0;
	}
	.locations__list--select{
		display: none;
		position: absolute;
		top: 50%;
		right: 24px;
		width: 100%;
		max-width: 314px;
		transform: translateY(-50%);
	}
}
.strategy--form__btn{
	text-align: right;
	margin: 0 0 37px;
	button{
		margin-left: 15px;
	}
}
