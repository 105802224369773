@import './../../../../../../theme/variables.scss';

.modify-table {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    font-family: Pangram;
    .ant-table{
        background-color: transparent !important;
        &-thead.ant-table-thead {
            > tr {
            > th.ant-table-cell {
                font-weight: 500;
                font-size: 14px;
                line-height: 9px;
                color: $primaryColor;
                border-bottom: 0 solid #dee2e6;
                padding: 12px 20px;
                background: transparent;
                vertical-align: bottom;
                white-space: nowrap;
                
                &:before
                {
                    display: none !important;
                }
                .ant-row{
                    align-items: center;
                    .ant-col-xs-12 {
                        display: block;
                        flex: 0 !important;
                        max-width: 100%;
                        > svg{
                            margin-left: 5px;
                        }
                    }
                }
                
            }
            }
        }

    }
    tbody {
      // background: #FFFFFF;
      // box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
      // border-radius: 6px;
      &:before {
        content: '';
        background: #FFFFFF;
        box-shadow: 5px 5px 20px #E5E5E5;
  -webkit-box-shadow: 5px 5px 20px #E5E5E5;
  -moz-box-shadow: 5px 5px 20px #E5E5E5;
        border-radius: 6px;
        position: absolute;
        top: 41px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        @media (min-width: 1024px) {
          box-shadow: 5px 5px 20px #E5E5E5;
        }
      }
      tr {
        td {
        font-size: 16px;
        color: $primaryColor;
        font-weight: 500;
        height: 65px;
        vertical-align: middle;
        padding: 10px 20px;
        position: relative;
        z-index: 1;
        cursor: pointer;
        border-bottom:none !important;
          a {
            text-decoration: underline;
          }
        }
        td.ant-table-cell-row-hover{
            background: none !important;
        }
      }
    }

    tbody tr {
      @include readOnlyClient;
      &:nth-child(2n+1) {
        background-color: #FAFAFA;
        &:hover {
            background: #FAFAFA;
        }
      }
    }
    .table-column-title,
    .table-column-sorter {
      display: table-cell;
      vertical-align: middle;
    }
    .table-column-sorter{
      svg {
        margin-left: 5px;
      }
    }
  }
