@import './../../../../theme/variables.scss';
.forboxshadowdrop {
    box-shadow: 0 2px 4px #eaeaea;
    ul {
        li {
            padding: 0px 5px !important;
        }
    }
}
.desktop__research--tabs {
    @media #{$phone767}{
        display: none;
    }
}
.selectmobresearch {
    @media #{$phone767}{
        position: relative;
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 15px);
    }
}
.insight__upload--wrapper {
    width: 100%;
    margin-bottom: 25px;
    span {
        width: auto;
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 0 !important;
        button {
            padding: 6px 14px 8px 14px !important;
            line-height: normal;
            margin-bottom: 0 !important;
        }

        .or {
            color: $primaryColor;
        }
    }
}
.extra__space{
    margin-top: 50px;
}
