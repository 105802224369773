@import './../../../../theme/variables.scss';

.dashboard-layout-content {
  .checkbox__list.checkbox-list-grid>li {
    padding: 10px;
  }
  .jc-pdf-changes{
    margin:0px !important;
    padding:0px !important;
    .jc_pdf_disable{
      display: inline-block;
      width: 100%;
      height: 100%;
      background:#e0e0e0;
      padding: 0;
      line-height: 43px;
      margin-top: -13px;
      padding-left: 15px;
    }
    .jc-pdf-active{
      background: $secondaryColor;
      min-height: 26px;
      margin-top: 0px;
      padding: 9px;
      box-sizing: border-box;
      padding-left: 15px;
      img{
        width:20px;
      }
    }
  }
}
