@import '../../../../../../theme/variables.scss';
.tui-full-calendar-layout {
  padding-left: 350px;
  font-family: $Pangram;
  .tui-full-calendar-month-dayname {
    background-color: #F5F5F5 !important;
    border-top: 0px solid #e5e5e5 !important;
    .tui-full-calendar-month-dayname-item {
      font-family: $Pangram;
      font-weight: $normal;
      font-size: 13px;
      text-align: center;
      letter-spacing: 0.2px;
      color: $primaryColor;
      border-right: 0px solid #e5e5e5 !important;
    }
  }
  .tui-full-calendar-weekday-grid {
  .tui-full-calendar-weekday-grid-line {
    // border-right: 1px solid #e5e5e5 !important;
    .tui-full-calendar-weekday-grid-header {
      .tui-full-calendar-weekday-grid-date {
        font-family: $Pangram;
      }
    }
    .tui-full-calendar-weekday-grid-footer {
      padding: 3px 10px;
      z-index: 1;
      .calendar-footer-more-schedules {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: $primaryColor;
        padding: 3px 8px;
      }
    }
  }
}
  .tui-full-calendar-weekday-schedules {
    .tui-full-calendar-weekday-schedule-bullet {
        width: 8px;
        height: 8px;
    }
    .tui-full-calendar-weekday-schedule-title {
      font-family: $Pangram;
      font-size: 10px;
      padding-left: 15px;
    }
  }
}

.tui-full-calendar-layout {
  padding-left: 0px !important;
  // .tui-full-calendar-month {
  //   min-height: 100%;
  // }
  // .tui-view-7 {
  //   height: calc(100vh - 99px) !important;
  // }
}
.tui-full-calendar-popup-detail {
  &.tui-full-calendar-popup{
    font-weight: inherit;
  }
}
.rat-TimeSlot_component {
  width: calc(100% - 40px) !important;
}


.time__availability__drawer {
  .ant-drawer-title {
    font-family: $Pangram;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: $primaryColor;
  }
  .ant-drawer-close {
    background: url(/img/img_v2/close.svg) no-repeat;
    background-position: center;
    font-size: 0;
  }
  .time__available {
    .card {
      margin-bottom: 22px;
    }
  }
}
.time__availability__list {
  > li {
    font-family: $Pangram;
    font-size: 16px;
    line-height: 28px;
    color: $primaryColor;
  }
}
.tui-full-calendar-month-week-item .tui-full-calendar-today .tui-full-calendar-weekday-grid-date-decorator {
  color: #fff;
  background-color: rgba(0,0,0,1) !important;
}

.tui-full-calendar-dayname-container {
  overflow-y: auto !important;
}
.tui-full-calendar-right {
  height: 0px !important;
  overflow-y: hidden !important;
}
