@import './../../theme/variables.scss';
.mob__coverage--marg {
	@media #{$phone767}{
		margin-bottom: 0 !important;
	}
}
#phone-form-control{
	display: none !important;
  }
.country__code__profile{
  #flag-dropdown {
    //height: 30px !important;
      background: none !important;
      border: 0;
      border-bottom: 1px solid #9b9b9b;
  }
}
.flag-dropdown__login .flag-dropdown {
	height: 22px !important;
	margin-top: 13px !important;
}
#flagInputControlMainPhone {
	margin-left: 40px !important;
	width: 84%  !important;
}

.strategistEmailSync {
	.navigation__links--google-sync {
		font-size: 14px;
		font-weight: 500;
		text-align: center;
		color: #fff;
		background: #3367d6;
		border-radius: 4px;
		min-width: 128px;
		height: 44px;
		padding: 0 15px 0 0;
		margin-left: 10px;
		border: 0;
		margin-top: 0;
		float: right;
		img {
			height: 30px !important;
			display: inline-block !important;

		}
	}
}
.profile__container{

}
 .profile__container--form{
 	max-width: 687px;
 	margin:0 auto;
 	margin-top: 47px;
  .profile__container--section{
    position: absolute;
    top: 19px;
    right: 45px;
    .profile__container--avtar{
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 100px;
      display: inline-block;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .edit__photo--btn{
      font-size: 9px;
      color: #C6CE4A;
      letter-spacing: 0;
      display: block;
      text-align: center;
      cursor: pointer;
    }
  }
  .custom-select{
		height: 55px;
		padding: 16px 24px;
    border: 1px solid #CED0DA;
	}
  .additional__textarea{
		border: 1px solid #CED0DA;
		border-radius: 4px;
		resize: none;
		font-size: 13px;
		color: rgba(0,0,0,0.54);
		text-align: left;
		line-height: 16px;
		padding: 16px 15px;
		&:focus{
			border: 1px solid #CED0DA;
		}
	}

  .checkbox__list{
    list-style: none;
    padding: 0;
    margin: 0 0 19px;
    > li{
      margin: 0 0 15px;
    }
  }


 	.panel {
	    margin-bottom: 44px;
	    background-color: #fff;
	    border-radius: 6px;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	}
 	.panel-default {
	    border-color: #E6EBF0;
	}
 	.panel-default>.panel-heading {
	    background-color: #fff;
	    border-color: #E6EBF0;
	    color: #73777A;
      position: relative;
	}
	.panel-heading {
	    padding: 32px 44px;
	    border-bottom: 1px solid transparent;
	    border-top-left-radius: 6px;
	    border-top-right-radius: 6px;
	}
	.panel-title {
	    margin-top: 0;
	    margin-bottom: 0;
	    font-size: 20px;
	    font-weight: 600;
	    color: inherit;
	}
	.panel-body {
	    padding: 33px 45px 27px;
	}
	.receive-email-bottom{
		font-size: 15px;
		font-weight:600;
		color: #73777A;
		margin-bottom:14px;
	}
	.toggle-email{
		margin-left:14px;
	}
 }
  // Media Css
@media (max-width:767px){
	.profile-container .panel-heading {
	    padding: 20px 15px;
	}
	.profile-container .panel-body {
	    padding: 33px 20px 27px;
	}
}
// Media Css

.forCursor{
	cursor: pointer;
}
