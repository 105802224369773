@import './../../../../theme/variables.scss';
.textarea__textmsg {
  width: calc(100% - 150px) !important;
}
.textareabtn__textmsg {
  width: 150px !important;
}
.received_msg.received_msg--mob {
  @media #{$phone}{
    padding-top: 0 !important;
  }
}
.type_msg_mob.mob {
@media #{$phone}{
    background: #fff;
    left: 5px !important;
    right: 5px !important;
    bottom: 10px !important;
    position: absolute !important;
  }
}

.chat__msg--historymob {
  padding: 25px 25px 100px 25px !important;
}
.typemsg-mob {
  @media #{$phone}{
    position: absolute !important;
    bottom: 5px !important;
    left: 5px !important;
    right: 5px !important;
    width: auto;
    border: 2px solid #e5e5e5;
    border-radius: 40px;
    background: #fff;
  }
}
.chat__container.chat__container--msgs {
  @media #{$phone}{
    margin: 0 0 20px 0 !important;
  }
}
.type_msg--mobile {
  @media #{$phone}{
    left: 10px !important;
    right: 10px !important;
    bottom: 5px !important;
    width: auto;
    border: 2px solid #e5e5e5;
    border-radius: 40px;
    background: #fff;
  }
}
.clients__Messages--container{
    .received_withd_msg {
        width: auto;
        max-width: 80%;
        position: relative;
          @media #{$tablet}{
            max-width: 95%;
          }
          @media #{$phone}{
            max-width: 95%;
          }
        p {
            background: #F7F7F5 none repeat scroll 0 0;
            border-radius: 13px;
            color: #73777A;
            font-size: 13px;
            font-weight: 400;
            line-height: 21px;
            margin: 0;
            padding: 15px 15px 15px 15px;
            width: auto;
            word-break: break-word;
            float: left;
        }
        .notRecieveToreciever {
            position: absolute;
            top: 5px;
            height: 100%;
            margin-left: 5px;
        }
        .recievedToreciever {
            position: absolute;
            top: 5px;
            height: 100%;
            margin-left: 5px;
        }
    }



    .sent_msg {
        width: auto;
        max-width: 80%;
        position: relative;
        @media #{$tablet}{
            max-width: 80%;
          }
          @media #{$phone}{
            max-width: 95%;
            margin-bottom: 10px;
          }
        p {
            background: #C7CE4A none repeat scroll 0 0;
            color: #fff;
            border-radius: 13px;
            font-size: 13px;
            font-weight: 400;
            line-height: 21px;
            margin: 0;
            padding: 15px 15px 15px 15px;
            word-break: break-word;
            float: right;
        }
        
        .notRecieveToreciever {
            position: absolute;
            left: -40px;
            top: 5px;
            height: 100%;
            margin-right: 5px;
        }
        .recievedToreciever {
            position: absolute;
            top: -5px;
            height: 100%;
            margin-right: 5px;
            left: -60px;
        }
    }

}
