@import './../../theme/variables.scss';

.drawer-schedule-appointment {
  z-index: 9999 !important;
  .ant-drawer-content-wrapper {
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
  .drawer-schedule-appointment-header {
    display: flex;
    align-items: center;
    padding: 15px 45px;
    position: relative;
    @media (max-width: 767px) {
      padding: 15px 24px;
    }
    .drawer-title {
      font-size: 28px;
      color: $primaryColor;
      line-height: 48px;
      font-family: 'Pier Sans';
      font-weight: bold;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }
    .btn-close-drawer {
      background-color: transparent;
      position: absolute;
      right: 10px;
      z-index: 10;
    }
  }
  .upcoming__empty {
    width: 100%;
    height: calc(100vh - 170px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0px 40px;
    @media (max-width: 767px) {
      height: auto;
    }
    p {
      font-family: 'Pier Sans';
      font-size: 24px;
      color: #9B9B9B;
      text-align: center;
    }
    .empt__cal--img {
      margin-bottom: 24px;
    }
    button {
      border-radius: 0px;
      height: 58px;
      font-family: 'Pier Sans';
      font-weight: bold;
      font-size: 12px;
      color: #FFFFFF;
      letter-spacing: 1.75px;
      text-align: center;
      margin-top: 43px;
    }
  }
  .schedule__collapse--sectino {
    .schedule__collapse {
      border-bottom: 2px solid #E5E5E5;
      padding: 15px 40px;
      margin: 0px;
      /* &:first-child {
  border-top: 2px solid #E5E5E5;
  }  */
    }
  }
  .schedule__collapse--sectino {
    &:first-child {
      border-top: 2px solid #E5E5E5 !important;
    }
  }
  .schedule__collapse {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    cursor: pointer;
    .schedule--img--box {
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .schedule--img,
      .no-ImgBox {
        margin-right: 22px;
        width: 53px;
        max-height: 53px;
        max-width: 53px;
        height: 53px;
        border-radius: 50px;
        text-align: center;
        vertical-align: middle;
        line-height: 53px;
        overflow: hidden;
        img {
          max-width: 100%;
        }
      }
      /* .schedule__profile--text{
    background: rgba(198, 206, 74, 0.2);
       line-height: 50px;
           font-family: 'Pier Sans';
   font-weight: bold;
   font-size: 24px;
   color: $secondaryColor;
   } */
      .title {
        font-family: 'Pier Sans';
        font-weight: bold;
        font-size: 15px;
        color: $primaryColor;
        text-align: left;
        margin: 0px 0 6px;
        text-transform: capitalize;
      }
      p {
        font-family: 'Pier Sans';
        font-weight: 500;
        font-size: 14px;
        color: #73777A;
        text-align: left;
        margin: 0px;
      }
    }
  }
}

.mobile__btns {
  margin-top: 15px;
}

.agentclient__btn--drop {
  width: 100%;
  position: relative;
  height: 60px;
  border: 2px solid #e5e5e5;
  border-radius: 4px;
  text-align: left;
  padding: 0 20px;
  font-size: 14px;
  background: #fff url('/img/custom-select-arrow.svg');
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  &::after {
    content: '';
    height: 56px;
    width: 4px;
    background: $secondaryColor;
    position: absolute;
    left: -2px;
    top: 0;
    bottom: 0;
    border-radius: 4px 0 0 4px;
  }
}

.agentclient__btn--dropfocus {
  border: 2px solid $secondaryColor !important;
}

.agentclient__dropdownsshow {
  display: block !important;
  position: absolute;
  background: #fff;
  margin-top: 2px;
  z-index: 99;
  border: 2px solid #e5e5e5;
  box-shadow: 0 0 10px #ccc;
  padding: 10px 10px;
  width: 100%;
  border-top: 0;
}

.agentclient__dropdowns {
  background: #fff;
  display: none;
  ul {
    li {
      width: 100%;
      padding: 5px 10px;
      font-size: 14px;
      a {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}

.error_comment_msg {
  color: red;
  display: block;
  float: left;
  width: 100%;
  margin-top: 12px;
}

.profile-image-40 {
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.comment-update {
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  .counter {
    background: #c7ce4a;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 16px;
    position: absolute;
    right: 8px;
    padding: 4px;
  }
}

// comment css open
.comment-wrapper {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 20px 30px;
  width: 780px !important;
  max-width: 780px !important;
  overflow-y: auto !important;
  .comment-box-wrapper {
    .modalclose {
      position: absolute;
      right: 25px;
      top: 20px;
      width: 40px;
      height: 40px;
      // background: #F1F1F1;
      // border-radius: 50%;
      cursor: pointer;
      img {
        width: 40px
      }
    }
    h2 {
      font-family: "Pier Sans";
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      color: $primaryColor;
      margin: 0 0 15px 0;
    }
    p {
      font-family: "Pier Sans";
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $primaryColor;
      margin: 0 50px 24px 0;
      padding: 0;
    }
    textarea {
      border: 1px solid #E5E5E5;
      width: 100%;
      /*height: 130px;*/
      font-family: "Pier Sans";
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $primaryColor;
      resize: none;
      padding: 10px 15px;
      margin-bottom: 15px;
    }
    .send-btn {
      width: 178px;
      height: 53px;
      background: $secondaryColor;
      font-family: "Pier Sans";
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 1.75385px;
      text-transform: uppercase;
      color: #FFFFFF;
      border: 0;
      margin-bottom: 10px;
    }
  }
  .comment-list-wrapper {
    height: calc(100vh - 380px);
    overflow-y: auto;
    ul {
      li {
        margin-bottom: 17px;
        .justify-content-start {
          justify-content: flex-start !important;
        }
        .profile-image {
          width: 40px;
          height: 40px;
          background: #F1F1F1;
          border-radius: 50%;
          margin-right: 15px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .profile-name {
          font-family: "Pier Sans";
          font-weight: bold;
          font-size: 16px;
          margin-right: 10px;
          margin-left: 0;
        }
        .profile-date {
          font-family: "Pier Sans";
          font-weight: normal;
          font-size: 14px;
          color: #9B9B9B;
        }
      }
      .txt-wrapper {
        background: #F8F8F8;
        width: calc(100% - 55px);
        padding: 15px;
        margin-left: 55px;
        margin-bottom: 6px;
        p {
          font-family: "Pier Sans";
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $primaryColor;
          padding: 0;
          margin: 0;
          white-space: pre-wrap;
        }
      }
      .btn-wrapper {
        width: calc(100% - 55px);
        margin-left: 55px;
        .edit-delete-chat {
          font-family: "Pier Sans";
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          letter-spacing: 1.46154px;
          text-decoration-line: underline;
          text-transform: uppercase;
          color: #9B9B9B;
          margin-right: 20px;
        }
      }
    }
  }
}

// comment css close
.Select.is-clearable.is-open {
  .Select-control {
    border: 2px solid $secondaryColor;
  }
}

.editselectbox {
  .Select-menu-outer {
    max-height: 145px;
    .Select-menu {
      max-height: 145px;
    }
  }
  .Select-control {
    width: 100%;
    border: 2px solid #e5e5e5;
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    height: 54px !important;
    border-radius: 4px;
    line-height: 19px !important;
    padding: 0 15px;
    font-size: 14px !important;
    letter-spacing: 1px !important;
    .Select-multi-value-wrapper {
      height: 100%;
      .Select-placeholder {
        line-height: 50px;
        padding: 0 15px;
        color: rgba(0, 0, 0, 0.87);
      }
      .Select-input {
        padding-left: 0px;
        padding-right: 0;
        display: none !important;
      }
    }
    .Select-clear-zone {
      display: none;
    }
    .Select-value-label {
      line-height: 48px;
      padding-left: 5px !important;
    }
  }
  .Select-option {
    font-size: 14px;
    color: $primaryColor;
    background: #fff;
  }
  .Select-arrow-zone {
    position: relative;
    width: 100%;
    z-index: 9;
    &:after {
      content: '';
      background: url("/img/custom-select-arrow.svg");
      width: 15px;
      height: 15px;
      background-size: 12px;
      right: 0;
      top: 24px;
      position: absolute;
      background-repeat: no-repeat;
    }
    .Select-arrow {
      display: none;
    }
  }
}

.newtext {
  font-family: 'Pier Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.46875px;
  color: $primaryColor;
  width: calc(100% - 120px);
  margin: 0;
  @media #{$phone767} {
    width: 100%;
  }
}

.btnviewinsight {
  bottom: 60px !important;
}

.newtestbtns {
  background: #fff;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  img {
    width: 50px;
  }
}

.movedet__txt {
  text-transform: uppercase !important;
  font-size: 12px !important;
  color: $primaryColor !important;
  font-weight: 600 !important;
}

.letterspacing {
  letter-spacing: 1.75px !important;
}

.letterspacing__one {
  letter-spacing: 1px !important;
}

.schedulebtn__new {
  letter-spacing: 1.75px;
  font-size: 14px !important;
  border-radius: 0 !important;
}

.popup__textarea--feedback {
  resize: none;
  width: 100%;
  // border: 1px solid #e5e5e5;
  padding: 10px 15px;
  border: 2px solid #E5E5E5;
  height: 135px;
  // color: #9B9B9B;
  color: $primaryColor;
  font-size: 16px;
  font-family: 'Pier Sans';
  &:focus {
    outline: none;
  }
}

.feedback__error {
  margin-bottom: 14px;
}

.rating-icons-feedback {
  margin: 0 0 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  >li {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 40px;
      cursor: pointer;
    }
    span {
      margin-top: 5px;
      display: block;
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: #606A7D;
    }
  }
}

.feedback__button--client {
  background: $secondaryColor;
  display: inline-block;
  padding: 15px 22px 14px 22px;
  color: #fff;
  font-family: 'Pier Sans';
  font-weight: 500 !important;
  font-size: 10px !important;
  letter-spacing: 1.75px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 10px;
}

.showonlymob {
  display: none;
  @media #{$tablet768} {
    display: block;
  }
}

.desktopchatebuttn {
  margin-right: 10px;
  @media #{$tablet768} {
    display: none;
  }
}

.mobileclosemob.tabmobileclosemob {
  @media #{$tablet768} {
    margin-right: 45px
  }
}

.form__label--text.formlabeltextedit {
  margin-bottom: 10px;
}

.add--modal.add--modaledit {
  .modal-body {
    padding: 24px !important;
  }
  #close_user_modal {
    position: static;
    padding: 0;
  }
}

.form__headerflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .modal-title-custom {
    margin: 0 !important;
  }
}

.coverage__area.coverage__areaedit {
  .Select-value-icon {
    padding: 2px 8px 4px !important;
    font-weight: normal;
  }
}

.show__mob--desk {
  position: relative;
  display: inline-block !important;
  width: calc(100% - 80px) !important;
  margin: 15px 40px !important;
}

.dec__marg--btm {
  margin-bottom: 0 !important;
}

.popup__thanks--btn.remove__strategist {
  margin-top: 15px !important;
  min-height: 51px !important;
}

.popup__textarea {
  resize: none;
  width: 100%;
  border: 1px solid #e5e5e5;
  padding: 5px;
  &:focus {
    outline: none;
  }
}

.flexbuttons .btns__fullwidth.yes-remove {
  width: 100%;
}

.flexbuttons {
  display: flex;
  justify-content: space-between;
  .btns__fullwidth {
    width: 48%;
  }
}

.btngreenshedule {
  border-radius: 0 !important;
  font-size: 12px !important;
  letter-spacing: 1.75385px;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.btngreencancel {
  border-radius: 0 !important;
  font-size: 12px !important;
  letter-spacing: 1.75385px;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.flexd {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  width: 100% !important;
  .padd24 {
    padding-left: 24px !important;
  }
  .popup__header {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 24px;
    color: $primaryColor;
  }
  .close_img--white {
    background: none !important;
    border: none !important;
    cursor: pointer;
    margin-right: 24px;
    img {
      width: 32px;
    }
  }
}

.profile__modal {
  .modal-body.modalbody24 {
    padding: 24px !important;
  }
}

.addmarbtms {
  margin-bottom: 20px;
}

.close32 {
  width: 32px !important;
}

.uppercasefonts {
  text-transform: uppercase !important;
  margin-top: 15px;
}

.newheadergolbal {
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: $primaryColor !important;
}

.forlefttext {
  text-align: left !important;
}

.piersandfonts {
  font-family: 'Pier Sans' !important;
}

.no-radius {
  border-radius: 0 !important;
}

.marg25 {
  margin-bottom: 25px !important;
}

.rightsign {
  z-index: 1;
}

.tabletviewmargin {
  @media #{$tablet768} {
    padding: 0 15px !important;
  }
}

.tabletViewheader {
  display: block;

  @media (min-width: 1240px) {
    display: none;
    width: calc(100% + 30px);
    margin: 0 -15px;
  }

  .mobile-logo {
    max-width: 400px;
    margin: 0px auto 0px;
    z-index: 100;
    img {
      width: 100%;
    }
  }

}

.profile__modal--mobile {
  .modal-dialog {
    @media #{$mobileLandscape} {
      width: 96% !important;
    }
  }
}

.addpadbottom {
  @media #{$phone767} {
    margin-bottom: 75px !important;
  }
}

.fixedhead {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  z-index: 9999;
  @media #{$tablet768} {
    left: 15px;
    right: 15px;
    width: calc(100% - 30px);
  }
  @media #{$phone767} {
    left: 0;
    right: 0;
    width: 100%;
  }
}

.sendwrapper__mob {
  @media #{$phone767} {
    border-radius: 100px;
    margin: 0 20px;
    padding: 0 10px 0 24px;
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 9;
    height: auto !important;
  }
  textarea {
    max-height: 120px !important;
    min-height: 58px;
    //line-height: 58px !important;
    transition: all .1s ease-in-out;
    padding: 0 10px !important;
    &:focus {
      line-height: 28px !important;
    }
  }
}

.mobview__dropdown--select.tabmob {
  @media #{$tablet768} {
    width: calc(100% - 90px);
    margin: 0 45px;
  }
  // @media #{$phone767} {
  //   width: calc(100% - 0px) !important;
  //   margin: 100px 0 0 0 !important;
  // }
}

.modalmartop {
  @media #{$phone767} {
    margin-top: 60px !important;
  }
}

.chat_ul_scrollnew {
  @media #{$phone767} {
    height: calc(100% - 150px) !important;
    padding-bottom: 80px !important;
  }
}

.chat__listingnewmob {
  @media #{$phone767} {
    height: calc(100vh - 1px) !important;
  }
}

.upcoming__emptybtn {
  @media #{$phone767} {
    display: block;
    background: $secondaryColor;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    font-family: 'Pier Sans';
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.75385px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 26px 15px;
    border: 0;
    z-index: 99;
  }
}

.auth-name {
  position: relative;
  @media #{$tablet768} {
    font-size: 12px;
    z-index: 9999 !important;
  }
}

.addspace {
  @media #{$phone767} {
    padding-top: 100px !important;
  }
  .navbar-fixed-top {
    @media #{$tablet768} {
      top: 50px;
    }
    @media #{$phone767} {
      top: 65px;
    }
  }
}

.for__edit--center {
  @media #{$phone767} {
    position: absolute;
    right: 20px;
  }
}

.center__rate {
  @media #{$phone767} {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
  }
}

.tabcontent__padbtm {
  @media #{$tablet768} {
    padding-bottom: 60px !important;
  }
}

.hidelist__tab {
  @media #{$tablet768} {
    display: none !important;
  }
}

.mobview__dropdown--selectshced {
  @media #{$tablet768} {
    margin-top: 90px !important;
  }
}

.agentclient__btn--drop.gapdrop {
  @media #{$tablet768} {}
}

.msg__agent--greenbtnmob {
  font-family: 'Pier Sans';
  font-weight: bold !important;
  font-size: 10px !important;
  letter-spacing: 1.75px;
  text-align: center;
  background: $secondaryColor;
  border-radius: 0 !important;
  min-width: 128px;
  padding: 0px 22px !important;
  height: 40px !important;
  line-height: 40px !important;
  text-transform: uppercase;
  color: #fff;
  width:calc(100% - 50px);
  margin: 0 25px;
  &:hover,
  &:active {
    color: #fff;
    background: $secondaryColor;
  }
}

.mobschesection--label {
  @media #{$tablet768} {
    display: inline-block;
    font-size: 18px !important;
  }
}

.mobileclosemob {
  display: none;
  @media #{$tablet768} {
    display: block;
    margin-top: 10px;
    float: right;
  }
}

.tabletView {
  display: none;
  @media #{$tablet768} {
    display: block;
  }
}

.strategist__form--mob {
  // @media #{$tablet768} {
  //   position: absolute !important;
  //   transform: translateX(100%);
  //   transition: all .3s ease-in-out;
  //   padding-top: 45px !important;
  //   top: 0;
  // }
}

.strategist__form--mobshow {
  transform: translateX(0%) !important;
  z-index: 9999;
  background: #f7f7f7;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow-y: auto;
  .strategist_form {
    top: 0 !important;
    @media #{$phone6} {
      padding: 15px;
      min-height: auto;
      margin-bottom: 0px;
      padding-bottom: 20px;
      max-width: 100%;
      position: inherit;
      max-height: 100vh !important;
      height: 100vh !important;
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }
}

.scheduleapp__mob--btn {
  display: none;
  @media #{$tablet768} {
    display: block;
    background: $secondaryColor;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    font-family: 'Pier Sans';
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.75385px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 26px 15px;
    border: 0;
    z-index: 99;
  }
}

.notshowtab {
  @media #{$tablet768} {
    display: none !important;
  }
}

.scheduleappform__mob--btn {
  display: none;
  @media #{$phone6} {

    display: block;
    background: $secondaryColor;
    position: absolute;
    bottom: -15px;
    left: -15px;
    right: -15px;
    width: calc(100% + 30px);
    font-family: 'Pier Sans';
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center;
    letter-spacing: 1.75385px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 26px 15px !important;
    border: 0 !important;
    z-index: 999 !important;
    border-radius: 0 !important;
    top: auto;
  }
}

.desktopmenu {
  display: block;
  @media (max-width: 1240px) {
    display: none;
  }
}

.tab__section--label {
  @media #{$tablet768} {
    margin-top: 15px !important;
    font-size: 24px !important;
  }
}

.appointment__desktop {
  @media #{$tablet768} {
    display: none !important;
  }
}

.flexboxnewmob {
  @media #{$phone6} {
    display: unset !important;
  }
  .not__int--txt {
    @media #{$phone6} {
      font-size: 11px;
      margin: 0 0 15px 0;
      width: 100%;
    }
  }
  .ratetown__btn {
    @media #{$phone6} {
      width: 100%;
      margin: 15px 0 25px 0;
      display: inline-block;
      text-align: left !important;
    }
  }
}

.toggle__wrapper--clients {
  position: relative;
  display: flex;
  align-items: flex-start;
  p {
    width: auto;
    float: left;
    margin: 0;
    padding: 0;
    position: static !important;
  }
  .para__color {
    // color: #71777B !important;
    width: calc(100% - 110px);
    display: inline-block;
  }
  .para__middle {
    width: 40px;
    margin-top: 9px;
  }
  span {
    width: auto;
    float: left;
    margin-left: 10px;
    font-size: 16px;
  }
  .switch1 {
    margin-top: -7px;
    position: absolute;
    right: 0;
    width: 68px !important;
  }
  .slider1 {
    &:before {
      content: '';
      left: 6px !important;
    }
  }
  .yes__label {
    color: $secondaryColor !important;
    font-weight: 500 !important;
    font-family: "Pier Sans";
  }
  .no__label {
    color: #D0021B !important;
    font-weight: 500 !important;
    font-family: "Pier Sans";
  }
}

.close__btn--mob {
  margin-right: 20px;
}

.before__edit-list {
  ul {
    height: calc(100vh - 146px);
    z-index: 99999;
    overflow: hidden auto;
  }
}

button {
  outline: 0;
}

.blank__img {
  height: calc(100vh - 85px);
}

.drag__list--icons {
  background-image: url('/img/rate-remove.svg') !important;
  background-size: 30px !important;
  height: 30px;
  outline: 0;
}

.hide_schedule--sidebar {
  display: none;
}

.drag__drop--ratetown {
  font-family: 'Pier Sans';
  font-weight: bold !important;
  letter-spacing: 1.75px;
  text-align: center;
  background: $secondaryColor;
  border-radius: 0 !important;
  width: calc(100% - 30px);
  padding: 0px 10px !important;
  height: 46px !important;
  line-height: 46px !important;
  text-transform: uppercase;
  color: #fff;
  border: 0;
  margin: 15px;
}

.flexboxnew {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .ratetown__btn {
    font-family: 'Pier Sans' !important;
    font-weight: bold !important;
    font-size: 16px;
    line-height: 16px;
    color: $secondaryColor;
    cursor: pointer;
    width: 176px;
    text-align: right;
  }
}

.close__btn--townrate {
  left: 30px;
  right: auto !important;
}

.rate__color {
  color: #5D7A7F !important;
}

.for__edit {
  float: right;
  font-family: 'Pier Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #BBD400;
  cursor: pointer;
}

.rate__town--outer {
  position: relative;
  height: calc(100% - 85px);
  .rate__town--wrapper {
    width: 331px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .rate__town--img {
      max-width: 199px;
    }
    .rate__town--para {
      margin-top: 21px;
      margin-bottom: 21px;
      font-family: 'Pier Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      color: #5C6A81;
    }
    .btn__contant {
      background: $secondaryColor;
      padding: 20px 10px;
      min-width: 303px;
      font-family: 'Pier Sans';
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 1.75385px;
      text-transform: uppercase;
      color: #FFFFFF;
      border-radius: 0;
    }
  }
  .nodisplay {
    display: none;
  }
  .drag__remove--list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .remove__list {
      width: 30px;
      margin-right: 20px;
      background: none;
      border: 0;
      padding: 0;
    }
    .town__naming {
      width: calc(100% - 100px);
      font-family: 'Pier Sans';
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #3B3B3B;
    }
    .drag__list {
      width: 30px;
      margin-left: 20px;
    }
  }
  .drag__wrapper {
    background: #F4F4F4;
    border-radius: 80px;
    width: calc(100% - 30px);
    margin: 15px;
    padding: 8px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .drag__icon {
      width: 28px;
      margin-right: 20px;
    }
    .drag__txt {
      width: calc(100% - 48px);
      font-family: 'Pier Sans';
      font-size: 11px;
      line-height: 16px;
      color: #444444;
      margin: 0;
      padding: 0;
    }
  }
  .rate__town--listing {
    ul {
      li {
        border-bottom: 2px solid #F4F4F4;
        padding: 20px 20px;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        transform: translate3d(0, 0, 0) !important;
        .serials {
          width: 29px;
          height: 29px;
          border: 2px solid $secondaryColor;
          margin-right: 17px;
          border-radius: 50%;
          display: flex;
          color: $secondaryColor;
          font-family: 'Pier Sans';
          font-weight: 500;
          font-size: 13px;
          align-items: center;
          justify-content: center;
        }
        .names {
          font-family: 'Pier Sans';
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #3B3B3B;
        }
        .profile__image {
          width: 36px;
          height: 36px;
          margin-right: 17px;
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .towns__agents--tabs {
    display: flex;
    text-align: center;
    li {
      width: 50%;
      a {
        margin: 0 !important;
        border-radius: 0;
        color: #9B9B9B;
        padding: 20px 0 !important;
        font-family: 'Pier Sans';
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .active {
      a {
        background: none !important;
        border-bottom: 2px solid $secondaryColor;
        color: $secondaryColor;
        &:hover {
          color: $secondaryColor;
        }
        &:focus {
          color: $secondaryColor;
        }
      }
    }
  }
}

.btn-warning {
  display: none;
}

.popup__paragraph {
  background: #fff;
  text-align: center !important;
  width: 100%;
  display: inline-block !important;
  padding: 5px 15px;
  margin: 10px 0 0 0;
}

.popup__thanks--btn {
  font-family: 'Pier Sans' !important;
  font-weight: bold;
  background: $primaryColor;
  border-radius: 0px;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 1.75px;
  text-align: center;
  min-height: 58px;
  padding: 18px 12px;
  width: 100%;
  border: 0;
  margin: 0 !important;
  text-transform: uppercase;
}

.remove__client-agent {
  padding-top: 25px;
  font-family: 'Pier Sans' !important;
  font-weight: 600;
  font-size: 16px;
  color: $secondaryColor;
  text-align: center;
  cursor: pointer;
  background: none;
  border: 0;
  width: 100%;
  text-align: center;
  padding: 0;
  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
}

.chat__listing--newclient {
  height: calc(100% - 215px) !important;
}

.date__format--client {
  font-family: "Pier Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.337647px;
  color: #9B9B9B;
  margin: 5px 0 0 0;
}

.search__poppins {
  font-family: 'Poppins' !important;
}

.search__peirsans {
  font-family: 'Pier Sans' !important;
}

.strategist__new--search {
  width: 100%;
  padding: 15px 20px;
  input[type="text"] {
    background-image: url('/img/client_arrow.svg');
    background-position: 96% center;
    background-size: 15px !important;
    box-sizing: border-box;
    font-size: 17px;
    background-color: white;
    background-repeat: no-repeat !important;
    padding: 13px 20px 13px 20px !important;
    border-radius: 3px;
    height: 52px;
    border: 1px solid #CED0DA;
    margin-bottom: 25px !important;
    position: relative;
    top: 2px;
  }
}

.new__search--outer {
  width: calc(100% - 40px)!important;
  max-height: 100% !important;
  overflow-y: auto;
  box-shadow: 2px 5px 10px #c5c5c5;
  position: absolute;
  background: #fff;
  z-index: 99;
  top: 155px !important;
  left: 20px !important;
  right: 20px !important;
  .custom-checkbox {
    padding-left: 0 !important;
  }
}

.hover__button {
  cursor: pointer;
}

.minus__gap {
  float: none !important;
  display: inline-block !important;
}

.ul__scroll::-webkit-scrollbar {
  width: 0.9em;
  height: 0.9em;
  height: 15px !important;
}

.ul__scroll::-webkit-scrollbar-track {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #f1f1f1 !important;
}

.ul__scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #c1c1c1 !important;
  -webkit-box-shadow: none;
}

.ul__scroll::-webkit-scrollbar-thumb:window-inactive {
  background: #c1c1c1 !important;
}

.ul__scroll {
  overflow-x: auto !important;
  white-space: nowrap;
  max-width: 100%;
}

.msg__desktop {
  display: block !important;
  font-family: 'Pier Sans' !important;
  font-weight: bold !important;
  border-radius: 0px;
  font-size: 12px !important;
  color: #FFFFFF !important;
  letter-spacing: 1.75px !important;
  text-align: center !important;
  padding: 0!important;
  margin: 0 !important;
  height: auto;
}

.chat__mobile {
  display: none !important;
}

.profile--button {
  text-transform: uppercase;
}

.btn__grey--schedule {
  font-family: 'Pier Sans' !important;
  border-radius: 0px !important;
  font-size: 12px !important;
  color: #FFFFFF;
  letter-spacing: 1.75px;
  text-align: center;
  height: 58px;
  padding: 18px 12px !important;
  background: #9B9B9B;
  border-color: #9B9B9B !important;
  margin-top: 12px;
  margin-bottom: 18px;
  width: 100%;
  text-transform: uppercase;
  &:hover,
  &:visited {
    color: #FFFFFF !important;
  }
}

.share__para {
  font-size: 14px !important;
  line-height: 17px !important;
  color: $primaryColor !important;
  opacity: 0.9;
  margin-bottom: 13px;
  margin-top: 15px !important;
  float: left;
  width: 100%;
}

.toggle__wrapper {
  position: relative;
  p {
    position: absolute;
    top: 0;
    left: 75px;
    font-size: 16px !important;
    line-height: 19px !important;
    color: $primaryColor !important;
    span {
      font-weight: bold !important;
      color: $primaryColor !important;
    }
  }
  .slider1 {
    background-color: #D0021B;
    padding: 14px !important;
    &::before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 7px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
    }
  }
  input:checked+.slider1 {
    background-color: $secondaryColor;
  }
  .switch1 {
    width: 60px;
  }
}

.help__find--btn {
  margin-left: 0 !important;
}

.schedulemsg__Btn {
  font-weight: bold !important;
  font-size: 12px !important;
  text-transform: uppercase;
  font-family: 'Pier Sans' !important;
}

.msg__agent--greenbtn {
  font-family: 'Pier Sans';
  font-weight: bold !important;
  font-size: 10px !important;
  letter-spacing: 1.75px;
  text-align: center;
  background: $secondaryColor;
  border-radius: 0 !important;
  min-width: 128px;
  padding: 0px 22px !important;
  float: right;
  height: 40px !important;
  line-height: 40px !important;
  text-transform: uppercase;
  color: #fff;
  &:hover,
  &:visited {
    background: $secondaryColor;
    color: #fff;
  }
}

.schedule__sidebar--ht {
  height: calc(100vh - 110px) !important;
}

.backdrop-remove {
  display: none !important;
}

.btn__view--insight {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background: $secondaryColor;
  border-radius: 50px;
  padding: 12px 18px;
  border: none;
  position: absolute;
  bottom: 30px;
  right: 15px;
  outline: none;
}

.navigation__links.btn__grey.client_sms--btn.open__sans--font.message--green {
  background: $secondaryColor !important;
}

.login-tab-wrapper {
  .Select-control {
    height: 52px;
    padding: 0px 5px;
    background-color: #fff;
    font-family: 'Pier Sans' !important;
    font-weight: 500;
    font-size: 16px;
    color: #72777A;
    background-image: url('/img/client_arrow.svg') !important;
    border: 2px solid #e5e5e5 !important;
    border-radius: 4px !important;
    background-repeat: no-repeat;
    /* background-position: 457px 18px; */
    background-size: 21px;
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    cursor: pointer;
    background-position: 96% center !important;
    background-repeat: no-repeat !important;
    .Select-placeholder {
      line-height: 46px;
    }
    .Select-clear {
      font-size: 0 !important;
    }
  }
  .Select-arrow-zone {
    display: none;
  }
}

.edit__suburbs {
  cursor: pointer;
}

.ReactModal__Content--after-open {
  overflow: inherit !important;
}

.Select-menu-outer {
  max-height: 100px;
}

.overflow__hide {
  overflow: hidden;
}

.not__int--txt {
  font-family: "Pier Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71777B;
  margin: 0;
  img {
    margin-right: 5px;
    position: relative;
    top: -3px;
  }
}

.strategist__active {
  background: $secondaryColor;
}

.strategist__list.strategist__active {
  .details {
    h2 {
      color: #fff !important;
    }
    p {
      color: #fff !important;
    }
  }
}

.strategist__list {
  cursor: pointer;
}

.chat__box__open {}

.Select-menu {
  max-height: 80px;
  text-align: left;
}

.Select-placeholder {
  text-align: left;
}

.Select-control {
  text-align: left;
}

.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #666;
  cursor: pointer;
  display: block;
  padding: 4px 10px;
  z-index: 999;
  position: relative;
  text-align: left;
}

.right__chat--section {
  background: #0000008a;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
}

.add--modal {
  .modal-dialog {
    width: 482px;
    margin: 30px auto;
  }
  .modal-content {
    border-radius: 0px;
  }
  .modal-body {
    padding: 20px 29px 20px;
  }
  .modal-title {
    font-size: 24px;
    font-weight: 500;
    color: #141820;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 24px;
  }
  .h3-custom {
    font-family: 'Pier Sans';
    font-weight: normal;
    font-size: 12px;
    color: #646A78;
    letter-spacing: 0;
    line-height: 19px;
    margin: 9px 0 0 0;
    text-align: left;
  }
  label {
    width: 100%;
    font-family: Pier Sans;
    font-weight: 700;
    font-size: 10px;
    color: #9b9b9b;
    letter-spacing: 1.46px;
    margin: 0;
    line-height: normal;
    text-align: left;
  }
  .form-control {
    box-shadow: none;
    border: 1px solid #e5e5e5 !important;
    height: 50px !important;
    margin-top: 10px;
    margin-bottom: 20px !important;
    padding: 15px 10px !important;
    letter-spacing: 1.75px;
    border-radius: 0;
    color: #495057;
    font-size: 14px !important;
  }
  button {
    color: #fff;
    background-color: $secondaryColor;
    border-color: $secondaryColor;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    border-radius: 0px;
    padding: 14px 0;
    &:hover {
      background-color: $secondaryColor;
      border-color: $secondaryColor;
      color: #fff;
    }
  }
  #close_user_modal {
    position: absolute;
    top: 3px;
    right: 31px;
    background: transparent;
    border: 0;
    img {
      max-width: 32px;
    }
  }
}

.right__section {
  background: #fff;
  width: 37%;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 98px;
  left: auto;
  .strategist__listing {
    width: 100%;
    float: left;
    border-bottom: 2px solid #E5E5E5;
    padding-bottom: 0px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 15px 20px;
        .strategist--profile--item {
          margin-right: 16px;
          width: 45px;
          height: 45px;
          object-fit: cover;
          border-radius: 50%;
          background: #f7f7f7;
          overflow: hidden;
          img {
            border-radius: 50px;
            width: 45px;
            height: 45px;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }
        .details {
          flex: 1;
        }
        h2 {
          font-family: "Pier Sans";
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          color: $primaryColor;
          margin: 0;
        }
        p {
          font-family: "Pier Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #636A7A;
          margin: 0;
        }
      }
    }
  }
  .chat__listing--section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .chat__listing {
    width: 100%;
    background: #fff;
    // height: calc(100vh - 90px);
    height: calc(100vh - 1px);
    min-height: 5vh;
    max-height: 100vh;
    float: left;
    ul {
      list-style: none;
      padding: 0 0 35px 0;
      margin: 0;
      overflow-y: auto;
      //   height: calc(100% - 220px);
      height: calc(100% - 255px);
      display: flex;
      flex-direction: column-reverse;
      li {
        padding: 25px 23px 0 23px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        img {
          margin-right: 16px;
          width: 45px;
          height: 45px;
          object-fit: cover;
          margin-top: 23px;
          border-radius: 50%;
        }
        .details {
          flex: 1;
          h6 {
            font-family: "Pier Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.337647px;
            color: #9B9B9B;
            margin: 0 0 7px 0;
          }
          p {
            margin: 0;
            font-family: "Pier Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.41px;
            color: #231F20;
            padding: 12px 15px;
            background: #E5E5EA;
            border-radius: 17px;
            display: inline-block;
            position: relative;
            z-index: 9;
            &::before {
              content: "";
              background: none !important;
              width: 40px;
              height: 40px;
              background-size: 40px;
              position: absolute;
              left: -10px;
              background-repeat: no-repeat;
              z-index: -9;
              top: 2px;
            }
          }
        }
        h5 {
          color: #9B9B9B;
          font-size: 12px;
        }
      }
      .you {
        flex-direction: row-reverse;
        img {
          margin-left: 16px;
          margin-right: 0;
        }
        .details {
          text-align: right;
          p {
            background: $secondaryColor !important;
            color: #fff !important;
            display: inline-block;
            text-align: left;
            position: relative;
            z-index: 9;
            &::before {
              content: "";
              background: url(/img/green-tail.png);
              width: 40px;
              height: 40px;
              background-size: 36px;
              background-repeat: no-repeat;
              position: absolute;
              left: auto !important;
              z-index: -9;
              top: 2px;
              right: -14px;
            }
          }
        }
      }
    }
    .send__wrapper {
      height: 60px;
      background: #fff;
      border: 2px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 36px;
      padding: 0 23px;
      margin: 0 23px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      input {
        border: none;
        background: #fff;
        font-family: "Pier Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 50px;
        color: $primaryColor;
        max-width: 80%;
        width: calc(100% - 50px);
        margin-bottom: 0 !important;
        &:focus {
          outline: 0;
        }
      }
      button {
        border: none;
        background: #fff;
        font-family: "Pier Sans";
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 50px;
        color: #9B9B9B;
        float: right;
        cursor: pointer;
        outline: none;
        width: 50px;
      }
    }
  }
}

.popular__cities {
  position: relative;
  .close__not--int {
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: -7px;
    img {
      width: 24px;
    }
  }
}

.gray__box {
  // background: #F4F4F4 !important;
  // border-radius: 8px !important;
  text-align: center;
  position: relative !important;
  // height: calc(100% - 45px) !important;
  // min-height: 208px;
  padding-top: 66%;
  height: auto !important;
  img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    img {
      position: static;
      width: auto;
      height: auto;
    }
    p {
      font-family: "Pier Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: $secondaryColor;
      margin: 22px 0 0 0;
      text-align: center;
      padding: 0;
    }
  }
}

.suggest__btn {
  font-family: $PierSans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $secondaryColor;
  background: #f4f4f4;
  border-radius: 50px;
  border: 0;
  padding: 12px 25px;
  margin-left: 10px;
  outline: none;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
  @media #{$phone6} {
    // font-size: 14px;
    // padding: 12px 19px;
    // margin-left: 0;
    // width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    text-align: left;
    padding: 12px 19px;
    margin-left: 0;
    width: 100%;
    margin-bottom: 20px;
  }
}

.pre__margin {
  padding-top: 49px;
}

.client__home--page {
  ::-webkit-scrollbar {
    width: 0px;
    /* remove scrollbar space */
    background: transparent;
  }
  header {
    padding: 20px 0;
    .avart__arrow--icon {
      top: 12px !important;
    }
  }
  .logo-header {
    width: 100%;
    img {
      height: 125px;
      @media #{$desktop1023} {
        height: 35px;
      }
    }
  }
  .costom-dropdown {
    .dropdown-menu {
      left: auto;
    }
  }
  .section--label {
    margin-top: 0;
    font-size: 28px;
    color: $primaryColor;
    line-height: 48px;
    font-family: 'Pier Sans';
    font-weight: bold;
  }
  .profile-name {
    font-family: 'Pier Sans';
    font-weight: 500;
    font-size: 16px;
    color: #73777A;
    margin: 0 0 0 4px;
    text-transform: capitalize;
  }
  .profile-email {
    margin: 0 0 0 7px;
  }
  .react-datepicker-popper {
    transform: translate3d(130px, -282px, 90px) !important;
    .react-datepicker__header {}
    .react-datepicker__month {
      .react-datepicker__week {
        .react-datepicker__day {
          font-weight: bold !important;
          color: $primaryColor;
        }
        .react-datepicker__day--disabled {
          font-weight: normal !important;
        }
      }
    }
  }
  .upcoming__empty {
    /* background-image: url(/img/curved-arrow-with-broken-line.svg); */
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    width: 100%;
    max-width: 532px;
    min-height: auto;
    padding: 30px 0px 60px;
    display: none !important;
    &:first-child {
      display: block !important;
    }
    .arrow_img {
      float: right;
      height: 180px;
      margin: 20px -20px 0 0;
    }
    p {
      font-family: 'Pier Sans';
      font-weight: 500;
      font-size: 28px;
      color: #9B9B9B;
      text-align: center;
    }
  }
  .strategist_side {
    width: 100%;
    padding: 0 40px;
    // max-width: 56%;
    // max-width: 65%;
    @media #{$desktop1023} {
      padding: 0 20px !important;
    }
    .strategist__slider--slider {
      width: 100%;
      max-width: 200px;
    }
    .navbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .strategist__profile {
      display: flex;
      /* align-items: center; */
      margin: 20px 0;
      .slick-slider {
        width: 56%;
        margin: 0 20px;
        .slick-list {
          .slick-track {
            min-width: 314px;
            /* .slick-slide{
        } */
          }
        }
      }
      .strategist--title {
        margin: 0px;
        font-family: 'Pier Sans';
        font-weight: 500;
        font-size: 20px;
        // color: #72777A;
        color: $primaryColor;
        line-height: 48px;
        @media #{$phone6} {
          font-size: 16px;
          line-height: 48px;
        }
      }
      .strategist--profile--list {
        /* display: flex;
   align-items: center;
   justify-content: space-between; */
      }
      .strategist--profile--item {
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;
        text-align: center;
      }
      .strategist--img {
        border-radius: 50px;
        width: 56px;
        height: 56px;
        object-fit: cover;
      }
    }
    .no-ImgBox {
      min-width: 56px;
      min-height: 56px;
      border-radius: 50px;
      background: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Pier Sans';
      font-weight: bold;
      font-size: 24px;
      color: $secondaryColor;
      text-transform: capitalize;
    }
  }
  // towns__tab--sectino Css::START
  .schedule__collapse {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    cursor: pointer;
    .schedule--img--box {
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .schedule--img,
      .no-ImgBox {
        margin-right: 22px;
        width: 53px;
        max-height: 53px;
        max-width: 53px;
        height: 53px;
        border-radius: 50px;
        text-align: center;
        vertical-align: middle;
        line-height: 53px;
        overflow: hidden;
        img {
          max-width: 100%;
        }
      }
      /* .schedule__profile--text{
    background: rgba(198, 206, 74, 0.2);
       line-height: 50px;
           font-family: 'Pier Sans';
   font-weight: bold;
   font-size: 24px;
   color: $secondaryColor;
   } */
      .title {
        font-family: 'Pier Sans';
        font-weight: bold;
        font-size: 15px;
        color: $primaryColor;
        text-align: left;
        margin: 0px 0 6px;
        text-transform: capitalize;
      }
      p {
        font-family: 'Pier Sans';
        font-weight: 500;
        font-size: 14px;
        color: #73777A;
        text-align: left;
        margin: 0px;
      }
    }
  }
  .towns__tab--sectino,
  .strategist_form--tabe {
    .nav>li>a:focus,
    .nav>li>a:hover,
    .nav-pills>li.active>a,
    .nav-pills>li.active>a:focus,
    .nav-pills>li.active>a:hover {
      background: none !important;
    }
    .nav-pills {
      margin: 10px 0 15px;
      .active {
        a {
          color: $secondaryColor !important;
        }
      }
      .nav-item {
        margin-right: 10px;
        a {
          font-family: $PierSans !important;
          font-weight: bold;
          font-size: 12px;
          color: $primaryColor;
          letter-spacing: 1.75px;
          text-align: center;
          padding-left: 0;
          margin: 0 30px 0 0 !important;
          &:hover,
          &:focus {
            background: none !important;
          }
        }
      }
    }
    .popular__towns--container {
      .popular__cities--wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .towns__boxnew {
        position: relative;
        padding-top: 66%;
        height: auto !important;
        img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
      .towns__box {
        width: 100%;
        max-width: 100%;
        height: 208px;
        position: relative;
        background: #ccc;
        border-radius: 8px;
        margin-right: 0px;
        margin-bottom: 16px;
        overflow: hidden;
        cursor: pointer;
      }
      .popular__cities {
        margin-bottom: 24px;
        width: 100%;
        max-width: 48%;
        /* height: 208px; */
        height: auto;
        .popular__cities--city-image {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
        .position-overlay {
          opacity: 0.56;
          /* background: rgba(28,31,39,.38); */
          border-radius: 8px;
          position: absolute;
        }
        .overlay {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: $primaryColor;
        }
        .popular__cities--name {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          letter-spacing: 3.51px;
          width: 100%;
          text-align: center;
          top: 50%;
          left: 0;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          position: absolute;
        }
      }
    }
  }
  .towns__tab--sectino {
    .tab-content {
      .tab-pane {}
    }
  }
  // towns__tab--sectino Css::END
  //strategist_form Css::START
  .strategist_form--inherit {
    position: inherit !important;
  }
  .strategist_form {
    // width: 100%;
    // max-width: 44%;
    // background-color: #f7f7f7;
    // padding: 78px  0px 50px 0px;
    // height: 100%;
    // max-height: 100%;
    // // position: fixed;
    // overflow: auto;
    // right: 0;
    width: 100%;
    // max-width: 44%;
    max-width: 35%;
    background-color: #f7f7f7;
    padding: 20px 0px 50px 0px;
    height: 100%;
    // max-height: 100%;
    max-height: 100vh;
    overflow: auto;
    right: 0;
    position: fixed;
    top: 0px;
    padding-top: 50px;
    select.custom-select {
      height: 52px;
      padding: 0px 28px;
      background-color: #f7f7f7;
      font-family: 'Pier Sans' !important;
      font-weight: 500;
      font-size: 16px;
      color: #72777A;
      background-image: url(/img/client_arrow.svg) !important;
      border: 2px solid #e5e5e5 !important;
      border-radius: 4px !important;
      background-repeat: no-repeat;
      /* background-position: 457px 18px; */
      background-size: 21px;
      appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      cursor: pointer;
    }
    .upcoming__empty {
      position: absolute;
      left: 50%;
      top: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 502px;
      min-height: auto;
      padding: 30px 0px 60px;
      text-align: center;
      p {
        font-family: 'Pier Sans';
        font-size: 24px;
        color: #9B9B9B;
        text-align: center;
      }
      .empt__cal--img {
        margin-bottom: 24px;
      }
      button {
        border-radius: 0px;
        height: 58px;
        font-family: 'Pier Sans';
        font-weight: bold;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 1.75px;
        text-align: center;
        margin-top: 43px;
      }
    }
    .strategist_form--tabe {
      position: relative;
      .nav-pills {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 40px;
        padding-right: 40px;
        .nav-item {
          a {
            color: #A7AEBB;
          }
        }
      }
      #new-appointment {
        margin-top: 15px;
        padding: 0 40px;
      }
      #upcoming_tab {
        margin-top: 44px;
        position: relative;
        height: 65vh;
      }
    }
    /* .upcoming__empty{
    display: none !important;
   } */
    .section--label {
      margin-bottom: 0;
      padding-right: 40px;
      padding-left: 40px;
      @media #{$desktop1023} {
        font-size: 20px !important;
      }
    }
    .select__label {
      font-family: 'Pier Sans' !important;
      font-weight: bold;
      font-size: 12px;
      color: $primaryColor;
      letter-spacing: 1.75px;
      margin-bottom: 14px;
    }
    .select__time {
      .clock-img {
        position: absolute;
        left: 23px;
        top: 43px;
      }
      .custom-select {
        padding-left: 71px !important;
      }
    }
    .profile__container--form {
      /* .panel {
    margin-bottom: auto;
    } */
      .panel-body {
        padding: 0px;
        .form-group {
          margin-bottom: 35px;
        }
      }
      .panel {
        background: transparent;
      }
    }
    .schedule__collapse--sectino {
      .schedule__collapse {
        border-bottom: 2px solid #E5E5E5;
        padding: 15px 40px;
        margin: 0px;
        /* &:first-child {
    border-top: 2px solid #E5E5E5;
    }  */
      }
    }
    .schedule__collapse--sectino {
      &:first-child {
        border-top: 2px solid #E5E5E5 !important;
      }
    }
  }
  //strategist_form Css::END
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      .arrow-img {
        position: absolute;
        right: 14px;
        top: 20px;
      }
      .date__picker {
        background-color: #f7f7f700;
        font-family: 'Pier Sans' !important;
        font-weight: 500;
        font-size: 16px;
        color: #72777A;
        height: 52px;
        padding: 16px 24px;
      }
      .time__picker {
        background: url(/img/time_img.svg) no-repeat left;
        background-position: 19px;
        padding-left: 78px !important;
        font-family: 'Pier Sans' !important;
        font-weight: 500;
        font-size: 16px;
        color: #72777A;
        height: 52px;
        padding: 16px 24px;
      }
    }
  }
}

// Profile modal ::START
.profile__modal {
  .modal-body {
    padding: 45px 60px !important;
  }
  .close-btn {
    position: absolute !important;
    top: 23px;
    right: 24px;
    width: auto;
    min-width: 40px;
    border: 0;
    background: transparent;
    z-index: 99;
    border: 0px !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  .modal {
    padding: 0px !important;
  }
  .modal-body {
    padding: 60px;
    button {
      margin-top: 28px;
    }
    .profile-title {
      font-family: 'Pier Sans' !important;
      font-weight: bold;
      font-size: 20px;
      color: $primaryColor;
      line-height: 24px;
      text-align: center;
      margin: 15px 0;
      text-transform: capitalize;
    }
    .profile-sub--title {
      font-family: 'Pier Sans' !important;
      font-weight: 500;
      font-size: 16px;
      color: #9B9B9B;
      text-align: center;
      margin: 0;
    }
    .user--profile {
      margin: auto;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: cover;
        height: 72px;
        width: 72px;
        border-radius: 50px;
      }
      .no-ImgBox {
        min-width: 63px;
        min-height: 63px;
        line-height: 63px;
        margin: 0px;
      }
    }
    .email__contact {
      a {
        font-family: 'Pier Sans' !important;
        font-weight: normal;
        font-size: 16px;
        color: $secondaryColor;
        display: block;
        text-align: center;
        margin: 15px 0;
        text-decoration: underline;
        img {
          margin-right: 10px;
        }
      }
      margin-bottom: 22px;
    }
    span {
      margin-bottom: 15px;
    }
    p,
    span {
      font-family: 'Pier Sans' !important;
      font-weight: normal;
      font-size: 16px;
      // color: #626A7B;
      letter-spacing: 0;
      line-height: 24px;
      text-align: left;
      margin-top: 22px;
      margin-bottom: 0px;
      display: inline;
      color: #606A7D;
    }
    .ShowMoreToggle {
      text-decoration: underline;
    }
    .profile--button {
      font-family: 'Pier Sans' !important;
      font-weight: bold;
      background: $secondaryColor;
      border-radius: 0px;
      font-size: 12px;
      color: #FFFFFF;
      letter-spacing: 1.75px;
      text-align: center;
      height: auto;
      padding: 16px 12px;
    }
  }
}

// Profile modal ::END
// appointment modal::START
.appointment__modal {
  .react-datepicker-popper {
    transform: translate3d(130px, -282px, 90px) !important;
  }
  .react-datepicker__input-container {
    .appointment--select {
      padding-left: 71px !important;
      background-color: #fff !important;
    }
    select {
      padding-left: 71px !important;
    }
    .clock-img {
      position: absolute;
      left: 12px;
      top: 12px;
    }
  }
  .close-btn {
    position: absolute;
    top: 27px;
    right: 27px;
    width: auto;
    min-width: 40px;
    border: 0;
    background: transparent;
    z-index: 99;
    border: 0px !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  .modal {
    padding: 0px !important;
  }
  /* .modal-dialog{
margin: auto !important;
} */
  .modal-header {
    padding: 19px 30px 26px 30px;
    justify-content: space-between;
    border: 0px;
    .appointment-title {
      margin: 0px 0 15px 0;
      width: 100%;
      font-family: 'Pier Sans' !important;
      font-weight: bold;
      font-size: 32px;
      color: $primaryColor;
      letter-spacing: 0;
    }
    .close-btn {
      position: inherit;
    }
  }
  .modal-body {
    padding: 0px 24px 24px 24px !important;
    .appointment__list {
      margin-bottom: 27px;
      .list_1 {
        // font-weight: bold;
        // font-size: 12px;
        // color: $primaryColor;
        // letter-spacing: 1.75px;
        color: #606A7D;
        font-weight: normal !important;
      }
      li {
        padding: 0px 0px 12px;
        display: block;
        font-family: 'Pier Sans' !important;
        // font-weight: 500;
        font-size: 16px;
        // color: $primaryColor;
        cursor: pointer;
        color: #606A7D;
        font-weight: normal !important;
        line-height: normal;
      }
      .form-group {
        margin: 0px;
      }
      .custom-select {
        padding: 12px 0px;
        display: block;
        font-family: 'Pier Sans' !important;
        // font-weight: 500;
        font-size: 16px;
        // color: $primaryColor;
        cursor: pointer;
        height: auto;
        background: none !important;
        border: 0 !important;
        padding: 0 !important;
        color: #606A7D;
        font-weight: normal !important;
      }
    }
    .panel {
      margin-bottom: 0px !important;
      .panel-body {
        padding: 0px !important;
        .form-group {
          margin-bottom: 24px;
        }
        .select__label {
          font-family: 'Pier Sans' !important;
          font-weight: bold;
          font-size: 12px;
          color: $primaryColor;
          letter-spacing: 1.75px;
        }
        form {
          margin-top: 27px;
          input {
            height: 52px;
            font-family: 'Pier Sans' !important;
            font-size: 16px;
            color: $primaryColor;
          }
        }
      }
    }
    .btn--cancel {
      color: #E20000 !important;
      background: transparent !important;
      outline: 0px !important;
      padding: 10px 0 !important;
      margin-top: 10px !important;
      max-width: 120px;
      margin: auto;
    }
  }
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      .arrow-img {
        position: absolute;
        right: 18px;
        top: 21px;
        z-index: 99;
      }
      .date__picker {
        background-color: #fff;
        font-family: 'Pier Sans' !important;
        background-position: 10px;
        font-weight: 500;
        font-size: 16px;
        color: #72777A;
        height: 52px;
      }
      .time__picker {
        background: url(/img/time_img.svg) no-repeat left;
        background-position: 10px;
        padding-left: 71px !important;
        font-family: 'Pier Sans' !important;
        font-weight: 500;
        font-size: 16px;
        color: #72777A;
        height: 52px;
      }
    }
  }
}

// appointment modal::END
//view__booking--modal
.view__booking--react {
  .ReactModal__Content {
    background: transparent !important;
    border: 0px !important;
  }
  .modal-content {
    border: 0px !important;
  }
}

.view__booking--modal {
  .modal {
    padding: 0px !important;
  }
  .modal-dialog {
    margin: auto !important;
  }
  .modal-dialog {
    .modal-content {
      .modal-body {
        padding: 50px;
        img {
          max-width: 100%;
          margin: 10px 0 30px;
        }
        h1 {
          color: $secondaryColor;
          font-weight: bold;
        }
        p {
          font-family: 'Pier Sans';
          font-size: 16px;
          color: #5C6A81;
          letter-spacing: 0;
          text-align: center;
          line-height: 24px;
          padding: 0 42px;
          span {
            color: $secondaryColor;
          }
        }
      }
    }
  }
  button {
    border-radius: 0px;
    margin-top: 36px;
    padding: 19px 0;
    background: $secondaryColor !important;
    font-family: 'Pier Sans';
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 1.75px;
    text-align: center;
  }
}

//view__booking--modal ::END
// Media Css START
@media screen and (min-width: 767px) {
  .client_pl {
    padding-left: 0px;
  }
  .client_prt {
    padding-right: 0px;
  }
}


/* @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
.client__home--page {
.strategist_form{
   padding: 78px 10px 50px 10px;
       min-height: calc(100vh - 0px);
}
}
} */

@media only screen and (max-width: 1280px) {
  .client__home--page {
    .msg__agent--greenbtn {
      float: none;
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .client__home--page {
    .towns__tab--sectino .nav-pills .nav-item,
    .strategist_form--tabe .nav-pills .nav-item {
      margin: 0px;
      a {
        text-align: left;
      }
    }
    .strategist_side {
      .upcoming__empty {
        padding: 0 40px !important;
        .arrow_img {}
      }
    }
  }
}

@media only screen and (max-width: 812px) {
  .strategist_side {
    padding: 0 15px;
    max-width: 100%;
    .upcoming__empty {
      height: 100%;
      display: inline-block;
      background-size: 21%;
      position: inherit;
      transform: inherit;
      margin: auto;
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .client__home--page {
    .upcoming__empty {
      .arrow_img {
        float: none;
        height: 180px;
        margin: 20px -20px 0 0;
      }
    }
  }
  .client__home--page {
    .logo-header {
      img {
        height: 35px;
      }
    }
    .schedule__collapse {
      .schedule--img--box {
        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 91px;
        }
      }
    }
    .strategist_form {
      .schedule__collapse--sectino {
        .schedule__collapse {
          padding: 15px 20px;
        }
      }
    }
  }
  .client__home--page {
    .strategist_form,
    .strategist_side {
      position: inherit;
      width: 100%;
      max-width: 100%;
    }
    .msg__agent--greenbtn {
      float: right;
      display: inline-block;
    }
  }
}

.mob__header--close {
  display: none;
}

@media only screen and (max-width: 768px) {
  .client__home--page {
    .msg__agent--greenbtn {
      float: right;
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 767px) {
  .appointment__modal {
    .modal-body {
      padding: 0px 20px 10px 20px !important;
    }
  }
  .appointment__title--mob {
    padding: 0 0 0 20px;
    font-size: 22px !important;
    margin-bottom: 15px !important;
    text-align: left;
  }
  .modal__dialog--mob {
    width: 96%;
  }
  .popup__paragraph {
    background: #fff;
    text-align: center !important;
    width: 100%;
    display: inline-block !important;
    padding: 5px 15px;
    margin: 10px 0 0 0;
  }
  .popup__thanks--btn {
    margin: 15px 0px !important;
  }
  .client__home--page {
    .msg__agent--greenbtn {
      float: none;
      display: block;
    }
  }
  .profile--button {
    text-transform: capitalize !important;
  }
  .btn__grey--schedule {
    background: #626A7B;
    border-color: #626A7B !important;
    text-transform: capitalize !important;
  }
  .msg__desktop {
    display: none !important;
  }
  .chat__mobile {
    display: block !important;
    margin: 0 !important;
    border-radius: 0px !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    letter-spacing: 1.75px !important;
    text-align: center !important;
    width: 100%;
  }
  .closearrow {
    width: 20px !important;
  }
  .desk__close {
    display: none;
  }
  .mob__header--close {
    display: block;
    background: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    min-height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    div {
      width: 0;
      flex: 1 1 0;
    }
    .centered {
      text-align: center;
      flex-grow: 5;
    }
    h3 {
      margin: 0px !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }
    .close-btn {
      position: static !important;
    }
  }
  .profile__modal--mobile {
    .modal {
      left: -2px;
      right: -2px;
    }
  }
    
  .share__para {
    display: block !important;
  }
  .profile__modal .modal-body p.showmore__para {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 23px !important;
    color: #606A7D !important;
  }
  .mobile__btns {
    z-index: 9;
    display: block;
    margin-top: 50px;
    .profile--button {
      margin: 0 !important;
    }
    .btn__grey--schedule {
      margin: 0 !important;
    }
  }
  .client__home--page {
    .secondary__navbar__header--navigation {
      margin: 0px;
      .client_sms--btn {
        margin: 0 4px;
      }
      .profile-name {
        margin: 0px;
      }
    }
    .towns__tab--sectino .nav-pills .nav-item,
    .strategist_form--tabe .nav-pills .nav-item {
      width: auto;
      a {
        text-align: left;
      }
    }
    .towns__tab--sectino {
      .nav-pills {
        .nav-item {
          display: block;
          width: 100%;
          a {
            text-align: left;
          }
        }
      }
      .popular__towns--container {
        .popular__cities--wrapper {
          flex-direction: column;
          .popular__cities {
            max-width: 100%;
            margin-bottom: 20px;
            min-height: 208px;
            height: auto;
          }
        }
      }
    }
    .strategist_form {
      padding: 15px;
      min-height: auto;
      margin-bottom: 0px;
      padding-bottom: 20px;
      max-width: 100%;
      position: inherit;
      .section--label {
        padding: 0px;
      }
      .strategist_form--tabe {
        .nav-pills {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
        #new-appointment {
          padding: 0;
          margin-top: 55px;
          // height: calc(65vh - 55px);
          // overflow-y: auto;
        }
      }
    }
    .strategist_side {
      padding: 0 15px;
      max-width: 100%;
      .upcoming__empty {
        height: 100%;
        display: inline-block;
        background-size: 21%;
        position: inherit;
        transform: inherit;
        margin: auto;
        margin-bottom: 40px;
        text-align: center;
      }
    }
  }
  .appointment__modal {
    .modal-header {
      .close-btn {
        min-width: 20px;
      }
    }
  }
  .view__booking--modal {
    .modal {
      &:before {
        margin-right: auto;
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .client__home--page {
    .logo-header {
      img {
        height: 35px;
      }
    }
    .schedule__collapse {
      .schedule--img--box {
        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .strategist__profile {
    overflow-y: auto;
  }
  .appointment__modal {
    .modal {
      padding: 0px !important;
      &:before {
        height: 100%;
        margin: 0;
      }
      .modal-dialog {
        margin: auto;
      }
    }
    .modal-header {
      .close-btn {
        min-width: 20px;
      }
    }
  }
  .profile__modal {
    .modal {
      padding: 0px !important;
      &:before {
        height: 100%;
        margin: 0;
      }
      .modal-dialog {
        margin: auto;
      }
    }
  }
  .view__booking--modal {
    .modal {
      padding: 0px !important;
      &:before {
        height: 100%;
        margin: 0;
        margin-right: auto;
      }
      .modal-dialog {
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .client__home--page {
    .logo-header {
      img {
        height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .client__home--page {
    .strategist_side {
      .navbar {
        margin-bottom: 0px;
      }
    }
    .profile-name {
      margin: 0px;
    }
    .section--label {
      font-size: 24px;
    }
  }
  .appointment__modal {
    .modal {
      padding: 0px !important;
      &:before {
        height: auto;
        margin: 0;
      }
      .modal-dialog {
        margin: auto;
      }
    }
    .modal-header {
      .close-btn {
        min-width: 20px;
      }
    }
  }
  .profile__modal {
    .modal-body {
      padding: 30px;
    }
    .modal {
      padding: 0px !important;
      &:before {
        height: auto;
        margin: 0;
      }
      .modal-dialog {
        margin: auto;
      }
    }
  }
  .view__booking--modal {
    .modal {
      padding: 0px !important;
      &:before {
        height: auto;
        margin: 0;
        margin-right: auto;
      }
      .modal-dialog {
        margin: auto;
      }
    }
  }
}

.switch1 {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider1 {
  background-color: #2196F3;
}

input:focus+.slider1 {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider1:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


/* Rounded sliders */

.slider1.round {
  border-radius: 34px;
}

.slider1.round:before {
  border-radius: 50%;
}

.switch__padding {
  padding: 17px !important;
}

.para__color.yesno__para {
  font-size: 12px !important;
  line-height: 14px !important;
  font-family: "Pier Sans";
  font-style: normal;
  font-weight: 500;
}
.selectbox__height {
  height: 54px !important;
}
