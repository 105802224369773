@import './../../theme/variables.scss';

#phone-form-control{
	display: none !important;
  }
  
  #flag-dropdown {
		background: none !important;
		border: 0;
		border-bottom: 1px solid #CCC;
		height: 30px !important;
		margin: 0 !important;
  }
  .flag-dropdown__login .flag-dropdown {
	height: 43px !important;
	margin-top: 13px !important;
  }
  #flagInputControlMainPhone {
	margin-left: 40px !important;
	width: 84%  !important;
  }
  
.review__select{
   margin-bottom: 25px;
}



.profile__container{
	.btn__cancel {
		margin-right: 20px;
		padding-top: 15px;
    padding-bottom: 15px;
	}
	.text-right-btn {
		float: right;
		text-align: right;
	}
	.addclient-heading {
		font-size: 20px;
		font-weight: 600;
		color: #3B3B3B;
		line-height: 32px;
		margin-bottom: 11px;
		padding: 0;
		margin-top: 30px;
		@media #{$phone767}{
			margin-top: 0px;
		}
	}
	.addclient-sub {
		font-size: 15px;
		font-weight: 200;
		color: #73777A;
		line-height: 25px;
		margin: 0;
		padding: 0;
		@media #{$tablet980}{
			font-size: 13px;
		}
	}
	.text-group {
		margin-bottom: 35px;
	}
}
 .addclient__container--form{
 	max-width: 687px;
	 margin:0 auto !important;
	 .date__picker {
		height: 52px;
		padding-left: 71px !important;
		font-size: 14px;
		background: url('/img/calendar-icon.svg') no-repeat center;
		background-position: 19px;
		text-align: left;
		font-weight: 600;
		font-family: 'Poppins' !important;
}.error_field {
    color: red;
    display: block;
    float: left;
    margin-top: 0px;
}

.custom_err_msg {
    color: red;
    display: block;
    float: left;
    margin-top: -30px;
}
	 
  .profile__container--section{
    position: absolute;
    top: 19px;
    right: 45px;
    .profile__container--avtar{
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 100px;
      display: inline-block;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .edit__photo--btn{
      font-size: 9px;
      color: #C6CE4A;
      letter-spacing: 0;
      display: block;
      text-align: center;
      cursor: pointer;
    }
	}
	.more__margin--btm {
		margin-bottom: 30px;
	}
	.select__label {
		color: #CED0DA;
		font-weight: 400;
		font-size: 13px;
		color: rgba(0, 0, 0, .50);
		line-height: 16px;
		margin-bottom: 10px;
	}
  .custom-select{
		height: 55px;
		padding: 16px 24px;
		border: 2px solid #e5e5e5 !important;
		border-radius: 4px !important;
		font-family: 'Poppins' !important;
	}
  .additional__textarea{
		border: 2px solid #e5e5e5 !important;
		border-radius: 4px;
		resize: none;
		font-size: 13px;
		color: rgba(0,0,0,0.54);
		text-align: left;
		line-height: 16px;
		padding: 16px 15px;
		&:focus{
			border: 1px solid #CED0DA !important;
		}
	}

  .checkbox__list{
    list-style: none;
    padding: 0;
    margin: 0 0 19px;
    > li{
      margin: 0 0 15px;
    }
  }
  .coverage__area{
    margin: 0 0 30px;
    &--label{
      font-size: 14px;
      font-weight: 600;
      color: #9B9B9B;
      letter-spacing: 0;
      margin: 0 0 13px;
    }
    &--input{
      border: 1px solid #CED0DA;
      border-radius: 4px;
      font-size: 13px;
      color: #787878;
      line-height: 19px;
      height: 55px;
    }
  }

 	.panel {
	    margin-bottom: 44px;
	    background-color: #fff;
	    border-radius: 6px;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	}
 	.panel-default {
	    border-color: transparent !important;
	}
 	.panel-default>.panel-heading {
	    background-color: #fff;
	    border-color: #E6EBF0;
	    color: #73777A;
      position: relative;
	}
	.panel-heading {
	    padding: 32px 44px;
	    border-bottom: 1px solid transparent;
	    border-top-left-radius: 6px;
	    border-top-right-radius: 6px;
	}
	.panel-title {
	    margin-top: 0;
	    margin-bottom: 0;
	    font-size: 20px;
	    font-weight: 600;
	    color: inherit;
	}
	.panel-body {
	    padding: 0px 45px 27px;
	}
	.receive-email-bottom{
		font-size: 15px;
		font-weight:600;
		color: #73777A;
		margin-bottom:14px;
	}
	.toggle-email{
		margin-left:14px;
	}
	
 }
  // Media Css
@media (max-width:767px){
	.mobile__panel {
		margin-bottom: 0 !important;
	}
	.profile-container .panel-heading {
	    padding: 20px 15px;
	}
	.profile-container .panel-body {
	    padding: 33px 20px 27px;
	}
	.profile__container .profile__container--form .panel-body {
    padding: 33px 27px;
}
.text-right-btn {
	float: left !important;
	width: 100%;
	.btn__cancel {
		width: 47%;
		min-width: 100px;
	}
	.btn__green {
		width: 47%;
		min-width: 100px;
	}
}



}
// Media Css




.strategy--container{

}



	.strategy--form__card{
		border: 1px solid #CED0DA;
		margin: 0 0 33px;
		max-height: 57vh;
    	overflow-y: auto;
	}
	.locations__list{
		list-style: none;
		padding: 0;
		margin: 0;
		> li{
			display: block;
			position: relative;
			padding: 24px;
			cursor: pointer;
			border-bottom: 1px solid #CED0DA;
			&.active{
				background: $secondaryColor;
			}
			&.active .locations__list--select,
			&.active .select__agent--name{
				display: block;
				color: #fff;
			}
      &:last-child{
        border-bottom: 0px solid #CED0DA;
      }
		}

	.checkbox__icon{
		margin-right: 19px;
		img {
			width: 36px;
		}
	}
	.select__agent--name{
		font-size: 17px;
		font-weight: 600;
		color: #9B9B9B;
		letter-spacing: 0;
	}
	.locations__list--select{
		display: none;
		position: absolute;
		top: 50%;
		right: 24px;
		width: 100%;
		max-width: 314px;
		transform: translateY(-50%);
	}
}
.strategy--form__btn{
	text-align: right;
	margin: 0 0 37px;
	button{
		margin-left: 15px;
	}
}

