@import './../../../../theme/variables.scss';
.add__button__fixed {
    margin-left: 153px;
    position: absolute;
    top: 66px;
    right: 179px;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
    &:focus{
        outline:none;
    }
 }
.table__margin {
   margin-top: 35px;
}
.vertical__mid{
    vertical-align: middle !important;
}
.border__class {
    border: 1px solid #ddd;
}
.school__table td {
    border: 1px solid #ddd;
    word-break: break-all;
    max-width: 250px;
}
.table__links {
    border: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background: #C7CE4A;
    border-radius: 4px;
    padding: 7px 20px;
    float: left;
    margin: 0 7px 7px 7px;
    &:focus,
    &:hover,
    &:active {
        background: #C7CE4A;
        outline: none;
        border: 0;
    }
}
.edit__btn--popup {
    font-family: 'Pier Sans' !important;
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0.75px;
    text-align: center;
    background: #C3CF21;
    padding: 12px 40px;
    outline: none;
    border: 0;
    border-radius: 3px;
    margin: 7px;
}