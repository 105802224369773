@import './../../theme/variables.scss';
#phone-form-control{
  display: none !important;
}
.flaglogin__wrapper {
  #flag-dropdown {
    height: 40px !important;
    margin-top: 0 !important;
    position: absolute;
    top: 25px;
    border: 0;
    border-right: 1px solid #ebebeb;
    left: 1px;
  }
  .flagInputControlMainPhone {
    padding-left: 40px !important;
    border: 1px solid #E5E5E5 !important;
    width: 100%;
    height: 43px;
    border-radius: 0;
    outline: none;
    padding: 0px 20px 0 50px!important;
    background: transparent;
    margin: 23px 0 17px 0 !important;
    font-family: 'Pier Sans' !important;
    font-weight: 500;
    font-size: 16px;
    color: #72777A;
  }
}
.login__flag {
  .flag-dropdown {
    background: none !important;
    border: 0;
    border-bottom: 1px solid #CCC;
    height: 49px !important;
  }
  .flagInputControlMainPhone {
    margin-left: 40px !important;
    width: 84% !important;
    border: 0 !important;
    border-bottom: 1px solid #ccc !important;
  }
}
.country__code_login_page {
  #flag-dropdown {
    height: 30px !important;
    background: none !important;
    border: 0;
    border-bottom: 1px solid #9b9b9b;
  }
}
.flag-dropdown__login .flag-dropdown {
  height: 22px !important;
  margin-top: 13px !important;
}
#flagInputControlMainPhone {
  margin-left: 40px !important;
  width: 84%  !important;
}
.login__page{
  .success_field{
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }
}
.TabLoginSection {
  .error_field {
    margin-top: -15px !important;
    margin-bottom: 15px;
  }
}


.login__container{
  input {
    &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
      color: #9B9B9B;
      opacity: 100;
      font-size: 16px;
      font-family: 'Dosis-ExtraLight';
      font-style: italic;
    }
  }



  /*our team details close*/

  /*login css open*/
  .v-container {
    position: relative;
    height: calc(100vh - 0px) !important;
  }
  span.create-accoutn.client-login {
    width: 100%;
    margin-top: 10px;
    float: left;
  }
  .loginAccesstype{
    margin: 30px 0;
    display: block;
  }
  .LoignSection .loginerrorMsg {
    color:red;
    margin: 10px 0;
    display: block;
  }
  .login-header {
    background: #fff;
    z-index: 99999;
    position: relative;
  }

  .LoignSection {
    position: relative;
    background-size: cover;
    height: calc(100vh - 0px) !important;
    // background-image: url('/../../img/home-banner.png');
    background:none !important;
  }
  .loginbox_new {
    margin: 0 auto;
    background: #fff;
    width: 100%;
    max-width: 418px;
    padding: 17px 28px!important;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    @media #{$phone}{
      transform: translate(-50%, 50%) !important;
    }
    h1{
      font-family: 'Pier Sans';
      font-weight: 500;
      font-size: 24px;
      color: $primaryColor;
      letter-spacing: 0;
      margin: 0px;
    }
    h3 {
      font-family: 'Pier Sans';
      font-weight: normal;
      font-size: 12px;
      color: #646A78;
      letter-spacing: 0;
      line-height: 19px;
      margin: 9px 0 0 0;
    }
    p {
      text-align: center;
      font-size: 16px;
      margin-top: -15px;
      margin-bottom: 20px;
    }
    .product-dtl-tab-wrapper {
      padding-bottom: 0;
      .nav-tabs {
        margin: 12px 0 9px 0;
        padding: 3px;
        background: #ececec;
        width: auto;
        display: inline-block;
        border-radius: 4px;
        li {
          min-width: 85px;
          text-align: center;
          margin: 0px;
          a {
            padding: 4px 16px;
            font-family: 'Pier Sans';
            font-weight: bold;
            font-size: 10px;
            color: #9B9B9B;
            letter-spacing: 0;
            line-height: 19px;
            border: 0px;
            min-width: 102px;
          }
        }
      }
      .nav-tabs>li.active {
        a {
          background: $secondaryColor !important;
          color: #fff;
          border: 0 !important;
          border-radius: 4px;
        }
      }
    }
    // label {
    //   width: 100%;
    //   position: absolute;
    //   top: -6px !important;
    //   left: 0;
    //   font-family: 'Pier Sans';
    //   font-weight: bold;
    //   font-size: 10px;
    //   color: #9B9B9B;
    //   letter-spacing: 1.46px;
    //   margin: 0px;
    //   line-height: normal;
    // }
    /* .phoneNumber {
      border: 1px solid #E5E5E5;
      width: 100%;
      height: 43px;
      color: #000;
      font-size: 16px;
      border-radius: 0;
      font-family: 'Dosis-Regular';
      outline: none;
      padding: 0px 20px !important;
      background: transparent;
      margin: 18px 0 17px 0;
    } */
    /* input {
      &[type="email"], &[type="password"] {
        border: 1px solid #E5E5E5 !important;
        width: 100%;
        height: 50px;
        color: #000;
        font-size: 16px;
        border-radius: 0;
        font-family: 'Dosis-Regular';
        outline: none;
        padding: 0px 20px !important;
        background: transparent;
        margin: 18px 0 17px 0;
      }
     &:focus {
        border-bottom: 1px solid $secondaryColor;
        + label {
          color: $secondaryColor !important;
          margin-top: -20px;
        }
        ~ label {
          top: 0 !important;
          color: #9B9B9B;
          font-size: 14px;
          font-family: "Dosis-Medium";
          line-height: 17px !important;
          font-weight: bold;
        }
      }
    } */
    .chat__container {
      border: 0;
      margin: 0px;
    }

  }





  .login-box {
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.85);
    width: 50%;
    padding: 20px 70px 40px 70px !important;
    position: absolute;
    top: 55% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    h3 {
      text-align: center;
      font-size: 24px;
      font-family: "Dosis-SemiBold";
      margin-bottom: 0 !important;
    }
    p {
      text-align: center;
      font-size: 16px;
      margin-top: -15px;
      margin-bottom: 20px;
    }
    label {
      width: 100%;
      color: #9B9B9B;
      font-size: 14px;
      font-family: "Dosis-Medium";
      position: absolute;
      top: -20px !important;
      left: 0;
      line-height: 17px !important;
    }
    input {
      &[type="email"], &[type="password"] {
        border: 0;
        border-bottom: 1px solid #9B9B9B;
        width: 100%;
        height: 30px;
        color: $primaryColor;
        font-size: 16px;
        border-radius: 0;
        font-family: 'Dosis-Regular';
        outline: none;
        padding: 0px 0 0px !important;
        background: transparent;
        margin-bottom: 55px !important;
      }
      &:focus {
        border-bottom: 1px solid $secondaryColor;
        + label {
          color: $secondaryColor !important;
          margin-top: -20px;
        }
        ~ label {
          top: 0 !important;
          color: #9B9B9B;
          font-size: 14px;
          font-family: "Dosis-Medium";
          line-height: 17px !important;
          font-weight: bold;
        }
      }
    }
  }

  input:valid ~ .text-label {
    top: 0 !important;
    color: #9B9B9B;
    font-size: 14px;
    font-family: "Dosis-Medium";
    line-height: 17px !important;
    font-weight: bold;
  }

  .login__page{
    .login-submit {
      width: 100%;
      height: 43px;
      font-family: 'Pier Sans' !important;
      font-weight: bold;
      font-size: 12px !important;
      color: #FFFFFF;
      letter-spacing: 1.75px;
      text-align: center;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .loginbox_new{
      .outer-input{
        margin-bottom: 15px;
        .phoneNumber{
          border: 1px solid #E5E5E5 !important;
          width: 100%;
          height: 43px;
          border-radius: 0;
          outline: none;
          padding: 0px 20px !important;
          background: transparent;
          margin: 23px 0 17px 0 !important;
          font-family: 'Pier Sans' !important;
          font-weight: 500;
          font-size: 16px;
          color: #72777A;
        }
        .email_input{
          border: 1px solid #E5E5E5 !important;
          width: 100%;
          height: 43px;
          border-radius: 0;
          outline: none;
          padding: 0px 20px !important;
          background: transparent;
          margin: 23px 0 17px 0 !important;
          font-family: 'Pier Sans' !important;
          font-weight: 500;
          font-size: 16px;
          color: #72777A;
        }
      }
      .close_btn--img{
        position: absolute;
        right: 23px;
        top: 16px;
        cursor: pointer;
        background: transparent;
        border: 0px;
        outline: none;
        box-shadow: none;
      }
    }
  }
  .for-left {
    float: left;
  }

  .for-right {
    float: right;
  }

  .create-accoutn {
    font-size: 16px;
    font-family: 'Dosis-SemiBold';
    a {
      color: $primaryColor;
      text-decoration: none;
      &:active, &:hover, &:focus {
        color: $primaryColor !important;
      }
    }
  }

  .mor-marg-top {
    margin-top: 25px;
  }

  .login-img {
    z-index: 999;
    position: relative;
    height: 100%;
  }

  .login-header {
    .close {
      padding-top: 20px;
      font-size: 20px;
      opacity: 1;
      padding-right: 10px;
    }
    h4 {
      padding-top: 20px;
      font-size: 20px;
      color: #3B3B3B;
      padding-left: 10px;
    }
  }

  .left-align {
    text-align: left;
  }

  .right-align {
    text-align: right;
  }

  .center-align {
    text-align: center;
  }

  .LoignSection {
    .error_field {
      color: red;
      display: block;
      float: left;
      margin-top: -50px;
    }
    .success_field{
      margin-top: 4px;
    }
  }

  /*login css close*/

  @media screen and (max-width: 980px) {
    .LoignSection {
      position: relative;
      height: 100% !important;
      min-height: 100vh;
      padding-top: 100px !important;
      padding-bottom: 50px !important;
      background-size: auto;
    }
    // .v-container {
    //   margin: 0 auto;
    //   width: 100%;
    //   height: auto !important;
    //   position: static !important;
    //   transform: none !important;
    // }
    .login-box {
      margin: 0 auto !important;
      width: 96%;
      height: auto !important;
      position: static !important;
      transform: none !important;
      padding-bottom: 100px !important;
      padding: 25px 30px 85px 30px !important;
    }
  }

  @media screen and (max-width: 768px) {
    .login-box {
      margin: 100px auto !important;
      width: 96%;
      height: auto !important;
      position: static !important;
      -ms-transform: none !important;
      transform: none !important;
      padding-bottom: 100px !important;
      padding: 25px 30px 85px 30px !important;
    }
  }

  @media screen and (max-device-width: 823px) and (orientation: landscape) {
    .login__page {
      min-height: calc(100vh - -93px);
      .loginbox_new{
        transform: translate(-50%, 28%);
      }
    }
  }
  @media screen and (max-device-width: 812px) and (orientation: landscape) {
    .login__page {
      .loginbox_new{
        transform: translate(-50%, 28%);
      }
    }
  }
  @media screen and (max-device-width: 767px) and (orientation: landscape) {
    .login__page {
      .loginbox_new{
        transform: translate(-50%, -6%) !important;
      }
    }
  }
  @media screen and (max-device-width: 568px) and (orientation: landscape) {
    .login__page {
      min-height: calc(100vh - -75px) !important;
      .loginbox_new{

      }
    }
  }

  @media screen and (max-width: 767px) {
    .LoignSection {
      position: relative;
      height: 100% !important;
      padding-top: 100px !important;
      padding-bottom: 50px !important;
      padding: 0 10px;
    }
    .v-container {
      margin: 0 auto;
      width: 100%;
      height: auto !important;
      position: static !important;
      transform: none !important;
    }
    .login-box {
      margin: 0 auto !important;
      width: 96%;
      height: auto !important;
      position: static !important;
      transform: none !important;
      padding-bottom: 100px !important;
      padding: 25px 30px 85px 30px !important;
    }

    .right-align {
      text-align: center !important;
    }
    .create-accoutn {
      font-size: 16px;
      font-family: Dosis-SemiBold;
      width: 50%;
      float: left;
    }
    .for-right {
      text-align: right;
    }
    .popup-logo {
      width: 190px !important;
    }
    .login-header {
      .close {
        padding-top: 15px;
        padding-right: 0;
      }
      h4 {
        padding-top: 15px;
      }
    }
  }
  @media screen and (max-width: 414px) {
    .login__page{
      .loginbox_new{
        top: 50%;
        left: 50%;
        transform: translate(-50%, 50%)
      }
    }
  }
  @media screen and (max-width: 340px) {
    .LoignSection {
      position: relative;
      height: 100% !important;
      padding-top: 100px !important;
      padding-bottom: 50px !important;
    }
    .v-container {
      position: relative;
      height: calc(100vh - 1px);
    }
    .popup-logo {
      width: 150px !important;
      margin-top: 15px;
    }
    .login-header h4 {
      padding-top: 20px;
      font-size: 16px;
      padding-left: 0;
    }
    .login-box h3 {
      text-align: center;
      font-size: 24px;
      font-family: Dosis-SemiBold;
      margin-bottom: 30px;
    }
  }
}
