@import './../../../../theme/variables.scss';
// .testing {
//     display: flex !important;
//     justify-content: space-between !important;
//     flex-wrap: wrap !important;
// }
.extra__padding {
    padding: 12px 40px !important;
}
.edit__btn--new {
    background: url('/img/edit-icon.svg') !important;
    background-size: 20px !important;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    @media #{$phone767}{
        background-size: 14px !important;
        width: 14px;
        height: 14px;
        margin-right: 2px;
    }
}
.delete__btn--new {
    background: url('/img/delete_icon.svg') !important;
    background-size: 20px !important;
    width: 20px;
    height: 20px;
    @media #{$phone767}{
        background-size: 14px !important;
        width: 14px;
        height: 14px;
    }
}
.flex__table {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 !important;
    padding: 15px;
    line-height: normal;
    border-top: 0px solid #E6EBF0 !important;
}
.flex__table:nth-child(even) {background: rgba(236, 237, 232, 0.4);}
.rem__odd--even {
    background: none !important;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    border-top: 1px solid #E6EBF0 !important;
}
.name__table {
    width: 30%;
    white-space: wrap;
    word-wrap: break-word;
    @media #{$phone767}{
        font-size: 10px;
    }
}
.grade__table {
    width: 20%;
    white-space: wrap;
    word-wrap: break-word;
    @media #{$phone767}{
        font-size: 10px;
    }
}
.teacher__table {
    width: 20%;
    white-space: wrap;
    word-wrap: break-word;
    @media #{$phone767}{
        font-size: 10px;
    }
}
.student__table {
    width: 20%;
    white-space: wrap;
    word-wrap: break-word;
    @media #{$phone767}{
        font-size: 10px;
    }
}

.school__name {
    width: 30%;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 0 5px 0 0;
    @media #{$phone767}{
        font-size: 10px;
    }
}

.grade__name {
    width: 10%;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 0 5px 0 0;
    @media #{$phone767}{
        font-size: 10px;
    }
}

.rating__name {
    width: 10%;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 0 5px 0 0;
    @media #{$phone767}{
        font-size: 10px;
    }
}

.teacher__student--name {
    width: 20%;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 0 5px 0 0;
    @media #{$phone767}{
        font-size: 10px;
    }
}

.student__name {
    width: 20%;
    white-space: wrap;
    word-wrap: break-word;
    @media #{$phone767}{
        font-size: 10px;
    }
}

.fl__right {
    width: 10%;
    text-align: right;
}
.ReactVirtualized__Table__headerRow {
    display: flex;
}
.ReactVirtualized__Table__row {
    display: flex;
}
.ReactModal__Overlay--after-open{
	overflow: auto;
  z-index: 1;
}
.town__err{
	margin-bottom: 10px !important;
    margin-top: -13px !important;
}
.student__town{
	margin-top: -50px !important;
}
.add__report__btn {
    position: absolute;
    float: right;
    right: 0px;
}
.add__report__txt {
    position: absolute;
    float: right;
    right: 0px;
    padding: 0 16px;
    color: #ffffff !important;
    background: #a8af26;
}

.position__container {
    position: relative;
}
.link__view__more {
    color: #c7cd4a;
    margin-top: 5px;
    cursor: pointer;
}
.add__button__fixed_new {
    margin-left: 153px;
    position: absolute;
    top: 66px;
    right: 204px;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
    &:focus{
        outline:none;
    }
 }


.add__button__fixed {
    margin-left: 153px;
    position: absolute;
    top: 66px;
    right: 204px;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
    &:focus{
        outline:none;
    }
 }
 .right_button{
    margin-left: 153px;
    position: absolute;
    top: 66px;
    right: 85px;
    padding: 10px;
    background: #C7CE4A;
    border: 0;
    color: white;
 }
 .sidebar__shadow {
    box-shadow: -1px 0 25px #e4e4e4;
    height: 100vh !important;
    overflow-y: auto;
    .schedule__sidebar--body {
        height: calc(100vh - 94px);
    }
 }


 .schedule__sidebar--form--wrapper {
    ul {
        float: left;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 7px;
        padding-bottom :7px;
        li {
            width: 100%;
            margin-bottom: 2px;
            .label__text {
                width: 60px;
                color: #c7cd4a;
                font-weight: 600;
                font-size: 14px;
                display: inline-block;
            }
            .label__details {
                width: calc(100% - 60px);
                padding-left: 15px;
            }
            .label__text--edit {
                padding-left: 50px;
                width: auto;
            }
            .label__details--edit {
                width: auto;
            }
            .btn__add {
                    width: auto;
                    background: #c7cd4a;
                    color: #fff;
                    height: auto;
                    text-align: center;
                    border: 0;
                    border-radius: 2px;
                    font-weight: 600;
                    padding: 5px 10px;
                }
        }
    }
 }
