@import '../../../../theme/variables.scss';
.calender__fullpage {
  padding-left: 350px;
  .calendar__header {
    display: flex;
    align-items: center;
    width: calc(100% - 350px);
    padding: 10px 15px;
    position: fixed;
    top: 0;
    left: 350px;
    right: 0;
    z-index: 10;
    background-color: #fff;
    .calendar__header__left,
    .calendar__header__right {
      display: flex;
      align-items: center;
    }
    .calendar__header__left {
      .btn__year__change{
        background: transparent;
        padding: 10px 15px;
        &:hover {
          background: #f5f5f5;
        }
        &.left {

        }
        &.right {

        }
      }
      .calendar__year {
        font-family: $Pangram;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: #979797;
        margin-left: 10px;
      }
    }
    .calendar__header__right {
      margin-left: auto;
      .btn__calendar {
        min-width: 104px;
        margin-right: 12px;
      }
      .calendar__select {
        min-height: 48px;
        width: 134px;
        font-family: $Pangram;
        font-size: 14px;
        line-height: 17px;
        color: $primaryColor;
      }
      .btn__calendar__close {
        background-color: transparent;
        margin-left: 30px;
      }
    }
  }
  .calendar__full__view {
    padding-top: 66px;
    height: calc(100% - 66px);
  }
}
