.ReactModal__Overlay--after-open{
	overflow: auto;
	z-index: 1;
}
.quick__edit{
	cursor: pointer;
}
.media__heading--new {
	font-size: 15px;
	font-weight: normal !important;
	margin-bottom: 10px;
	margin-top: 16px !important;
}
.new__para {
	font-size: 15px !important;
    font-weight: normal !important;
    margin-bottom: 10px !important;
    line-height: 18px !important;
}
.new__media--heading {
	margin-top: 16px !important;
}
