@import './../../theme/variables.scss';
.syncCalenderBtns {
	@media #{$phone767}{
		margin-bottom: 30px;
	}
}
.availability__container{
	.syncbtn-width {
		min-width: 250px;
		@media #{$phone767}{
			width: 100%;
		}
  }
  .googleCalSync {
		margin-bottom: 10px;
		@media #{$tablet768}{
			float: left;
		}		
		@media #{$phone767}{
			width: auto;
			float: none;
		}
	}
	
  &--wrapper-form{

  }
	.custom-select{
		height: 48px;
		padding: 13px 18px;
		font-size: 14px;
		font-weight: 400;
		color: #9B9B9B;
		letter-spacing: 0;
    border: 1px solid #CED0DA;
	}
	.availability--form__card{
		border: 1px solid #CED0DA;
		margin: 0 0 33px;
		@media #{$phone767}{
			margin: 0 0 20px 0;
		}
	}
	.locations__list{
		list-style: none;
		padding: 0;
		margin: 0;
		> li{
			display: block;
			position: relative;
			padding: 34px 24px;
      border-bottom: 1px solid #CED0DA;
      &:last-child{
        border-bottom: 0px solid #CED0DA;
			}
			@media #{$phone767}{
				padding: 10px 15px 74px 15px;
			}
      .error_msg-tab {
					color: red;
					display: block;
					float: left;
					width: 100%;
					position: absolute;
					bottom: -21px;
			}
 		}
	}
	.select__days--name{
		font-size: 17px;
		font-weight: 600;
		color: #9B9B9B;
		letter-spacing: 0;
	}
  .time__list--select-label{
    font-size: 9px;
    color: #9B9B9B;
    letter-spacing: 0;
    font-weight: 400;
    position: absolute;
    left: 18px;
		top: 3px;
		@media #{$phone767}{
			top: 5px;
			font-size: 10px;
		}
  }
	.time__list--select{
  	 	position: absolute;
  		top: 50%;
  		right: 24px;
  		width: 100%;
  		max-width: 436px;
  		transform: translateY(-50%);
      &--col-6{
        width: calc(100% / 2 - 12px);
        float: left;
        margin-left: 12px;
        position: relative;
			}
			@media #{$phone767}{
				position: absolute;
				top: 65%;
				right: 0;
				width: calc(100% - 12px);
				max-width: 436px;
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				left: 0;
			}
  	}

  .availability__container--form__btn{
  	text-align: right;
		margin: 0 0 37px;
		@media #{$phone767}{
			margin: 0 0 15px;
			display: flex;
			justify-content: space-between;
		}
  	button{
			margin-left: 15px;
			@media #{$phone767}{
				width: 48%;
				margin: 0;
			}
		}
		.cancelmob {
			@media #{$phone767}{
				width: 48%;
			}
		}
  }
}
