@import './../../theme/variables.scss';
.client__profile--container{

}
.client__profile--form{
	.form-group{
		margin-bottom: 37px;
	}
	.custom-select{
		height: 55px;
		padding: 16px 24px;
	}
}
.client__profile--form__btn{
	text-align: right;
	margin: 0 0 37px;
	button{
		margin-left: 15px;
	}
}
