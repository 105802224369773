@import './../../theme/variables.scss';
.edit-town-modal .ReactModal__Overlay--after-open {
  z-index: 9999;
}
.btnright {
  float: right;
  margin-top: -7px;
  margin-right: 100px;
}
.fullinput {
    width: 100% !important;
    .sel-outer {
      width: 100% !important;
    }
}
.fulllistwidthinput {
  width: 100% !important;
  max-width: 100% !important;
  border: 2px solid #e5e5e5 !important;
  border-radius: 4px !important;
}
.filter__question {
  color: #C7CE4A!important;
}
.fulllistwidth {
  @media #{$tablet768}{
    width: 100%;
 }
}
.filter__sub--container {
  @media #{$phone767}{
    border-top: 0px solid #E6EBF0;
  }
}
.custom__greenline {
  @media #{$tablet768}{
    height: 54px !important;
    margin-bottom: 15px;
    border: 2px solid #e5e5e5 !important;
    border-radius: 4px !important;
    width: 100% !important;
    max-width: 100% !important;
 }
  &::after {
    @media #{$tablet768}{
      content: '';
      height: 54px;
      width: 4px;
      background: #C7CE4A;
      position: absolute;
      left: -4px;
      top: -2px;
      bottom: 0;
      border-radius: 4px 0 0 4px;
   }

  }
}
.body__wrappers .form-group {
  position: relative;
}

.body__wrappers {
  height: calc(100vh - 160px);
  // overflow-y: auto;
  width: calc(100% + 6px);
}
.body__wrappers::-webkit-scrollbar {
  width: 0.9em;
  height: 0.9em;
}
.body__wrappers::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
   background: none;
}
.body__wrappers::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: transparent;
  -webkit-box-shadow: none;
}
.body__wrappers::-webkit-scrollbar-thumb:window-inactive {
  background: transparent;
}


.edit__btn--ai {
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.75px;
  text-align: center;
  background: #C3CF21;
  padding: 12px 40px;
  outline: none;
  border: 0;
  border-radius: 3px;
  margin: 15px 0 0 0;
  width: 100%;
}

.filter__sub--ai {
    // width: calc(100% - 30px);
    // margin-left: 15px;
    // margin-right: 15px;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    @media #{$tablet768}{
      margin-top: 30px !important;
      border-top: 0 solid #e5e5e5 !important;
   }
    @media #{$phone767}{
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 25px;
    }
    li {
      @media #{$phone767}{
        width: 100%;
        float: none;
      }
    }
    .sel-outer {
      @media #{$phone767}{
        top: 60px !important;
        width: 100%;
        overflow-x: hidden;
      }
    }
}

.sidebar__nav--img {
	width: 50px;
}
.school__table--tabs {
  border-top: 1px solid #E6EBF0;
  @media #{$tablet768}{
    border-top: 0 solid #E6EBF0;
  }
  th {
    font-size: 13px;
    font-weight: 600;
    color: #72777A;
    line-height: 19px;
    padding: 10px 18px !important;
    vertical-align: middle !important;
    border: 0 !important;
  }
  td {
    font-size: 13px;
    font-weight: normal;
    color: #71777B;
    padding: 18px 18px !important;
    vertical-align: middle !important;
    border: 0 !important;
  }
  .edit__btn {
    border: none;
    background: none;
    outline: none;
    img {
      width: 21px;
      margin-right: 15px;
    }
    &:last-child {
      img {
        margin-right: 0;
    }
    }
  }
}
.table-striped>tbody>tr:nth-of-type(odd) {
    background: rgba(236, 237, 232, 0.4);
  }
