@import './../../../../theme/variables.scss';
.mobile__agent--app {
  display: none;
  @media #{$phone767}{
    display: block;
  }
}
.onlymob {
    display: none;
    @media #{$phone767}{
      display: block;
    }
    .table__fullname {
      width: 50%;
      float: left;
      padding-top: 10px;
      color: $primaryColor;
      font-size: 14px;
    }
    .table__fulltime {
      width: 50%;
      float: left;
      text-align: right;
      padding-top: 10px;
      font-size: 14px;
      color: #C3D020;
    }
    .table__both {
      width: 100%;
      float: left;
      padding-top: 5px;
      padding-bottom: 10px;
      font-size: 11px;
      color: #70777B;
    }
  }

.table__fullname {

}
.upcoming__appointments--section{
  .upcoming__appointments--table-list{
    .table {
      margin-bottom: 0;
      > thead tr {
        border-top: 1px solid #E6EBF0;
      }
    }
    .table-striped > tbody > tr:nth-of-type(odd) {
      background-color: rgba(236, 237, 232, 0.4);
    }

    .table > {
      thead > tr > th {
        border-bottom: 0px solid #ddd;
        font-size: 13px;
        font-weight: 600;
        color: #73777A;
        padding: 7px 45px;

      }
      tbody > tr > td {
        padding: 8px 45px;
        font-size: 13px;
        font-weight: 400;
        color: #73777A;
        border-top: 0px solid #ddd;
        vertical-align: middle;
        @media #{$phone767}{
          padding: 8px 15px !important;
        }
      }
    }
    .btn__calandar,
    .btn__profile{
      padding: 10.5px 6px;
      margin-left: 24px;
      font-size: 13px;
      font-weight: 500;
      min-width: 150px;
      text-align: center;
      line-height: 19px;
    }
  }
}
