@import './../../theme/variables.scss';

.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
    .date__picker {
      text-align: left;
      font-weight: 600;
    }

  }



  .react-datepicker__input-container .schedule-date{
    border: 0 !important;
    margin: 0 0 20px !important;
    background: url('/img/calendar-icon.png');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 20px;
    cursor: pointer;
    text-align: left;
    width: 100px;
  }
}
.react-datepicker__week{
  .react-datepicker__day{
    font-weight: 700 !important;
    color:  $secondaryColor !important;
  }
  .react-datepicker__day--disabled{
    color: #ccc !important;
  }
}

span.strategist-name-bold {
  font-weight: 800;
  color: #c3cf22;
  font-size: large;
}

.left-section {
  width: 58.3333%;
}
.contact-frm label {
  width: 100%;
  color: #9b9b9b;
  font-size: 14px !important;
  position: absolute !important;
  top: -25px !important;
  left: 0;
  line-height: 16px;
}
.contact-frm input[type="email"],
.contact-frm input[type="text"],
.contact-frm input[type="number"] {
  border: 0;
  border-bottom: 1px solid #E6EBF0;
  width: 100%;
  height: 30px;
  color: $primaryColor;
  font-size: 16px;
  border-radius: 0;
  outline: none;
  padding: 0px 0 0px !important;
}
.contact-frm {
  padding: 0 63px;
  float: right;
  width: 42.33333%;
}
.contact-frm .signups label {
  position: relative !important;
  width: 100% !important;
}
.contact-frm .slick-prev {
  left: 1px;
}
.contact-frm .slick-next {
  right: -15px;
  top: 40%;
}
.contact-frm .slick-list {
  width: 94%;
  margin: 0 auto;
}
.No-timeSlots {
  width: 48%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border: 1px solid $primaryColor;
  margin: 5px 26% 20px 30%;
  float: left;
  padding: 10px 0;
}

.slider-listing {
  z-index: 99;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding:0 20px;
  .slick-initialized .slick-slide {
    display: block;
    float: none;
    display: inline-block;
    width: auto !important;
    vertical-align: middle;
    margin:0 20px;
  }
}



/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}


.slider-listing ul li {
  width: auto !important;
  float: left;
  margin: 0 20px;
}
.bg-padd {
  padding: 100px 0 20px 0 !important;
  height: 100vh;
}
.signup-img {
  background-image: url('/../../img/home-banner.png') ;
  background-size: cover;
  height: calc(100vh - 68px);
  position: fixed;
  width: 100%;
  max-width: 58.33333333%;
  top: 68px;
}
.overlay-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
  top: 0;
  left: 0;
}
.pop-up-header {
  border-bottom: 1px solid #f1f1f1;
  width: 102%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
  .close {
    border-bottom: 0px solid #ccc;
  }
}
.time-listing {
  width: 100%;
  float: left;
  margin: 0 0 15px 0;
}
.time-listing ul li {
  float: left;
  width: 29.333%;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border: 1px solid $primaryColor;
  margin: 0 2% 5px 2%;
}
.time-listing ul li a {
  color: $primaryColor;
}
.time-listing ul li.active {
  background: #C3CF21;
  border: 1px solid #C3CF21;
  color: #fff !important;
}
.time-listing ul li.active a {
  color: #fff !important;
}
.timePrev {
  width:5%;
  float: left;
  padding-top: 50px;
  text-align: center;
  cursor:pointer;
}
.add-padd {
  padding-bottom: 15px;

}
.padd-lr {
  padding-left: 15px;
  padding-right: 15px;
}

.timePrev-head {
  cursor:pointer;
}
.contact-frm .slider {
  background: transparent !important;
  overflow: hidden;
}

/*checkbox css open*/
.signups-pad {
  padding-left: 30px !important;
}
.signups {
  position: relative;
  padding-left: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.signups label {
  width: auto !important;
  top: 0 !important;
  margin-right: 10px;
  position: relative !important;
}

.signups input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.signups:hover input ~ .checkmark {
  background-color: #fff;
}

.signups input:checked ~ .checkmark {
  background-color: #979797;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.signups input:checked ~ .checkmark:after {
  display: block;
}
.signups .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*checkbox css close*/

/* Arrows */
.slick-prev,
.slick-next
{
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 40%;

  display: block !important;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: $primaryColor;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
  opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;

  opacity: .75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
  left: -3px;
}
[dir='rtl'] .slick-prev
{
  right: -25px;
  left: auto;
}
.slick-prev:before {
  /* content: 'â†'; */
  content: "";
  width: 8px;
  height: 12px;
  position: absolute;
  background: url("/../../img/left-arrow-icon.svg");
  background-repeat: no-repeat;
  left: 0;
  opacity: 1;
}
[dir='rtl'] .slick-prev:before
{
  content: '→';
}

.slick-next
{
  right: -25px;
  top: 40%;
}
[dir='rtl'] .slick-next
{
  right: auto;
  left: -25px;
}
.slick-next:before
{
  /*content: '→';*/
  content: "";
  width: 8px;
  height: 12px;
  position: absolute;
  background: url("/../../img/right-arrow-icon.svg");
  background-repeat: no-repeat;
  right: 15px;
  opacity: 1;
}
[dir='rtl'] .slick-next:before
{
  content: '←';
}

.fb-btn {

}
.continue-fb {
  width: 250px;
  margin: 12px auto 0;
}
.continue-fb img {
  width: 100%;
}

// .continue-fb {
//     background: #4267b2;
//     border-radius: 2px;
//     color: white;
//     height: 34px;
//     text-align: center;
//     padding: 0;
//     border: 0;
//     width: 230px;
//     font-weight: bold;
//     margin-top: 15px;
// }
.left-align {
  text-align: left;
}
.right-align {
  text-align: right;
}
.center-align {
  text-align: center;
}

.fb-login-button {
  padding-top: 20px !important;
  width: 125px !important;
}

.textarea-counter {
  float: right;
  position: relative;
  bottom: 48px;
  right: 8px;
  color: #9B9B9B;
}



@media screen and (max-width: 980px) {
  .signup-img {
    height: calc(100vh - 60px);
    position: static!important;
    max-width: 100%;
    padding: 0 0!important;
    overflow: hidden;
  }
  .signup-img .slick-slide img {
    display: block;
    width: 100%;
  }
  .contact-box-outer {
    position: absolute;
    height: auto!important;
    padding-bottom: 45px!important;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  .contact-box {
    padding: 45px 20px !important;
    position: static !important;
    transform: none !important;
  }
  .slider-listing {
    bottom: 14px !important;
  }
  .left-section {
    width: 100%;
  }
  .contact-frm {
    float: left;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .scheduleCalendar-wrapper {
    .react-datepicker-popper {
      margin-top: -10px;
      left: -90px !important;
    }
    .react-datepicker__triangle {
      left: 135px;
    }
  }
  .No-timeSlots {
    width: 60%;
    margin: 5px 20% 20px;
  }
  .left-section {
    display: none;
  }
  .contact-popup {
    width: 102% !important;
  }
  .pop-up-header {
    width: 105%;
    z-index: 999;
  }
  .signup-img {
    height: calc(100vh - 120px);
    position: static!important;
    max-width: 100%;
    padding: 0!important;
    overflow: hidden;
  }
  .contact-frm {
    padding: 0 4% !important;
    width: 96%;
  }
  .left-align, .right-align  {
    text-align: center;
  }
  .continue-fb {
    margin-top: 0;
    margin-bottom: 7px;
    margin-left: 10%;
  }
  .pop-up-header .close {
    padding-top: 0 !important;
    position: absolute;
    top: -40px;
    right: 5px;
  }
}

@media screen and (max-width: 320px) {
  .contact-frm {
    padding: 0 5%!important;
    width: 95%;
  }
}
