@import './../../theme/variables.scss';

.otpVerifySection {
	.success_field {
		margin: 10px 0px 0px !important;
		margin-bottom: 10px;
	}
	.login-box {
		background-color: #fff !important;
		padding: 15px 30px 30px 30px !important;
		width: 100%;
		max-width: 418px;
		.close-btn{
			position: absolute;
			top: 16px;
			right: 23px;
			cursor: pointer;
			img{
				width: auto;
			}
		}
		h3{
			font-family: 'Pier Sans';
			font-weight: 500;
			font-size: 24px;
			color: $primaryColor;
			letter-spacing: 0;
			margin-bottom: 19px !important;
			margin-top: 15px;
			text-align: left;
		}
		h4{
			font-family: 'Pier Sans';
			font-weight: bold;
			font-size: 10px;
			color: #9B9B9B;
			letter-spacing: 1.46px;
			margin: 23px 0 15px 0;
		}
		p{
			margin: auto !important;
			font-family: 'Pier Sans';
			font-weight: normal;
			font-size: 14px;
			color: #646A78;
			letter-spacing: 0;
			line-height: 22px;
			text-align: left;
		}
	}
	.outer-input{
		div{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin: 0 15px 0 0px;
			input{
				width: 100% !important;
				border: 0;
				border: 1px solid #E5E5E5;
				height: 43px;
				background: no-repeat;
				outline: none;
			}
		}
		.otp__text{
			position: inherit !important;
		}
		.error_field{
			// margin: 0 0 10px 0 !important;
			position: inherit !important;
			margin: 2px 0 0;
		}
	}
	.button__style{
		margin: 27px 0 0 0;
		outline: none;
		background: none;
		border: 0;
		padding: 0;
		text-align: center;
		width: 100%;
		font-family: 'Pier Sans';
		font-weight: 500;
		font-size: 14px;
		color: $secondaryColor;
		letter-spacing: 0;
	}
}



.body_text{
	.close-btn{
		position: absolute;
		right: 40px;
		border: 1px solid #c3c3c3;
		cursor: pointer;
		width: 40px;
		height: 40px;
		padding: 2px;
		border-radius: 50%;
		text-align: center;
		line-height: 33px;
		font-size: 32px;
		img {
			width: 16px;
		}
	}
	P,h3{
		text-align: left !important;
	}
}
