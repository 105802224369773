@import './../../../../../theme/variables.scss';

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
.disable-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 118px;
    height: 48px;
}

.no-border{
    border: none !important;
}

.no-padding-top{
    padding-top: 0 !important;
}

tr.read-only-client {
    @include readOnlyClient;
}
