@import './../../../../theme/variables.scss';
.towngoogledrivebtn {
  white-space: nowrap;
  padding: 15px 60px !important;
  font-size: 14px;
  margin: 7px 0 15px 7px;
  font-family: 'Pier Sans' !important;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 0.75px;
  text-align: center;
  background: #C3CF21;
  outline: none;
  border: 0;
  border-radius: 3px;
  @media #{$phone767}{
    width: 100%;
    margin: 7px 0 15px 0;
  }
}
.ul__img {
  width: 100%;
  float: left;
  padding-top: 58%;
  position: relative;
  overflow: hidden;
}
.ul__img img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}
.green__btn{
  white-space: nowrap;
  padding: 15px 60px;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 15px;
}
.fixed__remove{
    position: absolute;
    right: 0;
    bottom: -23px;
    background: none;
    border: 0;
    font-weight: 600;
    outline: none;
    box-shadow: none;
    border: none;
    font-size: 13px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #D0021B;
    &:focus, &:active, &:hover {
      outline: none;
      box-shadow: none;
      border: none;
    }
}

.fixed__featured{
    position: absolute;
    bottom: -23px;
    background: none;
    border: 0;
    left: 0;
    color: #004361;
    font-weight: 600;
    margin-top: 5px;
    font-size: 13px;
    outline: none;
    box-shadow: none;
    border: none;
    &:focus, &:active, &:hover {
      outline: none;
      box-shadow: none;
      border: none;
    }
}

.list__close {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  cursor: pointer;
  img {
    width: 20px !important;
    position: static !important;
    height: 20px !important;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.image-item {
  width: 100%;
  height: 100%;
}
ul, li {
  list-style-type: none;
}
.img__featured {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  color: #004361;
  margin: 0;
}

.town__image--selected{
  border: 4px solid #c7ce4a;
  border-radius: 8px;
  display: inline-block;
}

.featured__btn--single {
  position: absolute;
    left: 20px;
    top: 135px;
    background: none;
    border: none;
    color: #004361;
    font-weight: 600;
    font-size: 13px;
}
.grid-list-container-pad {
  padding: 0 !important;
}
.grid-list-container {
  padding: 0 15px;
  ul {
    li {
      width: 18%;
      display: inline-block;
      margin: 1% 2% 3% 0;
      position: relative;
      &:first-child {
        + .featured__btn--single {
          position: absolute;
          left: 15px;
          bottom: 8px;
          top: auto !important;
          @media #{$phone767}{
            bottom: 28px;
            top: 0 !important;
          }
        }
      }
      @media #{$desktop1199}{
        width: 30%;
      }
      @media #{$phone767}{
        width: 100%;
        margin: 0 0 25px 0;
      }
    }
  }
}
.fixed__check--checkbox {
  background: url('/img/checked-ic.png') no-repeat;
  background-size: 15px;
  width: 16px;
  height: 16px;
  bottom: -20px;
  right: 20px;
}
.fixed__remove--checkbox {
  background: url('/img/close-fill.svg') no-repeat;
  background-size: 15px;
  width: 16px;
  height: 16px;
  bottom: -20px;
  right: 0;
}