@import '../../../../theme/variables.scss';
.agent-signup {
  min-height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  .agent-signup-header {
    width: 100%;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    @media (max-width: 767px){
      padding: 10px 15px;
    }
      .agent-signup-title {
        font-family: $Pangram;
        font-weight: $bold;
        font-size: 22px;
        line-height: 26px;
        color: $primaryColor;
      }
      .agent-signup-progress {
        width: 600px;
        margin: 0 auto;
        @media (min-width: 768px) and (max-width: 1024px){
          width: 320px;
        }
        @media (max-width: 767px){
          display: none;
        }
        .progress-bar-text {
          font-family: $Pangram;
          font-size: 14px;
          color: $primaryColor;
        }
      }
      .agent-signup-cta {
        margin-left: auto;
        .btn-dark {
          min-width: 120px;
        }
      }
  }
  .agent-signup-inner {
    min-height: calc(100% - 70px);
    .agent-signup-body {
      width: 100%;
      max-width: 621px;
      margin: 30px auto;
      @media (max-width: 767px){
        padding: 0 15px;
      }
    }
  }
  .btn-login-here {
    color: #DF0000;
    font-weight: $exbold;
    text-decoration: underline;
  }
  .material-textfield .material-textfield-input {
    min-height: 54px;
  }
  .progress {
    height: 6px;
    margin-bottom: 10px;
    .progress-bar {
      background-color: $primaryColor;
    }
  }
  .note__text {
    font-family: $Pangram;
    font-size: 12px;
    color: #979797;
    margin: 7px 0;
  }
  .btn-go-back {
    font-size: 14px;
    line-height: 17px;
    color: $primaryColor;
    background-color: transparent;
  }
  .material-textfield-long-text {
    .form-control.material-textfield-input{
      @media (max-width: 767px){
        padding-top: 36px;
        min-height: 70px;
      }
    }
  }
  .contact__sent__wrapper {
    max-width: 465px;
    margin: 0 auto;
    .title {
      font-family: $Pangram;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: $primaryColor;
      margin-bottom: 17px;
      text-align: center;
    }
    .subtitle {
      font-family: $Pangram;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #979797;
      margin-bottom: 37px;
    }
    .contact__sent__wrapper__inner {
      h2 {
        font-family: $Pangram;
        font-size: 16px;
        line-height: 19px;
        color: $primaryColor;
      }
      p {
        font-family: $Pangram;
        font-size: 12px;
        color: #979797;
        margin-bottom: 25px;
      }
    }
    .signature__status {
      font-family: $Pangram;
      font-size: 12px;
      line-height: 24px;
      color: $primaryColor;
      .status {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 8px;
        border-radius: 50px;
        &.pending {
          background-color:  #FBBC05;
        }
        &.signed {
          background-color:  #0F9D58;
        }
      }
    }
  }
}

// React ReactMultiSelectCheckboxes
// .multiselect__checkboxes__field {
//   position: relative;
//   font-family: $Pangram;
//   .multiselect__checkboxes__field__label {
//     font-family: $Pangram;
//     font-weight: 700;
//     font-size: 12px;
//     line-height: 14px;
//     color: #000;
//     position: absolute;
//     top: 8px;
//     left: 15px;
//   }
//   &.multiselect__checkboxes__field__lg {
//     button[class*="css-"] {
//       min-height: 66px;
//     }
//   }
//   button[class*="css-"] {
//     border: 2px solid #E5E5E5;
//     min-height: 54px;
//     border-radius: 4px;
//     color: #000;
//     padding: .375rem 15px;
//     padding-top: 20px;
//     font-size: 14px;
//     box-shadow: none;
//     background:#fff url('/img/img_v2/select-icon.svg') no-repeat right 15px center;
//     background-size: 16px;
//     > span[class*="css-1v99tuv"] {
//       text-transform: capitalize;
//     }
//     .css-1gpjby2 {
//       display: none;
//     }
//     & + div {
//       width: 100%;
//       margin-top: 3px !important;
//       position: relative !important;
//       > div {
//         border-radius: 4px;
//         background: #fff;
//         box-shadow: 5px 10px 30px #E5E5E5;
//         > div {
//           display: flex;
//           align-items: center;
//           border-bottom: 1px solid #E5E5E5;
//           + div {
//             // background-color: red;
//             > div {
//               width: 100%;
//
//               div {
//                 cursor: pointer;
//                 color: #000;
//                 font-weight: 400;
//                 font-size: 14px;
//                 padding: 6px 12px;
//                 background-color: transparent;
//                 position: relative;
//                 vertical-align: middle;
//                 min-width: 150px;
//                 &::before {
//                   content: "";
//                   position: absolute;
//                   top: 6px;
//                   left: 10px;
//                   height: 20px;
//                   width: 20px;
//                   cursor: pointer;
//                   border: 2px solid #e5e5e5;
//                 }
//                 &:active,
//                 &:hover {
//                   background-color: transparent;
//                 }
//                 input[type="checkbox"] {
//                   opacity: 0;
//                   margin-right: 15px !important;
//                 }
//               }
//               .css-1qprcsu-option {
//                   &::before {
//                     content: "";
//                     background-color: #000;
//                     border: 2px solid #000;
//                   }
//                   &::after {
//                     position: absolute;
//                     content: '';
//                     width: 6px;
//                     height: 12px;
//                     border: solid #fff;
//                     border-width: 0 2px 2px 0;
//                     transform: rotate(45deg);
//                     top: 8px;
//                     left: 17px;
//                   }
//                 }
//             }
//           }
//         }
//       }
//     }
//   }
// }

// Dropzone
.dropzone {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  &.dropzone-sm {
    max-width: 100%;
    // margin: 0 auto;
    .dropzone-inner-area  {
      height: 176px;
    }
  }
  .upload-container {
    cursor: pointer;
  }
  .dropzone-inner-area {
    width: 100%;
    height: 250px;
    @media (max-width: 767px) {
      height: 100%;
      padding: 30px 0;
    }
    background: #fff;
    border: 2px dashed #B4B4B4;;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:focus {
      outline: none;
    }
  }
  .upload-text {
    font-weight: $bold;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    color: $primaryColor;
    margin-bottom: 5px;
  }
  .btn-upload {
    min-width: 177px;
  }
}

.dropzone-title {
  font-family: 'Pangram';
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: $primaryColor;
  margin-bottom: 6px;
}
.dropzone-subtitle {
  font-family: 'Pangram';
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $primaryColor;
  margin-bottom: 12px;
}

.dropzone-image{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100px;
}
// Dropzone

.extra__spacing{
  margin-top: 50px;
}
