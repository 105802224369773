@import './../../../theme/variables.scss';
.light-bg {
	background: #f7f8f6 !important;
	padding: 0 0 15px 0;
	margin-bottom: 32px !important;
}
.reporting-sel {
    border: 0px solid #d6d6d6;
    width: 160px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    color: #787878;
    line-height: 19px;
    padding: 10px 40px 10px 20px !important;
    background-image: url("/img/custom-select-arrow.svg");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 90%;
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    cursor: pointer;
    position: relative;
    margin-left: 15px;
}
.stragist-report {
	margin-bottom: 40px;
}

.reporting__btn {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background: #C7CE4A;
    border-radius: 4px;
    min-width: 128px;
    padding: 17px 30px;
	margin-left: 6px;
    float: right;
	margin-top: 22px;
	border: 0;
	outline: none;
}
.reporting-heading {
	font-size: 30px;
	font-weight: 600;
	color: #67797d;
	margin-top: 30px;
}
.typeahead {
.reporting-search {
    width: 100%;
    box-sizing: border-box;
	border: 0 !important;
	height: 50px !important;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    background-color: white;
    background-image: url("/img/search-icon.svg");
    background-position: 22px 17px;
    background-repeat: no-repeat;
    padding: 13px 20px 13px 61px !important;
    box-shadow: 0 2px 20px 5px rgba(31, 31, 31, 0.04);
    border-radius: 6px !important;
}
}




.jungler__page--container{
	
	.filter__sub--container{
		border-top: 1px solid #E6EBF0;
		min-width: 1440px;
		max-width: 100%;
	.custom-select{
			max-width: 228px;
			display: inline-block;
			height: 46px;
			border: 0;
			border-right: 1px solid #E6EBF0;
			font-size: 15px;
			font-weight: 600;
			color: #787878;
			letter-spacing: 0.44px;
			text-align: center;
			background-position: calc(100% - 12px);
			border-radius: 0;
		}
		.hide__inactive{
			margin-left: 24px;
			display: inline-block;
		}
		.displ-none {
			display: none;
		}
	}
	.fordt-picker {
		.btn__edit {
			margin: 0 9px;
			position: absolute;
			left: 95px;
			top: 25px;
		}
		.react-datepicker-wrapper {
			position: absolute;
			top: 23px;
			left: 0;
			width: 100px;
			height: 18px;
		}
		.react-datepicker__input-container {
			button {
				background: none;
				border: 0;
				height: 18px;
			}
		}
	}
	.jungler__list{
		a {
			&:hover {
				text-decoration: none;
			}
		}
		.btn__edit {
			margin: 0 9px;
		}
		.table {
			margin-bottom: 0;
			> thead tr {
				border-top: 1px solid #E6EBF0;
			}
		}
		.table-striped > tbody > tr:nth-of-type(odd) {
			background-color: rgba(236, 237, 232, 0.4);
		}
		.table > {
			thead > tr > th,
			tbody > tr > td {
				color: #73777A;
				font-size: 13px;
			}
			thead > tr > th {
				border-bottom: 0px solid #ddd;
				font-weight: 600;
				padding: 7px 25px;
					&:last-child {
						padding: 7px 10px;
					}
			}
			tbody > tr > td {
				padding: 8px 25px;
				font-weight: 400;
				border-top: 0px solid #ddd;
				vertical-align: middle;
				&:last-child {
					padding: 7px 10px;
				}
			}
			tbody > tr > td.noClientResults {
			    text-align: center;
				padding: 50px;
				font-size: 18px;
				color: #c7ce49;
				font-weight: 600;
			}

		}
	}
}
