@import './../../theme/variables.scss';

.login__container{
  input {
    &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
      color: #9B9B9B;
      opacity: 100;
      font-size: 16px;
      font-family: 'Dosis-ExtraLight';
      font-style: italic;
    }
  }

  /*our team details close*/

  /*login css open*/

  .v-container {
    position: relative;
    height: calc(100vh - 67px);
  }

  .login-header {
    background: #fff;
    z-index: 99999;
    position: relative;
  }

  .LoignSection {
    position: relative;
    background-size: cover;
    height: calc(100vh - 67px);
    background-image: url('/../../img/home-banner.png');
  }

  .login-box {
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.85);
    width: 50%;
    padding: 50px 70px 50px 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h3 {
      text-align: center;
      font-size: 24px;
      font-family: "Dosis-SemiBold";
      margin-bottom: 51px;
    }
    p {
      text-align: center;
      font-size: 16px;
      margin-top: -15px;
      margin-bottom: 20px;
    }
    label {
      width: 100%;
      color: #9B9B9B;
      font-size: 14px;
      font-family: "Dosis-Medium";
      position: absolute;
      top: -20px !important;
      left: 0;
      line-height: 17px !important;
    }
    input {
      &[type="email"], &[type="text"], &[type="password"] {
        border: 0;
        border-bottom: 1px solid #9B9B9B;
        width: 100%;
        height: 30px;
        color: $primaryColor;
        font-size: 16px;
        border-radius: 0;
        font-family: 'Dosis-Regular';
        outline: none;
        padding: 0px 0 0px !important;
        background: transparent;
      }
      &:focus {
        border-bottom: 1px solid #C3CF21;
        + label {
          color: #C3CF21 !important;
          margin-top: -20px;
        }
        ~ label {
          top: 0 !important;
          color: #9B9B9B;
          font-size: 14px;
          font-family: "Dosis-Medium";
          line-height: 17px !important;
          font-weight: bold;
        }
      }
    }
  }

  input:valid ~ .text-label {
    top: 0 !important;
    color: #9B9B9B;
    font-size: 14px;
    font-family: "Dosis-Medium";
    line-height: 17px !important;
    font-weight: bold;
  }

  .login-submit {
    width: 100%;
    height: 58px;
    font-size: 18px !important;
    font-family: 'Dosis-SemiBold' !important;
    margin-bottom: 41px;
  }

  .for-left {
    float: left;
  }

  .for-right {
    float: right;
  }

  .create-accoutn {
    font-size: 16px;
    font-family: 'Dosis-SemiBold';
    a {
      color: $primaryColor;
      text-decoration: none;
      &:active, &:hover, &:focus {
        color: $primaryColor !important;
      }
    }
  }

  .mor-marg-top {
    margin-top: 25px;
  }

  .login-img {
    z-index: 999;
    position: relative;
    height: 100%;
  }

  .login-header {
    .close {
      padding-top: 20px;
      font-size: 20px;
      opacity: 1;
      padding-right: 10px;
    }
    h4 {
      padding-top: 20px;
      font-size: 20px;
      color: #3B3B3B;
      padding-left: 10px;
    }
  }

  .left-align {
    text-align: left;
  }

  .right-align {
    text-align: right;
  }

  .center-align {
    text-align: center;
  }

  .LoignSection .error_field {
    color: red;
    display: block;
    float: left;
    margin-top: -50px;
  }

  .LoignSection .success_field {
    color: green;
    display: block;
    float: left;
    margin-top: -50px;
  }
  /*login css close*/

  @media screen and (max-width: 980px) {
    .login-box {
      width: 65%;
    }
  }

  @media screen and (max-width: 767px) {
    .login-box {
      width: 94%;
      padding: 25px 30px;
    }
    .create-accoutn {
      font-size: 16px;
      font-family: Dosis-SemiBold;
      width: 50%;
      float: left;
    }
    .for-right {
      text-align: right;
    }
    .popup-logo {
      width: 190px !important;
    }
    .login-header {
      .close {
        padding-top: 15px;
        padding-right: 0;
      }
      h4 {
        padding-top: 15px;
      }
    }
  }

  @media screen and (max-width: 340px) {
    .v-container {
      position: relative;
      height: calc(100vh - 1px);
    }
    .popup-logo {
      width: 150px !important;
      margin-top: 15px;
    }
    .login-header h4 {
      padding-top: 20px;
      font-size: 16px;
      padding-left: 0;
    }
    .login-box h3 {
      text-align: center;
      font-size: 24px;
      font-family: Dosis-SemiBold;
      margin-bottom: 30px;
    }
  }
}
