@import './../../theme/variables.scss';
.margleftheader {
    margin-left: 117px;
    .agent__fixedhead {
        left: 117px;
        width: calc(100% - 117px);
    }
}
.nonefixed {
    position: static !important;
    width: 100%;
}

.agent__fixedhead {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    z-index: 999;
    @media #{$tablet768}{
      top: 0 !important;
   }
   @media #{$phone767}{
    left: 0;
      right: 0;
      width: 100%;
   }
}

.clientactivity__autofixtoptab {
    @media #{$tablet768}{
        left: 0;
        right: 0;
        width: 100%;
        top: 0 !important;
        position: fixed;
        z-index: 9;
      }
}
.impstratefixedhead {
    position: fixed;
    top: -40px;
    left: 0px !important;
    width: 100% !important;
    right: 0px !important;
    z-index: 9999;
}

.fixedhead__for_desk--tab {
    position: fixed;
    top: 0;
    left: 117px !important;
    width: calc(100% - 117px) !important;
    right: 0;
    z-index: 9999;
    @media #{$tablet768}{
          left: 0 !important;
          right: 0;
          width: 100% !important;
          top: -40px !important;
      }
      @media #{$phone767}{
        left: 0;
          right: 0;
          width: 100%;
      }
}

.impstrateMobilefixedhead {
    position: fixed;
    top: -60px !important;
    left: 0px !important;
    width: 100% !important;
    right: 0px !important;
    z-index: 9999;
}

.impstrateMobileTwoTabfixedhead {
    position: fixed;
    top: 0;
    left: 0px !important;
    width: 100% !important;
    right: 0px !important;
    z-index: 9999;
}

.showonlylandscape {
    display: none;
    @media #{$tabletLandscape}{
        display: block;
    }
}
.tabletViewheaderadmin {
  @media #{$tablet768}{
          width: calc(100% + 30px);
          margin: 0 -15px;
      }
}

.mobileAdminStickyHeader {
  @media #{$tablet768}{
          width: calc(100% + 30px);
          margin: 0 -15px;
      }
}

.hidelandscape {
    @media #{$tabletLandscape}{
        display: none;
    }
}
.showlandscape {
    display: none;
    @media #{$tabletLandscape}{
        display: block;
        padding: 0 10px;
    }
}
.navbar__header--navigation {
    .navigation__links {
        @media #{$tabletLandscape}{
            min-width: 120px !important;
        }
    }
}
.showonlylandscape {
    display: none;
    @media #{$tabletLandscape}{
        display: block;
    }
}
.fullsearch__bg {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1043;
    height: 65px;
    border-bottom: 1px solid #dcdfe3;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    @media #{$tabletLandscape}{
        height: 85px;
        -webkit-transform: translateY(-85px);
        transform: translateY(-85px);
    }
    @media #{$tablet768}{
        height: 105px;
        -webkit-transform: translateY(-105px);
        transform: translateY(-105px);
    }
    @media #{$phone}{
        height: 65px;
    }
    &.open{
        -webkit-transform: translateY(0) !important;
        transform: translateY(0) !important;

    }
    .typeahead.typeaheadnewmob {
        display: flex;
        align-items: center;
        .costom-search {
            width: calc(100% - 60px);
            float: right;
            margin-right: 15px;
            height: 64px;
            box-shadow: none;
            background-position: 22px center !important;
            @media #{$tabletLandscape}{
                height: 84px;
            }
            @media #{$tablet768}{
                height: 105px;
              }
            @media #{$phone}{
                top: 0;
                margin-bottom: 0 !important;
                height: 64px;
            }
        }

    }
}
.open-search {
    @media #{$tablet768}{
          -webkit-transform: translateY(0) !important;
        transform: translateY(0) !important;
      }
}
.extrapadtop {
    @media #{$tablet768}{
            padding-top: 60px !important;
    }
}
.impersbtngreen {
    margin-top: 60px;
    @media #{$tablet768}{
        margin-top: 40px;
    }
}
.wrapperpadtopmob {
    @media #{$phone}{
        margin-top: 80px;
    }
}
.typeaheadnewmob {
    @media #{$phone}{
        bottom: -10px !important;
    }
    .costom-search {
        @media #{$tablet768}{
            width: 80%;
            float: right;
        }
        @media #{$phone}{
        width: 100%;
    }
    }
}
#sidebar-wrapper {
    @media #{$tablet768}{
       display: none;
       
    }
}
#wrapper {
    @media #{$tablet768}{
       padding-left: 0 !important;
       
    }
}
.onlymob {
    display: none;
    @media #{$phone}{
        display: block;
    }
}
.headerdesktop {
    margin-left: 150px;
    @media #{$tablet768}{
       display: none !important;
       
    }
}
.headermobile {
    display: none;
    @media #{$tablet768}{
        display: block !important;
     }
     .navbar__header {
         height: 60px;
     }
     .navbar-header {
        float: right;
        width: 100%;
     }
}
.desktop__header--web {
    @media #{$tablet980}{
        display: none;
    }
}
.desktop__header--mob {
    display: none;
    @media #{$tablet980}{
        display: block;
    }
    .navbar__header {
        padding: 0;
        height: auto;
        // @media #{$phone}{
        //     margin-top: 23px;
        // }
        .navbar__header--mobile {
            width: 100%;
        }
    }
}

.navbar__header--mobile {
    display: flex;
    align-items: center;
    .navbar__brand--mobile {
        padding: 0;
        height: 62px;
        width: 100px;
        img {
            //height: 100%;
            //width: auto;
        }
    }
    .right__contents {
        width: calc(100%);
        align-items: center;
        display: flex;
        justify-content: flex-end;
        .navbar__toggle--mobile {
            padding: 0;
            margin: 20px 15px 15px 15px !important;
        }
        .searchicon {
            width: 24px;
        }
        .notificationicon {
            width: 24px;
            margin: 0 10px 0 25px;
        }
    }    
}
// #page-content-wrapper {
//     @media #{$phone}{
//         padding: 72px 0 0 !important;
//     } 
// }

.mobile__drawer--close {
    transform: translateX(100%) !important;
}
.mobile__drawer {
    transform: translateX(0);
    transition: all .3s ease-in-out;
    width: 40%;
    height: 100vh;
    overflow-y: auto;
    float: right;
    background: #fff;
    position: fixed;
    right: 0px;
    top: 0;
    padding: 20px 20px 20px 24px;
    z-index: 99999;
    @media #{$phone}{
        width: 75%;
    } 
    ul {
        li {
            width: 100%;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 37px;
            color: $primaryColor;
            margin: 5px 0;
            a {
                color: $primaryColor;
                display: block;
            }
        }
    }
    .mobileclose {
        width: 100%;
        float: left;
        margin-bottom: 30px;
        text-align: right;
        z-index: 99;
        position: relative;
        img {
            width: 24px;
            cursor: pointer;
        }
    }
}
.mobilemenuopen {
    background: none;
    border: 0;
    margin-right: 20px;
    margin-left: 15px;
}


.agent__client--both {
    top: 40px !important;
    transition: all .3s ease-in-out;
    .chat__listing {
        height: calc(100vh - 80px) !important;
    }
}
.gmailSyncPop-container {
    padding: 30px 20px 15px 20px;
    h5 {
        font-size: 20px;
        margin: -24px 0 15px 0;
    }
}
.for-Cursor {
    cursor: pointer;
    margin-right: 10px;
}

.navigation__links--google-sync{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background: #3367d6;
    border-radius: 4px;
    min-width: 128px;
    min-height: 44px;
    padding:0 15px 0 0;
    margin-right: 10px;
    border: 0;
    > img{
        margin: 7px 10px;
        height: 30px;
        background: #fff;
        padding: 4px;
        border-radius: 4px;
    }
    &:hover{
        background: #1e4caf;
    }
}

.navbar__header--desktop .costom-search {
    width: 100%;
    box-sizing: border-box;
    border: 0;
    // font-family: 'Source Sans Pro';
    font-family: 'Poppins';
    font-size: 14px;
    background-color: white;
    background-image: url("/img/search-icon.svg");
    background-position: 22px 12px;
    background-repeat: no-repeat;
    padding: 13px 20px 13px 61px !important;
    box-shadow: 0 2px 20px 5px rgba(31, 31, 31, 0.04);
    border-radius: 6px;
    margin: 0 !important;
    height: 42px;
    @media #{$tablet980}{
        font-size: 15px;
        background-position: 10px 12px;
        padding: 13px 10px 13px 35px !important;
    } 
}
.navbar__header--desktop .clear-txt {
    position: absolute;
    right: 10px;
    z-index: 10;
    top: 10px;
    display: none;
}


.mobileView {
    display: none;
    .navbar__header {
        height: 60px;
    }
}
.logostyle {
    margin-right: 0;
    width: 85px;
    height: auto;
    object-fit: cover;
}
.profilelogo-style {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    float: left;
}
.logoImg {
    margin-right: 15px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
    float: left;
}
.navbar-edit {
	height: auto;
	border: 0;
    background: #fff !important;
    padding: 0 !important;
}
.navbar-brand-edit {
	height: auto;
}
.navbar-brand-padding {
	padding: 0;
}
.mobileView .searchbar-header {
    width: 100%;
    margin-bottom: 5px;
}
.mobileView .logo__title {
    font-size: 17px;
    font-weight: bold;
    padding-top: 17px;
	float: left;
	color: #73777A;
	letter-spacing: 0.5px;
}
.mobileView .btn__all {
	font-size: 14px;
    font-weight: 500;
    text-align: center;
	color: #fff !important;
	border-radius: 4px;
    min-width: 128px !important;
    padding: 12px 6px !important;
	margin-right: 10px;
	letter-spacing: 0.5px;
}
.mobileView .btn__grey {
    background-color: #CED0DA;
    border-color: #CED0DA;
}
.mobileView .btn__green {
	background: #C7CE4A;
	border-color: #C7CE4A;

}
.navbar-right-edit {
	padding-top: 20px;
	margin-right: 0;
}
.navbar-right-edit li {
    width: 100%;
    float: left;
    margin-bottom: 15px;

    .navigation__links {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #fff !important;
        background: #C7CE4A;
        border-radius: 4px;
        min-width: 128px;
        padding: 18px 0px !important;
        margin-right: 0;
        float: left;
        width: 100%;
    }
}
.mobileView .profile-name {
    font-size: 15px;
    font-weight: 600;
    color: #73777A !important;
    letter-spacing: 0.5px;
    float: left;
    width: 100%;
    margin: 5px 0 0 0;
    padding-bottom: 60px;
}
.navbar-edit .nav > li > a:hover, .navbar-edit .nav > li > a:focus {
    text-decoration: none;
    border-bottom: 0px solid #337ab7;
    background: #C7CE4A;
}
.mobileView .avart__arrow--icon {
	width: 15px;
	margin-left: 15px;
}
.mobileView .navbar-default .navbar-nav > .open > a, 
.mobileView .navbar-default .navbar-nav > .open > a:hover, 
.mobileView .navbar-default .navbar-nav > .open > a:focus {
    background: #fff !important;
}
.navbar-edit .dropdown-menu {
	border: 0;
	background: #F7F7F5;
	padding: 0;
}
.navbar-edit .dropdown-menu > li {
	background: #F7F7F5;
}

.navbar-edit .dropdown-menu > li > a {
    padding: 0 25px;
    font-weight: 600;
    color: #73777A;
    font-size: 15px;
    border-top: 1px solid #E5E5E5;
    height: 46px;
    line-height: 46px;
}
.mobileView .typeahead {
    position: relative;
        bottom: 3px;
        @media #{$phone767}{
            position: static;
        }
}
.mobileView .costom-search {
    width: 100%;
    box-sizing: border-box;
    border: 0;
    // font-family: 'Source Sans Pro';
    font-family: 'Poppins';
    font-size: 14px;
    background-color: white;
    background-image: url(/img/search-icon.svg);
    background-position: 23px 12px;
    background-repeat: no-repeat;
    padding: 13px 20px 13px 61px !important;
    box-shadow: 0 2px 20px 5px rgba(31, 31, 31, 0.04);
    border-radius: 6px;
    margin-bottom: 15px !important;
    height: 42px;
    @media #{$phone767}{
        top: 9px;
    }
}
.mobileView .clear-txt {
    position: absolute;
    right: 10px;
    z-index: 10;
    top: 15px;
}
.mobileView .clear-txt .btn-clear {
    padding: 6px 10px;
    font-size: 1.4rem;
    font-weight: 400;
    border: 0px solid transparent;
    border-radius: 6px;
    min-width: inherit;
    background: transparent;
    color: #73777A;
    display: none;
}

.mobileView .test {
	width: 100%;
	float: left;
	height: 1000px;
}





@media screen and (max-width:1024px) {
    .dashboard__navbar--tabs {
        margin: 34px 0 0;
    }
    .navbar__header--navigation .navigation__links {
            // min-width: 70px;
            min-width: 90px;
            margin-right: 5px;
    }
    .dashboard__navbar--tabs > li {
        width: calc(50% / 2);
        height: 90px;
    }
    .dashboard__navbar--tabs > li > a.active {
        color: #C6CE4A;
        border-bottom: 4px solid #C6CE4A;
        background: #fff;
        height: 90px;
    }
}

@media screen and (max-width:767px) {
    .profilelogo-style {
        margin-bottom: 12px;
    }
    .dashboard__navbar--tabs > li {
        width: calc(50% / 2);
        height: 60px;
    }
    .dashboard__navbar--tabs > li > a {
        padding: 20px 0;
    }
    
    .dashboard__navbar--tabs > li > a.active {
        color: #C6CE4A;
        border-bottom: 4px solid #C6CE4A;
        background: #fff;
        height: 60px;
    }
    .mobileView {
        display: block;
    }
    .leftsidebar-menu {
        position: absolute;
        top: 90px;
        z-index: 999;
        height: 40px;
        right: 15px;
        left: 15px;
        
    }
    .leftsidebar-menu a {
        color: #666;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        width: 100%;
        float: left;
    }
    .mobile-dropdown {
        width: 100%;
        padding: 20px 15px;
        margin-top: 5px;
    }
    .mobile-dropdown>li {
        margin: 10px 0;
        float: left;
        width: 100%;
    }
    .mobile-dropdown>li>a {
        padding: 5px 20px 5px 0;
        font-weight: 500;
        font-size: 16px;
    }
    .mobileView .logo__title {
		display: none;
	}
	.navbar-right-edit {
		padding-top: 10px;
		margin: 7.5px auto;
	}
	.navbar-edit .navbar-toggle {
		margin-top: 5px;
    }
    .navbar-edit .icon-bar {
        background-color: $primaryColor !important;
    }
	.mobileView .btn__all {
        margin: 0 0 5px 0;
        width: 100%;
        float: left;
	}
	.mobileView .navbar-nav .open .dropdown-menu > li > a {
        line-height: normal;
        display: inline-block;
        width: 100%;
        padding-top: 20px;
    }
    .mobileView .navbar-default .navbar-nav > .open > a, 
    .mobileView .navbar-default .navbar-nav > .open > a:hover, 
    .mobileView .navbar-default .navbar-nav > .open > a:focus {
        background: none !important;
    }
}




.whitebody {
	background: #fff !important;
}
.gray-header {
	background: #F7F7F5 !important;
}
.mobileView .notification {
    padding: 0px 19px 0 5px;
}
.mobileView .notification a {
    position: relative;
}
.mobileView .notificatonShow-icon {
    position: absolute;
    right: 18px;
    top: 0;
    width: 12px;
    height: 12px;
    background-color: #CE0319;
    border-radius: 100px;
    border: 2px solid #fff;
}
.mobileView .navbar-fixed-top .navbar-collapse, 
.mobileView .navbar-fixed-bottom .navbar-collapse {
    max-height: 100vh;
    height: 100vh;
    overflow-y: auto;
}
.mobileView .navbar-default .navbar-toggle {
    border-color: transparent;
}
.mobileView .notificaton__icon {
    position: relative;
    top: 10px;
    width: 50px;
    margin: 0 auto 25px;
}
.mobileView .notification .dropdown-menu {
    top: 104%;
    left: -140px;
    position: absolute;
    min-width: 318px;
    max-width: 318px;
    max-height: 240px;
	min-height: 240px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    background-color: #F7F7F5;
    background-clip: padding-box;
    border: 0px solid #ccc;
    border: 0px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.mobileView .notification .dropdown-menu:before {
    bottom: 100%;
    left: 55%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #F7F7F5;
    border-width: 17px 8px;
    margin-left: -30px;
}
.mobileView .notification .dropdown-menu > li {
    border-bottom: 1px solid #E5E5E5;
    position: relative;
}
.mobileView .notification .dropdown-menu > li > a {
    padding: 15px;
    padding-right: 30px;
    white-space: pre-line;
    font-size: 14px;
    color: #73777A;
	font-weight: 400;
}











.search-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 46px;
    z-index: 999;
    width: 100%;
    background-color: #fff;
    min-height: 15px;
    max-height: 450px;
    overflow-y: auto;
    box-shadow: 0 2px 5px #ccc;
    @media #{$tabletLandscape}{
        top: 65px !important;
    }
    @media #{$tablet768}{
        top: 65px !important;
    }
        li {
            cursor: pointer;
            border-bottom: 1px solid #f7f7f7;
          a {
            margin-top: -1px;
            /* background-color: #f8f8f8; */
            padding: 6px 12px;
            text-decoration: none;
            font-size: 18px;
            color: black;
            display: -ms-flexbox;
            display: flex;
            text-align: left;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: justify;
            justify-content: space-between;
            .search-left{
              display: flex;
              align-items: center;
              .user-img{
                max-width: 100%;
                width: auto;
                margin-right: 7px;
                img{
                  max-width: 50px;
                  height: auto;
                }
              }
              .title{
                margin: 0px;
                line-height: normal;
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
                color: #6f6f6f;                
              }
              small{
                font-size: 12px;
              }
              span{
                line-height: 17px;
              }
            }
            .search-right{
              p{
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                margin: 0px;
                text-transform: capitalize;
                color: #6f6f6f;
                width: 160px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        .search-close{
            width: auto;
            text-align: right;
            padding: 10px 10px;
            opacity: 1;
            border-bottom: 0px solid #eaeaea;
            cursor: pointer;
            position: absolute;
            right: 0;
            background: white;
            z-index: 1;
            .fa{
              
            }
        }
      }

      .close{
            width: 100%;
            text-align: right;
            padding: 10px 10px;
            opacity: 1;
            border-bottom: 1px solid #eaeaea;
            .fa{
              
            }
        }
