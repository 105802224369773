@import './../../theme/variables.scss';

// .sendwrapper__mob {
//   @media #{$phone767}{
//       border-radius: 50px;
//       margin: 0 20px;
//       padding: 0 10px 0 24px;
//       position: fixed;
//       bottom: 10px;
//       left: 0;
//       right: 0;
//       z-index: 9;
//       height: auto !important;
//   }
//   textarea {
//         max-height: 120px !important;
//         min-height: 58px;
//         line-height: 58px !important;
//         transition: all .1s ease-in-out;
//         &:focus {
//           line-height: 28px !important;
//         }
//   }
// }

.anwser__multiple_text{
  color: #73777A;
  font-weight: 600;
  font-size: 14px;
  list-style-type: disc !important;
}

.anwser__multiple_place{
  color: #73777A;
  font-weight: 600;
  font-size: 14px;
  margin: 6px 0 10px !important;
}

.break__text{
  white-space: pre-line;
  word-wrap: break-word;
}
.right__chat--section.remove__index--forImage{
  z-index: 9999;
}
.chat_ul_scroll.showchat__agent-client{
  height: calc(100% - 120px) !important;
  margin-top: 10px !important;
}
.empty__data--content{
  text-align: center;
}
.extra__gap--forChat{
  margin-top: 60px;
}

.no_agent_wap{
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 24px;
  font-size: 16px;
}
.select_wrap_drawer{
  width: 90%;
  margin: 15px auto 0;
}

.savemsg__{
  font-size: 16px;
}
.questionlistedit {
  height: calc(100vh - 10px) !important;
}
.questionlistedit__ai {
  height: calc(100vh - 127px) !important;
}
.strategyformcard {
  border-bottom: 0px solid #CED0DA !important;
  max-height: 67vh !important;
}
.fulllistwidth {
  width:100%;
  margin: 0 ;
  .locations__list {
    border-bottom: 1px solid #CED0DA !important;
  }
  .select__agent--name {
    font-weight: 500 !important;
  }
}
.fulllistwidth__ai {
  width:100%;
  margin: 0 24px;
  .locations__list {
    border-bottom: 1px solid #CED0DA !important;
  }
  .select__agent--name {
    font-weight: 500 !important;
  }
}
.fullbtnswidth {
  width: 100%;
  padding: 16px 10px !important;
}
.fullheaderwithclose {
  span {
    width: calc(100% - 40px);
    float: left;
    text-align: left;
    @media #{$phone}{
      width: calc(100% - 1px);
      font-size: 16px;
    }
  }
  .resultsbtns {
    color: #fff;
    background-color:$secondaryColor;
    border-color: $secondaryColor;
    letter-spacing: .75px;
    padding: 11px 32px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 13px;
    border-radius: 4px;
    @media #{$phone}{
      margin-right: 0px;
      padding: 12px 10px;
    }
  }
  .close-btn {
    right: 24px !important;
    @media #{$phone}{
      right: 15px !important;
    }
  }
}
.empty__ai--msg {
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
}
.showAI {
  display: block;
}
.hideAI {
  display: none;
}
.btn__green_viewmore.viewmorebtns{
  font-size: 14px !important;
  font-weight: 500 !important;
}
.chatcontainer__pad {
  padding-bottom: 10px;
}
.uppercasefonts {
  text-transform: uppercase !important;
}
.font500 {
  font-weight: 500 !important;
}
.btn.new__common--btn {
  padding: 14px 22px;
  font-weight: 500;
  font-size: 14px;
  width: auto !important;
}
.mobile__tab--container {
  @media #{$phone767}{
    padding-top: 10px !important;
  }
}
.deskview__btns {
  @media #{$tablet980}{
    display: none;
  }
}
.mobview__dropdown--select {
  display: none;
  width: 100%;
  position: relative;
  @media #{$tablet980}{
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media #{$phone767}{
    display: block;
    margin-top: 5px;
  }
  .selectdropdown {
    border: 2px solid #e5e5e5;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: $primaryColor;
    padding: 18px 17px 18px 17px;
    -webkit-appearance: none;
    overflow: hidden;
    background-image: url('/img/custom-select-arrow.svg');
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: calc(100% - 15px);
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    cursor: pointer;
    width: 100%;
    &:focus {
      outline: 0;
      border: 2px solid $secondaryColor;
    }
  }
  &::after {
    content: '';
    height: 60px;
    width: 4px;
    background: $secondaryColor;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px 0 0 4px;
  }
}

.select__tab {
  @media #{$tablet768}{
    width: 60% !important;
  }
  @media #{$phone}{
    width: 100% !important;
  }
}
.poppinsfonts {
  font-family: 'poppins' !important;
}
.send__wrapper--clientstrat {
  height: 160px;
  background: #fff;
  border: 2px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 0;
  padding: 0px 0 0px 15px;
  margin: 0 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  textarea {
    height: 160px !important;
    @media #{$phone, $phone6, $phone6Plus}{
      height: 58px !important;
    }
  }
  @media #{$phone, $phone6, $phone6Plus}{
    height: 58px;
    border-radius: 50px;
    margin: 0 20px;
    padding: 0 10px 0 24px;
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
  }
  textarea {
    border: none;
    background: #fff;
    font-family: "Pier Sans";
    resize: none;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    // color: #9B9B9B;
    color: $primaryColor;
    padding: 7px 0;
    height: 100%;
    width: calc(100% - 80px);
    margin-bottom: 0 !important;
    &:focus {
      outline: 0;
    }
    @media #{$phone, $phone6, $phone6Plus}{
      font-size: 16px;
      color: #9B9B9B;
      padding: 0;
      background: none;
      height: 26px;
      line-height: 28px;
    }
  }
  button {
    border: none;
    background: none;
    font-family: "Pier Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    color: #9B9B9B;
    float: right;
    cursor: pointer;
    outline: none;
    width: 80px;
    height: auto;
    position: absolute;
    right: 15px;
    bottom: 10px;
    @media #{$phone, $phone6, $phone6Plus}{
      font-size: 16px;
      height: 100%;
      padding-top: 0;
      background: none;
      position: static;
    }
  }
}

.start__btn--table {
  background: #C6CE4A;
  border-radius: 4px;
  font-family: 'poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #F8F8F8;
  padding: 8px 20px !important;
  img {
    width: 18px;
    margin-right: 5px;
  }
}

.play__btn--table {
  background: #D0021B !important;
  border-radius: 4px;
  font-family: 'poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #F8F8F8;
  padding: 9px 20px !important;
  img {
    width: 18px;
    margin-right: 5px;
  }
}
.newclassdate {
  .react-datepicker-wrapper {
    // top: 0 !important;
    opacity: 0;
  }
}
.empty__town--msg {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  line-height: 24px;
  font-size: 16px;
  @media #{$phone}{
    width: 85%;
  }
}
.rate__town--listingnew {
  ul {
    li {
      border-bottom: 2px solid #F4F4F4;
      padding: 20px 20px;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      transform: translate3d(0, 0, 0) !important;
      .serials {
        width: 29px;
        height: 29px;
        border: 2px solid #C3CF21;
        margin-right: 17px;
        border-radius: 50%;
        display: flex;
        color: #C3CF21;
        font-family: 'Pier Sans';
        font-weight: 500;
        font-size: 13px;
        align-items: center;
        justify-content: center;
      }
      .names {
        font-family: 'Pier Sans';
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #3B3B3B;
      }
      .profile__image {
        width: 36px;
        height: 36px;
        margin-right: 17px;
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }
}


.for__date--format{
  display: block;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #9B9B9B;
  font-weight: 500;
}
.rigth__btn {
  float: right;
}
.select__edit {
  .Select-clear-zone {
    display: none;
  }
  .Select-arrow-zone {
    display: none;
  }
  .Select-control {
    text-align: left;
    background-image: url(/img/profile-arrow.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 15px !important;
    background-position: 96% center !important;
  }
}
.q__a--wrapper {
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
  padding-bottom:100px;
  .questions__txt {
    color: $primaryColor;
    font-weight: 600;
    font-size: 14px;
    span {
      width: 100px;
    }
  }
  .questions__txt--inner {
    width: 100%;
    color: $primaryColor;
    font-weight: 600;
    font-size: 14px;
    padding-left: 15px;
  }
  .answer__txt {
    color: #73777A;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    span {
      width: 100px;
    }
  }
  .answer__txt--inner {
    width: 100%;
    color: #73777A;
    font-weight: 600;
    font-size: 14px;
    padding-left: 15px;
  }
}
.question__list{
  width: 100%;
  background: #fff;
  height: calc(100vh - 0px);
  min-height: 5vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: 600;
  color: #73777A;
  margin-top: 4px;
}

.activity__page{
  .chat__listing {
    ul{
      height: calc(100% - 220px);
    }
  }
  .chat__listing--activity {
    height: calc(100vh - 40px) !important;
  }
}
.activityHistorySidebar{
  height: calc(100vh - 100px) !important;
}
.product-dtl-tab-wrapper {
  padding-bottom: 60px;
}
.history__heading {
  color: $primaryColor !important;
  margin-bottom: 5px !important;
}
.history__date {
  color: #73777A;
  margin-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
}
.btn__green_viewmore{
  background: none;
  border: 0;
  font-size: 16px !important;
  font-weight: 800 !important;
  padding: 0 !important;
  color: $secondaryColor;
}

.btn__green_viewmore:hover, .btn__green_viewmore:focus {
  color: $secondaryColor;
  outline: none !important;
  box-shadow: none !important;
  background: none !important;
  padding: 0 !important;
}

.agent_blank_msg {
  // width: 841px;
  margin: 0 auto;
  text-align: center;
  padding: 100px 0;
  // border: 1px solid #ddd;
}

.calendar__center{
  .react-datepicker-popper {
    width: 300px;
    z-index: 9999999 !important;
    margin-left: 100px;
  }
}
.strategist__datepicker {
  border: 0 !important;
  height: auto !important;
  margin: 0 auto !important;
  background: url(/img/calendar-icon.png);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 20px;
  cursor: pointer;
  text-align: left;
  width: 140px;
}
.client-timeslot-slider{
  .schedule__time--list {
    width: 100%;
    float: left;
    margin: 0 0 0px 0 !important;
    &:focus {
      outline: none;
    }
    ul {
      margin: 0;
      padding: 0;
      text-align: center;
      li {
        float: none !important;
        width: 120px !important;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        border: 1px solid $primaryColor;
        margin: 0 0% 0px 11% !important;
        cursor: pointer;
        > a {
          color: $primaryColor;
        }
        &.active {
          background: #C3CF21;
          border: 1px solid #C3CF21;
          color: #fff !important;
          a {
            color: #fff !important;
          }
        }
      }
    }
  }
}
.m-b {
  margin-bottom: 25px;
}
.str_error_msg {
  color: red;
  display: block;
  float: left;
  width: 100%;
  margin-top: -30px;
}
.str_name_rating{
  display: inline-block;
  margin-right: 5px;
}
.strategist__rating{
  float: left;
  width: auto;
  margin-right: 15px;
}
.rating-icons {
  width: 100%;
  display: inline-block;
  li {
    display: inline-block;
    margin-right: 4px;
    padding: 0 !important;
  }
  img {
    width: 16px;
  }
}
.time_loading {
  padding: 0px 40px 0px 24px;
  height: 55px;
  border: 1px solid #CED0DA;
  font-size: 13px;
  width: 100%;
  float: left;
  background: #fff;
  font-weight: bold;
  text-align: left;
  line-height: 55px;
  border-radius: 4px;
  margin-bottom: 36px;
}

.deletealert-custom-ui {
  text-align: center;
  width: 520px;
  padding: 40px;
  background: #fff;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.27);
  color: $primaryColor;
  @media #{$phone}{
    width: 100%;
  }
}
.react-confirm-alert-overlay{
  // background: rgba(255, 255, 255, 0.08) !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.deletealert-custom-ui > h3 {
  margin-bottom: 25px;
  line-height: 35px;
}
// .deletealert-custom-ui > button {
// width: 160px;
// padding: 10px;
// border: 1px solid #C6CE4A;
// margin: 10px;
// border-radius: 4px;
// cursor: pointer;
// background: #C6CE4A;
// color: #fff;
// font-size: 14px;
// }

.client__activity--container{
  padding-top:30px;
  width: calc(100% - 94px);
  margin: 0 auto;

  @media #{$tablet}{
    padding-top:40px;
    width: calc(100% - 20px);
  }

  @media #{$phone}{
    padding-top: 40px;
    width: calc(100% - 10px);
  }

  .client__secondary_name{
    font-size: 12px;
    font-weight: 600;
    color: #73777A;
    line-height: 27px;
  }


  .client__name{
    font-size: 19px;
    font-weight: 600;
    color: #73777A;
    line-height: 27px;
    .client__name--status{
      font-size: 13px;
      font-weight: 400;
      color: #C6CE4A;
      margin-left: 9px;
    }
  }
  .client__action--btn{
    text-align: left;
    @media #{$tablet980}{
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
    }

    @media #{$phone}{
      text-align: left;
    }
    button,a{
      margin-right: 12px;
      margin-bottom: 8px;
      @media #{$tablet980}{
        margin: 5px 10px 5px 0px;
      }
    }
  }


  .client__activity--card{
    padding: 26px 35px;
    background: rgba(236, 237, 232, 0.40);
    margin: 10px 0;
    @media #{$phone}{
      padding: 20px 20px;
      margin: 10px -20px;
    }
  }
  .client__activity--info{
    &--items{
      margin: 0 0 15px;
      &:last-child{
        margin: 0;
      }
      h1{
        font-size: 15px;
        line-height: 25px;
        font-weight: 600;
        color: #73777A;
        margin: 0;
      }
      h2{
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
        color: #73777A;
        margin: 0;
        a{
          color: #C6CE4A;
        }
      }
    }
  }
}

#product-detail{

  @-webkit-keyframes "opacity" {
    0% {
      opacity: 0;
      -webkit-transform: scale(3);
      transform: scale(3);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes "opacity" {
    0% {
      opacity: 0;
      -webkit-transform: scale(3);
      transform: scale(3);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  img {
    max-width: 100%;
  }
  button{
    outline: 0;
    box-shadow: none;
    &:focus,&:active,&:visited{
      outline: 0;
      box-shadow: none;
    }
    &:active{
      box-shadow: none;
    }
  }
  .product-detail-inner {
    background: #fff;
    padding: 3em;
    line-height: 1.5em;
  }
  .preview {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .preview-pic {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .preview-thumbnail.nav-tabs {
    border: none;
    li {
      width: auto;
      margin-bottom: 10px;
      img {
        max-width: 100%;
        display: block;
      }
      a {
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid #c3c3c3;
        padding: 10px;
        border-radius: 3px;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .tab-content {
    img {
      width: auto !important;
      animation-name: opacity;
      animation-duration: .3s;
      text-align: center;
      margin: auto;
      display: block;
    }
  }

  .details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-left: 1px solid #f3f3f3;
    padding-left: 60px;
    .product-dtl-list{
      padding: 0;
      li{
        list-style: none;
        border-bottom: 1px dashed;
        border-color: #f1f1f1;
        padding: 11px 0;
        &:last-child{
          border-bottom: 0px;
        }
        span{
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }

  .product-title {
    text-transform: capitalize;
    margin-bottom: 15px;
    margin-top: 0;
    color: #3a3a3a;
    font-family: Brandon Text;
    font-size: 24px;
    letter-spacing: .7px;
    font-weight: 600;
  }
  .price {
    text-transform: capitalize;
    font-weight: bold;
    color: #3a3a3a;
    span {
      color: #3a3a3a;
      font-size: 17px;
      margin-left: 15px;
    }
    .old-price{
      text-decoration: line-through;
      margin-left: 10px;
      color: #ababab;
      font-size: 11px !important;
    }
  }
  .availability{
    text-transform: capitalize;
    font-weight: bold;
    color: #3a3a3a;
  }
  .brand {
    text-transform: capitalize;
    font-weight: bold;
    span{
      font-weight: 300;
      margin: 0 10px;
    }
  }
  .checked {
    color: #7151dd;
  }
  .rating {
    margin-bottom: 15px;
  }
  .product-description {
    margin-bottom: 15px;
  }
  .vote {
    margin-bottom: 15px;
  }
  .size {
    margin-right: 10px;
    &:first-of-type {
      margin-left: 40px;
    }
  }
  .color {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    height: 2em;
    width: 2em;
    border-radius: 2px;
    &:first-of-type {
      margin-left: 20px;
    }
  }
  .add-to-cart {
    border: none;
    color: #fff;
  }
  .like {
    background: #ff9f1a;
    padding: 1.2em 1.5em;
    border: none;
    text-transform: UPPERCASE;
    font-weight: bold;
    color: #fff;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
    &:hover {
      background: #b36800;
      color: #fff;
    }
  }
  .not-available {
    text-align: center;
    line-height: 2em;
    &:before {
      font-family: fontawesome;
      content: "\f00d";
      color: #fff;
    }
  }
  .orange {
    background: #7151dd;
  }
  .green {
    background: #85ad00;
  }
  .blue {
    background: #0076ad;
  }
  .tooltip-inner {
    padding: 1.3em;
  }
  .btn-outline{
    border: 1px solid #c2c2c2;
    background: none;
    min-width: 100px;
    padding: 10px 33px;
    border-radius: 50px;
  }
  .cart-quantity-box{
    border: 1px solid #c2c2c2;
    margin: 0px;
    border-radius: 100px;
    display: inline-block;
    width: auto;
    max-width: fit-content;
    .btn-group{
      display: flex;
      align-items: center;
      justify-content: center;
      button{
        background: none;
        width: auto;
        max-width: fit-content;
      }
    }

  }
  .product-detail-btn-group{
    .add-to-cart{
      margin-right: 5px;
    }

  }


  @media screen and (max-width: 996px) {
    .preview {
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: 997px) {
    .wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  }
  .product-dtl-tab-wrapper{
    .product-dtl-tab-section{
      width: 100%;
      margin: 40px auto;
      .nav-tabs{
        li{
          a{
            font-size: 16px;
            color: #3a3a3a;
          }
        }
      }
    }
  }

  .related-products{
    background-color: #fff;
    .product-card{
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .tabs__container ul > li {
    display: inline-block;
    margin: 0 0 0 0;
    width: 100%;
    text-align: center;
  }
}
