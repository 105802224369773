@import './../../../../../../theme/variables.scss';

.chats__wrapper__chats__list {
    .chats__card__textarea__edit {
        font-family: Pangram;
        font-size: 16px;
        color: $primaryColor;
        padding: 17px 20px;
        width: 100%;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        resize: vertical;
        margin-bottom: 5px;
    }
        
}
