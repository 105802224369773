@import '../../theme/variables.scss';
.topnavigation-layout-header {
  background: #F5F5F5;
  padding: 15px 30px 15px 15px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  @media (max-width: 1024px){
    display: none;
  }
  .topnavigation-layout-search{
    width: 100%;
    max-width: 581px;
    margin-right: 33px;
    .topnavigation-layout-wrapper {
      position: relative;
      width: 100%;
      .topnavigation-layout-input {
        font-family: $Pangram;
        min-height: 54px;
        border: 2px solid #E5E5E5;
        border-radius: 4px;
        font-size: 14px;
        &:focus {
          border: 2px solid $primaryColor;
        }
        &::placeholder {
          color: #4A4A4A;
        }
      }
    }
  }
  .topnavigation-layout-form{
    margin-left: auto;
    .btn-calendar {
      font-family: $Pangram;
      min-width: 156px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .calendar-icon {
        margin-right: 8px;
      }
    }
  }
  .topnavigation-layout-notification{
    margin-left: 24px;
    .chat-notificaton {
      position: relative;
      cursor: pointer;
      .chat-notificaton-count {
        font-family: $Pangram;
        font-weight: $medium;
        font-size: 12px;
        line-height: 20px;
        width: 20px;
        height: 20px;
        display: inline-block;
        color: #FFFFFF;
        background: #CF0808;
        border-radius: 50px;
        position: absolute;
        top: -2px;
        right: -7px;
        text-align: center;
      }
    }
  }
  .topnavigation-layout-divider{
    height: 34px;
    width: 2px;
    background-color: #D8D8D8;
    margin: 0 20px;
  }
  .topnavigation-layout__profile{
    .layout-profile-link {
      display: flex;
      align-items: center;
    }
    .ant-dropdown-link {
      &:hover {
        text-decoration: none;
      }
    }
    .avatar {
      font-family: $Pangram;
      position: relative;
      display: inline-block;
      overflow: hidden;
      color: $secondaryColor;
      font-size: 16px;
      letter-spacing: 1.5px;
      font-weight: $bold;
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      background: $white; //  rgba(199, 206, 74, 0.15);
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      margin-right: 14px;
    }
    .avatar-string {
      position: absolute;
      left: calc(50% + 1.5px);
      transform-origin: 0 center;
      transform: scale(1) translateX(-50%);
    }
    .avatar-info {
      margin-right: 14px;
      .avatar-name {
        font-family: $Pangram;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $primaryColor;
      }
      .avatar-email {
        font-family: $Pangram;
        color: #9B9B9B;
      }
    }
  }
}
