@import './../../theme/variables.scss';
.strategyPermission__error {
  color: red;
  display: block;
  float: left;
  width: 100%;
  margin-top: 5px;
}
.border-section {
  border: 1px solid #E6EBF0;
  border-radius: 5px;
  padding: 50px 40px 20px 40px;
  @media #{$phone767}{
    border: none;
    border-radius: 0;
    padding: 0;
  }
}
.junglerwrapperouter {
  max-width: 100%;
  width: 100%;
  overflow-x: hidden !important;
}
.maximg__label {
  position: relative;
  top: -5px;
  font-size: 12px;
}
.profile__impersonate--btn {
  color: #C7CE4A;
  background-color: transparent;
  border: 1px solid #C7CE4A;
  padding: 9px 12px;
  font-size: 13px;
  line-height: normal;
  font-weight: 600;
  border-radius: 4px;
  margin-bottom: 15px;
  &:focus, &:hover {
    color: #fff;
    background-color: #dae24c;
    border-color: #dae24c;
    outline: none;
    box-shadow: none;
  }
  @media #{$phone767}{
    width: 100%;
    padding: 13px 12px;
    font-size: 14px;
  }
}
#phone-form-control{
	display: none !important;
  }
.country__flag{
  #flag-dropdown {
      height: 30px !important;
      margin: 0 !important;
      background: none !important;
      border: 0;
      border-bottom: 1px solid #9b9b9b;
  }
}
.flag-dropdown__login .flag-dropdown {
	height: 22px !important;
	margin-top: 13px !important;
}
#flagInputControlMainPhone {
	margin-left: 40px !important;
	width: 84%  !important;
}
.additional__textarea.additional__textarea--ht {
  height: 160px !important;
}
.agent-yesno {
  padding-bottom: 25px;
}
.agent_switch {
  margin: 0 10px !important;
}
.tablet__btn--center {
  @media #{$tablet980}{
    margin: 0 auto;
    display: block;
  }
}
.deleteUserBtn{
  float: right;
  margin-top: -25px;
  margin-bottom: 50px;
  @media #{$tablet980}{
    margin: -25px auto 50px;
    display: block;
    float: none;
    padding: 14px 15px !important;
  }
  @media #{$phone767}{
    margin-bottom: 15px;
    padding: 14px 15px !important;
  }
}
.btm-marg {
  margin-bottom: 25px;
  margin-top: -20px;
}
.btn-file {
  position: relative;
  overflow: hidden;
  color: #C7CE4A;
  font-size: 14px;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none; 
  cursor: inherit;
  display: block;
}
.editPhotoBtn {
  right: 5px;
  bottom: 5px;
  position: relative;
  font-size: 10px;
  font-weight: 600;
  text-decoration: underline;
}
.profile__container{
	.min-price {
    position: absolute;
    left: 32px;
    font-weight: normal;
    font-size: 12px;
    top: 8px;
 }
 .custom-select-label {
	padding: 16px 24px 0 15px !important;
 }
}
.profile__container--form{
 	max-width: 687px;
 	margin:0 auto;
	 margin-top: 47px;
   .contact-frm input[type="email"], 
   .contact-frm input[type="text"], 
   .contact-frm input[type="number"] {
		margin-bottom: 0px !important;
		float: none;
	 }
  .profile__container--section{
    position: absolute;
    top: 19px;
    right: 45px;
    .profile__container--avtar{
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 100px;
      display: inline-block;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .edit__photo--btn{
      font-size: 9px;
      color: #C6CE4A;
      letter-spacing: 0;
      display: block;
      text-align: center;
      cursor: pointer;
    }
	}
	.more__margin--btm {
		margin-bottom: 30px;
	}
  .custom-select{
		height: 55px;
		padding: 16px 24px;
    border: 2px solid #e5e5e5 !important;
    &:focus {
      border: 2px solid #C7CE4A !important;
      outline: 0;
    }
	}
  .additional__textarea{
		border: 1px solid #e5e5e5 !important;
		border-radius: 4px;
		resize: none;
		font-size: 13px;
		color: rgba(0,0,0,0.54);
		text-align: left;
		line-height: 16px;
		padding: 16px 15px;
		&:focus{
			border: 1px solid #C7CE4A !important;
		}
	}

  .checkbox__list{
    list-style: none;
    padding: 0;
    margin: 0 0 19px;
    > li{
      margin: 0 0 15px;
      .checkmark {
        top: 1px !important;
        left: 0;
        width: 18px !important;
        height: 18px !important;
        &::after {
          content: '';
          left: 5px;
          top: 0px;
          width: 6px;
          height: 12px;
        }
      }
    }
  }


 	.panel {
	    margin-bottom: 44px;
	    background-color: #fff;
	    border-radius: 6px;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	}
 	.panel-default {
	    border-color: #E6EBF0;
	}
 	.panel-default>.panel-heading {
	    background-color: #fff;
	    border-color: #E6EBF0;
	    color: #73777A;
      position: relative;
	}
	.panel-heading {
	    padding: 32px 44px;
	    border-bottom: 1px solid transparent;
	    border-top-left-radius: 6px;
	    border-top-right-radius: 6px;
	}
	.panel-title {
	    margin-top: 0;
	    margin-bottom: 0;
	    font-size: 20px;
	    font-weight: 600;
	    color: inherit;
	}
	.panel-body {
	    padding: 33px 45px 27px;
	}
	.receive-email-bottom{
		font-size: 15px;
		font-weight:600;
		color: #73777A;
		margin-bottom:14px;
	}
	.toggle-email{
		margin-left:14px;
	}
 }
.error_msg {
    color: red;
    display: block;
    float: left;
    width: 100%;
    margin-top: -30px;
}

.admin-categories-popup{
     > div > div{
        max-width: 500px !important;
    }
    .formt-title{
        text-decoration: none;
        color: $primaryColor;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        line-height: 33px;
        margin: 0 0 10px;
    }
    .admin-categories-popup-form{       

        input{
          height: 50px;
          padding-left: 15px;
          padding-right: 15px;
          border: 1px solid #f3f3f3;
          border-radius: 0px;
          width: 100%;
          margin-bottom: 10px;
          outline: none;
          -webkit-transition: all 0.3s linear 0s;
          -o-transition: all 0.3s linear 0s;
          transition: all 0.3s linear 0s;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
        textarea{
          border: 1px solid #f3f3f3;
          border-radius: 0px;
          padding: 15px 15px  0px;
          width: 100%;
          margin-bottom: 10px;
          outline: none;
          -webkit-transition: all 0.3s linear 0s;
          -o-transition: all 0.3s linear 0s;
          transition: all 0.3s linear 0s;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
        .upload-typ-input{
            padding: 10px 15px;
        }
        button{
            margin-right: 5px;
        }
    }
}

.profile__header--wrapper {
  display: flex;
  align-items: center;
  padding: 25px 0;
  .profile__header {
    width: calc(100% - 30px);
    float: left;
    img {
      display: inline-block;
      height: 49px;
      @media #{$phone767}{
        display: none;
      }
    }
    h4 {
      display: inline-block;
      font-size: 15px;
      font-weight: 600;
      color: #73777A;
      margin-left: 30px;
      @media #{$phone767}{
        margin-left: 0;
      }
      span {
        padding-right: 20px;
        @media #{$phone767}{
          display: none;
        }
      }
    }
  }
  .profile__close {
    width: 30px;
    text-align: right;
    float: left;
    img {
      width: 23px;
    }
  }
}
.mobile__panel--body {
  @media #{$phone767}{
    padding: 0 !important;
  }
}
.coverage__area {
  @media #{$phone767}{
    margin-top: 30px;
  }
}

.mobile__profile--container {
  margin-left: 5px;
  margin-bottom: 25px;
  display: none;
  @media #{$phone767}{
    display: block;
  }
  .mobile__profile--avtar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}





  // Media Css
  @media (max-width:767px){
    .profile-container .panel-heading {
        padding: 20px 15px;
    }
    .profile-container .panel-body {
        padding: 10px 20px 27px;
    }
    .custom-select-label {
      padding: 16px 24px 0 24px !important;
      margin-bottom: 10px;
     }
    .mobile__no--border {
      border:0;
    }
    .mobile__no--display {
      display: none;
    }
    .mobile__full--btn {
      width: 100%;
    }
  }
  // Media Css


