@import './../../theme/variables.scss';
.profile__container{

}
 .profile__container--form{
 	max-width: 687px;
 	margin:0 auto;
 	margin-top: 47px;
  .profile__container--section{
    position: absolute;
    top: 19px;
    right: 45px;
    .profile__container--avtar{
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 100px;
      display: inline-block;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .edit__photo--btn{
      font-size: 9px;
      color: #C6CE4A;
      letter-spacing: 0;
      display: block;
      text-align: center;
      cursor: pointer;
    }
	}
	.more__margin--btm {
		margin-bottom: 30px;
	}
	.label-result {
		font-weight: normal;
		color: #C6CE4A;
	}
	.profile-result {
		font-weight: 600;
		font-size: 16px;
		width: 100%;
		color: $primaryColor;
	}
	.label-result-sub {
		width: 100%;
		font-weight: normal;
		color: #C6CE4A;
		float: left;
		margin-top: 35px;
	}
	.region_coverage {
		float: left;
		width: 100%;
		ul {
			li {
				width: auto;
				float: left;
				margin-right: 5px;
				color: #9B9B9B;
				background: #f7f7f7;
				padding: 3px 7px;
				margin-bottom: 5px;
				border: 1px solid #ecebeb;
				border-radius: 3px;
			}
		} 
	}
  .custom-select{
		height: 55px;
		padding: 16px 24px;
		border: 2px solid #e5e5ee !important;
		&:focus {
			border: 2px solid #C7CE4A !important;
			outline: 0;
		}
	}
  .additional__textarea{
		border: 1px solid #E6EBF0 !important;
		border-radius: 4px;
		resize: none;
		font-size: 13px;
		color: rgba(0,0,0,0.54);
		text-align: left;
		line-height: 16px;
		padding: 16px 15px;
		&:focus{
			border: 1px solid #E6EBF0 !important;
		}
	}

  .checkbox__list{
    list-style: none;
    padding: 0;
    margin: 0 0 19px;
    > li{
      margin: 0 0 15px;
    }
  }

 	.panel {
	    margin-bottom: 44px;
	    background-color: #fff;
	    border-radius: 6px;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	}
 	.panel-default {
	    border-color: #E6EBF0;
	}
 	.panel-default>.panel-heading {
	    background-color: #fff;
	    border-color: #E6EBF0;
	    color: #73777A;
      position: relative;
	}
	.panel-heading {
	    padding: 32px 44px;
	    border-bottom: 1px solid transparent;
	    border-top-left-radius: 6px;
	    border-top-right-radius: 6px;
	}
	.panel-title {
	    margin-top: 0;
	    margin-bottom: 0;
	    font-size: 20px;
	    font-weight: 600;
	    color: inherit;
	}
	.panel-body {
	    padding: 33px 45px 27px;
	}
	.receive-email-bottom{
		font-size: 15px;
		font-weight:600;
		color: #73777A;
		margin-bottom:14px;
	}
	.toggle-email{
		margin-left:14px;
	}
 }
  // Media Css
@media (max-width:767px){
	.profile-container .panel-heading {
	    padding: 20px 15px;
	}
	.profile-container .panel-body {
	    padding: 33px 20px 27px;
	}
}
// Media Css
