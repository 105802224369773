@import './../../theme/variables.scss';
.strategy--form__card.strategy__form-wrapper {
	overflow-x: hidden;
}
.checkbox__icon.checkboxmar {
	position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
}
.subtitlenew {
	font-style: italic;
    font-size: 14px;
    float: left;
    width: calc(100% - 15px);
    margin-left: 55px;
    word-break: break-word;
    white-space: pre-line;
    padding-right: 15px;
}
.titlenew {
	font-size: 16px;
    font-weight: 600;
    float: left;
    padding-left: 55px;
}
.selectagentname {
	display: unset !important;
	width: calc(100% - 440px) !important;
	position: relative;
	@media #{$phone}{
		width: 100% !important;
		margin-bottom: 15px !important;
    float: left;
	}
}
.locationslistwrapper {
	width: 440px !important;
	@media #{$phone}{
		width: 100% !important;
		display: unset !important;

	}
	.agent_sel--box {
		width: 49% !important;
		max-width: 49% !important;
		@media #{$phone}{
		width: 100% !important;
		max-width: 100% !important;
		}
	}
	.local_select--box {
		width: 49% !important;
		max-width: 49% !important;
		@media #{$phone}{
		width: 100% !important;
		max-width: 100% !important;
		}
		.custom-sel-filter {
			width: 100% !important;
			max-width: 100% !important;
		}
	}
}
.strategy__form-wrapper.strategyht {
	@media #{$tablet768}{
    height: calc(100vh - 420px);
	}
}
.fullsearch__strategy {
	.startegy__input {
	    font-size: 14px !important;
	    background-color: white;
	    background-image: url('/img/search-icon.svg');
	    background-position: 16px 17px;
	    background-repeat: no-repeat;
	    padding: 13px 20px 13px 50px !important;
	    border-radius: 3px !important;
	    height: 54px !important;
	    border: 2px solid #e5e5e5 !important;
	    width: 100%;
	    &:focus {
	    border: 2px solid #C7CE4A !important;
	    outline: 0;
	  }
	}
}
.agent_sel--box {
	appearance: none;
	-o-appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	height: 44px !important;
	line-height: 44px !important;
	background-image: url('/img/custom-select-arrow.svg');
	background-size: 12px;
	background-repeat: no-repeat;
	background-position: 90%;
	width: 260px;
	max-width: 250px !important;
	margin-top: 4px;
	font-size: 14px !important;
  	font-weight: 400;
	color: #9B9B9B !important;
	padding: 0 10px !important;
	@media #{$phone}{
		background-position: 96%;
	}
}
.local_select--box {
	.checkmark {
		top: 5px !important;
	}
	.custom-sel-filter-new {
		height: 44px !important;
		line-height: 44px !important;
		@media #{$phone}{
			width: 100% !important;
			max-width: 100% !important;
			margin: 10px 0;
			background-position: 96%;
		}
	}
	.sel-outer {
		padding: 10px 15px;
		top: 60px;
		border-top: 1px solid #ccc;
	}
}
.Select-menu-outer {
	max-height: 120px;
	z-index: 1042;
	display: block !important;
}

.filter__container--town-name{
	  .selected_towns {
		    color: #767a7d !important;
		    border: 1px solid #767a7d !important;
		    background: #fff !important;
		    border-radius: 3px !important;
		    margin-right: 5px !important;
		    height: auto !important;
		    width: auto !important;
		    min-width: 10px !important;
		    padding: 3px 5px !important;
		    cursor: unset;
		}
}
.strategy--container{
	.filter__container {
		.filter__container--filter-name {
			display: unset;
			button {
				margin-right: 15px !important;
				margin-bottom: 10px !important;
			}
		}

	}
}
.active_sub {
    color: #fff !important;
    border: 2px solid #C6CE4A !important;
    background: #C6CE4A !important;
}


.strategy--form{
	.form-group{
		margin-bottom: 37px;
	}
	.custom-select{
		height: 48px;
		padding: 13px 18px;
		font-size: 14px;
		font-weight: 400;
		color: #9B9B9B;
		letter-spacing: 0;
		border: 0;
		&:focus{
			border: 0;
		}
	}
	.strategy--form__card{
		border: 1px solid #CED0DA;
		margin: 0 0 33px;
		max-height: 100vh;
		// max-height: 580px;
		min-height: 150px;
    overflow-y: auto;
	}

	.strategy__form-wrapper{
		.agent-town-lists {
			.form-control {
				padding: 0 20px !important;
				height: 43.5px !important;
				line-height: 43.5px !important;
				border: 0px solid #CED0DA !important;
				margin-top: 5px !important;
			}
		}

		.locations__list{
			list-style: none;
			padding: 0;
			margin: 0;
			> li{
				display: flex;
				align-items: center;
				position: relative;
				padding: 24px;
				cursor: pointer;
				border-bottom: 1px solid #fff;
				transition: all 0.2s ease-in;
				padding-right: 24px !important;
				@media #{$phone}{
					padding: 14px !important;
					display: block;
				}
				&:last-child{
					border-bottom: 1px solid #fff;
				}
				&.active{
					background: $secondaryColor;
				}
				&.active .select__agent--name{
					display: inherit;
					width: 37%;
					@media #{$tablet}{
						width: 30%;
					}
					@media #{$phone}{
						width: 100%;
						padding: 0 0 5px 0!important;
					}
				}
				&.active .locations__list--wrapper{
					display: -ms-flexbox;
					display: flex;
					-ms-flex-align: center;
					align-items: center;
					-ms-flex: 1;
					flex: 1;
					justify-content: space-between;
					flex-wrap: wrap;
				}
			}
		}
	    .sel-outer{
         display: none
		}
		.local-multiselect-box{
			display: inline-block;
			width: 100%;
			overflow: auto;
			max-height: 200px;
			max-height: 180px;
			margin: 10px;
			.local-m-list{
				list-style: none;
				padding: 0;
				margin: 0;
				> li {
					background: #717171;
					padding: 5px !important;
					color: #fff;
					font-weight: bold;
					margin-bottom: 1px;
					&:last-child{
						margin-bottom: 0px;
					}
					.local-m-list__icon{
						margin-right: 10px;
						img{
							width: 25px;
						}
					}
				}
			}
		}
		.select__agent--name{
			display: flex;
			align-items: center;
		}
		.locations__list--wrapper{
			display: none;
			.form-control{
				max-width: 180px;
				@media #{$phone}{
					width: 100%;
					max-width: 100% !important;
				}
			}

		}

	}




	.checkbox__icon{
		margin-right: 19px;
	}
	.select__agent--name{
		font-size: 17px;
		font-weight: 600;
		color: #9B9B9B;
		letter-spacing: 0;
	}
	.locations__list--select{
		display: none;
		// position: absolute;
		// top: 50%;
		// right: 24px;
		// width: 100%;
		// max-width: 580px !important;
		// transform: translateY(-50%);
		z-index:1043;
		@media #{$phone}{
			width: 70% !important;
			left: 70px;
		}
		.form-control{
			width: 25%;
		    float: left;
		    margin: 0 5px;
		}
		.Select{
		    float: left;
			width: 71%;
		    margin: 0 5px;
		}
	}
}
.strategy--form__btn{
	text-align: right;
	margin: 0 0 37px;
	button{
		margin-left: 15px;
	}
}
