@import './../../../theme/variables.scss';

.btn__mob--mar {
	@media #{$phone}{
		margin-top: 10px;
	 }
}
.right__cont {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	.enteries-span {
		margin-right: 10px;
		@media #{$phone}{
			margin-right: 0;
		 }
		.enteries-label {
			font-size: 14px;
			font-weight: 600;
			color: $primaryColor;
			margin-right: 10px;
		}
		.selectinputs__global {
			width: 100px !important;
			border: 2px solid #e5e5e5;
			background: #fff;
			color: rgba(0, 0, 0, 0.87);
			height: 54px;
			border-radius: 4px;
			resize: none;
			line-height: 19px;
			padding: 16px 15px;
			font-size: 14px;
			background:#fff url('/img/custom-select-arrow.svg');
			background-size: 12px;
			background-repeat:no-repeat;
			background-position: calc(100% - 15px) center;
			appearance: none;
			-moz-appearance: none;
			-o-appearance: none;
			-webkit-appearance: none;
			-ms-appearance: none;
			cursor:pointer;
			&:focus {
				border: 2px solid #C7CE4A;
				outline: 0;
			}
		}
	}
	.totalRecords__label {
    color: #C7CE4A;
    padding-top: 14px;
    display: inline-block;
    margin-right: 20px;
	}
}
.reporting__btn--new {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background: #C7CE4A;
    border-radius: 4px;
    min-width: 128px;
    padding: 11px 22px;
    margin-left: 6px;
    float: right;
    margin-top: 0;
    border: 0;
    outline: none;
}
.client__click{
	cursor: pointer;
}
.typeahead {
	.costom__search--report {
		width: 100%;
		height: 50px !important;
		border: 0 !important;
		font-family: 'Source Sans Pro';
		font-size: 14px;
		background-color: white;
		background-image: url(/img/search-icon.svg);
		background-position: 22px 17px;
		background-repeat: no-repeat;
		padding: 13px 20px 13px 61px !important;
		box-shadow: 0 2px 20px 5px rgba(31, 31, 31, 0.15);
		border-radius: 6px !important;
		margin: 0 0 20px 0 !important;
	}
	}
.light-bg {
	background: #f7f8f6 !important;
	padding: 0 0 15px 0;
	margin-bottom: 32px !important;
}
.reporting-sel {
    border: 0px solid #d6d6d6;
    width: 160px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    color: #787878;
    line-height: 19px;
    padding: 10px 40px 10px 20px !important;
    background-image: url("/img/custom-select-arrow.svg");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 90%;
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    cursor: pointer;
    position: relative;
    margin-left: 15px;
}
.stragist-report {
	margin-bottom: 40px;
}
.padd-l-r-50 {
	padding-left: 50px;
	padding-right: 50px;
}
.stragist-span {
	margin-top: 0;
	width: auto !important;
	.strategist-search-float {
		input {
			float: none !important;
		}
	}
	select {
		border: 2px solid #e5e5e5;
		border-radius: 4px;
		background-color: #fff;
		font-size: 14px;
		font-weight: 600;
		color: $primaryColor;
		line-height: 19px;
		padding: 10px 20px 10px 20px;
		-webkit-appearance: none;
		overflow: hidden;
		background-image: url("/img/custom-select-arrow.svg");
		background-size: 14px;
		background-repeat: no-repeat;
		background-position: calc(100% - 15px) center;
		appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		cursor: pointer;
		height: 55px;
		position: relative;
		width: 270px;
		margin-bottom: 15px;
		@media #{$phone767}{
			width: 100%;
		}
	}
}

.reporting__btn {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    background: #C7CE4A;
    border-radius: 4px;
    min-width: 128px;
    padding: 17px 30px;
	margin-left: 6px;
    float: right;
	margin-top: 22px;
	border: 0;
	outline: none;
}
.reporting-heading {
	font-size: 30px;
	font-weight: 600;
	color: #67797d;
	margin-top: 30px;
}
.reporting-loginrecords {
	font-size: 30px;
	font-weight: 600;
	color: #67797d;
}
.typeahead {
.reporting-search {
    width: 100%;
    box-sizing: border-box;
	border: 0 !important;
	height: 50px !important;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    background-color: white;
    background-image: url("/img/search-icon.svg");
    background-position: 22px 17px;
    background-repeat: no-repeat;
    padding: 13px 20px 13px 61px !important;
    box-shadow: 0 2px 20px 5px rgba(31, 31, 31, 0.04);
    border-radius: 6px !important;
}
}




.jungler__page--container{
	
	.filter__sub--container{
		border-top: 1px solid #E6EBF0;
		min-width: 1440px;
		max-width: 100%;
		
		.custom-select{
			max-width: 228px;
			display: inline-block;
			height: 46px;
			border: 0;
			border-right: 1px solid #E6EBF0;
			font-size: 15px;
			font-weight: 600;
			color: #787878;
			letter-spacing: 0.44px;
			text-align: center;
			background-position: calc(100% - 12px);
			border-radius: 0;
		}
		.hide__inactive{
			margin-left: 24px;
			display: inline-block;
		}
		.displ-none {
			display: none;
		}
	}
	.fordt-picker {
		.btn__edit {
			margin: 0 9px;
			position: absolute;
			left: 95px;
			top: 25px;
		}
		.react-datepicker-wrapper {
			position: absolute;
			top: 23px;
			left: 0;
			width: 100px;
			height: 18px;
		}
		.react-datepicker__input-container {
			button {
				background: none;
				border: 0;
				height: 18px;
			}
		}
	}
	.jungler__list{
		a {
			&:hover {
				text-decoration: none;
			}
		}
		.btn__edit {
			margin: 0 9px;
		}
		.table {
			margin-bottom: 0;
			> thead tr {
				border-top: 1px solid #E6EBF0;
			}
		}
		.table-striped > tbody > tr:nth-of-type(odd) {
			background-color: rgba(236, 237, 232, 0.4);
		}
		.table > {
			thead > tr > th,
			tbody > tr > td {
				color: #73777A;
				font-size: 14px;
			}
			thead > tr > th {
				border-bottom: 0px solid #ddd;
				font-weight: 600;
				padding: 7px 25px;
					&:last-child {
						padding: 7px 10px;
					}
			}
			tbody > tr > td {
				padding: 8px 25px;
				font-weight: 400;
				border-top: 0px solid #ddd;
				vertical-align: middle;
				&:last-child {
					padding: 7px 10px;
				}
			}
			tbody > tr > td.noClientResults {
			    text-align: center;
				padding: 50px;
				font-size: 18px;
				color: #c7ce49;
				font-weight: 600;
			}

		}
	}
}
