@import './../../theme/variables.scss';
.reports__listing--all {
	padding-top: 60px;
	@media #{$phone767}{
		margin-bottom: 150px;
	}
	h4 {
		font-size: 14px;
		font-weight: 600;
		color: #73777A;
		margin: 0 0 10px 0;
		padding: 0;
	}
	ul {
		li {
			width: 100%;
			float: left;
			margin-top: 20px;
			a {
				color: #C7CE4A;
				font-size: 14px;
				text-decoration: underline;
			}
		}
	}
}
.fontsize14 {
	font-size: 14px !important;
}
.list_table {
	tbody {
		@media #{$tablet768}{
			width: 760px !important;
		}
		// @media #{$phone767}{
		// 	width: 410px !important;
		// }
	}
}
.fullwidthwrapper {
	@media #{$phone767}{
		margin: 0 15px 25px 15px !important;
		width: calc(100% - 30px) !important;
	}
}
.fontsize12 {
	@media #{$phone767}{
		font-size: 14px;
	}
}
.loader__container {
	height: calc(100vh - 340px);
	position: relative;
	img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50% , -50%);
	}
}
.marg_zero {
	margin: 0 0 26px !important;
}
.typeahead {
	.costom__search--report {
		width: 100%;
		height: 50px !important;
		border: 0 !important;
		font-family: 'Source Sans Pro';
		font-size: 14px;
		background-color: white;
		background-image: url(/img/search-icon.svg);
		background-position: 22px 17px;
		background-repeat: no-repeat;
		padding: 13px 20px 13px 61px !important;
		box-shadow: 0 2px 20px 5px rgba(31, 31, 31, 0.15);
		border-radius: 6px !important;
		margin: 0 0 20px 0 !important;
	}
}

.ShowMoreToggle{
	color:$secondaryColor;
	font-weight: 600;
	&:hover{
		color:$secondaryColor;
	}
}
.dp-wrapper {
	width: 225px;
	display: inline-block;
	margin: 0 7px 15px 0;
	.custom-select {
		margin: 0 0 25px 0 !important;
	}
	.date__picker {
		height: 55px;
		padding-left: 60px !important;
		font-size: 14px;
		background: url('/img/calendar-icon.svg') no-repeat !important;
		background-position: 6% 8px !important;
		text-align: left;
		font-weight: 600;
		font-family: 'Poppins' !important;
		background-size: 32px !important;
		border: 1px solid #CED0DA !important;
	}
}
.card-analytics {
	padding: 30px 21px !important;
	float: left;
	width: 100%;
	a {
		color: #C7CE4A;
		text-decoration: underline !important;
	}
}

.admin-dashboardContainer{
	max-width: 792px;
	margin:25px auto 0 !important;
	.monthPicker {
		.box {
			border: 1px solid #e0e0e0;
			background-color: #fff;
			padding-left: 0.9rem;
			box-sizing: border-box;
			line-height: normal;
			font-size: 1.3rem;
			color: #525252;
			width: 220px;
			margin: 20px auto;
			padding: 10px 0;
			border-radius: 4px;
			label {
				margin-bottom: 0;
			}
		}
	}
	.rmp-popup {
		border: 1px solid #ccc;
		box-shadow: 0 1px 5px #ddd;
		position: absolute;
		top: -15px !important;
		left: 50%;
		width: 300px !important;
		transform: translate(-50%, -15px) !important;
		-webkit-transform: translate(-50%, 0) !important;
	}
	.rmp-pad {
		width: 300px !important;
	}
	.rmp-btn {
		&:hover {
			background: #C7CE4A !important;
			color: #fff !important;
		}
	}

	.custom-select{
		min-width: 160px;
		margin:35px 0 27px;
		border: 1px solid #73777A;
		color: #73777A;
		background-image: url(/img/select-dropdown-grey.svg);
		appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		-moz-appearance: none;
		font-family: 'Poppins' !important;
	}
	.card{
		padding:18px 21px;
		margin: 0 0 26px;
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 4px;
		.date{
			font-size: 15px;
			font-weight:400;
			color: #73777A;
		}
		hr {
			margin-top: 18px;
			margin-bottom: 18px;
			border: 0;
			border-top: 1px solid #B5C9D4;
		}
		h4{
			font-size: 32px;
			font-weight: 400;
			color: #73777A;

		}
		p{
			font-size: 14px;
			font-weight:600;
			color: #73777A;
		}
		h5{
			font-size: 14px;
			font-weight:600;
			color: #73777A;
		}
		h6{
			font-size: 16px;
			font-weight:300;
			color: #73777A;
		}
	}
	.card-bg{
		background: #F4F4F4;
		border-radius: 4px;
		padding:18px 33px;
		margin: 0 0 26px;
		.col-md-4{
			padding: 0;
		}
		.card-2 {
			background: transparent;
			border: 0px solid #73777A;
			border-radius: 0;
			margin: 0;
			.date{
				font-size: 15px;
				font-weight:400;
				color: #73777A;
			}
			hr {
				margin-top: 18px;
				margin-bottom: 18px;
				border: 0;
				border-top: 1px solid #B5C9D4;
			}
			h4{
				font-size: 32px;
				font-weight: 400;
				color: #73777A;
			}
			p{
				font-size: 14px;
				font-weight:600;
				color: #73777A;
			}
			h5{
				font-size: 14px;
				font-weight:600;
				color: #73777A;
			}
			h6{
				font-size: 16px;
				font-weight:300;
				color: #73777A;
			}
		}
	}
}
