@import './../../../theme/variables.scss';

.rightsidefull {
  width: 100% !important;
  padding: 35px 0 0 0 !important;
  margin-bottom: 30px;
}
.strategist__width {
  white-space: nowrap !important;
  vertical-align: top !important;
  padding-top: 10px !important;
  p {
    display: inline-block;
    margin: 0 0 0 5px;
  }
  .labelglobal {
    font-weight: 600 !important;
    margin: 0;
  }
}
.detailsgap {
  margin-bottom: 10px;
}
.strategist__links {
  background: #C7CE4A;
  color: #fff;
  padding: 7px 12px;
  border-radius: 4px;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
}
.reports__flex.reports__flex--bottom {
  align-items: baseline;
}
.dp__wrapper--hafl.wrapper__onethird {
    width: 300px !important;
}
.right__cont--agent.right__onethird {
      width: calc(100% - 310px) !important;
}
.datepickerformodal {
  .react-datepicker-popper {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%,-50%) !important;
  }
  .react-datepicker__triangle {
      display: none;
  }
}
.updatemodal {
  width: 500px;
  max-width: 96%;
  max-height: 90%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 25px;
  border: 0;
  outline: 0;
  .flexcontent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .modal-header {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: $primaryColor;
      border: 0;
      margin: 0;
    }
    .close__btn {
      cursor: pointer;
      img {
        width: 32px;
      }
    }
  }
  .subhead {
    font-size: 16px;
    line-height: 19px;
    color: #5E6A7F;
    margin-bottom: 21px;
    margin-top: 26px;
  }
  .leftside {
    width: 50%;
    float: left;
    padding-right: 10px;
  }
  .rightside {
    width: 50%;
    float: left;
    padding-left: 10px;
  }
  .labelglobal {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.46154px;
    text-transform: uppercase;
    color: #9B9B9B;
    margin-bottom: 15px;
    font-family: 'Poppins';
  }
  .inputglobal {
    height: 55px !important;
    width: 100%;
    padding: 0 20px;
    font-size: 14px;
    color: $primaryColor;
    text-align: left;
    font-weight: 500;
    border: 2px solid #e5e5e5;
    border-radius: 4px;
    background-color: #fff;
    &:focus {
      border: 2px solid #C7CE4A !important;
      outline: 0;
    }
  }
  .selectdateglobal {
    width: 100%;
    border: 2px solid #e5e5e5 !important;
    background: #fff !important;
    color: $primaryColor !important;
    font-weight: 500;
    text-align: left !important;
    height: 54px;
    border-radius: 4px !important;
    resize: none;
    line-height: 19px;
    padding: 16px 15px 16px 70px !important;
    font-size: 14px !important;
    background-image: url('/img/custom-select-arrow.svg'), url("/img/calendar-icon.svg") !important;
    background-position: calc(100% - 15px), 19px center !important;
    background-repeat: no-repeat, no-repeat !important;
    background-size: 12px, 30px !important;
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    cursor:pointer;
    &:focus {
      border: 2px solid #C7CE4A !important;
      outline: 0;
    }
  }
  .savebtn__fullwidth {
    background: #C3CF21;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.75385px;
    color: #FFFFFF;
    width: 100%;
    border-radius: 3px;
    text-align: center;
    text-transform: capitalize;
    border: 0;
    padding: 16px 20px;
    margin-top: 25px;
  }
}

.modal-pop__bddev {
  width: 600px;
  max-width: 96%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 25px 0;
  border: 0;
  outline: 0;
  h2 {
    padding: 0 20px 20px 20px !important;
  }
  form {
    padding: 0 20px;
  }
  .save__btn--full {
    margin: 0 20px;
    width: calc(100% - 40px) !important;
  }
  .close__btn {
    position: absolute;
    right: 20px;
    top: 25px;
    cursor: pointer;
  }
}
.green__anchor {
  color: $secondaryColor !important;
  text-decoration: underline !important;
}
.modal-header--pop {
  text-align: left !important;
  line-height: 30px;
  margin-top: 0;
  padding-bottom: 10px !important;
  font-size: 18px;
}
.heading__small {
  font-size: 40px !important;
}
.stragist-heading--small {
  font-size: 14px !important;
  margin-top: 25px;
  span {
    font-size: 30px !important;
  }
}

.modalwidth {
  .styles_modal__gNwvD {
    max-height: 550px;
    overflow-y: auto;
    width: 700px !important;
  }
}
.popup__listingedit {
  margin-top: 20px;
  .titles {
    width: 35%;
    display: inline-block;
    font-size: 14px;
    color: $primaryColor;
    font-weight: 600;
    margin-bottom: 25px;
    }
    .dates {
      width: 30%;
      display: inline-block;
      font-size: 14px;
      color: $primaryColor;
      font-weight: 600;
      margin-bottom: 25px;
    }
    .lastdetails {
      width: 35%;
      display: inline-block;
      font-size: 14px;
      color: $primaryColor;
      font-weight: 600;
      margin-bottom: 25px;
    }
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    li {
      width: 30%;
      font-size: 14px;
      &:first-child {
        width: 35%;
        display: flex;
        align-items: baseline;
      }
      &:last-child {
        width: 35%;
        display: flex;
        align-items: baseline;
      }
      input {
        height: 40px;
        padding-left: 5px !important;
        margin-left: 5px;
        font-size: 14px !important
      }
    }
  }
}

.popup__new--listing {
  span {
    width: 75%;
    display: inline-block;
    font-size: 18px;
    color: $primaryColor;
    font-weight: 600;
    margin-bottom: 25px;
    }
    .span__last {
      width: 25% !important;
    }
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    li {
      width: 75%;
      font-weight: 600;
      font-size: 16px;
      &:last-child {
        width: 25%;
        display: flex;
        align-items: baseline;
      }
      input {
        border-bottom: 1px solid #C7CE4A;
        margin-bottom: 0 !important;
        height: 20px;
        padding-left: 5px !important;
      }
    }
  }
}
  .save__btn--full {
    width: 100%;
    border: 1px solid #C7CE4A;
    background: #C7CE4A;
    color: #fff;
    text-align: center;
    height: 46px;
    font-size: 16px;
    font-weight: 600;
  }

.handsontable__responsive {
  .handsontable {
    th {
        background-color: #fff;
        color: #c7ce4a;
        font-weight: 600;
        border: 1px solid #f1f1f1 !important;
        height: auto !important;
        padding: 5px 10px;
    }
    td {
      padding: 8px 10px;
      border: 1px solid #f1f1f1 !important;
    }
    tr:nth-child(odd) > td:nth-child(1) {
    background: #fff;
}
  }
}

.information-icon-button {
    width: 20px;
    height: 20px;
    border: none;
    top: 0px;
    outline: none !important;
    cursor: pointer;
    margin-left: 10px;
    background-size: 20px;
    position: inherit;
  }
  .multilines {
      white-space : unset !important;
      vertical-align: middle !important;
  }
  
  .defaultCheckboxContainer {
    display: inline-block;
      width: auto;
      margin: 0 30px 0 0;
       input{
        margin: 0 15px 0 0;
       }
  
  }
  .filterWrapOvveride {
    margin: 0 0 5px 0 !important;
    &.dp-wrapper{
      .custom-select{
        margin: 0 0 10px 0 !important;
      }
    }
  }
  
  .filterHideOverride {
    margin: 0 0 5px 0 !important;
    &.dp-wrapper{
      .custom-select{
        margin: 0 0 10px 0 !important;
        opacity: 0.50;
      }
    }
  }
  .defaultChecboxCustom {
  
    width: 100%;
    vertical-align: middle;
  
      input {
       margin-right: 15px;
       vertical-align: middle;
      }
    span {
      vertical-align: middle;
  
    }
  
  }
