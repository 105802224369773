.orderchange {
    order: unset !important;
}
.dflex-left {
    margin: 0 !important;
    justify-content: flex-start !important;
    .copyBoxButton {
        margin-bottom: 10px !important;
        .page-generator__button-icon {
            margin-left: 5px;
        }
    }
}
.margdiv {
    margin-top: 10px;
}
.multilines {
	white-space : unset !important;
	vertical-align: middle !important;
}

.defaultCheckboxContainer {
	display: inline-block;
    width: auto;
    margin: 0 30px 0 0;
     input{
     	margin: 0 15px 0 0;
     }

}
.filterWrapOvveride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
    	margin: 0 0 10px 0 !important;
    }
  }
}

.filterHideOverride {
  margin: 0 0 5px 0 !important;
  &.dp-wrapper{
    .custom-select{
      margin: 0 0 10px 0 !important;
      opacity: 0.50;
    }
  }
}

.defaultChecboxCustom {

	width: 100%;
	vertical-align: middle;

    input {
     margin-right: 15px;
     vertical-align: middle;
    }
	span {
		vertical-align: middle;

	}

}

.copyButton{
    flex: 0 1 auto;
    order: 1;
    cursor: pointer;
    min-width: 90px;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0.375em;
    padding: 1em 1em 0.875em;
    background-color: #c7ce4a;
    color: #FFFFFF;
    font-size: 14px;
    font-size: 0.875em;
    font-weight: 700;
    font-style: normal;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.28571;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    -webkit-appearance: none;
    order: 2;
    width: auto;
    margin-bottom: 0;
}
.page-generator__button-icon{
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 6px;
}

.copyBoxButton {
    flex: 0 1 auto;
    order: 1;
    cursor: pointer;
    min-width: 90px;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0.375em;
    padding: 1em 1em 0.875em;
    background-color: #c7ce4a;
    color: #FFFFFF;
    font-size: 14px;
    font-size: 0.875em;
    font-weight: 700;
    font-style: normal;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.28571;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    -webkit-appearance: none;
    order: 2;
    width: auto;
    margin-bottom: 0;
}
.townModalHeader{
    &.modal-header{
        padding: 17px 0;
        font-size: inherit;
        h2{
            text-align: left;
            font-size: 15px;
            margin: 0 4px 0 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 247px;
            display: inline-block;
            &:hover{
                overflow: visible;
                word-break: break-all;
                white-space: pre-wrap;
            }
        }
    }
}
.towmModalBody{
    margin-top:10px;
}