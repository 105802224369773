@import './../../theme/variables.scss';
.downwrapper {
	@media #{$phone360}{
		padding-bottom: 70px !important;
	}
}
.reportslistingpadrem {
	padding-top: 0 !important;
}
.analyticsContainer__paddingbtm {
	padding-bottom: 200px;
	@media #{$phone}{
		padding-bottom: 0;
	}
}
.analyticsContainer__padding{
	// padding-top: 50px;
	padding-left: 20px;
}
.analyticsContainer__padding--forAgent{
	padding-left: 20px;
}
.extra__spacing--between{
	margin-bottom: 20px;
}
.impersonate__strat--dash {
	@media #{$tablet768}{
		margin-top: 40px;
	}
	@media #{$phone}{
		margin-top: 60px;
	}
}



.downwrapper {
	@media #{$tablet768}{
		margin-top: 10px !important;
	}
	@media #{$phone}{
		margin-top: 0px !important;
		margin-bottom: 0px;
	}
}
.admindownwrapper {
	@media #{$tablet768}{
		margin-top: 40px !important;
	}
	@media #{$phone}{
		margin-top: 0px !important;
	}
}





.homedownwrapper {
	@media #{$tablet768}{
		margin-top: 20px !important;
	}
	@media #{$phone}{
		margin-top: 0px !important;
	}
}
.imper__homedownwrapper {
	padding-top: 0 !important;
	@media #{$tablet768}{
		margin-top: 10px !important;
	}
	@media #{$phone}{
		margin-top: 20px !important;
	}
}





.clientlistdownwrapper {
	@media #{$tablet768}{
		margin-top: 30px !important;
	}
	@media #{$phone}{
		margin-top: 0px !important;
	}
}
.imper__clientlistdownwrapper {
	padding-top: 0 !important;
	@media #{$tablet768}{
		margin-top: 0px !important;
	}
	@media #{$phone}{
		margin-top: 0px !important;

	}
	.filter__container--users {
		@media #{$phone}{
			margin-top: 0px !important;

		}
	}
}

.impr__junglerusers {
	padding-top: 0 !important;
	@media #{$tablet768}{
		margin-top: 0px !important;
	}
	@media #{$phone}{
		margin-top: 0px !important;
	}
}
.without__impr--junglerusers {
	@media #{$tablet768}{
		margin-top: 15px !important;
	}
	@media #{$phone}{
		margin-top: 0px !important;
	}
}


.trgap {
	padding-top: 0 !important;
}

.for__townHeader{
	padding-top: 0 !important;
}

.onlyformobile__town--report  {
	@media #{$tablet768}{
		margin-top: 0px !important;
	}
	@media #{$phone}{
		padding-top: 0px !important;
	}
	.townreportgap {
		margin-top: 0px !important;
	}
}

.tabletviewonly__ai--content{
	@media #{$tablet768}{
		margin-top: 30px !important;
	}
	@media #{$phone}{
		margin-top: 0px !important;
	}
}












.marg_zero {
	margin: 0 0 26px !important;
}
.typeahead {
	.costom__search--report {
		width: 100%;
		height: 50px !important;
		border: 0 !important;
		font-family: 'Source Sans Pro';
		font-size: 14px;
		background-color: white;
		background-image: url(/img/search-icon.svg);
		background-position: 22px 17px;
		background-repeat: no-repeat;
		padding: 13px 20px 13px 61px !important;
		box-shadow: 0 2px 20px 5px rgba(31, 31, 31, 0.15);
		border-radius: 6px !important;
		margin: 0 0 20px 0 !important;
	}
}

.ShowMoreToggle{
	color:$secondaryColor;
	font-weight: 600;
	&:hover{
		color:$secondaryColor;
	}
}

.card-analytics {
	padding: 30px 21px !important;
	float: left;
	width: 100%;
	a {
		color: #C7CE4A;
		text-decoration: underline !important;
	}
}

.strategist-dashboardContainer{
	max-width: 792px;
	margin:0 auto;
	.monthPicker {
		.box {
			border: 1px solid #e0e0e0;
			background-color: #fff;
			padding-left: 0.9rem;
			box-sizing: border-box;
			line-height: normal;
			font-size: 1.3rem;
			color: #525252;
			width: 220px;
			margin: 20px auto;
			padding: 10px 0;
			border-radius: 4px;
			label {
				margin-bottom: 0;
			}
		}
	}
	.rmp-popup {
		border: 1px solid #ccc;
		box-shadow: 0 1px 5px #ddd;
		position: absolute;
		top: -15px !important;
		left: 50%;
		width: 300px !important;
		transform: translate(-50%, -15px) !important;
		-webkit-transform: translate(-50%, 0) !important;
	}
	.rmp-pad {
		width: 300px !important;
	}
	.rmp-btn {
		&:hover {
			background: #C7CE4A !important;
			color: #fff !important;
		}
	}

	.custom-select{
		min-width: 160px;
		margin:35px 0 27px;
		border: 1px solid #73777A;
		color: #73777A;
		background-image: url(/img/select-dropdown-grey.svg);
		appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		-moz-appearance: none;
		font-family: 'Poppins' !important;
	}
	.card{
		padding:18px 21px;
		margin: 132px 0 26px;
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 4px;
		@media #{$tablet768}{
			margin: 100px 0 26px;
		}
		@media #{$phone6}{
			margin: 50px 0 26px;
		}
		.date{
			font-size: 15px;
			font-weight:400;
			color: #73777A;
		}
		hr {
			margin-top: 18px;
			margin-bottom: 18px;
			border: 0;
			border-top: 1px solid #B5C9D4;
		}
		h4{
			font-size: 32px;
			font-weight: 400;
			color: #73777A;

		}
		p{
			font-size: 14px;
			font-weight:600;
			color: #73777A;
		}
		h5{
			font-size: 14px;
			font-weight:600;
			color: #73777A;
		}
		h6{
			font-size: 16px;
			font-weight:300;
			color: #73777A;
		}
	}
	.card-bg{
		background: #F4F4F4;
		border-radius: 4px;
		padding:18px 33px;
		margin: 0 0 26px;
		.col-md-4{
			padding: 0;
		}
		.card-2 {
			background: transparent;
			border: 0px solid #73777A;
			border-radius: 0;
			margin: 0;
			.date{
				font-size: 15px;
				font-weight:400;
				color: #73777A;
			}
			hr {
				margin-top: 18px;
				margin-bottom: 18px;
				border: 0;
				border-top: 1px solid #B5C9D4;
			}
			h4{
				font-size: 32px;
				font-weight: 400;
				color: #73777A;
			}
			p{
				font-size: 14px;
				font-weight:600;
				color: #73777A;
			}
			h5{
				font-size: 14px;
				font-weight:600;
				color: #73777A;
			}
			h6{
				font-size: 16px;
				font-weight:300;
				color: #73777A;
			}
		}
	}
}
