@import './../../theme/variables.scss';

.thankyou__page{

  .header-fix {
    position: fixed;
    width: 100%;
    z-index: 999999;
    top: 0;
    left: 0;
    right: 0;
  }
  .content-pad {
    padding-top: 210px !important;
  }


  /*menu open*/
  .menu-top-menu-container ul li {
    display: inline-block;
    padding: 0 25px;
    font-family: 'Dosis-SemiBold';
    font-size: 18px;
    position: relative;
  }
  .menu-top-menu-container ul li a {
    color: $primaryColor !important;
    text-decoration: none !important;
  }
  .menu-top-menu-container ul li .sub-menu {
    display: none;
  }
  .menu-top-menu-container ul li:hover .sub-menu {
    display: block;
    position: absolute;
    float: left;
    height: auto;
    text-align: left;
    margin: 0;
    padding: 16px 0 20px 0;
    background: #fff;
    box-shadow: 0 4px 6px #ccc;
    z-index: 9999;
    width: 220px;
  }
  .menu-top-menu-container li .sub-menu li {
    display: block;
    padding: 15px 25px 0 25px;
  }

  .main-navigation {
    clear: both;
    display: block;
    float: left;
    width: 100%;
  }

  .main-navigation ul {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  .main-navigation ul ul {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    float: left;
    position: absolute;
    top: 100%;
    left: -999em;
    z-index: 99999;
  }

  .main-navigation ul ul ul {
    left: -999em;
    top: 0;
  }

  .main-navigation ul ul li:hover > ul,
  .main-navigation ul ul li.focus > ul {
    left: 100%;
  }

  .main-navigation ul ul a {
    width: 200px;
  }

  .main-navigation ul li:hover > ul,
  .main-navigation ul li.focus > ul {
    left: auto;
  }

  .main-navigation li {
    float: left;
    position: relative;
  }

  .main-navigation a {
    display: block;
    text-decoration: none;
  }
  .menu-item-has-children:after {
    content: "";
    width: 10px;
    height: 20px;
    background-image: url("/../../img/menu-dropdown.png");
    position: absolute;
    right: 5px;
    top: 9px;
    background-repeat: no-repeat;
  }
  /* Small menu. */
  .menu-toggle,
  .main-navigation.toggled ul {
    display: block;
  }

  @media screen and (min-width: 37.5em) {
    .menu-toggle {
      display: none;
    }
    .main-navigation ul {
      display: block;
    }
  }

  .site-main .comment-navigation, .site-main
  .posts-navigation, .site-main
  .post-navigation {
    margin: 0 0 1.5em;
    overflow: hidden;
  }

  .comment-navigation .nav-previous,
  .posts-navigation .nav-previous,
  .post-navigation .nav-previous {
    float: left;
    width: 50%;
  }

  .comment-navigation .nav-next,
  .posts-navigation .nav-next,
  .post-navigation .nav-next {
    float: right;
    text-align: right;
    width: 50%;
  }
  /*menu close*/


  /*header css open*/
  .edit-header {
    background: #fff !important;
    border: none;
    padding: 10px 0 0 0 !important;
    margin: 0;
  }
  .edit-header .navbar-header {
    width: 100%;
  }
  .socialMenu {
    width: 25%;
    float: left;
    padding: 30px 4% 0 0;
    text-align: right;
  }
  .socialMenu ul li {
    display: inline-block;
    margin-right: 22px;
  }
  .edit-header .site-branding {
    width: 50%;
    float: left;
  }
  .LogiSection {
    width: 25%;
    float: left;
    padding: 30px 0 0 0;
  }
  .btn-login {
    min-width: 120px !important;
    height: 38px!important;
    line-height: 34px!important;
    padding: 0!important;
    text-align: center;
    background: transparent;
    border: 2px solid $primaryColor !important;
    color: $primaryColor;
    font-size: 14px!important;
    border-radius: 0!important;
    font-family: 'Dosis-Bold';
  }
  .btn-login a {
    color: $primaryColor !important;
    text-decoration: none !important;
  }
  .btn-login:hover, .btn-login:focus, .btn-login:active {
    color: $primaryColor;
    text-decoration: none;
    outline: none !important;
    box-shadow: none;
    border: 2px solid $primaryColor;
  }
  .DefaultBtn {
    padding: 0;
    text-align: center;
    background: $secondaryColor;
    border: 2px solid $secondaryColor !important;
    color: #fff !important;
    font-size: 14px;
    border-radius: 0;
    font-family: 'Dosis-Bold';
    outline: none;
    box-shadow: none;
    border: none;
    text-decoration: none !important;
  }
  .DefaultBtn a {
    color: #fff;
  }
  .DefaultBtn:hover, .DefaultBtn:focus, .DefaultBtn:active {
    color: #fff;
    text-decoration: none;
    outline: none !important;
    box-shadow: none;
    border: none;
  }



  .btn-get-started-header {
    min-width: 120px !important;
    height: 38px;
    line-height: 34px;
    margin-left: 14px;
  }

  .menu {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 35px;
  }
  .menu ul li {
    display: inline-block;
    font-family: 'Dosis-SemiBold';
    font-size: 16px;
    padding-right: 42px;
  }
  .menu ul li a {
    color: $primaryColor;
  }
  /*header css close*/

  /*thankyou css open*/
  .ThankSection {
    padding: 100px 0 50px 0;
    background-size: cover;
    height: 100%;
    float: left;
    width: 100%;
    background-image: url('/../../img/login-bg-img.png');
  }
  .thankyou-box {
    font-size: 16px;
    font-family: 'Dosis-SemiBold';
    width: 50%;
    padding: 50px 70px 100px 70px;
    margin: 0 auto;
    background: #fff;
  }
  .thankyou {
    text-align: center;
  }
  .thankyou-left {
    float: left;
    width: 50%;
  }
  .thankyou-right {
    float: right;
    width: 50%;
  }
  .thankyou h3 {
    text-align: center;
    font-size: 40px;
    font-family: "Dosis-SemiBold";
  }
  .thankyou img {
    width: 90px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .thankyou p {
    text-align: center;
    font-size: 18px;
    font-family: "Dosis-Regular";
    padding: 15px 0 50px 0;
  }
  .btn.active, .btn:active {
    box-shadow: none !important;
  }
  .btn-thankyou {
    width: 97%;
    margin-right: 3%;
    padding: 15px 0;
    text-align: center;
    background: transparent;
    border: 2px solid $primaryColor !important;
    color: $primaryColor !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    font-family: 'Dosis-Bold';
    outline: 0 !important;
  }
  .DefaultBtn-thankyou {
    padding: 15px 0;
    text-align: center;
    background: $secondaryColor;
    border: 2px solid $secondaryColor !important;
    color: #fff !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    font-family: 'Dosis-Bold';
    outline: none;
    box-shadow: none;
    border: none;
    width: 97%;
    margin-left: 3%;
    outline: 0 !important;
  }
  .btn-thankyou a {
    color: $primaryColor !important;
  }
  .DefaultBtn-thankyou a {
    color: #fff !important;
  }
  /*thankyou css close*/

  /*footer open*/
  footer {
    background-color: #383838 !important;
    padding: 35px 7px 20px 25px !important;
    float: left;
    width: 100%;
  }
  footer a {
    text-decoration: none !important;
  }
  .footer-a {
    width: 24%;
    float: left;
  }
  .footer-b {
    width: 24%;
    float: left;
  }
  .footer-c {
    width: 24%;
    float: left;
    opacity: 0;
  }
  .footer-d {
    width: 28%;
    float: left;
  }
  .footer-copyright {
    font-family: "Dosis-ExtraLight";
    color: #fff;
    float: left;
    margin-top: 14px;
  }
  footer h3 {
    font-size: 18px;
    text-transform: capitalize;
    font-family: "Dosis-SemiBold";
    color: #fff;
    padding-bottom: 13px;
    margin-bottom: 5px;
  }
  footer p {
    font-family: "Dosis-Regular";
    padding: 0;
    margin: 0 0 16px 0;
    font-size: 16px;
  }
  footer p a {
    color: #fff !important;
  }
  footer a:active, a:focus {
    color: #fff !important;
  }
  .bord-btm {
    width: 70%;
    height: 2px;
    float: left;
    background: $secondaryColor;
    margin-bottom: 14px;
  }
  footer .widget-title-reg {
    font-family: "Dosis-Regular";
  }
  footer input[type="email"] {
    background: #565656 !important;
    border: 1px solid #565656 !important;
    color: #fff !important;
    height: 50px;
    width: 80%;
    float: left;
    border-radius: 0;
    font-size: 17px;
    font-family: "Dosis-Regular";
    padding-left: 7px;
  }
  footer input[type="button"] {
    background: $secondaryColor url("/../../img/footer-arrow.svg");
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid $secondaryColor !important;
    color: #fff;
    height: 50px;
    width: 20%;
    float: left;
    border-radius: 0;
    background-size: 25px;
  }
  .folow-us-widget-footer {
    float: left;
    width: 100%;
    margin-top: 40px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .folow-us-widget-footer ul li {
    display: inline-block;
    padding: 0 20px 0 0 !important;
    font-size: 25px;
  }
  /*footer close*/



  /*media open*/
  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 980px) {
    .btn-default-story {
      width: 216px;
      margin-right: 15px;
    }
    .socialMenu {
      width: 26%;
      padding: 10px 0 0 0;
    }
    .edit-header .site-branding {
      width: 36%;
    }
    .site-branding img {
      width: 85%;
    }
    .btn-login {
      width: 100px;
    }
    .btn-get-started {
      width: 100px;
    }
    .thankyou-box {
      width: 70%;
    }
    .LogiSection {
      width: 38%;
      float: left;
      padding: 0px 0 0 0;
    }
  }

  @media screen and (max-width: 767px) {
    .menu-top-menu-container ul li {
      display: inline-block;
      padding: 15px 0 !important;
      width: 100%;
      font-size: 20px !important;
      border-top: 1px solid #f1f1f1;
    }
    .menu-item-has-children:after {
      content: "";
      width: 10px;
      height: 20px;
      background-image: url(http://192.168.1.116/suburbanjungle/wp-content/uploads/2018/03/menu-dropdown.png);
      position: absolute;
      right: auto;
      top: 30px;
      background-repeat: no-repeat;
      margin-left: 15px;
    }
    .LogiSection {
      width: 100%;
      padding: 0px 0 0 0;
      text-align: center;
    }
    .thankyou-box {
      width: 90%;
      padding: 50px 30px 50px 30px;
      float: left;
      margin: 0 5%;
    }
    .thankyou-left {
      width: 100%;
      margin-bottom: 12px;
    }
    .thankyou-right {
      float: left;
      width: 100%;
    }
    .btn-thankyou {
      margin-right: 0;
    }
    .DefaultBtn-thankyou {
      margin-left: 0;
    }
    .socialMenu {
      width: 100%;
      padding: 0 0 10px 0;
      text-align: center;
    }
    .menu-item-has-children:after {
      content: "";
      width: 10px;
      height: 20px;
      background-image: url("/../../menu-dropdown.png");
      position: absolute;
      right: auto !important;
      top: 30px !important;
      background-repeat: no-repeat;
      margin-left: 15px;
    }
    .site-branding img {
      width: 60%;
      padding-top: 5px;
    }
    .edit-header {
      background: #fff;
      border: none;
      padding: 30px 0 0 0;
      margin: 0;
      z-index: 9999;
    }
    .menu-top-menu-container {
      height: 100vh;
      background: #fff;
      z-index: 999999;
      position: relative;
    }
    .menu-top-menu-container ul li {
      display: inline-block;
      padding: 15px 0;
      width: 100%;
      font-size: 20px;
      border-top: 1px solid #f1f1f1;
    }
    .menu-top-menu-container ul li ul {
      margin-left: 0;
    }
    .menu-top-menu-container li .sub-menu li {
      display: block;
      padding: 15px 0 15px 0;
      float: left;
      width: 100%;
      text-align: center;
      font-size: 20px;
    }
    .menu-top-menu-container ul li:hover .sub-menu {
      display: block;
      position: absolute;
      float: left;
      height: auto;
      text-align: center;
      margin: 0;
      padding: 15px 0 15px 0;
      background: #fff;
      width: 100%;
      z-index: 999;
    }
    .bg-padd {
      padding: 50px 25px 50px 25px;
    }
    .header-img {
      padding: 100px 0;
    }
    .btn-default-story {
      width: 100%;
      margin-bottom: 15px;
    }
    .btn-bord-story {
      width: 100%;
      margin-bottom: 50px;
    }
    .edit-header .navbar-header {
      width: 100%;
      margin: 0;
      padding-bottom: 25px;
    }
    .socialMenu {
      width: 100%;
      padding: 0 0 10px 0;
      text-align: center;
    }
    .edit-header .site-branding {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }
    .navbar-toggle {
      position: absolute;
      top: 0;
      right: 0;
    }
    .nav-menu-style {
      position: absolute;
      top: 55px;
      background: rgb(255, 255, 255);
      left: 0px;
      right: 0px;
      border: none;
    }
    .menu ul li {
      display: inline-block;
      font-size: 14px;
      padding-right: 0px;
      width: 100%;
      padding: 15px 0 0 0;
    }
    #custom_html-2, #custom_html-3, #custom_html-4, #text-3, #custom_html-5 {
      width: 100%;
      float: left;
    }
    .widget_customclass2 {
      width: 100%;
    }
    .more-padd {
      padding: 80px 0;
    }
    .footer-a, .footer-b, .footer-c, .footer-d {
      width: 100%;
    }
    .footer-c {
      display: none;
    }
    .navbar-default .navbar-toggle .icon-bar {
      background-color: $primaryColor !important;
    }
  }
  /*media close*/
}
