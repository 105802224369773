@import '../../../theme/variables.scss';
.administration__mob__nav__list {
  > li {
    font-family: $Pangram;
    font-size: 16px;
    display: block;
    padding: 7px 0;
    font-weight: 600;
    > a {
      color: $primaryColor;
    }
    &.active {
      > a {
        color: #c7ce4a;
      }
    }
  }
}
.text__transform {
  text-transform: capitalize;
}
