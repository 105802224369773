@import '/../../theme/variables.scss';
.ReactModal__Overlay--after-open {
  z-index: 9999 !important;
}
.update__logo{
  height: 40px;
}
.navbarheaderform {
  width: calc(100% - 40px) !important;
  .costom__search--report {
    &:focus {
      outline: 0;
      border: 0;
      box-shadow: none;
    }
  }
}
.start_stop_button{
  margin-right: 15px;
}
.newprofile__header--wrapper.withstop__btn {
  .start__btn--table {
    padding: 12px 20px !important;
      @media #{$phone767}{
            position: absolute;
            top: 48px;
            z-index: 9;
      }
  }
  .play__btn--table {
      padding: 12px 20px !important;
      @media #{$phone767}{
            position: absolute;
            top: 48px;
            z-index: 9;
      }
    }
    .new__view--townreport {
      @media #{$phone767}{
            position: absolute;
            top: 48px;
            right: 0px;
            z-index: 9;
      }
    }
}
.withstop__btn {
  .newprofile__header {
    width: calc(100% - 430px) !important;
    @media #{$phone767}{
        width: 100% !important;
   }
  }
}
.townreporttopmarg {
  margin-top: 100px !important;
}
.filter__container--users.townreportgap {
  @media #{$tablet768}{
    float: left;
    width: 100%;
   margin-bottom: 15px !important;
   }
}
.filtersviewtown {
  @media #{$phone767}{
        width: calc(100% - 15px) !important;
   }
}
.scrollhoriz {
  @media #{$tablet980}{
    overflow-x: auto !important;
    white-space: pre !important;
    max-width: 100% !important;
    width: 100% !important;
   }
}
.newprofile__close--townreo {
  @media #{$tablet768}{
    float: right;
    text-align: right;
   }
   a {
    margin-right: 15px !important;
   }
}
.rempadleft {
  @media #{$tablet768}{
    padding-left: 0 !important;
   }
   @media #{$phone767}{
    font-size: 14px !important;
    line-height: 18px !important;
   }
}
.generatereport__mobbtn {
  @media #{$phone767}{
    background: #C3CF21;
    position: fixed;
    bottom: -15px;
    border-radius: 0 !important;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 60px !important;
    line-height: 60px !important;
 }
}
.sel-outer-townreport {
  top: 56px !important;
  width: 100% !important;
  @media #{$phone767}{
    width: calc(100% - 15px) !important;
  }
}
.rem-flexmargtab {
 @media #{$tablet768}{
  margin-bottom: 0 !important;
 }
}
.custom__all--filters {
  width: 260px;
  max-width: 250px;
  height: 54px;
  line-height: 54px;
  padding: 0 20px;
  border: 0;
  border-right: 1px solid #E6EBF0;
  font-size: 15px;
  font-weight: 600;
  color: #787878;
  letter-spacing: 0.44px;
  border-radius: 0;
  background-color: #fff;
  background-image: url(/img/custom-select-arrow.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  cursor: pointer;
  position: relative;
  @media #{$desktop1199}{
    width: 210px;
    max-width: 210px;
 }
  @media #{$desktop1024}{
    width: 205px;
    max-width: 205px;
 }
  @media #{$tablet980}{
    width: calc(100% - 20px);
    max-width: 100%;
    border: 2px solid #e5e5e5 !important;
    border-radius: 4px !important;
    height: 60px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  @media #{$tablet768}{
    width: 100%;
    max-width: 100%;
    border: 2px solid #e5e5e5 !important;
    border-radius: 4px !important;
    height: 60px;
    line-height: 60px;
    margin-bottom: 10px;
    &::after {
      content: '';
      height: 58px;
      width: 4px;
      background: #C7CE4A;
      position: absolute;
      left: -2px;
      top: -2px;
      bottom: 0;
      border-radius: 4px 0 0 4px;
    }
  }
}

.filterby__dropdown {
    border: 2px solid #e5e5e5 !important;
    border-radius: 4px !important;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: $primaryColor;
    padding: 18px 17px 18px 17px;
    -webkit-appearance: none;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: calc(100% - 15px);
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    cursor: pointer;
    width: 100% !important;
    position: relative;
    max-width: 100% !important;
  &::after {
    content: '';
    height: 58px;
    width: 4px;
    background: #C7CE4A;
    position: absolute;
    left: -2px;
    top: -2px;
    bottom: 0;
    border-radius: 4px 0 0 4px;
  }
}

  // #placeSearchBoxField {
  //   position: relative;
  //   &:focus {
  //     outline: 0;
  //     &::before {
  //       content: "";
  //       border-bottom: 2px solid #C7CE4A !important;
  //       width: 100%;
  //       position: absolute;
  //       left: 0;
  //       right: 0;
  //       bottom: 8px;
  //       z-index: 99;
  //     }
  //   }
  // }
  .profile__header--wrappertown {
    padding: 0 !important;
  }
.newprofile__header--wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  .newprofile__header {
    width: calc(100% - 245px);
    .reportlogo {
      @media #{$phone767}{
        display: none !important;
       }
    }
    h4 {
      display: inline-block;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #72777A;
      padding-left: 20px;
      @media #{$phone767}{
        padding-left: 0;
       }
      span {
        margin-right: 10px;
        @media #{$phone767}{
          display: none;
         }
      }
    }
  }
  .newprofile__close {
    width: 245px;
    .new__view--townreport {
      background: #C6CE4A;
      border-radius: 6px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: normal;
      text-align: center;
      color: #FFFFFF;
      padding: 10px 18px;
      margin-right: 47px;
    }
  }
}
  .townrep__para {
    font-size: 14px;
    margin-top: 45px;
}
  .close-btn__townreport {
    margin: 6px 0 0 !important;
  }
  .for__text__break{
    white-space: pre-line;
  }

  .picker-dialog {
    z-index: 9999 !important;
  }

.add__rotate{
  margin-right: 60px;
}
.top__space{
  margin-top: 18px;
}

.common__close--right {
  cursor: pointer;
  float: right;
}
.no__data--show {
  text-align: center;
  width: 100%;
  float: left;
  padding: 100px 0 0 0;
  font-size: 18px;
  font-weight: 600;
}

.common__close{
  cursor: pointer;
}
.update__town__btn{
  padding-top: 10px;
  padding-bottom: 10px;
}
.town__name{
    color: #c8ce4e;
    text-decoration: underline;
    &:focus, &:active, &:hover{
      color: #c8ce4e;
      text-decoration: underline;
    }
}
.padding-left-117 {
  padding-left: 117px;
}
.suburb-filter {
  // padding: 12px;
   padding: 7px;
  .custom-checkbox {
    margin-bottom: 7px;
  }
}
.missing-filter {
  height: 75px !important;
}
.search-placebox {
  margin-top: 25px;
  margin-bottom: 50px  !important;
}

.create-cut-fil {
  line-height: 50px !important;
  height: 50px !important;
      width: 100%;
    max-width: 100% !important;
}
.new__del--icon {
  margin-left: 5px;
  img {
    width: 22px;
  }
}
.right_align {
  text-align: right;

}
.extra__marg {
    padding-left: 60px !important;
}
.tableextra__marg {
  padding-left: 60px !important;
  @media #{$tablet768}{
    padding-left: 30px !important;
   }
   @media #{$phone767}{
    padding-left: 15px !important;
   }
}
.btn__profile--mob {
  @media #{$tablet768}{
    margin-right: 0 !important;
    padding: 4px 6px !important;
    width: auto;
    min-width: 40px !important;
   }
}
.hideDesk {
  display: none;
  @media #{$phone767}{
    display: block;
   }
}
.hidemob {
  @media #{$phone767}{
    display: none;
   }
}
.Upload_txt {
  color: #C7CE4A;
  font-size: 15px;
  line-height: normal;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
    span {
      color: #20415C;
      text-decoration: none !important;
      display: inline-block;
    }
}

.tabs__town--reports {
  margin-top: 48px;
  .nav.nav-pills {
  }
}
// .tabs__town--reports .nav-pills>li a.active {
//   color: #fff;
//   background: none;
//   border-bottom: 2px solid #c7cd4a;
//   border-radius: 0;
//   color: #c7cd4a;
//   padding-bottom: 20px !important;
// }
.tabs__town--reports .nav-pills>li.active>a,
.tabs__town--reports .nav-pills>li.active>a:focus,
.tabs__town--reports .nav-pills>li.active>a:hover {
  color: #fff;
  background: none;
  border-bottom: 2px solid #c7cd4a;
  border-radius: 0;
  color: #c7cd4a;
  padding-bottom: 20px !important;
}
.nav-pills>li>a {
  color: #c7cd4a;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  padding: 10px 0 !important;
  margin: 0 15px !important;
  &:hover {
    background: none;
  }
}
.school__table--tabs {
  border-top: 1px solid #E6EBF0;
  th {
    font-size: 13px;
    font-weight: 600;
    color: #72777A;
    line-height: 19px;
    padding: 10px 18px !important;
    vertical-align: middle !important;
    border: 0 !important;
  }
  td {
    font-size: 13px;
    font-weight: normal;
    color: #71777B;
    padding: 18px 18px !important;
    vertical-align: middle !important;
    border: 0 !important;
  }
  .edit__btn {
    border: none;
    background: none;
    outline: none;
    img {
      width: 21px;
      margin-right: 15px;
    }
    &:last-child {
      img {
        margin-right: 0;
    }
    }
  }
}
.table-striped>tbody>tr:nth-of-type(odd) {
    background: rgba(236, 237, 232, 0.4);
  }


.loader-main {
  width: 70px;
  position: absolute;
  top: 120%;
  left: 80%;
  transform: translate(-50%,-50%);
}
.hide {
  display: none !important;
}
.save-loader {
  width: 70px;
  position: absolute;
  top: 108%;
  left: 86%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.image-loader {
  width: 70px;
  position: absolute;
  top: 50px;
  left: 36%;
  transform: translate(-50%,-50%);
}
.wordpress-btn {
  position: relative;
  float: left;
  width: 100%;
}
.repush-loader {
    width: 70px;
    position: absolute;
    top: 0;
    right: 65px;
}
.push-loader {
  width: 70px;
  position: absolute;
  top: 100%;
  left: 80%;
  transform: translate(-50%, -50%);
}
.attom_processed_text {
  color: #ea0000 !important;
}
.attom_processed_text_yes {
  color: #C7CE4A !important;
}
.custom_search {
    background-image: url(/img/search-icon.svg) !important;
    background-position: 22px 17px !important;
    background-repeat: no-repeat;
    padding: 13px 20px 13px 61px !important;
    box-shadow: 0 2px 20px 5px rgba(31, 31, 31, 0.08) !important;
    border-radius: 6px !important;
    margin: 0 0 40px 0!important;
    height: 50px !important;
    border: 0px solid #ddd !important;
    font-family: 'Source Sans Pro';
    font-size: 14px;
}

.town-report input[type="text"] {
  padding: 13px 20px 13px 61px !important;
  z-index: 99;
  &:focus {
    padding: 0 20px 3px 61px !important;
  }
}

.town-report {
  .town-table {
    margin: 0 0 0 0;
  }
  .town-button {
    background-color: #C7CE4A;
    color: white;
    padding: 0 14px;
    height: 50px;
    line-height: 50px;
    border: 2px solid #C7CE4A;
    border-radius: 5px;
    margin: 0px 0px 15px;
    font-size: 15px;
    width: 197px;
    height: 55px;
    line-height: 55px;
    text-align: center;
  }
  .dashed-border {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 0;
    border: 2px dashed #AAAEB8;
    text-align: center;
    padding: 35px 0;
    .green_text {
      color: #C7CE4A;
    }
    .drag-box {
      margin: 14px;
      color: #20415C;
    }
  }
  .edit-image-save {
    position:relative;
    top:120px;
  }
  .save-town-button {
    background-color: #C7CE4A;
    color: white;
    padding: 0 14px;
    height: 55px;
    line-height: 50px;
    border: 2px solid #C7CE4A;
    border-radius: 5px;
    margin: 0px 0px 15px;
    width: 197px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .update_images {
    font-size: 20px;
    color: #3B3B3B;
  }
  .update_para {
    font-size: 15px;
    margin-top: 13px;
    color: #73777A;
  }
  .cross-image {
    width: 20px;
    margin-top: 100px;
    margin-bottom: -129px;
    margin-left: 90%;
  }
  .town-img-outer:focus {
    outline: 0;
  }
  .town-images {
    width: 160px;
    height: 100px;
    margin-bottom: 13px;
    border-radius: 8px;
  }
  .town-images img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 7px;
  }
  .town-image-select {
    width: 60%;
    margin-bottom: 0;
    border: 4px solid #c7ce4a;
    border-radius: 8px;
    position: relative;
    padding-top: 34%;
    display: inline-block;
  }
  .town-image-select img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .town-marks {
    width: 20px;
    margin-right: 28px;
    margin-bottom: 15px;
  }
  .slick-prev, .slick-next {
    top: 35%;
  }
  .listing_txt {
    font-size: 15px;
    line-height: normal;
    position: relative;
    bottom: 5px;
  }
  .featured_img {
    width: 50%;
    float: left;
    color: #004361;
    font-weight: 500;
    font-size: 15px;
  }
  .remove_img {
    width: 50%;
    float: right;
    text-align: right;
    color: #D0021B;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
  }
  .data_checklist {
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 500;
  }
  .town-button-red {
    background-color: white;
    color: #ea0000;
    padding: 0;
    border: 2px solid #ea0000;
    border-radius: 5px;
    margin: 0px 21px 25px 0px;
    height: 50px;
    line-height: 50px;
    width: 147px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .table-town-button {
    background-color: white;
    color: black;
    padding: 7px;
    border: 2px solid #4caf50;
    border-radius: 5px;
    margin: 0px 0px 15px;
  }
  .create-town-button {
    background-color: #C7CE4A;
    color: white;
    height: auto;
    line-height: normal;
    width: auto;
    border: 0px solid #C7CE4A;
    border-radius: 4px;
    margin-right: 25px;
    float: right;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 30px;
    @media #{$phone767}{
      margin-left: 15px;
      margin-right: 15px;
      margin-top: 15px;
      width: calc(100% - 15px);
    }
  }
  .paging-button {
    color: white;
    height: 40px;
    line-height: 40px;
    width: auto;
    padding: 0 15px;
    margin-right: 25px;
    float: right;
    @media #{$tablet768}{
      margin-right: 0;
    }
  }
}
